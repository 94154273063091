import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShopify, faWordpress } from "@fortawesome/free-brands-svg-icons";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  faClose,
  faDownload,
  faL,
  faTag,
  faUpload,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { CSVDownload, CSVLink } from "react-csv";
import Footer from "../components/Footer";
import Cookies from "js-cookie";
import { Modal } from "react-bootstrap";

export default function Discounts() {
  //Navigator
  const navigate = useNavigate();
  //Variáveis step 1
  const [aplicarDesconto, setAplicarDesconto] = useState(false);
  const [reverterDesconto, setReverterDesconto] = useState(false);

  //Variáveis step 2
  const [lojaEscolhida, setLojaEscolhida] = useState("");
  const [lojaEscolhidaS, setLojaEscolhidaS] = useState(false);
  const [lojaEscolhidaW, setLojaEscolhidaW] = useState(false);

  // Variáveis step 3
  const [ficheiroAtualizar, setFicheiroAtualizar] = useState([]);
  const [ficheiroFornecedor, setFicheiroFornecedor] = useState([]);
  const [nomeFicheiroAtualizar, setNomeFicheiroAtualizar] = useState("");
  const [nomeFicheiroFornecedor, setNomeFicheiroFornecedor] = useState("");

  //Variáveis step 4
  const [erroRetiraValor, setErroRetiraValor] = useState(false);
  const [percentagemRetirar, setPercentagemRetirar] = useState("");
  const [percentagemRetirarStatus, setPercentagemRetirarStatus] =
    useState(false);

  //Variável step 5
  const [csvData, setCsvData] = useState([]);
  const [csvFilename, setCsvFilename] = useState("");

  const [freeze, setFreeze] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  //Traduções
  const { t, i18n } = useTranslation();

  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const miliseconds = date.getMilliseconds();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  var data_ = 0;

  let monthC = month;
  if (month < 10) {
    monthC = "0" + month;
  }
  let day = date.getDate();
  if (date.getDate() < 10) {
    day = "0" + date.getDate();
  }
  data_ = `${day}-${monthC}-${year}`;

  const steps =
    lojaEscolhidaW && reverterDesconto
      ? [t("step1d"), t("step1"), t("carregarF"), t("step6")]
      : [t("step1d"), t("step1"), t("carregarF"), t("desconto"), t("step6")];

  const options = [t("percentagem"), t("numerico")];
  const optionsIva = ["23 %", "13 %", "6 %"];

  useEffect(() => {
    if (csvData.length != 0) {
      setFreeze(false);
    }
  }, [csvData]);

  useEffect(() => {
    const id = localStorage.getItem("id");
    const token = Cookies.get("token");

    if (!id && !token) {
      navigate("/");
    }
  }, []);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    if (activeStep == 2 && lojaEscolhidaW && reverterDesconto) {
      setActiveStep(4);
    } else {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    if (activeStep == 4 && lojaEscolhidaW && reverterDesconto) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  function verificacao() {
    //Step 1
    if (activeStep == 0) {
      if (!aplicarDesconto && !reverterDesconto) {
        toast.error(t("selecionarDesconto"));
      } else {
        handleComplete();
      }
    }
    //Step 2
    else if (activeStep == 1) {
      if (!lojaEscolhida) {
        toast.error(t("erroLoja"));
      } else {
        handleComplete();
      }
    }
    // Step 3
    else if (activeStep == 2) {
      if (ficheiroAtualizar.length == 0) {
        toast.error(t("erroFicheiroAtualizar"));
      } else {
        handleComplete();
      }
    }
    //Step 4
    else if (activeStep == 3) {
      if (percentagemRetirar && percentagemRetirar < 100) {
        handleComplete();
      } else {
        if (!percentagemRetirar) {
          setErroRetiraValor(true);
          toast.error(t("percentagemValida"));
        } else {
          setErroRetiraValor(true);
          toast.error(t("percentagemErro"));
        }
      }
    }
  }

  function ficheiroAtualizarVerificacao(file) {
    let teste = file.name.split(".");
    setNomeFicheiroAtualizar(file.name);
    if (teste[1] == "tsv" || teste[1] == "csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setFicheiroAtualizar(results.data);
        },
      });
    } else if (teste[1] == "xlsx") {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 0 });

        setFicheiroAtualizar(dataParse);
      };
      reader.readAsBinaryString(file);
    }
  }

  function criarFicheiro() {
    //Array final
    let arrayFinal = [];
    var percentagemFinal = percentagemRetirar / 100;
    if (aplicarDesconto) {
      //Shopify
      if (lojaEscolhidaS) {
        ficheiroAtualizar.forEach((element) => {
          if (element["Handle"].replaceAll(",", "") != "") {
            const linha = {
              Handle: element["Handle"].replaceAll(",", ""),
              Title: element["Title"].replaceAll(",", ""),
              //"Body (HTML)": element["Body (HTML)"].replaceAll(',', ''),
              Vendor: element["Vendor"],
              "Product Category": element["Product Category"],
              Type: element["Type"].replaceAll(",", ""),
              Tags: element["Tags"].replaceAll(",", ""),
              Published: element["Published"],
              "Option1 Name": element["Option1 Name"],
              "Option1 Value": element["Option1 Value"],
              "Option2 Name": element["Option2 Name"],
              "Option2 Value": element["Option2 Value"],
              "Option3 Name": element["Option3 Name"],
              "Option3 Value": element["Option3 Value"],
              "Variant SKU": element["Variant SKU"],
              "Variant Grams": element["Variant Grams"],
              "Variant Inventory Tracker": element["Variant Inventory Tracker"],
              "Variant Inventory Qty": element["Variant Inventory Qty"],
              "Variant Inventory Policy": element["Variant Inventory Policy"],
              "Variant Fulfillment Service":
                element["Variant Fulfillment Service"],
              "Variant Price": (
                parseFloat(element["Variant Price"]) -
                parseFloat(element["Variant Price"]) * percentagemFinal
              ).toFixed(2),
              "Variant Compare At Price": parseFloat(element["Variant Price"]),
              "Variant Requires Shipping": element["Variant Requires Shipping"],
              "Variant Taxable": element["Variant Taxable"],
              "Variant Barcode": element["Variant Barcode"],
              "Image Src": element["Image Src"],
              "Image Position": element["Image Position"],
              "Image Alt Text": element["Image Alt Text"].replaceAll(",", ""),
              "Gift Card": element["Gift Card"],
              "SEO Title": element["SEO Title"].replaceAll(",", ""),
              "SEO Description": element["SEO Description"].replaceAll(",", ""),
              "Google Shopping / Google Product Category": element[
                "Google Shopping / Google Product Category"
              ].replaceAll(",", ""),
              "Google Shopping / Gender": element[
                "Google Shopping / Gender"
              ].replaceAll(",", ""),
              "Google Shopping / Age Group": element[
                "Google Shopping / Age Group"
              ].replaceAll(",", ""),
              "Google Shopping / MPN": element[
                "Google Shopping / MPN"
              ].replaceAll(",", ""),
              "Google Shopping / AdWords Grouping": element[
                "Google Shopping / AdWords Grouping"
              ].replaceAll(",", ""),
              "Google Shopping / AdWords Labels": element[
                "Google Shopping / AdWords Labels"
              ].replaceAll(",", ""),
              "Google Shopping / Condition": element[
                "Google Shopping / Condition"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 0": element[
                "Google Shopping / Custom Label 0"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 1": element[
                "Google Shopping / Custom Label 1"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 2": element[
                "Google Shopping / Custom Label 2"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 3": element[
                "Google Shopping / Custom Label 3"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 4": element[
                "Google Shopping / Custom Label 4"
              ].replaceAll(",", ""),
              "Variant Image": element["Variant Image"],
              "Variant Weight Unit": element["Variant Weight Unit"],
              "Variant Tax Code": element["Variant Tax Code"],
              "Cost per item": element["Cost per item"],
              "Price / Internacional": element["Price / Internacional"],
              "Compare At Price / Internacional":
                element["Compare At Price / Internacional"],
              Status: element["Status"],
            };
            arrayFinal.push(linha);
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "descontos_atualizados_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Wordpress
      else if (lojaEscolhidaW) {
        ficheiroAtualizar.forEach((element) => {
          if (element["REF"] != "") {
            const linha = {
              // ID: element["ID"],
              REF: element["REF"],
              "Preço normal": element["Preço normal"],
              "Preço promocional":
                element["Preço normal"] == ""
                  ? ""
                  : (
                      parseFloat(element["Preço normal"].replace(",", ".")) -
                      parseFloat(element["Preço normal"].replace(",", ".")) *
                        percentagemFinal
                    )
                      .toFixed(2)
                      .toLocaleString("pt-PT", {
                        style: "currency",
                        currency: "EUR",
                      })
                      .replace(".", ","),
              // Publicado: element["Publicado"],
              // Stock: element["Stock"],
              // "Em stock?": element["Em stock?"],
              // "Atributo 1 nome": element["Atributo 1 nome"],
              // "Atributo 1 valor(es)": element["Atributo 1 valor(es)"],
              // "Atributo 1 global": element["Atributo 1 global"],
              // "Atributo 2 nome": element["Atributo 2 nome"],
              // "Atributo 2 valor(es)": element["Atributo 2 valor(es)"],
              // "Atributo 2 global": element["Atributo 2 global"],
              // "Atributo 3 nome": element["Atributo 3 nome"],
              // "Atributo 3 valor(es)": element["Atributo 3 valor(es)"],
              // "Atributo 3 global": element["Atributo 3 global"],
            };
            arrayFinal.push(linha);
          }
        });

        let arrayFiltrado = [];

        arrayFinal.forEach((element) => {
          if (
            element["Preço normal"] != "" &&
            element["Preço promocional"] != ""
          ) {
            arrayFiltrado.push(element);
          }
        });

        //Verificar que o array não está vazio
        if (arrayFiltrado.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "descontos_atualizados_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFiltrado);
        } else {
          toast.error(t("erro"));
        }
      }
    } else if (reverterDesconto) {
      //Shopify
      if (lojaEscolhidaS) {
        ficheiroAtualizar.forEach((element) => {
          if (element["Handle"].replaceAll(",", "") != "") {
            const linha = {
              Handle: element["Handle"].replaceAll(",", ""),
              Title: element["Title"].replaceAll(",", ""),
              //"Body (HTML)": element["Body (HTML)"].replaceAll(',', ''),
              Vendor: element["Vendor"],
              "Product Category": element["Product Category"],
              Type: element["Type"].replaceAll(",", ""),
              Tags: element["Tags"].replaceAll(",", ""),
              Published: element["Published"],
              "Option1 Name": element["Option1 Name"],
              "Option1 Value": element["Option1 Value"],
              "Option2 Name": element["Option2 Name"],
              "Option2 Value": element["Option2 Value"],
              "Option3 Name": element["Option3 Name"],
              "Option3 Value": element["Option3 Value"],
              "Variant SKU": element["Variant SKU"],
              "Variant Grams": element["Variant Grams"],
              "Variant Inventory Tracker": element["Variant Inventory Tracker"],
              "Variant Inventory Qty": element["Variant Inventory Qty"],
              "Variant Inventory Policy": element["Variant Inventory Policy"],
              "Variant Fulfillment Service":
                element["Variant Fulfillment Service"],
              "Variant Price": (
                parseFloat(element["Variant Price"]) +
                parseFloat(element["Variant Compare At Price"]) *
                  percentagemFinal
              ).toFixed(2),
              "Variant Compare At Price": "",
              "Variant Requires Shipping": element["Variant Requires Shipping"],
              "Variant Taxable": element["Variant Taxable"],
              "Variant Barcode": element["Variant Barcode"],
              "Image Src": element["Image Src"],
              "Image Position": element["Image Position"],
              "Image Alt Text": element["Image Alt Text"].replaceAll(",", ""),
              "Gift Card": element["Gift Card"],
              "SEO Title": element["SEO Title"].replaceAll(",", ""),
              "SEO Description": element["SEO Description"].replaceAll(",", ""),
              "Google Shopping / Google Product Category": element[
                "Google Shopping / Google Product Category"
              ].replaceAll(",", ""),
              "Google Shopping / Gender": element[
                "Google Shopping / Gender"
              ].replaceAll(",", ""),
              "Google Shopping / Age Group": element[
                "Google Shopping / Age Group"
              ].replaceAll(",", ""),
              "Google Shopping / MPN": element[
                "Google Shopping / MPN"
              ].replaceAll(",", ""),
              "Google Shopping / AdWords Grouping": element[
                "Google Shopping / AdWords Grouping"
              ].replaceAll(",", ""),
              "Google Shopping / AdWords Labels": element[
                "Google Shopping / AdWords Labels"
              ].replaceAll(",", ""),
              "Google Shopping / Condition": element[
                "Google Shopping / Condition"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 0": element[
                "Google Shopping / Custom Label 0"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 1": element[
                "Google Shopping / Custom Label 1"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 2": element[
                "Google Shopping / Custom Label 2"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 3": element[
                "Google Shopping / Custom Label 3"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 4": element[
                "Google Shopping / Custom Label 4"
              ].replaceAll(",", ""),
              "Variant Image": element["Variant Image"],
              "Variant Weight Unit": element["Variant Weight Unit"],
              "Variant Tax Code": element["Variant Tax Code"],
              "Cost per item": element["Cost per item"],
              "Price / Internacional": element["Price / Internacional"],
              "Compare At Price / Internacional":
                element["Compare At Price / Internacional"],
              Status: element["Status"],
            };
            arrayFinal.push(linha);
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "descontos_atualizados_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Wordpress
      else if (lojaEscolhidaW) {
        ficheiroAtualizar.forEach((element) => {
          if (
            element["REF"] != "" &&
            element["Preço promocional"] != "" &&
            element["Preço normal"] != ""
          ) {
            const linha = {
              // ID: element["ID"],
              // Tipo: element["Tipo"],
              REF: element["REF"],
              // "Preço normal": element["Preço normal"],
              "Preço promocional": "",
              // Publicado: element["Publicado"],
              // Pai: element["Pai"],
              // "Atributo 1 nome": element["Atributo 1 nome"],
              // "Atributo 1 valor(es)": element["Atributo 1 valor(es)"],
              // "Atributo 1 global": element["Atributo 1 global"],
            };
            arrayFinal.push(linha);
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "descontos_atualizados_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
    }
  }

  return (
    <div className="priceupdate">
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguardaCSV")}
              </Typography>
              <CircularProgress style={{ marginTop: "20px" }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <Box
        sx={{
          width: "80%",
          marginTop: "0px",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Button
          color="warning"
          variant="contained"
          style={{ float: "right" }}
          onClick={() => {
            setAplicarDesconto(false);
            setReverterDesconto(false);
            setLojaEscolhida("");
            setLojaEscolhidaS(false);
            setLojaEscolhidaW(false);
            setFicheiroAtualizar([]);
            setFicheiroFornecedor([]);
            setNomeFicheiroAtualizar("");
            setNomeFicheiroFornecedor("");
            setErroRetiraValor(false);
            setPercentagemRetirar("");
            setPercentagemRetirarStatus(false);
            setCsvData([]);
            setCsvFilename("");
            setActiveStep(0);
            setCompleted({});
          }}
          sx={{ mr: 1 }}
        >
          {t("recomercar")}
        </Button>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "50px",
            fontWeight: "bold",
            fontSize: "30px",
          }}
        >
          {t("descontos")}
        </h2>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                onClick={completed[3] ? handleStep(index) : null}
                style={{ boxShadow: "none" }}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {/* Step 1 */}
        {activeStep == 0 ? (
          <div
            style={{
              width: "80vw",
              height: "55vh",
              marginTop: "20px",
            }}
          >
            <div class="container2">
              {/* Aplicar desconto */}
              {!reverterDesconto && (
                <div
                  class="card_d card7"
                  onClick={() => {
                    setAplicarDesconto(true);
                    setReverterDesconto(false);
                  }}
                >
                  <h3 className="tituloCardsD">
                    {t("discountd")}
                    <span>
                      <p style={{ fontSize: 14, marginTop: 40 }}>
                        {t("descontoA")}
                      </p>
                    </span>
                  </h3>
                </div>
              )}
              {/* Reverter desconto */}
              {!aplicarDesconto && (
                <div
                  class="card_d card8"
                  onClick={() => {
                    setAplicarDesconto(false);
                    setReverterDesconto(true);
                  }}
                >
                  <h3 className="tituloCardsD">
                    {t("reverterd")}
                    <span>
                      <p style={{ fontSize: 14, marginTop: 40 }}>
                        {t("descontoR")}
                      </p>
                    </span>
                  </h3>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {/* Step 2 */}
        {activeStep == 1 ? (
          <div
            style={{
              width: "80vw",
              height: "55vh",
              marginTop: "20px",
            }}
          >
            <div class="container2">
              {/* Shopify */}
              {!lojaEscolhidaW && (
                <div
                  class="card_ card4"
                  onClick={() => {
                    setLojaEscolhida("shopify");
                    setLojaEscolhidaS(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="priceupdateIcon"
                    icon={faShopify}
                  />
                </div>
              )}
              {/* Wordpress */}
              {!lojaEscolhidaS && (
                <div
                  class="card_ card5"
                  onClick={() => {
                    setLojaEscolhida("wordpress");
                    setLojaEscolhidaW(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="priceupdateIcon"
                    icon={faWordpress}
                  />
                </div>
              )}
            </div>
          </div>
        ) : null}
        {/* Step 3 */}
        {activeStep == 2 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              marginTop: "20px",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <div style={{ float: "left", top: "35%", position: "relative" }}>
              <h4 style={{ textAlign: "center" }}>{t("ficheiroAtualizar")}</h4>
              <div style={{ textAlign: "center", marginTop: "30px" }}>
                <label htmlFor="formId">
                  <input
                    type="file"
                    id="formId"
                    accept=".csv, .xlsx, .tsv"
                    multiple={false}
                    onChange={(e) => {
                      setCompleted({ 0: true, 1: true });
                      ficheiroAtualizarVerificacao(e.target.files[0]);
                    }}
                    hidden
                  />
                  <FontAwesomeIcon
                    icon={faUpload}
                    style={{
                      color: nomeFicheiroAtualizar ? "#228B22" : "#343434",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                </label>
                <p style={{ paddingTop: "20px" }}>
                  {ficheiroAtualizar.length == 0
                    ? t("selecioneFicheiro")
                    : nomeFicheiroAtualizar}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {/* Step 4 */}
        {activeStep == 3 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              marginTop: "20px",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <div style={{ float: "left", top: "35%", position: "relative" }}>
              <h4 style={{ textAlign: "center" }}>{t("desconto")}</h4>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                }}
              >
                <TextField
                  required
                  value={percentagemRetirar}
                  error={erroRetiraValor}
                  style={{ width: "100%" }}
                  id="standard-basic"
                  type="number"
                  label={t("percentagemaretirar")}
                  InputProps={{
                    startAdornment: percentagemRetirarStatus ? (
                      <span />
                    ) : (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                    endAdornment: percentagemRetirarStatus ? (
                      <InputAdornment position="end">%</InputAdornment>
                    ) : (
                      <span />
                    ),
                  }}
                  variant="standard"
                  onChange={(e) => {
                    e.target.value
                      ? setPercentagemRetirarStatus(true)
                      : setPercentagemRetirarStatus(false);
                    setErroRetiraValor(false);
                    setPercentagemRetirar(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        {/* Step 5 */}
        {activeStep == 4 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              marginTop: "15px",
            }}
          >
            <div class="container3">
              <div>
                <div
                  onClick={() => {
                    setActiveStep(0);
                  }}
                >
                  <h4
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("step6")}
                  </h4>
                  <Button
                    className="buttonVerificacao"
                    style={{ textTransform: "capitalize" }}
                  >
                    <span className="resumo">{t("step1d")}</span>
                    {aplicarDesconto ? t("discountd") : t("reverterd")}
                  </Button>
                </div>
                <div
                  onClick={() => {
                    setActiveStep(1);
                  }}
                >
                  <Button
                    className="buttonVerificacao"
                    style={{ textTransform: "capitalize" }}
                  >
                    <span className="resumo">{t("lojaSelecionada")}</span>
                    {lojaEscolhida}
                  </Button>
                </div>
                <div
                  onClick={() => {
                    setActiveStep(2);
                  }}
                >
                  <Button
                    className="buttonVerificacao"
                    style={{ textTransform: "none" }}
                  >
                    <span className="resumo">{t("nomeFicheiroAtualizar")}</span>
                    {nomeFicheiroAtualizar}
                  </Button>
                </div>

                <div
                  onClick={() => {
                    setActiveStep(3);
                  }}
                >
                  <Button
                    className="buttonVerificacao"
                    style={{ textTransform: "capitalize" }}
                  >
                    <span className="resumo">{t("desconto")}:</span>
                    {percentagemRetirar} %
                  </Button>
                </div>
              </div>
              {/* Botão para download do csv */}
              <CSVLink
                data={csvData}
                filename={csvFilename}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button
                  class="card3 card6"
                  onClick={() => {
                    criarFicheiro();
                  }}
                >
                  <FontAwesomeIcon className="csv" icon={faDownload} />
                </Button>
                <p className="csvText">{t("exportar")}</p>
              </CSVLink>
            </div>
          </div>
        ) : null}
        <div>
          {allStepsCompleted() ? null : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1, py: 1 }}></Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                }}
              >
                <Button
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t("anterior")}
                </Button>

                <Box sx={{ flex: "1 1 auto" }} />
                {completed[3] && activeStep == 4 ? null : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      verificacao();
                    }}
                    sx={{ mr: 1 }}
                  >
                    {t("seguinte")}
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
      <Footer />
    </div>
  );
}
