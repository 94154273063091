import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../images/sapi-logo.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
function ChangePassword() {
  const { token, id } = useParams();
  //Variáveis
  const [palavraPasse, setPalavraPasse] = useState("");
  const [palavraPasse2, setPalavraPasse2] = useState("");
  //Validações
 
  const [erroPassword, setErroPassword] = useState(false);
  const [erroPassword2, setErroPassword2] = useState(false);
  const [mensagemErroPalavraPasse, setMensagemErroPalavraPasse] = useState("");
  const [mensagemErroPalavraPasse2, setMensagemErroPalavraPasse2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {}, [palavraPasse,palavraPasse2]);

  useEffect(() => {

  }, [palavraPasse,palavraPasse2]);

  function VerificaLogin() {
    const id = toast.loading(t("autenticacao"));

    setTimeout(() => {
      toast.update(id, {
        render: t("loginSucesso"),
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        limit: 1,
        autoClose: 5000,
        closeOnClick: true,
        closeButton: true,
      });
    }, 3000);


  }

  const CloseButton = ({ closeToast }) => <FontAwesomeIcon icon={faClose} />;

  return (
    <div className="bodyLogin">
       
        <form action="" class="log-in" autocomplete="off">
        <h4>
            <img src={logo} style={{ width: "150px", height: "49px" }} />
            {/* Tectank <span>SAPI</span> */}
          </h4>
          <h5>
            <span>{t("alterarPalavraPasse")}</span>
          </h5>
          <TextField
            className="inputlogin"
            style={{marginBottom:"10px"}}
            id="password"
            label={t("palavraPasse")}
            variant="standard"
            error={erroPassword}
            type={showPassword ? "text" : "password"}
            helperText={mensagemErroPalavraPasse}
            onChange={(e) => {
              setPalavraPasse(e.target.value);
              setErroPassword(false);
              setMensagemErroPalavraPasse("");
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    disableRipple
                    disableFocusRipple
                    style={{
                      justifyContent: "right",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                      color: "#8A8A8A",
                    }}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </Button>
                </InputAdornment>
              ),
            }}
            required={true}
          ></TextField>
                <TextField
            className="inputlogin"
            style={{marginBottom:"10px"}}
            id="password2"
            label={t("palavraPasse2")}
            variant="standard"
            error={erroPassword2}
            type={showPassword2 ? "text" : "password"}
            helperText={mensagemErroPalavraPasse2}
            onChange={(e) => {
              setPalavraPasse2(e.target.value);
              setErroPassword2(false);
              setMensagemErroPalavraPasse2("");
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    disableRipple
                    disableFocusRipple
                    style={{
                      justifyContent: "right",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                      color: "#8A8A8A",
                    }}
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword2}
                  >
                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </Button>
                </InputAdornment>
              ),
            }}
            required={true}
          ></TextField>
          <button
            type="submit"
            className="button"
            onClick={() => {
              
            }}
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderRadius: 50,
            }}
          >
            {t("recuperar")}
          </button>
          <ToastContainer
            closeButton={CloseButton}
            position="top-right"
            autoClose={5000}
            limit={1}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </form>
        <div class="left">

</div>
        <Footer />
    </div>
  );
}
export default ChangePassword;
