export function handleResponse(response) {
  if (response.data) {
    return { success: true, data: response.data };
  }

  return { success: true, data: response.data };
}

export function handleError(error) {
  if (error.response.data) {
    return {
      success: false,
      error: error.response.data.error,
    };
  }

  return { success: false, error: error.response.data.error };
}
