import { makeRequestGet, makeRequestPost } from "../api";

//Login
export function loginPost(dados) {
  return makeRequestPost("authServer/login", dados);
}

//Registo
export function registoPost(dados) {
  return makeRequestPost("authServer/signup", dados);
}

//Email Registo
export function emailRegisto(dados) {
  return makeRequestPost("authServer/active-account", dados);
}
