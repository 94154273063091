import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../images/sapi-logo.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faClose } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";

function RecoverPassword() {
  //Variáveis
  const [email, setEmail] = useState("");

  //Validações
  const [erroEmail, setErroEmail] = useState(false);
  const [erroPassword, setErroPassword] = useState(false);
  const [mensagemErroEmail, setMensagemErroEmail] = useState("");
  const [sucessoEmail, setSucessoEmail] = useState("");

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {}, [email]);

  useEffect(() => {
    if (email) {
      //Validar email - obrigatóriamente da tectank
      let validacaoEmail = /^\"?[\w-_\.]*\"?@tectank\.pt$/.test(email);
      if (validacaoEmail) {
        setErroEmail(false);
        setErroEmail("");
        setMensagemErroEmail("");
        setSucessoEmail("success");
      } else {
        let validacaoFormatoEmail = /\S+@\S+\.\S+/.test(email);

        if (validacaoFormatoEmail) {
          setErroEmail(true);
          setMensagemErroEmail(t("naoExisteConta"));
        } else {
          setErroEmail(true);
          setMensagemErroEmail(t("emailInvalido"));
        }
      }
    }
  }, [email]);

  function VerificaLogin() {
    const id = toast.loading(t("autenticacao"));

    setTimeout(() => {
      toast.update(id, {
        render: t("loginSucesso"),
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        limit: 1,
        autoClose: 5000,
        closeOnClick: true,
        closeButton: true,
      });
    }, 3000);

    // navigate("/home");
    // toast.error("Erro")
    // } else {
    //   if (!email) {
    //     setErroEmail(true);
    //     setMensagemErroEmail("Email obrigatório!");
    //   } else if (!palavraPasse) {
    //   }
  }

  const CloseButton = ({ closeToast }) => <FontAwesomeIcon icon={faClose} />;

  return (
    <div className="bodyLogin">
      
        <form action="" class="log-in" autocomplete="off">
        <h4>
            <img src={logo} style={{ width: "150px", height: "49px" }} />
            {/* Tectank <span>SAPI</span> */}
          </h4>
          <h5>
            <span>{t("recuperarPP")}</span>
          </h5>
          {/* Email */}
          <TextField
            className="inputlogin"
            id="email"
            label={t("email")}
            variant="standard"
            required={true}
            placeholder="sapi@tectank.pt"
            error={erroEmail}
            helperText={mensagemErroEmail}
            color={sucessoEmail}
            onChange={(e) => {
              setEmail(e.target.value.toLowerCase());
            }}
          />
          <button
            type="submit"
            className="button"
            onClick={() => {
              VerificaLogin();
            }}
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderRadius: 50,
            }}
          >
            {t("recuperar")}
          </button>
          <ToastContainer
            closeButton={CloseButton}
            position="top-right"
            autoClose={5000}
            limit={1}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </form>
          <div class="left">
        </div>
        <Footer />
      </div>
    
  );
}
export default RecoverPassword;
