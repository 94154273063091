import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShopify, faWordpress } from "@fortawesome/free-brands-svg-icons";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  faClose,
  faDownload,
  faL,
  faUpload,
  faX,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { CSVDownload, CSVLink } from "react-csv";
import Footer from "../components/Footer";
import Cookies from "js-cookie";
import { Modal } from "react-bootstrap";
import arrayTaxonomiaProdutos from "./taxonomiaProdutosShopify";

export default function ColumnMapping() {
  //Navigator
  const navigate = useNavigate();
  //Variáveis step 1
  const [lojaEscolhida, setLojaEscolhida] = useState("");
  const [lojaEscolhidaS, setLojaEscolhidaS] = useState(false);
  const [lojaEscolhidaW, setLojaEscolhidaW] = useState(false);
  const [colunasShopify, setColunasShopify] = useState([
    { value: 0, inputValue: "Handle" },
    { value: 1, inputValue: "Title" },
    { value: 2, inputValue: "Body (HTML)" },
    { value: 3, inputValue: "Vendor" },
    { value: 4, inputValue: "Product Category" },
    { value: 5, inputValue: "Type" },
    { value: 6, inputValue: "Tags" },
    { value: 7, inputValue: "Published" },
    { value: 8, inputValue: "Option1 Name" },
    { value: 9, inputValue: "Option1 Value" },
    { value: 10, inputValue: "Option2 Name" },
    { value: 11, inputValue: "Option2 Value" },
    { value: 12, inputValue: "Option3 Name" },
    { value: 13, inputValue: "Option3 Value" },
    { value: 14, inputValue: "Variant SKU" },
    { value: 15, inputValue: "Variant Grams" },
    { value: 16, inputValue: "Variant Inventory Tracker" },
    { value: 17, inputValue: "Variant Inventory Qty" },
    // { value: 18, inputValue: "Variant Inventory Policy" },
    // { value: 19, inputValue: "Variant Fulfillment Service" },
    { value: 20, inputValue: "Variant Price" },
    { value: 21, inputValue: "Variant Compare At Price" },
    { value: 22, inputValue: "Variant Requires Shipping" },
    { value: 23, inputValue: "Variant Taxable" },
    { value: 24, inputValue: "Variant Barcode" },
    { value: 25, inputValue: "Image Src" },
    { value: 26, inputValue: "Image Position" },
    { value: 27, inputValue: "Image Alt Text" },
    { value: 28, inputValue: "Gift Card" },
    { value: 29, inputValue: "SEO Title" },
    { value: 30, inputValue: "SEO Description" },
    { value: 31, inputValue: "Google Shopping / Google Product Category" },
    { value: 32, inputValue: "Google Shopping / Gender" },
    { value: 33, inputValue: "Google Shopping / Age Group" },
    { value: 34, inputValue: "Google Shopping / MPN" },
    { value: 35, inputValue: "Google Shopping / AdWords Grouping" },
    { value: 36, inputValue: "Google Shopping / AdWords Labels" },
    { value: 37, inputValue: "Google Shopping / Condition" },
    { value: 38, inputValue: "Google Shopping / Custom Product" },
    { value: 39, inputValue: "Google Shopping / Custom Label 0" },
    { value: 40, inputValue: "Google Shopping / Custom Label 1" },
    { value: 41, inputValue: "Google Shopping / Custom Label 2" },
    { value: 42, inputValue: "Google Shopping / Custom Label 3" },
    { value: 43, inputValue: "Google Shopping / Custom Label 4" },
    { value: 44, inputValue: "Variant Image" },
    { value: 45, inputValue: "Variant Weight Unit" },
    { value: 46, inputValue: "Variant Tax Code" },
    { value: 47, inputValue: "Cost per item" },
    { value: 48, inputValue: "Included / Portugal" },
    { value: 49, inputValue: "Included / Internacional" },
    { value: 50, inputValue: "Price / Internacional" },
    { value: 51, inputValue: "Compare At Price / Internacional" },
    // { value: 52, inputValue: "Status" },
  ]);

  // Variáveis step 2
  const [ficheiroAtualizar, setFicheiroAtualizar] = useState([]);
  const [ficheiroFornecedor, setFicheiroFornecedor] = useState([]);
  const [nomeFicheiroAtualizar, setNomeFicheiroAtualizar] = useState("");
  const [nomeFicheiroFornecedor, setNomeFicheiroFornecedor] = useState("");

  //Variáveis step 3
  const [colunasFicheiroFornecedor, setColunasFicheiroFornecedor] = useState(
    []
  );
  const [colunasFicheiroAtualizar, setColunasFicheiroAtualizar] = useState([]);
  const [colunasFicheiroFornecedor2, setColunasFicheiroFornecedor2] = useState(
    []
  );
  const [colunaReferencia, setColunaReferencia] = useState("");
  const [colunaPreco, setColunaPreco] = useState("");
  const [referenciaErro, setReferenciaErro] = useState(false);
  const [precoErro, setPrecoErro] = useState(false);
  const [colunasWordpress, setColunasWordpress] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // termo de pesquisa
  const [searchTerm2, setSearchTerm2] = useState(""); // termo de pesquisa
  const [searchTerm3, setSearchTerm3] = useState(""); // termo de pesquisa
  const [colunasFiltradas, setColunasFiltradas] = useState([]); // array filtrado
  const handleSearch = (event) => {
    // função para lidar com a pesquisa
    setSearchTerm(event.target.value);
    setColunasFiltradas(
      colunasFicheiroAtualizar.filter((element) => {
        return element.toLowerCase().includes(event.target.value.toLowerCase());
      })
    );
  };
  const [colunasFiltradas2, setColunasFiltradas2] = useState(colunasShopify); // array filtrado
  const handleSearch2 = (event) => {
    // função para lidar com a pesquisa
    setSearchTerm2(event.target.value);
    setColunasFiltradas2(
      colunasShopify.filter((element) => {
        return element.inputValue
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      })
    );
  };
  const [colunasFiltradas3, setColunasFiltradas3] = useState(colunasShopify); // array filtrado
  const handleSearch3 = (event) => {
    // função para lidar com a pesquisa
    setSearchTerm3(event.target.value);
    setColunasFiltradas3(
      colunasWordpress.filter((element) => {
        return element.inputValue
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      })
    );
  };

  //Mapeamento
  const [mapeamento, setMapeamento] = useState([]);
  const [colunaFicheiro, setColunaFicheiro] = useState("");
  const [colunaLoja, setColunaLoja] = useState("");

  //Mapeamento categoria produto
  const [mapeamentoCategoria, setMapeamentoCategoria] = useState([]);
  const [categoriasFicheiro, setCategoriasFicheiro] = useState([]);
  const [categoriaFicheiro, setCategoriaFicheiro] = useState("");
  const [categoriasLoja, setCategoriasLoja] = useState(arrayTaxonomiaProdutos);
  const [categoriaLoja, setCategoriaLoja] = useState("");

  const [searchTermCategoria, setSearchTermCategoria] = useState(""); // termo de pesquisa
  const [categoriasFiltradas, setCategoriasFiltradas] =
    useState(categoriasFicheiro); // array filtrado
  const handleSearchCategorias = (event) => {
    // função para lidar com a pesquisa
    setSearchTermCategoria(event.target.value);
    setCategoriasFiltradas(
      categoriasFicheiro.filter((element) => {
        return element.toLowerCase().includes(event.target.value.toLowerCase());
      })
    );
  };

  const [searchTermCategoriaLoja, setSearchTermCategoriaLoja] = useState(""); // termo de pesquisa
  const [categoriasFiltradasLoja, setCategoriasFiltradasLoja] =
    useState(categoriasLoja); // array filtrado
  const handleSearchCategoriasLoja = (event) => {
    // função para lidar com a pesquisa
    setSearchTermCategoriaLoja(event.target.value);
    setCategoriasFiltradasLoja(
      categoriasLoja.filter((element) => {
        return element.inputValue
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      })
    );
  };

  //Variável step 4
  const [csvData, setCsvData] = useState([]);
  const [csvFilename, setCsvFilename] = useState("");

  const [freeze, setFreeze] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  //Traduções
  const { t, i18n } = useTranslation();

  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const miliseconds = date.getMilliseconds();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  var data_ = 0;

  let monthC = month;
  if (month < 10) {
    monthC = "0" + month;
  }
  let day = date.getDate();
  if (date.getDate() < 10) {
    day = "0" + date.getDate();
  }
  data_ = `${day}-${monthC}-${year}`;

  //Caso tenha correspondência para a categoria, aparece mais um step
  const steps = ["Product Category"].every((prop) => {
    return mapeamento.some((item) => Object.values(item).includes(prop));
  })
    ? [
        t("step1"),
        t("carregarFicheiro"),
        t("step3"),
        t("mapeamentoColunasCategoria"),
        t("step6"),
      ]
    : [t("step1"), t("carregarFicheiro"), t("step3"), t("step6")];
  useEffect(() => {
    if (csvData.length != 0) {
      setFreeze(false);
    }
  }, [csvData]);

  useEffect(() => {
    const id = localStorage.getItem("id");
    const token = Cookies.get("token");

    if (!id && !token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (colunaFicheiro && colunaLoja) {
      // Criando uma cópia do array existente
      const mapeamentoAtualizado = [...mapeamento];

      // Adicionando a nova linha à cópia atualizada
      const novaLinha = {
        coluna_ficheiro: colunaFicheiro,
        coluna_loja: colunaLoja.inputValue,
        id: colunaLoja.value,
      };
      mapeamentoAtualizado.push(novaLinha);

      // Definindo o novo estado com a cópia atualizada do array
      setMapeamento(mapeamentoAtualizado);

      //Eliminar a coluna do array do ficheiro
      const novoArray = colunasFicheiroAtualizar.filter(
        (elemento) => elemento !== colunaFicheiro
      );

      setColunasFiltradas(novoArray);
      setColunasFicheiroAtualizar(novoArray);
      //Eliminar a coluna do array da loja
      const novoArray2 = colunasShopify.filter(
        (elemento) => elemento.inputValue !== colunaLoja.inputValue
      );

      setColunasFiltradas2(novoArray2);
      setColunasShopify(novoArray2);

      //Limpar as variáveis
      setColunaFicheiro("");
      setColunaLoja("");
      setSearchTerm("");
      setSearchTerm2("");
      setSearchTerm3("");
    }
  }, [colunaFicheiro, colunaLoja]);

  useEffect(() => {
    if (categoriaFicheiro && categoriaLoja) {
      // Criando uma cópia do array existente
      const mapeamentoAtualizado = [...mapeamentoCategoria];

      // Adicionando a nova linha à cópia atualizada
      const novaLinha = {
        categoria_ficheiro: categoriaFicheiro,
        categoria_loja: categoriaLoja.inputValue,
        id: categoriaLoja.value,
      };
      mapeamentoAtualizado.push(novaLinha);

      // Definindo o novo estado com a cópia atualizada do array
      setMapeamentoCategoria(mapeamentoAtualizado);

      //Eliminar a coluna do array do ficheiro
      const novoArray = categoriasFicheiro.filter(
        (elemento) => elemento !== categoriaFicheiro
      );

      setCategoriasFicheiro(novoArray);
      setCategoriasFiltradas(novoArray);
      //Eliminar a coluna do array da loja
      const novoArray2 = categoriasLoja.filter(
        (elemento) => elemento.inputValue !== categoriaLoja.inputValue
      );

      setCategoriasLoja(novoArray2);
      setCategoriasFiltradasLoja(novoArray2);

      //Limpar as variáveis
      setCategoriaFicheiro("");
      setCategoriaLoja("");
      setSearchTermCategoria("");
      setSearchTermCategoriaLoja("");
    }
  }, [categoriaFicheiro, categoriaLoja]);

  //Atualizar array quando coluna da referência e do preço são atualizados
  useEffect(() => {
    let arrayFinal = [];
    if (colunaReferencia && colunaPreco) {
      colunasFicheiroFornecedor2.forEach((element) => {
        if (element != colunaReferencia && element != colunaPreco) {
          arrayFinal.push(element);
        }
      });
      setColunasFicheiroFornecedor(arrayFinal);
    } else if (!colunaReferencia && colunaPreco) {
      colunasFicheiroFornecedor2.forEach((element) => {
        if (element != colunaPreco) {
          arrayFinal.push(element);
        }
      });
      setColunasFicheiroFornecedor(arrayFinal);
    } else if (colunaReferencia && !colunaPreco) {
      colunasFicheiroFornecedor2.forEach((element) => {
        if (element != colunaReferencia) {
          arrayFinal.push(element);
        }
      });
      setColunasFicheiroFornecedor(arrayFinal);
    } else if (!colunaReferencia && !colunaPreco) {
      setColunasFicheiroFornecedor(colunasFicheiroFornecedor2);
    }
  }, [colunaReferencia, colunaPreco]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    if (activeStep == 4) {
      if (
        ["Product Category"].every((prop) => {
          return mapeamento.some((item) => Object.values(item).includes(prop));
        })
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 2);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  function verificacao() {
    //Step 1
    if (activeStep == 0) {
      if (!lojaEscolhida) {
        toast.error(t("erroLoja"));
      } else {
        handleComplete();
      }
    }
    //Step 2
    else if (activeStep == 1) {
      if (ficheiroAtualizar.length == 0) {
        toast.error(t("erroFicheiroAtualizar"));
      } else {
        handleComplete();
      }
    }
    //Step 3
    else if (activeStep == 2) {
      if (
        ["Product Category"].every((prop) => {
          return mapeamento.some((item) => Object.values(item).includes(prop));
        })
      ) {
        // Verificar que o Title e o Variant SKU têm correspondência
        const hasTitleAndVariantSKU = ["Title", "Variant SKU", "Handle"].every(
          (prop) => {
            return mapeamento.some((item) =>
              Object.values(item).includes(prop)
            );
          }
        );
        if (hasTitleAndVariantSKU) {
          // Ir buscar as categorias de produto do ficheiro
          const listaCategoriasFicheiro = [];
          ficheiroAtualizar.forEach((element) => {
            const elementFinal =
              element[
                mapeamento.find(
                  (elemento) => elemento.coluna_loja == "Product Category"
                ).coluna_ficheiro
              ];
            if (!listaCategoriasFicheiro.includes(elementFinal)) {
              listaCategoriasFicheiro.push(elementFinal);
            }
          });
          setCategoriasFicheiro(listaCategoriasFicheiro);
          setCategoriasFiltradas(listaCategoriasFicheiro);

          handleComplete();
        } else {
          toast.error(t("minMapeamento"));
        }
      } else {
        const hasTitleAndVariantSKU = ["Title", "Variant SKU", "Handle"].every(
          (prop) => {
            return mapeamento.some((item) =>
              Object.values(item).includes(prop)
            );
          }
        );

        if (hasTitleAndVariantSKU) {
          const newCompleted = completed;
          newCompleted[activeStep] = true;
          setCompleted(newCompleted);
          const newActiveStep =
            isLastStep() && !allStepsCompleted()
              ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
              : activeStep + 2;
          setActiveStep(newActiveStep);
        } else {
          toast.error(t("minMapeamento"));
        }
      }
    }
    // Step 4
    else if (activeStep == 3) {
      if (categoriasFicheiro.length != 0) {
        toast.error(t("categoriaProdutos"));
      } else {
        handleComplete();
      }
    }
  }

  function ficheiroAtualizarVerificacao(file) {
    let teste = file.name.split(".");
    setNomeFicheiroAtualizar(file.name);
    if (teste[1] == "tsv" || teste[1] == "csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setFicheiroAtualizar(results.data);

          let ficheiro = results.data;
          let colunas = Object.keys(ficheiro[0]);

          let i = 1;
          let arrayColunas = [];
          colunas.forEach((element) => {
            let linha = {
              value: i,
              inputValue: element,
            };
            arrayColunas.push(linha);
            i++;
          });

          setColunasFicheiroAtualizar(colunas);
          setColunasFiltradas(colunas);
        },
      });
    } else if (teste[1] == "xlsx") {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 0 });

        setFicheiroAtualizar(dataParse);
        let ficheiro = dataParse;
        let colunas = Object.keys(ficheiro[0]);

        let i = 1;
        let arrayColunas = [];
        colunas.forEach((element) => {
          let linha = {
            value: i,
            inputValue: element,
          };
          arrayColunas.push(linha);
          i++;
        });

        setColunasFicheiroAtualizar(colunas);
        setColunasFiltradas(colunas);
      };
      reader.readAsBinaryString(file);
    }
  }

  async function criarFicheiro() {
    setCsvData([]);
    // setFreeze(true);

    //Array final fornecedor
    let arrayFinalFornecedor = [];

    //Array final
    let arrayFinal = [];

    //Shopify
    if (lojaEscolhidaS) {
      if (
        ["Product Category"].every((prop) => {
          return mapeamento.some((item) => Object.values(item).includes(prop));
        })
      ) {
        //Ficheiro final para Shopify
        ficheiroAtualizar.forEach((element) => {
          let colunaCategoria = mapeamento.find(
            (elemento) => elemento.coluna_loja == "Product Category"
          ).coluna_ficheiro;

          const linha = {
            Handle: mapeamento.find(
              (elemento) => elemento.coluna_loja == "Handle"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Handle"
                  ).coluna_ficheiro
                ]
              : "",
            Title: mapeamento.find(
              (elemento) => elemento.coluna_loja == "Title"
            )
              ? element[
                  mapeamento.find((elemento) => elemento.coluna_loja == "Title")
                    .coluna_ficheiro
                ].replace(/"/g, "")
              : "",
            "Body (HTML)": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Body (HTML)"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Body (HTML)"
                  ).coluna_ficheiro
                ]
              : "",
            Vendor: mapeamento.find(
              (elemento) => elemento.coluna_loja == "Vendor"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Vendor"
                  ).coluna_ficheiro
                ]
              : "",
            "Product Category": mapeamentoCategoria.find(
              (elemento) =>
                elemento.categoria_ficheiro == element[colunaCategoria]
            ).categoria_loja,

            Type: mapeamento.find((elemento) => elemento.coluna_loja == "Type")
              ? element[
                  mapeamento.find((elemento) => elemento.coluna_loja == "Type")
                    .coluna_ficheiro
                ]
              : "",
            Tags: mapeamento.find((elemento) => elemento.coluna_loja == "Tags")
              ? element[
                  mapeamento.find((elemento) => elemento.coluna_loja == "Tags")
                    .coluna_ficheiro
                ]
              : "",
            Published: mapeamento.find(
              (elemento) => elemento.coluna_loja == "Published"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Published"
                  ).coluna_ficheiro
                ]
              : "",
            "Option1 Name": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Option1 Name"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Option1 Name"
                  ).coluna_ficheiro
                ]
              : "",
            "Option1 Value": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Option1 Value"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Option1 Value"
                  ).coluna_ficheiro
                ]
              : "",
            "Option2 Name": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Option2 Name"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Option2 Name"
                  ).coluna_ficheiro
                ]
              : "",
            "Option2 Value": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Option2 Value"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Option2 Value"
                  ).coluna_ficheiro
                ]
              : "",
            "Option3 Name": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Option3 Name"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Option3 Name"
                  ).coluna_ficheiro
                ]
              : "",
            "Option3 Value": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Option3 Value"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Option3 Value"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant SKU": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant SKU"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant SKU"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Grams": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Grams"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Grams"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Inventory Tracker": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Inventory Tracker"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Variant Inventory Tracker"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Inventory Qty": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Inventory Qty"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Variant Inventory Qty"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Inventory Policy": "deny",
            "Variant Fulfillment Service": "manual",
            "Variant Price": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Price"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Price"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Compare At Price": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Compare At Price"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Variant Compare At Price"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Requires Shipping": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Requires Shipping"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Variant Requires Shipping"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Taxable": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Taxable"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Taxable"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Barcode": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Barcode"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Barcode"
                  ).coluna_ficheiro
                ]
              : "",
            "Image Src": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Image Src"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Image Src"
                  ).coluna_ficheiro
                ]
              : "",
            "Image Position": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Image Position"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Image Position"
                  ).coluna_ficheiro
                ]
              : "",
            "Image Alt Text": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Image Alt Text"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Image Alt Text"
                  ).coluna_ficheiro
                ]
              : "",
            "Gift Card": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Gift Card"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Gift Card"
                  ).coluna_ficheiro
                ]
              : "",
            "SEO Title": mapeamento.find(
              (elemento) => elemento.coluna_loja == "SEO Title"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "SEO Title"
                  ).coluna_ficheiro
                ]
              : "",
            "SEO Description": mapeamento.find(
              (elemento) => elemento.coluna_loja == "SEO Description"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "SEO Description"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Google Product Category": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja ==
                "Google Shopping / Google Product Category"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja ==
                      "Google Shopping / Google Product Category"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Gender": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Google Shopping / Gender"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Gender"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Age Group": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Age Group"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Age Group"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / MPN": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Google Shopping / MPN"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / MPN"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / AdWords Grouping": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / AdWords Grouping"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja ==
                      "Google Shopping / AdWords Grouping"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / AdWords Labels": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / AdWords Labels"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / AdWords Labels"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Condition": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Condition"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Condition"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Custom Label 0": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Custom Label 0"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Custom Label 0"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Custom Label 1": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Custom Label 1"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Custom Label 1"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Custom Label 2": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Custom Label 2"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Custom Label 2"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Custom Label 3": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Custom Label 3"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Custom Label 3"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Custom Label 4": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Custom Label 4"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Custom Label 4"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Image": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Image"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Image"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Weight Unit": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Weight Unit"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Weight Unit"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Tax Code": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Tax Code"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Tax Code"
                  ).coluna_ficheiro
                ]
              : "",
            "Cost per item": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Cost per item"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Cost per item"
                  ).coluna_ficheiro
                ]
              : "",
            "Price / Internacional": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Price / Internacional"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Price / Internacional"
                  ).coluna_ficheiro
                ]
              : "",
            "Compare At Price / Internacional": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Compare At Price / Internacional"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Compare At Price / Internacional"
                  ).coluna_ficheiro
                ]
              : "",
            Status: "active",
          };
          arrayFinal.push(linha);
        });

        //   Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      } else {
        //Ficheiro final para Shopify
        ficheiroAtualizar.forEach((element) => {
          const linha = {
            Handle: mapeamento.find(
              (elemento) => elemento.coluna_loja == "Handle"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Handle"
                  ).coluna_ficheiro
                ]
              : "",
            Title: mapeamento.find(
              (elemento) => elemento.coluna_loja == "Title"
            )
              ? element[
                  mapeamento.find((elemento) => elemento.coluna_loja == "Title")
                    .coluna_ficheiro
                ].replace(/"/g, "")
              : "",
            "Body (HTML)": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Body (HTML)"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Body (HTML)"
                  ).coluna_ficheiro
                ]
              : "",
            Vendor: mapeamento.find(
              (elemento) => elemento.coluna_loja == "Vendor"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Vendor"
                  ).coluna_ficheiro
                ]
              : "",
            "Product Category": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Product Category"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Product Category"
                  ).coluna_ficheiro
                ]
              : "",
            Type: mapeamento.find((elemento) => elemento.coluna_loja == "Type")
              ? element[
                  mapeamento.find((elemento) => elemento.coluna_loja == "Type")
                    .coluna_ficheiro
                ]
              : "",
            Tags: mapeamento.find((elemento) => elemento.coluna_loja == "Tags")
              ? element[
                  mapeamento.find((elemento) => elemento.coluna_loja == "Tags")
                    .coluna_ficheiro
                ]
              : "",
            Published: mapeamento.find(
              (elemento) => elemento.coluna_loja == "Published"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Published"
                  ).coluna_ficheiro
                ]
              : "",
            "Option1 Name": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Option1 Name"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Option1 Name"
                  ).coluna_ficheiro
                ]
              : "",
            "Option1 Value": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Option1 Value"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Option1 Value"
                  ).coluna_ficheiro
                ]
              : "",
            "Option2 Name": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Option2 Name"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Option2 Name"
                  ).coluna_ficheiro
                ]
              : "",
            "Option2 Value": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Option2 Value"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Option2 Value"
                  ).coluna_ficheiro
                ]
              : "",
            "Option3 Name": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Option3 Name"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Option3 Name"
                  ).coluna_ficheiro
                ]
              : "",
            "Option3 Value": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Option3 Value"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Option3 Value"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant SKU": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant SKU"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant SKU"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Grams": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Grams"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Grams"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Inventory Tracker": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Inventory Tracker"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Variant Inventory Tracker"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Inventory Qty": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Inventory Qty"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Variant Inventory Qty"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Inventory Policy": "deny",
            "Variant Fulfillment Service": "manual",
            "Variant Price": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Price"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Price"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Compare At Price": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Compare At Price"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Variant Compare At Price"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Requires Shipping": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Requires Shipping"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Variant Requires Shipping"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Taxable": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Taxable"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Taxable"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Barcode": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Barcode"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Barcode"
                  ).coluna_ficheiro
                ]
              : "",
            "Image Src": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Image Src"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Image Src"
                  ).coluna_ficheiro
                ]
              : "",
            "Image Position": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Image Position"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Image Position"
                  ).coluna_ficheiro
                ]
              : "",
            "Image Alt Text": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Image Alt Text"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Image Alt Text"
                  ).coluna_ficheiro
                ]
              : "",
            "Gift Card": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Gift Card"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Gift Card"
                  ).coluna_ficheiro
                ]
              : "",
            "SEO Title": mapeamento.find(
              (elemento) => elemento.coluna_loja == "SEO Title"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "SEO Title"
                  ).coluna_ficheiro
                ]
              : "",
            "SEO Description": mapeamento.find(
              (elemento) => elemento.coluna_loja == "SEO Description"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "SEO Description"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Google Product Category": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja ==
                "Google Shopping / Google Product Category"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja ==
                      "Google Shopping / Google Product Category"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Gender": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Google Shopping / Gender"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Gender"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Age Group": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Age Group"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Age Group"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / MPN": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Google Shopping / MPN"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / MPN"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / AdWords Grouping": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / AdWords Grouping"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja ==
                      "Google Shopping / AdWords Grouping"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / AdWords Labels": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / AdWords Labels"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / AdWords Labels"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Condition": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Condition"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Condition"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Custom Label 0": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Custom Label 0"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Custom Label 0"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Custom Label 1": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Custom Label 1"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Custom Label 1"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Custom Label 2": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Custom Label 2"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Custom Label 2"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Custom Label 3": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Custom Label 3"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Custom Label 3"
                  ).coluna_ficheiro
                ]
              : "",
            "Google Shopping / Custom Label 4": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Google Shopping / Custom Label 4"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Google Shopping / Custom Label 4"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Image": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Image"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Image"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Weight Unit": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Weight Unit"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Weight Unit"
                  ).coluna_ficheiro
                ]
              : "",
            "Variant Tax Code": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Variant Tax Code"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Variant Tax Code"
                  ).coluna_ficheiro
                ]
              : "",
            "Cost per item": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Cost per item"
            )
              ? element[
                  mapeamento.find(
                    (elemento) => elemento.coluna_loja == "Cost per item"
                  ).coluna_ficheiro
                ]
              : "",
            "Price / Internacional": mapeamento.find(
              (elemento) => elemento.coluna_loja == "Price / Internacional"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Price / Internacional"
                  ).coluna_ficheiro
                ]
              : "",
            "Compare At Price / Internacional": mapeamento.find(
              (elemento) =>
                elemento.coluna_loja == "Compare At Price / Internacional"
            )
              ? element[
                  mapeamento.find(
                    (elemento) =>
                      elemento.coluna_loja == "Compare At Price / Internacional"
                  ).coluna_ficheiro
                ]
              : "",
            Status: "active",
          };
          arrayFinal.push(linha);
        });

        //   Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
    }
    //Wordpress
    else if (lojaEscolhidaW) {
      //Retirar preço
      //   if (alteracaoPrecoOpcao == 1) {
      //     //Ficheiro final para Wordpress
      //     ficheiroAtualizar.forEach((element) => {
      //       let teste = arrayFinalFornecedor.find(
      //         (element2) => element["REF"] == element2.ref
      //       );
      //       if (teste != undefined) {
      //         const linha = {
      //           ID: element["ID"],
      //           REF: element["REF"],
      //           "Preço normal": (
      //             teste.preco -
      //             (teste.preco * percentagemRetirar) / 100
      //           ).toFixed(2),
      //           Publicado: parseFloat(element["Publicado"]),
      //         };
      //         arrayFinal.push(linha);
      //       } else {
      //         if (element["REF"] != "") {
      //           const linha = {
      //             ID: element["ID"],
      //             REF: element["REF"],
      //             "Preço normal": parseFloat(element["Preço normal"]),
      //             Publicado: 0,
      //           };
      //           arrayFinal.push(linha);
      //         }
      //       }
      //     });
      //     //Verificar que o array não está vazio
      //     if (arrayFinal.length != 0) {
      //       toast.success(t("downloadSucesso"));
      //       setCsvFilename(
      //         "lista_atualizada_" +
      //           data_ +
      //           "_" +
      //           hours +
      //           ":" +
      //           minutes +
      //           ":" +
      //           seconds +
      //           ":" +
      //           miliseconds +
      //           ".csv"
      //       );
      //       setCsvData(arrayFinal);
      //     } else {
      //       toast.error(t("erro"));
      //     }
      //   }
    }
  }

  return (
    <div className="priceupdate">
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguardaCSV")}
              </Typography>
              <CircularProgress style={{ marginTop: "20px" }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <Box
        sx={{
          width: "80%",
          marginTop: "0px",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Button
          color="warning"
          variant="contained"
          style={{ float: "right" }}
          onClick={() => {
            setLojaEscolhida("");
            setLojaEscolhidaS(false);
            setLojaEscolhidaW(false);
            setFicheiroAtualizar([]);
            setFicheiroFornecedor([]);
            setNomeFicheiroAtualizar("");
            setNomeFicheiroFornecedor("");
            setColunasFicheiroFornecedor([]);
            setColunasFicheiroFornecedor2([]);
            setCsvData([]);
            setCsvFilename("");
            setActiveStep(0);
            setCompleted({});
            setSearchTerm("");
            setSearchTerm2("");
            setSearchTerm3("");
            setColunasFiltradas([]);
            setMapeamento([]);
            setColunaFicheiro("");
            setColunaLoja("");
            setColunasShopify([
              { value: 0, inputValue: "Handle" },
              { value: 1, inputValue: "Title" },
              { value: 2, inputValue: "Body (HTML)" },
              { value: 3, inputValue: "Vendor" },
              { value: 4, inputValue: "Product Category" },
              { value: 5, inputValue: "Type" },
              { value: 6, inputValue: "Tags" },
              { value: 7, inputValue: "Published" },
              { value: 8, inputValue: "Option1 Name" },
              { value: 9, inputValue: "Option1 Value" },
              { value: 10, inputValue: "Option2 Name" },
              { value: 11, inputValue: "Option2 Value" },
              { value: 12, inputValue: "Option3 Name" },
              { value: 13, inputValue: "Option3 Value" },
              { value: 14, inputValue: "Variant SKU" },
              { value: 15, inputValue: "Variant Grams" },
              { value: 16, inputValue: "Variant Inventory Tracker" },
              { value: 17, inputValue: "Variant Inventory Qty" },
              // { value: 18, inputValue: "Variant Inventory Policy" },
              // { value: 19, inputValue: "Variant Fulfillment Service" },
              { value: 20, inputValue: "Variant Price" },
              { value: 21, inputValue: "Variant Compare At Price" },
              { value: 22, inputValue: "Variant Requires Shipping" },
              { value: 23, inputValue: "Variant Taxable" },
              { value: 24, inputValue: "Variant Barcode" },
              { value: 25, inputValue: "Image Src" },
              { value: 26, inputValue: "Image Position" },
              { value: 27, inputValue: "Image Alt Text" },
              { value: 28, inputValue: "Gift Card" },
              { value: 29, inputValue: "SEO Title" },
              { value: 30, inputValue: "SEO Description" },
              {
                value: 31,
                inputValue: "Google Shopping / Google Product Category",
              },
              { value: 32, inputValue: "Google Shopping / Gender" },
              { value: 33, inputValue: "Google Shopping / Age Group" },
              { value: 34, inputValue: "Google Shopping / MPN" },
              { value: 35, inputValue: "Google Shopping / AdWords Grouping" },
              { value: 36, inputValue: "Google Shopping / AdWords Labels" },
              { value: 37, inputValue: "Google Shopping / Condition" },
              { value: 38, inputValue: "Google Shopping / Custom Product" },
              { value: 39, inputValue: "Google Shopping / Custom Label 0" },
              { value: 40, inputValue: "Google Shopping / Custom Label 1" },
              { value: 41, inputValue: "Google Shopping / Custom Label 2" },
              { value: 42, inputValue: "Google Shopping / Custom Label 3" },
              { value: 43, inputValue: "Google Shopping / Custom Label 4" },
              { value: 44, inputValue: "Variant Image" },
              { value: 45, inputValue: "Variant Weight Unit" },
              { value: 46, inputValue: "Variant Tax Code" },
              { value: 47, inputValue: "Cost per item" },
              { value: 48, inputValue: "Included / Portugal" },
              { value: 49, inputValue: "Included / Internacional" },
              { value: 50, inputValue: "Price / Internacional" },
              { value: 51, inputValue: "Compare At Price / Internacional" },
              // { value: 52, inputValue: "Status" },
            ]);
            setCategoriasFicheiro([]);
            setCategoriasLoja(arrayTaxonomiaProdutos);
            setCategoriaFicheiro("");
            setCategoriaLoja("");
            setSearchTermCategoria("");
            setSearchTermCategoriaLoja("");
            setMapeamentoCategoria([]);
          }}
          sx={{ mr: 1 }}
        >
          {t("recomercar")}
        </Button>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "50px",
            fontWeight: "bold",
            fontSize: "30px",
          }}
        >
          {t("mapeamento")}
        </h2>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                onClick={completed[4] ? handleStep(index) : null}
                style={{ boxShadow: "none" }}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {/* Step 1 */}
        {activeStep == 0 ? (
          <div
            style={{
              width: "80vw",
              height: "55vh",
              marginTop: "20px",
            }}
          >
            <div class="container2">
              {/* Shopify */}
              {!lojaEscolhidaW && (
                <div
                  class="card_ card4"
                  onClick={() => {
                    setLojaEscolhida("shopify");
                    setLojaEscolhidaS(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="priceupdateIcon"
                    icon={faShopify}
                  />
                </div>
              )}
              {/* Wordpress */}
              {!lojaEscolhidaS && (
                <div
                  class="card_ card5"
                  onClick={() => {
                    setLojaEscolhida("wordpress");
                    setLojaEscolhidaW(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="priceupdateIcon"
                    icon={faWordpress}
                  />
                </div>
              )}
            </div>
          </div>
        ) : null}
        {/* Step 2 */}
        {activeStep == 1 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              marginTop: "20px",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <div style={{ float: "left", top: "35%", position: "relative" }}>
              <h4 style={{ textAlign: "center" }}>{t("ficheiroAtualizar")}</h4>
              <div style={{ textAlign: "center", marginTop: "30px" }}>
                <label htmlFor="formId">
                  <input
                    type="file"
                    id="formId"
                    accept=".csv, .xlsx, .tsv"
                    multiple={false}
                    onChange={(e) => {
                      ficheiroAtualizarVerificacao(e.target.files[0]);
                    }}
                    hidden
                  />
                  <FontAwesomeIcon
                    icon={faUpload}
                    style={{
                      color: nomeFicheiroAtualizar ? "#228B22" : "#343434",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                </label>
                <p style={{ paddingTop: "20px" }}>
                  {ficheiroAtualizar.length == 0
                    ? t("selecioneFicheiro")
                    : nomeFicheiroAtualizar}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {/* Step 3 */}
        {activeStep == 2 ? (
          <div
            style={{
              width: "85vw",
              height: "50vh",
              marginTop: "20px",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            {/* Ficheiro a atualizar */}
            <div
              style={{
                float: "left",
                top: "10%",
                position: "relative",
                width: "30%",
              }}
            >
              <h5 style={{ textAlign: "center" }}>
                {t("colunasFicheiroAtualizar")}
              </h5>
              <input
                type="text"
                variant="filled"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Pesquisar..."
              />

              <div className="divMapeamento">
                {colunasFiltradas.map((element) => {
                  return (
                    <p
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          element == colunaFicheiro ? "#50c2fe" : "white",
                      }}
                      onClick={() => {
                        setColunaFicheiro(element);
                      }}
                    >
                      {element}
                    </p>
                  );
                })}
              </div>
            </div>
            {/* Mapeamento */}
            <div
              style={{
                float: "left",
                top: "10%",
                position: "relative",
                width: "30%",
              }}
            >
              <h5 style={{ textAlign: "center" }}>{t("mapeamento2")}</h5>
              <div className="divMapeamento2">
                {mapeamento.map((element) => {
                  return (
                    <div>
                      <p className="mapeamento-texto">
                        {element.coluna_ficheiro} {"<->"} {element.coluna_loja}
                      </p>
                      <Button
                        onClick={() => {
                          // Repor linha nos array do ficheiro
                          setColunasFiltradas([
                            ...colunasFiltradas,
                            element.coluna_ficheiro,
                          ]);
                          setColunasFicheiroAtualizar([
                            ...colunasFicheiroAtualizar,
                            element.coluna_ficheiro,
                          ]);

                          // Repor linha no array da loja
                          if (lojaEscolhidaS) {
                            setColunasFiltradas2([
                              ...colunasFiltradas2,
                              {
                                value: element.id,
                                inputValue: element.coluna_loja,
                              },
                            ]);
                            setColunasShopify([
                              ...colunasShopify,
                              {
                                value: element.id,
                                inputValue: element.coluna_loja,
                              },
                            ]);
                          }

                          //Eliminar elemento do array do mapeamento
                          const novoArray = mapeamento.filter(
                            (elemento) =>
                              elemento.coluna_ficheiro !==
                                element.coluna_ficheiro &&
                              elemento.coluna_loja != element.coluna_loja &&
                              elemento.id != element.id
                          );
                          setMapeamento(novoArray);
                        }}
                        color="error"
                        className="mapeamento-botao"
                      >
                        <FontAwesomeIcon icon={faX} />
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Colunas loja */}
            <div
              style={{
                float: "right",
                top: "10%",
                position: "relative",
                width: "30%",
              }}
            >
              <h5 style={{ textAlign: "center" }}>
                {lojaEscolhidaS ? t("colunasShopify") : t("colunasWordpress")}
              </h5>
              {lojaEscolhidaS ? (
                <input
                  type="text"
                  variant="filled"
                  value={searchTerm2}
                  onChange={handleSearch2}
                  placeholder="Pesquisar..."
                />
              ) : (
                <input
                  type="text"
                  variant="filled"
                  value={searchTerm3}
                  onChange={handleSearch3}
                  placeholder="Pesquisar..."
                />
              )}

              {lojaEscolhidaS ? (
                <div className="divMapeamento">
                  {colunasFiltradas2.map((element) => {
                    return (
                      <p
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            element.value == colunaLoja.value
                              ? "#50c2fe"
                              : "white",
                        }}
                        onClick={() => {
                          setColunaLoja({
                            inputValue: element.inputValue,
                            value: element.value,
                          });
                        }}
                      >
                        {element.inputValue}
                      </p>
                    );
                  })}
                </div>
              ) : (
                <div className="divMapeamento">
                  {colunasFiltradas3.map((element) => {
                    return <p>{element.inputValue}</p>;
                  })}
                </div>
              )}
            </div>
          </div>
        ) : null}
        {/* Step 4 */}
        {activeStep == 3 ? (
          <div
            style={{
              width: "85vw",
              height: "50vh",
              marginTop: "20px",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            {/* Categorias ficheiro */}
            <div
              style={{
                float: "left",
                top: "10%",
                position: "relative",
                width: "30%",
              }}
            >
              <h5 style={{ textAlign: "center" }}>{t("colunasCategoria")}</h5>
              <input
                type="text"
                variant="filled"
                value={searchTermCategoria}
                onChange={handleSearchCategorias}
                placeholder="Pesquisar..."
              />

              <div className="divMapeamento">
                {categoriasFiltradas.map((element) => {
                  return (
                    <p
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          element == categoriaFicheiro ? "#50c2fe" : "white",
                      }}
                      onClick={() => {
                        setCategoriaFicheiro(element);
                      }}
                    >
                      {element}
                    </p>
                  );
                })}
              </div>
            </div>
            {/* Mapeamento */}
            <div
              style={{
                float: "left",
                top: "10%",
                position: "relative",
                width: "30%",
              }}
            >
              <h5 style={{ textAlign: "center" }}>{t("mapeamento2")}</h5>
              <div className="divMapeamento2">
                {mapeamentoCategoria.map((element) => {
                  return (
                    <div>
                      <p className="mapeamento-texto">
                        {element.categoria_ficheiro} {"<->"}{" "}
                        {element.categoria_loja}
                      </p>
                      <Button
                        onClick={() => {
                          // Repor linha nos array do ficheiro
                          setCategoriasFiltradas([
                            ...categoriasFiltradas,
                            element.categoria_ficheiro,
                          ]);
                          setCategoriasFicheiro([
                            ...categoriasFicheiro,
                            element.categoria_ficheiro,
                          ]);

                          // Repor linha no array da loja
                          if (lojaEscolhidaS) {
                            setCategoriasFiltradasLoja([
                              ...categoriasFiltradasLoja,
                              {
                                value: element.id,
                                inputValue: element.categoria_loja,
                              },
                            ]);
                            setCategoriasLoja([
                              ...categoriasLoja,
                              {
                                value: element.id,
                                inputValue: element.categoria_loja,
                              },
                            ]);
                          }

                          //Eliminar elemento do array do mapeamento
                          const novoArray = mapeamentoCategoria.filter(
                            (elemento) =>
                              elemento.categoria_ficheiro !==
                                element.categoria_ficheiro &&
                              elemento.categoria_loja !=
                                element.categoria_loja &&
                              elemento.id != element.id
                          );
                          setMapeamentoCategoria(novoArray);
                        }}
                        color="error"
                        className="mapeamento-botao"
                      >
                        <FontAwesomeIcon icon={faX} />
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Colunas categoria Shopify */}
            <div
              style={{
                float: "right",
                top: "10%",
                position: "relative",
                width: "30%",
              }}
            >
              <h5 style={{ textAlign: "center" }}>
                {lojaEscolhidaS
                  ? t("colunasCategoriaShopify")
                  : t("colunasCategoriaWordpress")}
              </h5>
              {lojaEscolhidaS ? (
                <input
                  type="text"
                  variant="filled"
                  value={searchTermCategoriaLoja}
                  onChange={handleSearchCategoriasLoja}
                  placeholder="Pesquisar..."
                />
              ) : (
                <input
                  type="text"
                  variant="filled"
                  value={searchTerm3}
                  onChange={handleSearch3}
                  placeholder="Pesquisar..."
                />
              )}

              {lojaEscolhidaS ? (
                <div className="divMapeamento">
                  {categoriasFiltradasLoja.map((element) => {
                    return (
                      <p
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            element.value == categoriaLoja.value
                              ? "#50c2fe"
                              : "white",
                        }}
                        onClick={() => {
                          setCategoriaLoja({
                            inputValue: element.inputValue,
                            value: element.value,
                          });
                        }}
                      >
                        {element.inputValue}
                      </p>
                    );
                  })}
                </div>
              ) : (
                <div className="divMapeamento">
                  {colunasFiltradas3.map((element) => {
                    return <p>{element.inputValue}</p>;
                  })}
                </div>
              )}
            </div>
          </div>
        ) : null}
        {/* Step 5 */}
        {activeStep == 4 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              marginTop: "15px",
              // backgroundColor: "red",
            }}
          >
            <div class="container3">
              <div>
                <div
                  onClick={() => {
                    setActiveStep(0);
                  }}
                >
                  <h4
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("step6")}
                  </h4>
                  <Button
                    className="buttonVerificacao"
                    style={{ textTransform: "capitalize" }}
                  >
                    <span className="resumo">{t("lojaSelecionada")}</span>
                    {lojaEscolhida}
                  </Button>
                </div>
                <div
                  onClick={() => {
                    setActiveStep(1);
                  }}
                >
                  <Button
                    className="buttonVerificacao"
                    style={{ textTransform: "none" }}
                  >
                    <span className="resumo">{t("nomeFicheiroAtualizar")}</span>
                    {nomeFicheiroAtualizar}
                  </Button>
                </div>
              </div>
              {/* Botão para download do csv */}
              <CSVLink
                data={csvData}
                filename={csvFilename}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button
                  class="card3 card6"
                  onClick={() => {
                    criarFicheiro();
                  }}
                >
                  <FontAwesomeIcon className="csv" icon={faDownload} />
                </Button>
                <p className="csvText">{t("exportar")}</p>
              </CSVLink>
            </div>
          </div>
        ) : null}
        <div>
          {allStepsCompleted() ? null : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1, py: 1 }}></Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                }}
              >
                <Button
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t("anterior")}
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep == 4 ? null : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      verificacao();
                    }}
                    sx={{ mr: 1 }}
                  >
                    {t("seguinte")}
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
      <Footer />
    </div>
  );
}
