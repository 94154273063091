import axios from "axios";
import { useTranslation } from "react-i18next";
import { handleResponse, handleError } from "./response";

//Base url
//  const BASE_URL = "http://192.168.1.91:8000";
// const BASE_URL = "http://localhost:8000";
const BASE_URL = "https://sapi.tectank.pt:8000";

let config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Content-Type,Access-Control-Allow-Origin, Accept",
    "Accept-Language": localStorage.getItem("language"),
  },
};

//Pedido Get - ler dados
export const makeRequestGet = (resource) => {
  return axios
    .get(`${BASE_URL}/${resource}`)
    .then(handleResponse)
    .catch(handleError);
};

//Pedido Post - adicionar dados
export const makeRequestPost = (resource, model) => {
  return axios
    .post(`${BASE_URL}/${resource}`, model, config)
    .then(handleResponse)
    .catch(handleError);
};

//Pedido Put - alterar dados
export const makeRequestPut = (resource, model) => {
  return axios
    .put(`${BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError);
};

// export const apiProvider = {
//   makeRequestGet,
//   makeRequestPost,
//   makeRequestPut,
// };
