import React, { useEffect, useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { IconContext } from "react-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faRightFromBracket,
  faBell,
  faHome,
  faUser,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../images/sapi-logo.png";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

function Header() {
  //Navigator
  const navigate = useNavigate();

  //Traduções
  const { t, i18n } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function Logout() {
    //Remover os dados e encaminhar para a página de Login
    localStorage.removeItem("id");
    localStorage.removeItem("nome");
    Cookies.remove("token");
    navigate("/");
  }

  return (
    <>
      <IconContext.Provider value={{ color: "undefined" }}>
        <div className="navbar">
          <div style={{ float: "left" }}>
            <li className="navbar-toggle" style={{ height: 0 }}>
              <img
                src={logo}
                style={{ width: "150px", height: "49px", cursor: "pointer" }}
                onClick={() => {
                  navigate("/home");
                }}
              />
            </li>
          </div>

          <div style={{ float: "right" }}>
            <li className="navbar-toggle" style={{ height: 0 }}>
              {/* {localStorage.getItem("id") == 4 ||
              localStorage.getItem("id") == 5 ? (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/translate");
                  }}
                >
                  <FontAwesomeIcon className="headerIcons" icon={faLanguage} />
                </div>
              ) : null} */}
              <div>
                <Link className="headerName">
                  {localStorage.getItem("nome")}
                </Link>
              </div>
              <div
                onClick={() => {
                  handleClickOpen();
                }}
              >
                <Link className="headerIcons">
                  <FontAwesomeIcon icon={faRightFromBracket} />
                </Link>
              </div>
            </li>
          </div>
        </div>
      </IconContext.Provider>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("terminarSessao")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("terminarSessaoTexto")}
            {t("terminarSessaoTexto2")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="success"
            onClick={() => {
              Logout();
            }}
            autoFocus
          >
            {t("sim")}
          </Button>
          <Button
            sx={{ borderRadius: "20px" }}
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            {t("nao")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Header;
