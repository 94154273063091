import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShopify, faWordpress } from "@fortawesome/free-brands-svg-icons";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import {json2xml} from 'xml-js';
import {
  faClose,
  faDownload,
  faL,
  faTag,
  faUpload,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { CSVDownload, CSVLink } from "react-csv";
import Footer from "../components/Footer";
import Cookies from "js-cookie";
import { Modal } from "react-bootstrap";
import video1 from "../videos/api1.mp4";
import video2 from "../videos/api2.mp4";

export default function Xml() {
  //Navigator
  const navigate = useNavigate();
  //Variáveis step 1
  const [aplicarDesconto, setAplicarDesconto] = useState(false);
  const [reverterDesconto, setReverterDesconto] = useState(false);

  //Variáveis step 2
  const [lojaEscolhida, setLojaEscolhida] = useState("");
  const [lojaEscolhidaS, setLojaEscolhidaS] = useState(false);
  const [lojaEscolhidaW, setLojaEscolhidaW] = useState(false);

  // Variáveis step 3
  const [ficheiroAtualizar, setFicheiroAtualizar] = useState([]);
  const [ficheiroFornecedor, setFicheiroFornecedor] = useState([]);
  const [nomeFicheiroAtualizar, setNomeFicheiroAtualizar] = useState("");
  const [nomeFicheiroFornecedor, setNomeFicheiroFornecedor] = useState("");

  //Variáveis step 4
  const [erroRetiraValor, setErroRetiraValor] = useState(false);
  const [percentagemRetirar, setPercentagemRetirar] = useState("");
  const [percentagemRetirarStatus, setPercentagemRetirarStatus] =
    useState(false);

  //Variável step 5
  const [csvData, setCsvData] = useState([]);
  const [csvFilename, setCsvFilename] = useState("");

  const [freeze, setFreeze] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  //Traduções
  const { t, i18n } = useTranslation();

  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const miliseconds = date.getMilliseconds();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  var data_ = 0;

  let monthC = month;
  if (month < 10) {
    monthC = "0" + month;
  }
  let day = date.getDate();
  if (date.getDate() < 10) {
    day = "0" + date.getDate();
  }
  data_ = `${day}-${monthC}-${year}`;

  const steps = [
    t("step1"),
    t("ativarAPI") + " 1",
    t("ativarAPI") + " 2",
    t("step6"),
  ];

  const options = [t("percentagem"), t("numerico")];
  const optionsIva = ["23 %", "13 %", "6 %"];

  useEffect(() => {
    if (csvData.length != 0) {
      setFreeze(false);
    }
  }, [csvData]);

  useEffect(() => {
    const id = localStorage.getItem("id");
    const token = Cookies.get("token");

    if (!id && !token) {
      navigate("/");
    }
  }, []);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    if (activeStep == 2 && lojaEscolhidaW) {
      setActiveStep(4);
    } else {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    if (activeStep == 4 && lojaEscolhidaW) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  function verificacao() {
    //Step 1
    if (activeStep == 0) {
      if (!lojaEscolhida) {
        toast.error(t("erroLoja"));
      } else {
        if (lojaEscolhidaS) {
          handleComplete();
        } else {
          alert("O processo não está feito");
        }
      }
    }
    // Step 2
    else if (activeStep == 1) {
      handleComplete();
    }
    else if (activeStep == 2) {
      handleComplete();
    }
    //Step 4
    else if (activeStep == 3) {
     
        handleComplete();

    }
  }


  function ficheiroAtualizarVerificacao(file) {
    let teste = file.name.split(".");
    setNomeFicheiroAtualizar(file.name);
    if (teste[1] == "tsv" || teste[1] == "csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setFicheiroAtualizar(results.data);
        },
      });
    } else if (teste[1] == "xlsx") {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 0 });

        setFicheiroAtualizar(dataParse);
      };
      reader.readAsBinaryString(file);
    }
  }

  function criarFicheiro() {
    //Array final
    let arrayFinal = [];
    var percentagemFinal = percentagemRetirar / 100;
    if (aplicarDesconto) {
      //Shopify
      if (lojaEscolhidaS) {
        ficheiroAtualizar.forEach((element) => {
          if (element["Handle"].replaceAll(",", "") != "") {
            const linha = {
              Handle: element["Handle"].replaceAll(",", ""),
              Title: element["Title"].replaceAll(",", ""),
              //"Body (HTML)": element["Body (HTML)"].replaceAll(',', ''),
              Vendor: element["Vendor"],
              "Product Category": element["Product Category"],
              Type: element["Type"].replaceAll(",", ""),
              Tags: element["Tags"].replaceAll(",", ""),
              Published: element["Published"],
              "Option1 Name": element["Option1 Name"],
              "Option1 Value": element["Option1 Value"],
              "Option2 Name": element["Option2 Name"],
              "Option2 Value": element["Option2 Value"],
              "Option3 Name": element["Option3 Name"],
              "Option3 Value": element["Option3 Value"],
              "Variant SKU": element["Variant SKU"],
              "Variant Grams": element["Variant Grams"],
              "Variant Inventory Tracker": element["Variant Inventory Tracker"],
              "Variant Inventory Qty": element["Variant Inventory Qty"],
              "Variant Inventory Policy": element["Variant Inventory Policy"],
              "Variant Fulfillment Service":
                element["Variant Fulfillment Service"],
              "Variant Price": (
                parseFloat(element["Variant Price"]) -
                parseFloat(element["Variant Price"]) * percentagemFinal
              ).toFixed(2),
              "Variant Compare At Price": parseFloat(element["Variant Price"]),
              "Variant Requires Shipping": element["Variant Requires Shipping"],
              "Variant Taxable": element["Variant Taxable"],
              "Variant Barcode": element["Variant Barcode"],
              "Image Src": element["Image Src"],
              "Image Position": element["Image Position"],
              "Image Alt Text": element["Image Alt Text"].replaceAll(",", ""),
              "Gift Card": element["Gift Card"],
              "SEO Title": element["SEO Title"].replaceAll(",", ""),
              "SEO Description": element["SEO Description"].replaceAll(",", ""),
              "Google Shopping / Google Product Category": element[
                "Google Shopping / Google Product Category"
              ].replaceAll(",", ""),
              "Google Shopping / Gender": element[
                "Google Shopping / Gender"
              ].replaceAll(",", ""),
              "Google Shopping / Age Group": element[
                "Google Shopping / Age Group"
              ].replaceAll(",", ""),
              "Google Shopping / MPN": element[
                "Google Shopping / MPN"
              ].replaceAll(",", ""),
              "Google Shopping / AdWords Grouping": element[
                "Google Shopping / AdWords Grouping"
              ].replaceAll(",", ""),
              "Google Shopping / AdWords Labels": element[
                "Google Shopping / AdWords Labels"
              ].replaceAll(",", ""),
              "Google Shopping / Condition": element[
                "Google Shopping / Condition"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 0": element[
                "Google Shopping / Custom Label 0"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 1": element[
                "Google Shopping / Custom Label 1"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 2": element[
                "Google Shopping / Custom Label 2"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 3": element[
                "Google Shopping / Custom Label 3"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 4": element[
                "Google Shopping / Custom Label 4"
              ].replaceAll(",", ""),
              "Variant Image": element["Variant Image"],
              "Variant Weight Unit": element["Variant Weight Unit"],
              "Variant Tax Code": element["Variant Tax Code"],
              "Cost per item": element["Cost per item"],
              "Price / Internacional": element["Price / Internacional"],
              "Compare At Price / Internacional":
                element["Compare At Price / Internacional"],
              Status: element["Status"],
            };
            arrayFinal.push(linha);
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "descontos_atualizados_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Wordpress
      else if (lojaEscolhidaW) {
        ficheiroAtualizar.forEach((element) => {
          if (element["REF"] != "") {
            const linha = {
              ID: element["ID"],
              REF: element["REF"],
              "Preço normal": element["Preço normal"],
              "Preço promocional": (
                parseFloat(element["Preço normal"].replace(",", ".")) -
                parseFloat(element["Preço normal"].replace(",", ".")) *
                  percentagemFinal
              ).toFixed(2),
              Publicado: element["Publicado"],
            };
            arrayFinal.push(linha);
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "descontos_atualizados_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
    } else if (reverterDesconto) {
      //Shopify
      if (lojaEscolhidaS) {
        ficheiroAtualizar.forEach((element) => {
          if (element["Handle"].replaceAll(",", "") != "") {
            const linha = {
              Handle: element["Handle"].replaceAll(",", ""),
              Title: element["Title"].replaceAll(",", ""),
              //"Body (HTML)": element["Body (HTML)"].replaceAll(',', ''),
              Vendor: element["Vendor"],
              "Product Category": element["Product Category"],
              Type: element["Type"].replaceAll(",", ""),
              Tags: element["Tags"].replaceAll(",", ""),
              Published: element["Published"],
              "Option1 Name": element["Option1 Name"],
              "Option1 Value": element["Option1 Value"],
              "Option2 Name": element["Option2 Name"],
              "Option2 Value": element["Option2 Value"],
              "Option3 Name": element["Option3 Name"],
              "Option3 Value": element["Option3 Value"],
              "Variant SKU": element["Variant SKU"],
              "Variant Grams": element["Variant Grams"],
              "Variant Inventory Tracker": element["Variant Inventory Tracker"],
              "Variant Inventory Qty": element["Variant Inventory Qty"],
              "Variant Inventory Policy": element["Variant Inventory Policy"],
              "Variant Fulfillment Service":
                element["Variant Fulfillment Service"],
              "Variant Price": (
                parseFloat(element["Variant Price"]) +
                parseFloat(element["Variant Compare At Price"]) *
                  percentagemFinal
              ).toFixed(2),
              "Variant Compare At Price": "",
              "Variant Requires Shipping": element["Variant Requires Shipping"],
              "Variant Taxable": element["Variant Taxable"],
              "Variant Barcode": element["Variant Barcode"],
              "Image Src": element["Image Src"],
              "Image Position": element["Image Position"],
              "Image Alt Text": element["Image Alt Text"].replaceAll(",", ""),
              "Gift Card": element["Gift Card"],
              "SEO Title": element["SEO Title"].replaceAll(",", ""),
              "SEO Description": element["SEO Description"].replaceAll(",", ""),
              "Google Shopping / Google Product Category": element[
                "Google Shopping / Google Product Category"
              ].replaceAll(",", ""),
              "Google Shopping / Gender": element[
                "Google Shopping / Gender"
              ].replaceAll(",", ""),
              "Google Shopping / Age Group": element[
                "Google Shopping / Age Group"
              ].replaceAll(",", ""),
              "Google Shopping / MPN": element[
                "Google Shopping / MPN"
              ].replaceAll(",", ""),
              "Google Shopping / AdWords Grouping": element[
                "Google Shopping / AdWords Grouping"
              ].replaceAll(",", ""),
              "Google Shopping / AdWords Labels": element[
                "Google Shopping / AdWords Labels"
              ].replaceAll(",", ""),
              "Google Shopping / Condition": element[
                "Google Shopping / Condition"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 0": element[
                "Google Shopping / Custom Label 0"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 1": element[
                "Google Shopping / Custom Label 1"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 2": element[
                "Google Shopping / Custom Label 2"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 3": element[
                "Google Shopping / Custom Label 3"
              ].replaceAll(",", ""),
              "Google Shopping / Custom Label 4": element[
                "Google Shopping / Custom Label 4"
              ].replaceAll(",", ""),
              "Variant Image": element["Variant Image"],
              "Variant Weight Unit": element["Variant Weight Unit"],
              "Variant Tax Code": element["Variant Tax Code"],
              "Cost per item": element["Cost per item"],
              "Price / Internacional": element["Price / Internacional"],
              "Compare At Price / Internacional":
                element["Compare At Price / Internacional"],
              Status: element["Status"],
            };
            arrayFinal.push(linha);
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "descontos_atualizados_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Wordpress
      else if (lojaEscolhidaW) {
        ficheiroAtualizar.forEach((element) => {
          if (
            element["REF"] != "" &&
            element["Preço promocional"] != "" &&
            element["Preço normal"] != ""
          ) {
            const linha = {
              ID: element["ID"],
              Tipo: element["Tipo"],
              REF: element["REF"],
              "Preço normal": element["Preço normal"],
              "Preço promocional": "",
              // Publicado: element["Publicado"],
              Pai: element["Pai"],
              "Atributo 1 nome": element["Atributo 1 nome"],
              "Atributo 1 valor(es)": element["Atributo 1 valor(es)"],
              "Atributo 1 global": element["Atributo 1 global"],
            };
            arrayFinal.push(linha);
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "descontos_atualizados_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
    }
  }


  const handleGenerateXML = async () => {
    try {
      const response = await axios.get('http://localhost:8000/api/products', {
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
      });
      const products = response.data.products;
  
      console.log(products); 
  
      const convert = require('xml-js');
  
      const xmlData = convert.js2xml({ products }, { compact: true, spaces: 4, ignoreComment: true });
     const final = '<product>' + xmlData + "</product>";
  
      const link = document.createElement('a');
      const xmlBlob = new Blob([final], { type: 'application/xml' });
      link.href = URL.createObjectURL(xmlBlob);
      link.download = 'products.xml';
      link.click();
  
    } catch (error) {
      console.error('Erro ao gerar o arquivo XML:', error);
    }
  };
  
  
  return (
    <div className="priceupdate">
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguardaCSV")}
              </Typography>
              <CircularProgress style={{ marginTop: "20px" }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <Box
        sx={{
          width: "80%",
          marginTop: "0px",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Button
          color="warning"
          variant="contained"
          style={{ float: "right" }}
          onClick={() => {
            setAplicarDesconto(false);
            setReverterDesconto(false);
            setLojaEscolhida("");
            setLojaEscolhidaS(false);
            setLojaEscolhidaW(false);
            setFicheiroAtualizar([]);
            setFicheiroFornecedor([]);
            setNomeFicheiroAtualizar("");
            setNomeFicheiroFornecedor("");
            setErroRetiraValor(false);
            setPercentagemRetirar("");
            setPercentagemRetirarStatus(false);
            setCsvData([]);
            setCsvFilename("");
            setActiveStep(0);
            setCompleted({});
          }}
          sx={{ mr: 1 }}
        >
          {t("recomercar")}
        </Button>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "50px",
            fontWeight: "bold",
            fontSize: "30px",
          }}
        >
          {t("modeloTagManager")}
        </h2>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                onClick={completed[3] ? handleStep(index) : null}
                style={{ boxShadow: "none" }}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {/* Step 1 */}
        {activeStep == 0 ? (
          <div
            style={{
              width: "80vw",
              height: "55vh",
              marginTop: "20px",
            }}
          >
            <div class="container2">
              {/* Shopify */}
              {!lojaEscolhidaW && (
                <div
                  class="card_ card4"
                  onClick={() => {
                    setLojaEscolhida("shopify");
                    setLojaEscolhidaS(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="priceupdateIcon"
                    icon={faShopify}
                  />
                </div>
              )}
              {/* Wordpress */}
              {!lojaEscolhidaS && (
                <div
                  class="card_ card5"
                  onClick={() => {
                    setLojaEscolhida("wordpress");
                    setLojaEscolhidaW(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="priceupdateIcon"
                    icon={faWordpress}
                  />
                </div>
              )}
            </div>
          </div>
        ) : null}
        {/* Step 2 */}
        {activeStep == 1 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              marginTop: "40px",
            }}
          >
            <p>
              Para poder proceder à criação do xml pelo modelo do tag manager é
              necessário aceder à loja de modo a ter acesso a toda a informação
              disponível.
            </p>
            <p>
              Primeiro, deves aceder à tua loja Shopify. Na barra lateral
              esquerda deves carregar em Aplicações. Esta ação irá abrir um
              popup, no qual deves selecionar a opção "Definições da aplicação e
              do canal de vendas".
            </p>
            <video
              width="500"
              height="300"
              controls
              style={{ margin: "0 auto", display: "block" }}
            >
              <source src={video1} type="video/mp4"></source>
            </video>
          </div>
        ) : null}
        {/* Step 3 */}
        {activeStep == 2 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              marginTop: "40px",
            }}
          >
            <p>
              De seguida, carregas em desenvolver aplicações. Serás
              reencaminhado para uma página onde irás carregar em Criar uma
              aplicação. Atribui um nome e cria a aplicação.
            </p>
            <p>
              Após criares a aplicação deves alterar a configuração e dar
              permissão à leitura e escrita de produtos. De seguida deves
              instalar a aplicação. Por fim, irás conseguir visualizar os dados
              que te serão pedidos no próximo passo para conseguirmos fazer a
              conexão à loja.
            </p>
            <video
              width="500"
              height="300"
              controls
              style={{ margin: "0 auto", display: "block" }}
            >
              <source src={video2} type="video/mp4"></source>
            </video>
          </div>
        ) : null}
        {/* Step 5 */}
        {activeStep == 3 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              marginTop: "15px",
            }}
          >
            <div class="container3">
              
        
      <button onClick={handleGenerateXML}>Gerar XML</button>
  
           
            </div>
          </div>
        ) : null}
        <div>
          {allStepsCompleted() ? null : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1, py: 1 }}></Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                }}
              >
                <Button
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t("anterior")}
                </Button>

                <Box sx={{ flex: "1 1 auto" }} />
                {completed[3] && activeStep == 4 ? null : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      verificacao();
                    }}
                    sx={{ mr: 1 }}
                  >
                    {t("seguinte")}
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
      <Footer />
    </div>
  );
}
