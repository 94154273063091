import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShopify, faWordpress } from "@fortawesome/free-brands-svg-icons";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  faClose,
  faDownload,
  faL,
  faUpload,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { CSVDownload, CSVLink } from "react-csv";
import Footer from "../components/Footer";
import Cookies from "js-cookie";
import { Modal } from "react-bootstrap";

export default function PriceUpdate() {
  //Navigator
  const navigate = useNavigate();
  //Variáveis step 1
  const [lojaEscolhida, setLojaEscolhida] = useState("");
  const [lojaEscolhidaS, setLojaEscolhidaS] = useState(false);
  const [lojaEscolhidaW, setLojaEscolhidaW] = useState(false);

  // Variáveis step 2
  const [ficheiroAtualizar, setFicheiroAtualizar] = useState([]);
  const [ficheiroFornecedor, setFicheiroFornecedor] = useState([]);
  const [nomeFicheiroAtualizar, setNomeFicheiroAtualizar] = useState("");
  const [nomeFicheiroFornecedor, setNomeFicheiroFornecedor] = useState("");

  //Variáveis step 3
  const [colunasFicheiroFornecedor, setColunasFicheiroFornecedor] = useState(
    []
  );
  const [colunasFicheiroFornecedor2, setColunasFicheiroFornecedor2] = useState(
    []
  );
  const [colunaReferencia, setColunaReferencia] = useState("");
  const [colunaPreco, setColunaPreco] = useState("");
  const [referenciaErro, setReferenciaErro] = useState(false);
  const [precoErro, setPrecoErro] = useState(false);

  //Variáveis step 4
  const [prefixoStatusSim, setPrefixoStatusSim] = useState(false);
  const [prefixoStatusNao, setPrefixoStatusNao] = useState(false);
  const [sufixoStatusSim, setSufixoStatusSim] = useState(false);
  const [sufixoStatusNao, setSufixoStatusNao] = useState(false);
  const [erroPrefixo, setErroPrefixo] = useState(false);
  const [erroSufixo, setErroSufixo] = useState(false);
  const [sufixo, setSufixo] = useState("");
  const [prefixo, setPrefixo] = useState("");

  //variaveis step 5
  const [retiraValorSim, setRetiraValorSim] = useState(false);
  const [retiraValorNao, setRetiraValorNao] = useState(false);
  const [erroRetiraValor, setErroRetiraValor] = useState(false);
  const [adicionaValorSim, setAdicionaValorSim] = useState(false);
  const [adicionaValorNao, setAdicionaValorNao] = useState(false);
  const [erroAdicionaValor, setErroAdicionaValor] = useState(false);
  const [erroAdicionaTipoValor, setErroAdicionaTipoValor] = useState(false);
  const [tipoValor, setTipoValor] = useState("");
  const [percentagemRetirar, setPercentagemRetirar] = useState("");
  const [percentagemRetirarStatus, setPercentagemRetirarStatus] =
    useState(false);
  const [percentagemAdicionar, setPercentagemAdicionar] = useState("");
  const [valorAdicionar, setValorAdicionar] = useState("");
  const [valorAdicionarStatus, setValorAdicionarStatus] = useState(false);
  const [adicionaIvaSim, setAdicionaIvaSim] = useState(false);
  const [adicionaIvaNao, setAdicionaIvaNao] = useState(false);
  const [erroIva, setErroIva] = useState(false);
  const [valorIva, setValorIva] = useState("");
  const [atualizarInventarioSim, setAtualizarInventarioSim] = useState(false);
  const [atualizarInventarioNao, setAtualizarInventarioNao] = useState(false);
  const [resumoStep5, setResumoStep5] = useState("");

  //Variável step 6
  const [alteracaoPrecoOpcao, setAlteracaoPrecoOpcao] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [csvFilename, setCsvFilename] = useState("");

  const [freeze, setFreeze] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  //Traduções
  const { t, i18n } = useTranslation();

  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const miliseconds = date.getMilliseconds();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  var data_ = 0;

  let monthC = month;
  if (month < 10) {
    monthC = "0" + month;
  }
  let day = date.getDate();
  if (date.getDate() < 10) {
    day = "0" + date.getDate();
  }
  data_ = `${day}-${monthC}-${year}`;

  const steps = [
    t("step1"),
    t("step2"),
    t("step3"),
    t("step4"),
    t("step5"),
    t("step6"),
  ];

  const options = [t("percentagem"), t("numerico")];
  const optionsIva = ["23 %", "13 %", "6 %"];

  useEffect(() => {
    if (csvData.length != 0) {
      setFreeze(false);
    }
  }, [csvData]);

  useEffect(() => {
    const id = localStorage.getItem("id");
    const token = Cookies.get("token");

    if (!id && !token) {
      navigate("/");
    }
  }, []);

  //Atualizar array quando coluna da referência e do preço são atualizados
  useEffect(() => {
    let arrayFinal = [];
    if (colunaReferencia && colunaPreco) {
      colunasFicheiroFornecedor2.forEach((element) => {
        if (element != colunaReferencia && element != colunaPreco) {
          arrayFinal.push(element);
        }
      });
      setColunasFicheiroFornecedor(arrayFinal);
    } else if (!colunaReferencia && colunaPreco) {
      colunasFicheiroFornecedor2.forEach((element) => {
        if (element != colunaPreco) {
          arrayFinal.push(element);
        }
      });
      setColunasFicheiroFornecedor(arrayFinal);
    } else if (colunaReferencia && !colunaPreco) {
      colunasFicheiroFornecedor2.forEach((element) => {
        if (element != colunaReferencia) {
          arrayFinal.push(element);
        }
      });
      setColunasFicheiroFornecedor(arrayFinal);
    } else if (!colunaReferencia && !colunaPreco) {
      setColunasFicheiroFornecedor(colunasFicheiroFornecedor2);
    }
  }, [colunaReferencia, colunaPreco]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  function verificacao() {
    //Step 1
    if (activeStep == 0) {
      if (!lojaEscolhida) {
        toast.error(t("erroLoja"));
      } else {
        handleComplete();
      }
    }
    //Step 2
    else if (activeStep == 1) {
      if (!ficheiroAtualizar.length || !ficheiroFornecedor.length) {
        if (!ficheiroAtualizar.length && !ficheiroFornecedor.length) {
          toast.error(t("erroFicheiros"));
        } else if (
          ficheiroAtualizar.length != 0 &&
          ficheiroFornecedor.length == 0
        ) {
          toast.error(t("erroFicheiroFornecedor"));
        } else if (
          ficheiroAtualizar.length == 0 &&
          ficheiroFornecedor.length != 0
        ) {
          toast.error(t("erroFicheiroAtualizar"));
        }
      } else {
        handleComplete();
      }
    }
    //Step 3
    else if (activeStep == 2) {
      if (colunaPreco && colunaReferencia) {
        if (colunaReferencia == colunaPreco) {
          toast.error(t("erroColunas"));
        } else {
          handleComplete();
        }
      } else {
        if (colunaPreco && !colunaReferencia) {
          toast.error(t("erroColunaReferencia"));
          setReferenciaErro(true);
        } else if (!colunaPreco && colunaReferencia) {
          toast.error(t("erroColunaPreco"));
          setPrecoErro(true);
        } else if (!colunaPreco && !colunaReferencia) {
          setReferenciaErro(true);
          setPrecoErro(true);
          toast.error(t("erroColunasVazias"));
        }
      }
    }
    //Step 4
    else if (activeStep == 3) {
      if (prefixoStatusSim && sufixoStatusSim) {
        if (prefixo && sufixo) {
          handleComplete();
        } else if (!prefixo && sufixo) {
          toast.error(t("indicarPrefixo"));
          setErroPrefixo(true);
        } else if (prefixo && !sufixo) {
          toast.error(t("indicarSufixo"));
          setErroSufixo(true);
        } else if (!prefixo && !sufixo) {
          toast.error(t("presuf"));
          setErroPrefixo(true);
          setErroSufixo(true);
        }
      } else {
        if (prefixoStatusSim && !sufixoStatusSim) {
          if (!prefixo) {
            toast.error(t("indicarPrefixo"));
            setErroPrefixo(true);
          } else {
            handleComplete();
          }
        } else if (!prefixoStatusSim && sufixoStatusSim) {
          if (!sufixo) {
            toast.error(t("indicarSufixo"));
            setErroSufixo(true);
          } else {
            handleComplete();
          }
        } else if (!prefixoStatusSim && !sufixoStatusSim) {
          handleComplete();
        }
      }
    }
    //Step 5
    else if (activeStep == 4) {
      if (
        (retiraValorSim || retiraValorNao) &&
        (adicionaValorSim || adicionaValorNao) &&
        (adicionaIvaSim || adicionaIvaNao) &&
        (atualizarInventarioSim || atualizarInventarioNao)
      ) {
        //Retirar preço
        if (
          retiraValorSim &&
          !adicionaValorSim &&
          !adicionaIvaSim &&
          !atualizarInventarioSim
        ) {
          if (percentagemRetirar && percentagemRetirar < 100) {
            setResumoStep5(
              t("retirarPreco") + " - " + percentagemRetirar + " %"
            );
            setAlteracaoPrecoOpcao(1);
            handleComplete();
          } else {
            setErroRetiraValor(true);
            toast.error(t("percentagemRetirar"));
          }
        }
        //Retirar preço + iva
        else if (
          retiraValorSim &&
          !adicionaValorSim &&
          adicionaIvaSim &&
          !atualizarInventarioSim
        ) {
          if (percentagemRetirar && percentagemRetirar < 100 && valorIva) {
            setAlteracaoPrecoOpcao(2);
            setResumoStep5(
              t("retirarPreco") +
                " - " +
                percentagemRetirar +
                " % " +
                t("e") +
                t("adicionarIva2") +
                " - " +
                valorIva
            );
            handleComplete();
          } else {
            if (!percentagemRetirar && valorIva) {
              setErroRetiraValor(true);
              toast.error(t("percentagemRetirar"));
            } else if (
              percentagemRetirar &&
              percentagemRetirar < 100 &&
              !valorIva
            ) {
              setErroIva(true);
              toast.error(t("erroIva"));
            } else if (percentagemRetirar >= 100 && !valorIva) {
              setErroRetiraValor(true);
              setErroIva(true);
              toast.error(t("percentagemErro") + " " + t("erroIva"));
            } else if (percentagemRetirar >= 100) {
              setErroRetiraValor(true);
              toast.error(t("percentagemErro"));
            } else {
              setErroRetiraValor(true);
              setErroIva(true);
              toast.error(t("preencherCampos"));
            }
          }
        }
        //Adicionar preço percentagem + IVA
        else if (
          !retiraValorSim &&
          adicionaValorSim &&
          adicionaIvaSim &&
          !atualizarInventarioSim &&
          tipoValor == t("percentagem")
        ) {
          if (tipoValor && valorAdicionar && valorIva) {
            setAlteracaoPrecoOpcao(3);
            setResumoStep5(
              t("adicionarPreco") +
                " - " +
                valorAdicionar +
                " % " +
                t("e") +
                t("adicionarIva2") +
                " - " +
                valorIva
            );
            handleComplete();
          } else {
            if (!tipoValor || !valorAdicionar || !valorIva) {
              if (!tipoValor) {
                setErroAdicionaTipoValor(true);
              }
              if (!valorAdicionar) {
                setErroAdicionaValor(true);
              }
              if (!valorIva) {
                setErroIva(true);
              }
              toast.error(t("preencherCampos"));
            } else if (tipoValor && valorAdicionar && valorIva) {
              setAlteracaoPrecoOpcao(3);
              setResumoStep5(
                t("adicionarPreco") +
                  " - " +
                  valorAdicionar +
                  " % " +
                  t("e") +
                  t("adicionarIva2") +
                  " - " +
                  valorIva
              );
              handleComplete();
            }
          }
        }

        //Adicionar preço numérico
        else if (
          !retiraValorSim &&
          adicionaValorSim &&
          !adicionaIvaSim &&
          !atualizarInventarioSim &&
          tipoValor == t("numerico")
        ) {
          if (valorAdicionar) {
            setAlteracaoPrecoOpcao(4);
            setResumoStep5(
              t("adicionarPreco") + " - " + valorAdicionar + " " + t("cIva")
            );
            handleComplete();
          } else {
            setErroAdicionaValor(true);
            toast.error(t("preencherCampos"));
          }
        }

        //Adicionar IVA
        else if (
          !retiraValorSim &&
          !adicionaValorSim &&
          adicionaIvaSim &&
          !atualizarInventarioSim
        ) {
          if (valorIva) {
            setAlteracaoPrecoOpcao(5);
            setResumoStep5(t("adicionarIva") + " - " + valorIva);
            handleComplete();
          } else {
            setErroIva(true);
            toast.error(t("preencherCampos"));
          }
        }

        // Atualização de inventário
        else if (
          !retiraValorSim &&
          !adicionaValorSim &&
          !adicionaIvaSim &&
          atualizarInventarioSim
        ) {
          setAlteracaoPrecoOpcao(6);
          setResumoStep5(t("inventario"));
          handleComplete();
        } else {
          toast.error(t("verificarOpcoes"));
        }
      } else {
        if (atualizarInventarioSim) {
          setAlteracaoPrecoOpcao(6);
          setResumoStep5(t("inventario"));
          handleComplete();
        } else {
          toast.error(t("responderOpcoes"));
        }
      }
    }
  }

  function ficheiroAtualizarVerificacao(file) {
    let teste = file.name.split(".");
    setNomeFicheiroAtualizar(file.name);
    if (teste[1] == "tsv" || teste[1] == "csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          let dataParse = results.data;
          setFicheiroAtualizar(results.data);
        },
      });
    } else if (teste[1] == "xlsx") {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 0 });
        setFicheiroAtualizar(dataParse);
      };
      reader.readAsBinaryString(file);
    }
  }

  function ficheiroFornecedorVerificacao(file) {
    let teste = file.name.split(".");
    setNomeFicheiroFornecedor(file.name);
    if (teste[1] == "tsv" || teste[1] == "csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setFicheiroFornecedor(results.data);

          let ficheiro = results.data;
          let colunas = Object.keys(ficheiro[0]);

          let i = 1;
          let arrayColunas = [];
          colunas.forEach((element) => {
            let linha = {
              value: i,
              inputValue: element,
            };
            arrayColunas.push(linha);
            i++;
          });

          setColunasFicheiroFornecedor(colunas);
          setColunasFicheiroFornecedor2(colunas);
        },
      });
    } else if (teste[1] == "xlsx") {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 0 });

        setFicheiroFornecedor(dataParse);
      };
      reader.readAsBinaryString(file);
    }
  }

  async function criarFicheiro() {
    setCsvData([]);
    // setFreeze(true);

    //Array final fornecedor
    let arrayFinalFornecedor = [];

    //Array final
    let arrayFinal = [];

    //Prefixo
    if (prefixo && !sufixo) {
      //Reescrever array do fornecedor com as colunas ref (c/prefixo) e preco
      ficheiroFornecedor.map((value) => {
        const linha = {
          ref: prefixo + value[colunaReferencia],
          preco: parseFloat(value[colunaPreco]),
        };
        arrayFinalFornecedor.push(linha);
      });
    }
    //Sufixo
    else if (!prefixo && sufixo) {
      //Reescrever array do fornecedor com as colunas ref (c/sufixo) e preco
      ficheiroFornecedor.map((value) => {
        const linha = {
          ref: value[colunaReferencia] + sufixo,
          preco: parseFloat(value[colunaPreco]),
        };
        arrayFinalFornecedor.push(linha);
      });
    }
    //Ambos
    else if (prefixo && sufixo) {
      //Reescrever array do fornecedor com as colunas ref (c/prefixo e sufixo) e preco
      ficheiroFornecedor.map((value) => {
        const linha = {
          ref: prefixo + value[colunaReferencia] + sufixo,
          preco: parseFloat(value[colunaPreco]),
        };
        arrayFinalFornecedor.push(linha);
      });
    }
    //Nenhum
    else if (!prefixo && !sufixo) {
      //Reescrever array do fornecedor com as colunas ref (s/prefixo e sem sufixo) e preco
      ficheiroFornecedor.map((value) => {
        const linha = {
          ref: value[colunaReferencia],
          preco: parseFloat(value[colunaPreco]),
        };
        arrayFinalFornecedor.push(linha);
      });
    }
    //Shopify
    if (lojaEscolhidaS) {
      //Retirar preço
      if (alteracaoPrecoOpcao == 1) {
        //Ficheiro final para Shopify
        ficheiroAtualizar.forEach((element) => {
          let teste = arrayFinalFornecedor.find(
            (element2) => element["Variant SKU"] == element2.ref
          );

          if (teste != undefined) {
            const linha = {
              Handle: element["Handle"].replaceAll(",", ""),
              Title: element["Title"].replaceAll(",", ""),
              "Variant SKU": element["Variant SKU"],
              "Variant Price": (
                (teste.preco - teste.preco * percentagemRetirar) /
                100
              ).toFixed(2),
              "Option1 Name": element["Option1 Name"],
              "Option1 Value": element["Option1 Value"],
              "Option2 Name": element["Option2 Name"],
              "Option2 Value": element["Option2 Value"],
              "Option3 Name": element["Option3 Name"],
              "Option3 Value": element["Option3 Value"],
            };
            arrayFinal.push(linha);
          } else {
            if (element["Handle"].replaceAll(",", "") != "") {
              const linha = {
                Handle: element["Handle"].replaceAll(",", ""),
                Title: element["Title"].replaceAll(",", ""),
                "Variant SKU": element["Variant SKU"],
                "Variant Price": element["Variant Price"],
                "Option1 Name": element["Option1 Name"],
                "Option1 Value": element["Option1 Value"],
                "Option2 Name": element["Option2 Name"],
                "Option2 Value": element["Option2 Value"],
                "Option3 Name": element["Option3 Name"],
                "Option3 Value": element["Option3 Value"],
              };
              arrayFinal.push(linha);
            }
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Retirar preço (%) + IVA
      else if (alteracaoPrecoOpcao == 2) {
        let iva = valorIva.split(" ");
        let vIva = parseInt(iva[0]);
        let valorFinalIva;

        if (vIva == 23) {
          valorFinalIva = 1.23;
        } else if (vIva == 13) {
          valorFinalIva = 1.13;
        } else if (vIva == 6) {
          valorFinalIva = 1.06;
        }

        //Ficheiro final para Shopify
        ficheiroAtualizar.forEach((element) => {
          let teste = arrayFinalFornecedor.find(
            (element2) => element["Variant SKU"] == element2.ref
          );
          if (teste != undefined) {
            const linha = {
              Handle: element["Handle"].replaceAll(",", ""),
              Title: element["Title"].replaceAll(",", ""),
              "Variant SKU": element["Variant SKU"],
              "Variant Price": (
                (teste.preco - (teste.preco * percentagemRetirar) / 100) *
                valorFinalIva
              ).toFixed(2),
              "Option1 Name": element["Option1 Name"],
              "Option1 Value": element["Option1 Value"],
              "Option2 Name": element["Option2 Name"],
              "Option2 Value": element["Option2 Value"],
              "Option3 Name": element["Option3 Name"],
              "Option3 Value": element["Option3 Value"],
            };
            arrayFinal.push(linha);
          } else {
            if (element["Handle"].replaceAll(",", "") != "") {
              const linha = {
                Handle: element["Handle"].replaceAll(",", ""),
                Title: element["Title"].replaceAll(",", ""),
                "Variant SKU": element["Variant SKU"],
                "Variant Price": element["Variant Price"],
                "Option1 Name": element["Option1 Name"],
                "Option1 Value": element["Option1 Value"],
                "Option2 Name": element["Option2 Name"],
                "Option2 Value": element["Option2 Value"],
                "Option3 Name": element["Option3 Name"],
                "Option3 Value": element["Option3 Value"],
              };
              arrayFinal.push(linha);
            }
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Adicionar preço (%) + IVA
      else if (alteracaoPrecoOpcao == 3) {
        let iva = valorIva.split(" ");
        let vIva = parseInt(iva[0]);
        let valorFinalIva;

        if (vIva == 23) {
          valorFinalIva = 1.23;
        } else if (vIva == 13) {
          valorFinalIva = 1.13;
        } else if (vIva == 6) {
          valorFinalIva = 1.06;
        }

        //Ficheiro final para Shopify
        ficheiroAtualizar.forEach((element) => {
          let teste = arrayFinalFornecedor.find(
            (element2) => element["Variant SKU"] == element2.ref
          );
          if (teste != undefined) {
            const linha = {
              Handle: element["Handle"].replaceAll(",", ""),
              Title: element["Title"].replaceAll(",", ""),
              "Variant SKU": element["Variant SKU"],
              "Variant Price": (
                (parseFloat(teste.preco) +
                  (teste.preco * valorAdicionar) / 100) *
                valorFinalIva
              ).toFixed(2),
              "Option1 Name": element["Option1 Name"],
              "Option1 Value": element["Option1 Value"],
              "Option2 Name": element["Option2 Name"],
              "Option2 Value": element["Option2 Value"],
              "Option3 Name": element["Option3 Name"],
              "Option3 Value": element["Option3 Value"],
            };
            arrayFinal.push(linha);
          } else {
            if (element["Handle"].replaceAll(",", "") != "") {
              const linha = {
                Handle: element["Handle"].replaceAll(",", ""),
                Title: element["Title"].replaceAll(",", ""),
                "Variant SKU": element["Variant SKU"],
                "Variant Price": element["Variant Price"],
                "Option1 Name": element["Option1 Name"],
                "Option1 Value": element["Option1 Value"],
                "Option2 Name": element["Option2 Name"],
                "Option2 Value": element["Option2 Value"],
                "Option3 Name": element["Option3 Name"],
                "Option3 Value": element["Option3 Value"],
              };
              arrayFinal.push(linha);
            }
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Adicionar preço (numérico - c/IVA)
      else if (alteracaoPrecoOpcao == 4) {
        //Ficheiro final para Shopify
        ficheiroAtualizar.forEach((element) => {
          let teste = arrayFinalFornecedor.find(
            (element2) => element["Variant SKU"] == element2.ref
          );
          if (teste != undefined) {
            const linha = {
              Handle: element["Handle"].replaceAll(",", ""),
              Title: element["Title"].replaceAll(",", ""),
              "Variant SKU": element["Variant SKU"],
              "Variant Price": (
                parseFloat(teste.preco) * parseFloat(valorAdicionar)
              ).toFixed(2),
              "Option1 Name": element["Option1 Name"],
              "Option1 Value": element["Option1 Value"],
              "Option2 Name": element["Option2 Name"],
              "Option2 Value": element["Option2 Value"],
              "Option3 Name": element["Option3 Name"],
              "Option3 Value": element["Option3 Value"],
            };
            arrayFinal.push(linha);
          } else {
            if (element["Handle"].replaceAll(",", "") != "") {
              const linha = {
                Handle: element["Handle"].replaceAll(",", ""),
                Title: element["Title"].replaceAll(",", ""),
                "Variant SKU": element["Variant SKU"],
                "Variant Price": element["Variant Price"],
                "Option1 Name": element["Option1 Name"],
                "Option1 Value": element["Option1 Value"],
                "Option2 Name": element["Option2 Name"],
                "Option2 Value": element["Option2 Value"],
                "Option3 Name": element["Option3 Name"],
                "Option3 Value": element["Option3 Value"],
              };
              arrayFinal.push(linha);
            }
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Adicionar IVA
      else if (alteracaoPrecoOpcao == 5) {
        let iva = valorIva.split(" ");
        let vIva = parseInt(iva[0]);
        let valorFinalIva;

        if (vIva == 23) {
          valorFinalIva = 1.23;
        } else if (vIva == 13) {
          valorFinalIva = 1.13;
        } else if (vIva == 6) {
          valorFinalIva = 1.06;
        }

        //Ficheiro final para Shopify
        ficheiroAtualizar.forEach((element) => {
          let teste = arrayFinalFornecedor.find(
            (element2) => element["Variant SKU"] == element2.ref
          );
          if (teste != undefined) {
            const linha = {
              Handle: element["Handle"].replaceAll(",", ""),
              Title: element["Title"].replaceAll(",", ""),
              "Variant SKU": element["Variant SKU"],
              "Variant Price": (
                parseFloat(teste.preco) * valorFinalIva
              ).toFixed(2),
              "Option1 Name": element["Option1 Name"],
              "Option1 Value": element["Option1 Value"],
              "Option2 Name": element["Option2 Name"],
              "Option2 Value": element["Option2 Value"],
              "Option3 Name": element["Option3 Name"],
              "Option3 Value": element["Option3 Value"],
            };
            arrayFinal.push(linha);
          } else {
            if (element["Handle"].replaceAll(",", "") != "") {
              const linha = {
                Handle: element["Handle"].replaceAll(",", ""),
                Title: element["Title"].replaceAll(",", ""),
                "Variant SKU": element["Variant SKU"],
                "Variant Price": element["Variant Price"],
                "Option1 Name": element["Option1 Name"],
                "Option1 Value": element["Option1 Value"],
                "Option2 Name": element["Option2 Name"],
                "Option2 Value": element["Option2 Value"],
                "Option3 Name": element["Option3 Name"],
                "Option3 Value": element["Option3 Value"],
              };
              arrayFinal.push(linha);
            }
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Atualizar inventário
      else if (alteracaoPrecoOpcao == 6) {
        //Ficheiro final para Shopify
        ficheiroAtualizar.forEach((element) => {
          let teste = arrayFinalFornecedor.find(
            (element2) => element["Variant SKU"] == element2.ref
          );

          let html1 = element["Body (HTML)"].replace(/\\'/g, "'");
          let html2 = html1.replace(/\\"/g, '"');
          let html3 = html2.replace(",", "");

          if (teste != undefined) {
            const linha = {
              Handle: element["Handle"].replaceAll(",", ""),
              Title: element["Title"].replaceAll(",", ""),
              "Variant SKU": element["Variant SKU"],
              "Variant Price": teste.preco,
              "Option1 Name": element["Option1 Name"],
              "Option1 Value": element["Option1 Value"],
              "Option2 Name": element["Option2 Name"],
              "Option2 Value": element["Option2 Value"],
              "Option3 Name": element["Option3 Name"],
              "Option3 Value": element["Option3 Value"],
            };
            arrayFinal.push(linha);
          } else {
            if (
              element["Handle"].replaceAll(",", "") != "" &&
              element["Variant SKU"] != ""
            ) {
              const linha = {
                Handle: element["Handle"].replaceAll(",", ""),
                Title: element["Title"].replaceAll(",", ""),
                "Variant SKU": element["Variant SKU"],
                "Variant Price": element["Variant Price"],
                "Option1 Name": element["Option1 Name"],
                "Option1 Value": element["Option1 Value"],
                "Option2 Name": element["Option2 Name"],
                "Option2 Value": element["Option2 Value"],
                "Option3 Name": element["Option3 Name"],
                "Option3 Value": element["Option3 Value"],
              };
              arrayFinal.push(linha);
            }
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
    }
    //Wordpress
    else if (lojaEscolhidaW) {
      //Retirar preço
      if (alteracaoPrecoOpcao == 1) {
        //Ficheiro final para Wordpress
        ficheiroAtualizar.forEach((element) => {
          let teste = arrayFinalFornecedor.find(
            (element2) => element["REF"] == element2.ref
          );
          if (teste != undefined) {
            const linha = {
              ID: element["ID"],
              REF: element["REF"],
              "Preço normal": (
                teste.preco -
                (teste.preco * percentagemRetirar) / 100
              ).toFixed(2),
              Publicado: parseFloat(element["Publicado"]),
            };
            arrayFinal.push(linha);
          } else {
            if (element["REF"] != "") {
              const linha = {
                ID: element["ID"],
                REF: element["REF"],
                "Preço normal": parseFloat(element["Preço normal"]),
                Publicado: 0,
              };
              arrayFinal.push(linha);
            }
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Retirar preço (%) + IVA
      else if (alteracaoPrecoOpcao == 2) {
        let iva = valorIva.split(" ");
        let vIva = parseInt(iva[0]);
        let valorFinalIva;

        if (vIva == 23) {
          valorFinalIva = 1.23;
        } else if (vIva == 13) {
          valorFinalIva = 1.13;
        } else if (vIva == 6) {
          valorFinalIva = 1.06;
        }

        //Ficheiro final para Wordpress
        ficheiroAtualizar.forEach((element) => {
          let teste = arrayFinalFornecedor.find(
            (element2) => element["REF"] == element2.ref
          );
          if (teste != undefined) {
            const linha = {
              ID: element["ID"],
              REF: element["REF"],
              "Preço normal": (
                (teste.preco - (teste.preco * percentagemRetirar) / 100) *
                valorFinalIva
              ).toFixed(2),
              Publicado: parseFloat(element["Publicado"]),
            };
            arrayFinal.push(linha);
          } else {
            if (element["REF"] != "") {
              const linha = {
                ID: element["ID"],
                REF: element["REF"],
                "Preço normal": parseFloat(element["Preço normal"]),
                Publicado: 0,
              };
              arrayFinal.push(linha);
            }
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Adicionar preço (%) + IVA
      else if (alteracaoPrecoOpcao == 3) {
        let iva = valorIva.split(" ");
        let vIva = parseInt(iva[0]);
        let valorFinalIva;

        if (vIva == 23) {
          valorFinalIva = 1.23;
        } else if (vIva == 13) {
          valorFinalIva = 1.13;
        } else if (vIva == 6) {
          valorFinalIva = 1.06;
        }

        //Ficheiro final para Wordpress
        ficheiroAtualizar.forEach((element) => {
          ficheiroAtualizar.forEach((element) => {
            let teste = arrayFinalFornecedor.find(
              (element2) => element["REF"] == element2.ref
            );
            if (teste != undefined) {
              const linha = {
                ID: element["ID"],
                REF: element["REF"],
                "Preço normal": (
                  (parseFloat(teste.preco) +
                    (teste.preco * valorAdicionar) / 100) *
                  valorFinalIva
                ).toFixed(2),
                Publicado: parseFloat(element["Publicado"]),
              };
              arrayFinal.push(linha);
            } else {
              if (element["REF"] != "") {
                const linha = {
                  ID: element["ID"],
                  REF: element["REF"],
                  "Preço normal": parseFloat(element["Preço normal"]),
                  Publicado: 0,
                };
                arrayFinal.push(linha);
              }
            }
          });
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Adicionar preço (numérico - c/IVA)
      else if (alteracaoPrecoOpcao == 4) {
        //Ficheiro final para Wordpress
        ficheiroAtualizar.forEach((element) => {
          let teste = arrayFinalFornecedor.find(
            (element2) => element["REF"] == element2.ref
          );
          if (teste != undefined) {
            const linha = {
              ID: element["ID"],
              REF: element["REF"],
              "Preço normal": (
                parseFloat(teste.preco) * parseFloat(valorAdicionar)
              ).toFixed(2),
              Publicado: parseFloat(element["Publicado"]),
            };
            arrayFinal.push(linha);
          } else {
            if (element["REF"] != "") {
              const linha = {
                ID: element["ID"],
                REF: element["REF"],
                "Preço normal": parseFloat(element["Preço normal"]),
                Publicado: 0,
              };
              arrayFinal.push(linha);
            }
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Adicionar IVA
      else if (alteracaoPrecoOpcao == 5) {
        let iva = valorIva.split(" ");
        let vIva = parseInt(iva[0]);
        let valorFinalIva;

        if (vIva == 23) {
          valorFinalIva = 1.23;
        } else if (vIva == 13) {
          valorFinalIva = 1.13;
        } else if (vIva == 6) {
          valorFinalIva = 1.06;
        }

        //Ficheiro final para Wordpress
        ficheiroAtualizar.forEach((element) => {
          let teste = arrayFinalFornecedor.find(
            (element2) => element["REF"] == element2.ref
          );
          if (teste != undefined) {
            const linha = {
              ID: element["ID"],
              REF: element["REF"],
              "Preço normal": (parseFloat(teste.preco) * valorFinalIva).toFixed(
                2
              ),
              Publicado: parseFloat(element["Publicado"]),
            };
            arrayFinal.push(linha);
          } else {
            if (element["REF"] != "") {
              const linha = {
                ID: element["ID"],
                REF: element["REF"],
                "Preço normal": parseFloat(element["Preço normal"]),
                Publicado: 0,
              };
              arrayFinal.push(linha);
            }
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
      //Atualizar inventário
      else if (alteracaoPrecoOpcao == 6) {
        //Ficheiro final para Wordpress
        ficheiroAtualizar.forEach((element) => {
          let teste = arrayFinalFornecedor.find(
            (element2) => element["REF"] == element2.ref
          );
          if (teste != undefined) {
            const linha = {
              ID: element["ID"],
              REF: element["REF"],
              "Preço normal": parseFloat(teste.preco),
              Publicado: parseFloat(element["Publicado"]),
            };
            arrayFinal.push(linha);
          } else {
            if (element["REF"] != "") {
              const linha = {
                ID: element["ID"],
                REF: element["REF"],
                "Preço normal": parseFloat(element["Preço normal"]),
                Publicado: 0,
              };
              arrayFinal.push(linha);
            }
          }
        });

        //Verificar que o array não está vazio
        if (arrayFinal.length != 0) {
          toast.success(t("downloadSucesso"));
          setCsvFilename(
            "lista_atualizada_" +
              data_ +
              "_" +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              ":" +
              miliseconds +
              ".csv"
          );
          setCsvData(arrayFinal);
        } else {
          toast.error(t("erro"));
        }
      }
    }
  }

  return (
    <div className="priceupdate">
      <Header />
      {freeze && (
        <div
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Dialog
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              textAlign: "center",
            }}
          >
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("aguardaCSV")}
              </Typography>
              <CircularProgress style={{ marginTop: "20px" }} />
            </DialogContent>
          </Dialog>
        </div>
      )}

      <Box
        sx={{
          width: "80%",
          marginTop: "0px",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Button
          color="warning"
          variant="contained"
          style={{ float: "right" }}
          onClick={() => {
            setLojaEscolhida("");
            setLojaEscolhidaS(false);
            setLojaEscolhidaW(false);
            setFicheiroAtualizar([]);
            setFicheiroFornecedor([]);
            setNomeFicheiroAtualizar("");
            setNomeFicheiroFornecedor("");
            setColunasFicheiroFornecedor([]);
            setColunasFicheiroFornecedor2([]);
            setColunaReferencia("");
            setColunaPreco("");
            setReferenciaErro(false);
            setPrecoErro(false);
            setPrefixoStatusSim(false);
            setPrefixoStatusNao(false);
            setSufixoStatusSim(false);
            setSufixoStatusNao(false);
            setErroPrefixo(false);
            setErroSufixo(false);
            setSufixo("");
            setPrefixo("");
            setRetiraValorSim(false);
            setRetiraValorNao(false);
            setErroRetiraValor(false);
            setAdicionaValorSim(false);
            setAdicionaValorNao(false);
            setErroAdicionaValor(false);
            setErroAdicionaTipoValor(false);
            setPercentagemRetirar("");
            setPercentagemRetirarStatus(false);
            setPercentagemAdicionar("");
            setValorAdicionar("");
            setValorAdicionarStatus(false);
            setAdicionaIvaSim(false);
            setAdicionaIvaNao(false);
            setErroIva(false);
            setValorIva("");
            setAtualizarInventarioSim(false);
            setAtualizarInventarioNao(false);
            setResumoStep5("");
            setAlteracaoPrecoOpcao(0);
            setCsvData([]);
            setCsvFilename("");
            setActiveStep(0);
            setCompleted({});
          }}
          sx={{ mr: 1 }}
        >
          {t("recomercar")}
        </Button>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "50px",
            fontWeight: "bold",
            fontSize: "30px",
          }}
        >
          {t("atualizacaoPrecos")}
        </h2>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                onClick={completed[4] ? handleStep(index) : null}
                style={{ boxShadow: "none" }}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {/* Step 1 */}
        {activeStep == 0 ? (
          <div
            style={{
              width: "80vw",
              height: "55vh",
              marginTop: "20px",
            }}
          >
            <div class="container2">
              {/* Shopify */}
              {!lojaEscolhidaW && (
                <div
                  class="card_ card4"
                  onClick={() => {
                    setLojaEscolhida("shopify");
                    setLojaEscolhidaS(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="priceupdateIcon"
                    icon={faShopify}
                  />
                </div>
              )}
              {/* Wordpress */}
              {!lojaEscolhidaS && (
                <div
                  class="card_ card5"
                  onClick={() => {
                    setLojaEscolhida("wordpress");
                    setLojaEscolhidaW(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="priceupdateIcon"
                    icon={faWordpress}
                  />
                </div>
              )}
            </div>
          </div>
        ) : null}
        {/* Step 2 */}
        {activeStep == 1 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              marginTop: "20px",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <div style={{ float: "left", top: "35%", position: "relative" }}>
              <h4 style={{ textAlign: "center" }}>{t("ficheiroAtualizar")}</h4>
              <div style={{ textAlign: "center", marginTop: "30px" }}>
                <label htmlFor="formId">
                  <input
                    type="file"
                    id="formId"
                    accept=".csv, .xlsx, .tsv"
                    multiple={false}
                    onChange={(e) => {
                      ficheiroAtualizarVerificacao(e.target.files[0]);
                    }}
                    hidden
                  />
                  <FontAwesomeIcon
                    icon={faUpload}
                    style={{
                      color: nomeFicheiroAtualizar ? "#228B22" : "#343434",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                </label>
                <p style={{ paddingTop: "20px" }}>
                  {ficheiroAtualizar.length == 0
                    ? t("selecioneFicheiro")
                    : nomeFicheiroAtualizar}
                </p>
              </div>
            </div>
            <div style={{ float: "right", top: "35%", position: "relative" }}>
              <h4 style={{ textAlign: "center" }}>{t("ficheiroFornecedor")}</h4>
              <div style={{ textAlign: "center", marginTop: "30px" }}>
                <label htmlFor="formId2">
                  <input
                    type="file"
                    id="formId2"
                    accept=".csv, .xlsx, .tsv"
                    multiple={false}
                    onChange={(e) => {
                      setColunaPreco("");
                      setColunaReferencia("");
                      setCompleted([{ 0: true, 1: true }]);
                      ficheiroFornecedorVerificacao(e.target.files[0]);
                    }}
                    hidden
                  />
                  <FontAwesomeIcon
                    icon={faUpload}
                    style={{
                      color: nomeFicheiroFornecedor ? "#228B22" : "#343434",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                </label>
                <p style={{ paddingTop: "20px" }}>
                  {ficheiroFornecedor.length == 0
                    ? t("selecioneFicheiro")
                    : nomeFicheiroFornecedor}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {/* Step 3 */}
        {activeStep == 2 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              marginTop: "20px",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <div
              style={{
                float: "left",
                top: "10%",
                position: "relative",
                width: "30%",
              }}
            >
              <h4 style={{ textAlign: "center" }}>{t("referencia")}</h4>
              <p
                style={{
                  paddingTop: "15px",
                  textAlign: "center",
                  height: "20%",
                }}
              >
                {t("referenciaTexto")}
              </p>
              <Autocomplete
                onChange={(event, newValue) => {
                  //Guardar valor da coluna da referência
                  setColunaReferencia(newValue);
                  setReferenciaErro(false);
                }}
                onInputChange={(event, newInputValue) => {
                  setColunaReferencia(newInputValue);
                  setReferenciaErro(false);
                }}
                options={colunasFicheiroFornecedor}
                value={colunaReferencia}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    error={referenciaErro}
                    label={t("selecionarColuna")}
                  />
                )}
              />
            </div>
            <div
              style={{
                float: "right",
                top: "10%",
                position: "relative",
                width: "30%",
              }}
            >
              <h4 style={{ textAlign: "center" }}>{t("preco")}</h4>
              <p
                style={{
                  paddingTop: "15px",
                  textAlign: "center",
                  height: "20%",
                }}
              >
                {t("precoTexto")}
              </p>
              <Autocomplete
                onChange={(event, newValue) => {
                  //Guardar valor da coluna da referência
                  setColunaPreco(newValue);
                  setPrecoErro(false);
                }}
                value={colunaPreco}
                onInputChange={(event, newInputValue) => {
                  setColunaPreco(newInputValue);
                  setPrecoErro(false);
                }}
                options={colunasFicheiroFornecedor}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    error={precoErro}
                    label={t("selecionarColuna")}
                  />
                )}
              />
            </div>
          </div>
        ) : null}
        {/* Step 4 */}
        {activeStep == 3 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              marginTop: "20px",
              justifyContent: "space-around",
            }}
          >
            <h4
              style={{
                paddingLeft: "50px",
                paddingRight: "50px",
                paddingTop: "60px",
              }}
            >
              {t("prefixoSufixo")}
            </h4>
            {/* Prefixo */}
            <div
              style={{
                paddingLeft: "50px",
                paddingRight: "50px",
                paddingTop: "50px",
              }}
            >
              <p>{t("prefixoReferencia")}</p>
              <FormControlLabel
                style={{ paddingTop: "15px" }}
                control={
                  <Checkbox
                    checked={prefixoStatusSim}
                    onChange={() => {
                      setPrefixoStatusSim(true);
                      setPrefixoStatusNao(false);
                    }}
                  />
                }
                label={t("sim")}
              />
              <FormControlLabel
                style={{ paddingTop: "15px", marginRight: "90px" }}
                control={
                  <Checkbox
                    checked={prefixoStatusNao}
                    onChange={() => {
                      setPrefixoStatusNao(true);
                      setPrefixoStatusSim(false);
                      setPrefixo("");
                    }}
                  />
                }
                label={t("nao")}
              />
              {prefixoStatusSim ? (
                <TextField
                  required
                  defaultValue={prefixo}
                  error={erroPrefixo}
                  style={{ width: "35%" }}
                  id="standard-basic"
                  label={t("prefixo")}
                  variant="standard"
                  onChange={(e) => {
                    setErroPrefixo(false);
                    setPrefixo(e.target.value);
                  }}
                />
              ) : null}
            </div>
            {/* Sufixo */}
            <div
              style={{
                paddingLeft: "50px",
                paddingRight: "50px",
                paddingTop: "50px",
              }}
            >
              <p>{t("sufixoReferencia")}</p>
              <FormControlLabel
                style={{ paddingTop: "15px" }}
                control={
                  <Checkbox
                    checked={sufixoStatusSim}
                    onChange={() => {
                      setSufixoStatusSim(true);
                      setSufixoStatusNao(false);
                    }}
                  />
                }
                label={t("sim")}
              />
              <FormControlLabel
                style={{ paddingTop: "15px", marginRight: "90px" }}
                control={
                  <Checkbox
                    checked={sufixoStatusNao}
                    onChange={() => {
                      setSufixoStatusNao(true);
                      setSufixoStatusSim(false);
                      setSufixo("");
                    }}
                  />
                }
                label={t("nao")}
              />
              {sufixoStatusSim ? (
                <TextField
                  required
                  defaultValue={sufixo}
                  error={erroSufixo}
                  style={{ width: "35%" }}
                  id="standard-basic"
                  label={t("sufixo")}
                  variant="standard"
                  onChange={(e) => {
                    setErroSufixo(false);
                    setSufixo(e.target.value);
                  }}
                />
              ) : null}
            </div>
          </div>
        ) : null}
        {/* Step 5 */}
        {activeStep == 4 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              // backgroundColor: "red",
              marginTop: "20px",
              justifyContent: "space-around",
            }}
          >
            {/* Retirar Valor */}
            {!atualizarInventarioSim ? (
              <div>
                <div
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "10px",
                  }}
                >
                  <p>{t("retiravalor")}</p>
                  <FormControlLabel
                    style={{ paddingTop: "15px" }}
                    control={
                      <Checkbox
                        checked={retiraValorSim}
                        onChange={() => {
                          setRetiraValorSim(true);
                          setRetiraValorNao(false);
                        }}
                      />
                    }
                    label={t("sim")}
                  />
                  <FormControlLabel
                    style={{ paddingTop: "15px", marginRight: "90px" }}
                    control={
                      <Checkbox
                        checked={retiraValorNao}
                        onChange={() => {
                          setRetiraValorNao(true);
                          setRetiraValorSim(false);
                          setPercentagemRetirar("");
                        }}
                      />
                    }
                    label={t("nao")}
                  />
                  {retiraValorSim ? (
                    <TextField
                      required
                      value={percentagemRetirar}
                      error={erroRetiraValor}
                      style={{ width: "30%" }}
                      id="standard-basic"
                      type="number"
                      label={t("percentagemaretirar")}
                      InputProps={{
                        startAdornment: percentagemRetirarStatus ? (
                          <span />
                        ) : (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        endAdornment: percentagemRetirarStatus ? (
                          <InputAdornment position="end">%</InputAdornment>
                        ) : (
                          <span />
                        ),
                        // classes: defaultInputClasses,
                      }}
                      variant="standard"
                      onChange={(e) => {
                        e.target.value
                          ? setPercentagemRetirarStatus(true)
                          : setPercentagemRetirarStatus(false);
                        setErroRetiraValor(false);
                        setPercentagemRetirar(e.target.value);
                      }}
                    />
                  ) : null}
                </div>

                {/* Adicionar Valor */}
                <div
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    width: "100 vw",
                  }}
                >
                  <p>{t("adicionavalor")}</p>
                  <FormControlLabel
                    style={{ paddingTop: "15px" }}
                    control={
                      <Checkbox
                        checked={adicionaValorSim}
                        onChange={() => {
                          setAdicionaValorSim(true);
                          setAdicionaValorNao(false);
                        }}
                      />
                    }
                    label={t("sim")}
                  />
                  <FormControlLabel
                    style={{ paddingTop: "15px", marginRight: "90px" }}
                    control={
                      <Checkbox
                        checked={adicionaValorNao}
                        onChange={() => {
                          setAdicionaValorNao(true);
                          setAdicionaValorSim(false);
                          setValorAdicionar("");
                          setPercentagemAdicionar("");
                          setTipoValor("");
                        }}
                      />
                    }
                    label={t("nao")}
                  />
                  {adicionaValorSim ? (
                    <Autocomplete
                      disablePortal
                      value={tipoValor}
                      options={options}
                      sx={{
                        width: "30%",
                        display: "inline-flex",
                        marginRight: "90px",
                      }}
                      onChange={(event, newValue) => {
                        setTipoValor(newValue);
                      }}
                      onInputChange={(event, newInputValue) => {
                        if (newInputValue == t("numerico")) {
                          setValorAdicionarStatus(false);
                          setAdicionaIvaSim(false);
                          setAdicionaIvaNao(true);
                        } else {
                          setValorAdicionarStatus(true);
                        }
                        setTipoValor(newInputValue);
                        setErroAdicionaTipoValor(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          error={erroAdicionaTipoValor}
                          label={t("tipoadicionar")}
                        />
                      )}
                    />
                  ) : null}

                  {adicionaValorSim ? (
                    <TextField
                      required
                      value={valorAdicionar}
                      error={erroAdicionaValor}
                      style={{ width: "30%" }}
                      id="standard-basic"
                      type="number"
                      label={
                        tipoValor == t("percentagem")
                          ? t("percentagemAdicionar")
                          : t("valoradicionar")
                      }
                      variant="standard"
                      InputProps={{
                        startAdornment:
                          tipoValor &&
                          valorAdicionarStatus &&
                          !valorAdicionar ? (
                            <InputAdornment position="start">%</InputAdornment>
                          ) : (
                            <span />
                          ),
                        endAdornment:
                          tipoValor &&
                          valorAdicionarStatus &&
                          valorAdicionar ? (
                            <InputAdornment position="end">%</InputAdornment>
                          ) : (
                            <span />
                          ),
                      }}
                      onChange={(e) => {
                        setErroAdicionaValor(false);
                        setValorAdicionar(e.target.value);
                      }}
                    />
                  ) : null}
                </div>

                {/* Adiciona IVA */}
                {tipoValor == t("percentagem") ||
                tipoValor == "" ||
                tipoValor == null ? (
                  <div
                    style={{
                      paddingLeft: "50px",
                      paddingRight: "50px",
                      paddingTop: "15px",
                    }}
                  >
                    <p>{t("adicionaiva")}</p>
                    <FormControlLabel
                      style={{ paddingTop: "15px" }}
                      control={
                        <Checkbox
                          checked={adicionaIvaSim}
                          onChange={() => {
                            setAdicionaIvaSim(true);
                            setAdicionaIvaNao(false);
                          }}
                        />
                      }
                      label={t("sim")}
                    />
                    <FormControlLabel
                      style={{
                        paddingTop: "15px",
                        marginRight: "90px",
                      }}
                      control={
                        <Checkbox
                          checked={adicionaIvaNao}
                          onChange={() => {
                            setAdicionaIvaNao(true);
                            setAdicionaIvaSim(false);
                            setValorIva("");
                          }}
                        />
                      }
                      label={t("nao")}
                    />
                    {adicionaIvaSim ? (
                      <Autocomplete
                        value={valorIva}
                        onChange={(event, newValue) => {
                          setValorIva(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                          setErroIva(false);
                          setValorIva(newInputValue);
                        }}
                        disablePortal
                        options={optionsIva}
                        sx={{ display: "inline-flex", width: "30%" }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            error={erroIva}
                            label={t("escolheiva")}
                          />
                        )}
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : null}

            {/* Atualizar apenas produtos */}
            <div
              style={{
                paddingLeft: "50px",
                paddingRight: "50px",
                paddingTop: "15px",
              }}
            >
              <p>{t("atualizarInventario")}</p>
              <FormControlLabel
                style={{ paddingTop: "15px" }}
                control={
                  <Checkbox
                    checked={atualizarInventarioSim}
                    onChange={() => {
                      setAtualizarInventarioSim(true);
                      setAtualizarInventarioNao(false);
                      setRetiraValorNao(false);
                      setRetiraValorSim(false);
                      setPercentagemRetirar("");
                      setAdicionaValorNao(false);
                      setAdicionaValorSim(false);
                      setValorAdicionar("");
                      setValorIva("");
                      setAdicionaIvaNao(false);
                      setAdicionaIvaSim(false);
                    }}
                  />
                }
                label={t("sim")}
              />
              <FormControlLabel
                style={{
                  paddingTop: "15px",
                  marginRight: "90px",
                }}
                control={
                  <Checkbox
                    checked={atualizarInventarioNao}
                    onChange={() => {
                      setAtualizarInventarioNao(true);
                      setAtualizarInventarioSim(false);
                    }}
                  />
                }
                label={t("nao")}
              />
            </div>
          </div>
        ) : null}
        {activeStep == 5 ? (
          <div
            style={{
              width: "80vw",
              height: "50vh",
              marginTop: "15px",
              // backgroundColor: "red",
            }}
          >
            <div class="container3">
              <div>
                <div
                  onClick={() => {
                    setActiveStep(0);
                  }}
                >
                  <h4
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("step6")}
                  </h4>
                  <Button
                    className="buttonVerificacao"
                    style={{ textTransform: "capitalize" }}
                  >
                    <span className="resumo">{t("lojaSelecionada")}</span>
                    {lojaEscolhida}
                  </Button>
                </div>
                <div
                  onClick={() => {
                    setActiveStep(1);
                  }}
                >
                  <Button
                    className="buttonVerificacao"
                    style={{ textTransform: "none" }}
                  >
                    <span className="resumo">{t("nomeFicheiroAtualizar")}</span>
                    {nomeFicheiroAtualizar}
                  </Button>
                </div>
                <div
                  onClick={() => {
                    setActiveStep(1);
                  }}
                >
                  <Button
                    className="buttonVerificacao"
                    style={{ textTransform: "none" }}
                  >
                    <span className="resumo">
                      {t("nomeFicheiroFornecedor")}
                    </span>
                    {nomeFicheiroFornecedor}
                  </Button>
                </div>
                <div
                  onClick={() => {
                    setActiveStep(2);
                  }}
                >
                  <Button
                    className="buttonVerificacao"
                    style={{ textTransform: "capitalize" }}
                  >
                    <span className="resumo">{t("columaReferencia")}</span>
                    {colunaReferencia}
                  </Button>
                </div>
                <div
                  onClick={() => {
                    setActiveStep(2);
                  }}
                >
                  <Button
                    className="buttonVerificacao"
                    style={{ textTransform: "capitalize" }}
                  >
                    <span className="resumo">{t("colunaPreco")}</span>
                    {colunaPreco}
                  </Button>
                </div>
                {prefixo ? (
                  <div
                    onClick={() => {
                      setActiveStep(3);
                    }}
                  >
                    <Button
                      className="buttonVerificacao"
                      style={{ textTransform: "capitalize" }}
                    >
                      <span className="resumo">{t("prefixo")}:</span>
                      {prefixo}
                    </Button>
                  </div>
                ) : null}
                {sufixo ? (
                  <div
                    onClick={() => {
                      setActiveStep(3);
                    }}
                  >
                    <Button
                      className="buttonVerificacao"
                      style={{ textTransform: "capitalize" }}
                    >
                      <span className="resumo">{t("sufixo")}:</span>
                      {sufixo}
                    </Button>
                  </div>
                ) : null}

                <div
                  onClick={() => {
                    setActiveStep(4);
                  }}
                >
                  <Button
                    className="buttonVerificacao"
                    style={{ textTransform: "none" }}
                  >
                    <span className="resumo">{t("step5")}:</span>
                    {resumoStep5}
                  </Button>
                </div>
              </div>
              {/* Botão para download do csv */}
              <CSVLink
                data={csvData}
                filename={csvFilename}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button
                  class="card3 card6"
                  onClick={() => {
                    criarFicheiro();
                  }}
                >
                  <FontAwesomeIcon className="csv" icon={faDownload} />
                </Button>
                <p className="csvText">{t("exportar")}</p>
              </CSVLink>
            </div>
          </div>
        ) : null}
        <div>
          {allStepsCompleted() ? null : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1, py: 1 }}></Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                }}
              >
                <Button
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t("anterior")}
                </Button>

                <Box sx={{ flex: "1 1 auto" }} />
                {completed[4] && activeStep == 5 ? null : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      verificacao();
                    }}
                    sx={{ mr: 1 }}
                  >
                    {t("seguinte")}
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
      <Footer />
    </div>
  );
}
