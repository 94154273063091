import React from "react";

function Footer() {
  return (
    <div className="footer">
      <a href="https://tectank.pt/" className="footerText">
        <small>Copyright Tectank &reg; 2023</small>
      </a>
    </div>
  );
}

export default Footer;
