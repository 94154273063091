import {
  faArrowsLeftRight,
  faCode,
  faEuroSign,
  faFileInvoiceDollar,
  faFilePen,
  faLanguage,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Home() {
  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    const id = localStorage.getItem("id");
    const token = Cookies.get("token");

    if (!id && !token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Header />
      <div className="home">
        <div class="container">
          <div
            class="card card0"
            onClick={() => {
              navigate("/priceupdate");
            }}
          >
            <FontAwesomeIcon className="homeIcon" icon={faFileInvoiceDollar} />
            <h2 className="cards">{t("atualizacaoPrecos")}</h2>
          </div>
          <div
            class="card card1"
            onClick={() => {
              navigate("/mapping");
            }}
          >
            <FontAwesomeIcon className="homeIcon" icon={faArrowsLeftRight} />
            <h2 className="cards">{t("mapeamento")}</h2>
          </div>
          <div
            class="card card2"
            onClick={() => {
              navigate("/discounts");
            }}
          >
            <FontAwesomeIcon className="homeIcon" icon={faTags} />
            <h2 className="cards">{t("descontos")}</h2>
          </div>
          <div
            class="card card31"
            onClick={() => {
              navigate("/xml");
            }}
          >
            <FontAwesomeIcon className="homeIcon" icon={faCode} />
            <h2 className="cards">{t("xml")}</h2>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
