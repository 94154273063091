import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../images/sapi-logo.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { emailRegisto, loginPost, registoPost } from "../api/requests/Login";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";

function Signup() {
  //Variáveis
  const [email, setEmail] = useState("");
  const [palavraPasse, setPalavraPasse] = useState("");
  const [nome, setNome] = useState("");

  //Validações
  const [erroEmail, setErroEmail] = useState(false);
  const [erroPassword, setErroPassword] = useState(false);
  const [erroName, setErroName] = useState(false);
  const [mensagemErroEmail, setMensagemErroEmail] = useState("");
  const [mensagemErroNome, setMensagemErroNome] = useState("");
  const [mensagemErroPalavraPasse, setMensagemErroPalavraPasse] = useState("");
  const [sucessoEmail, setSucessoEmail] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    if (email) {
      //Validar email - obrigatóriamente da tectank
      let validacaoEmail = /^\"?[\w-_\.]*\"?@tectank\.pt$/.test(email);
      if (validacaoEmail) {
        setErroEmail(false);
        setErroEmail("");
        setMensagemErroEmail("");
        setSucessoEmail("success");
      } else {
        let validacaoFormatoEmail = /\S+@\S+\.\S+/.test(email);

        if (validacaoFormatoEmail) {
          setErroEmail(true);
          setMensagemErroEmail(t("naoExisteConta"));
        } else {
          setErroEmail(true);
          setMensagemErroEmail(t("emailInvalido"));
        }
      }
    }
    if (nome) {
      setErroName(false);
      setMensagemErroEmail("");
    }
  }, [email, palavraPasse, nome]);

  async function Registo() {
    if (email && palavraPasse && !erroEmail && nome) {
      const hashedPassword = bcrypt.hashSync(
        palavraPasse,
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      );
      await registoPost({
        nome: nome,
        email: email,
        password: hashedPassword,
      }).then(async (res) => {
        if (res.success) {
          setTimeout(() => {
            emailRegisto({
              nome: nome,
              email: email,
              id: res.data.id,
            }).then((res2) => {
              if (res2.success) {
                toast.success(t("registoSucesso"));
                navigate("/home");
              }
            });
          }, 3000);
        } else {
          toast.error(res.error);
        }
      });
    } else {
      if (!nome) {
        setErroName(true);
        setMensagemErroNome(t("nomeErro"));
      }
      if (!email && palavraPasse) {
        setErroEmail(true);
        setMensagemErroEmail(t("emailErro"));
      } else if (!palavraPasse && email) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("palavraPasseErro"));
      } else if (!email && !palavraPasse) {
        setErroEmail(true);
        setMensagemErroEmail(t("emailErro"));
        setMensagemErroPalavraPasse(t("palavraPasseErro"));
        setErroPassword(true);
      }
    }
  }

  // const CloseButton = ({ closeToast }) => <FontAwesomeIcon icon={faClose} />;

  return (
    <div className="bodyLogin">
     
        <form class="log-in">
          <h4>
            <img src={logo} style={{ width: "150px", height: "49px" }} />
            {/* Tectank <span>SAPI</span> */}
          </h4>
          <h5>
            <span>{t("registo")}</span>
          </h5>
          {/* Nome */}
          <TextField
           className="inputlogin"
           style={{marginBottom:"10px"}}
            id="text"
            label={t("nome")}
            variant="standard"
            required={true}
            error={erroName}
            helperText={mensagemErroNome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
          {/* Email */}
          <TextField
            className="inputlogin"
            style={{marginBottom:"10px", fontSize:"8px"}}
            id="email"
            label={t("email")}
            variant="standard"
            required={true}
            placeholder="sapi@tectank.pt"
            error={erroEmail}
            helperText={mensagemErroEmail}
            color={sucessoEmail}
            onChange={(e) => {
              setEmail(e.target.value.toLowerCase());
            }}
          />
          {/* Palavra-passe */}
          <TextField
          className="inputlogin"
            id="password"
            label={t("palavraPasse")}
            style={{marginBottom:"10px"}}
            variant="standard"
            error={erroPassword}
            type={showPassword ? "text" : "password"}
            helperText={mensagemErroPalavraPasse}
            onChange={(e) => {
              setPalavraPasse(e.target.value);
              setErroPassword(false);
              setMensagemErroPalavraPasse("");
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    disableRipple
                    disableFocusRipple
                    style={{
                      justifyContent: "right",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                      color: "#8A8A8A",
                    }}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </Button>
                </InputAdornment>
              ),
            }}
            required={true}
          ></TextField>
          <a href="/" className="recoverPassword" style={{ font: "2px", marginTop:"10px" }}>
            {t("contaExistente")}
          </a>
          <button
            className="button"
            type="button"
            onClick={() => {
              Registo();
            }}
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderRadius: 50,
            }}
          >
            {t("registar")}
          </button>
        </form>
        <div class="left"></div>
      <Footer />
    </div>
  );
}
export default Signup;
