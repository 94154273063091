import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
  createRoutesFromElements,
  useNavigate,
} from "react-router-dom";
import Home from "./routes/Home";
import "./App.css";
import "./i18next";
import Login from "./routes/Login";
import RecoverPassword from "./routes/RecoverPassword";
import ChangePassword from "./routes/ChangePassword";
import PriceUpdate from "./routes/PriceUpdate";
import User from "./routes/User";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import Discounts from "./routes/Discounts";
import Signup from "./routes/Signup";
import ColumnMapping from "./routes/ColumnMapping";
import Xml from "./routes/Xml";

const AppLayout = () => {
  //Traduções
  const { t, i18n } = useTranslation();
  //Navigator
  const navigate = useNavigate();

  localStorage.setItem("language", i18n.language);
  const id = localStorage.getItem("id");
  const token = Cookies.get("token");
  if (id) {
    if (token == undefined && window.location.pathname != "/") {
      navigate("/");
      //Remover os dados e encaminhar para a página de Login
      localStorage.removeItem("id");
      localStorage.removeItem("nome");
      Cookies.remove("token");
      toast.info(t("sessaoExpirada"));
    }
  }

  return (
    <>
      <Outlet />
      <ToastContainer
        style={{ marginTop: "50px" }}
        position="top-right"
        autoClose={3000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />}>
      <Route path="/" element={<Login />} />
      <Route path="/sign-up" element={<Signup />} />
      <Route path="/recoverpassword" element={<RecoverPassword />} />
      <Route path="/changepassword/:token/:id" element={<ChangePassword />} />
      <Route path="/home" element={<Home />} />
      <Route path="/priceupdate" element={<PriceUpdate />} />
      <Route path="/discounts" element={<Discounts />} />
      <Route path="/mapping" element={<ColumnMapping />} />
      <Route path="/xml" element={<Xml />} />
    </Route>
  )
);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
