const array = [
  { value: "1", inputValue: "Artigos para animais e animais estimação" },
  {
    value: "2",
    inputValue: "Artigos para animais e animais estimação > Animais vivos",
  },
  {
    value: "3",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais",
  },
  {
    value: "4",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para pássaros",
  },
  {
    value: "5",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para pássaros > Acessórios para pássaros de gaiola",
  },
  {
    value: "6",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para pássaros > Acessórios para pássaros de gaiola > Acessório de banho para pássaros de gaiola",
  },
  {
    value: "7",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para pássaros > Acessórios para pássaros de gaiola > Comedouros e bebedouros para pássaros de gaiola",
  },
  {
    value: "8",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para pássaros > Gaiolas e poleiros para pássaros",
  },
  {
    value: "9",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para pássaros > Comida para pássaros",
  },
  {
    value: "10",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para pássaros > Acessórios de diversão e ginástica para pássaros",
  },
  {
    value: "11",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para pássaros > Poleiros e escadas para pássaros",
  },
  {
    value: "12",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para pássaros > Brinquedos para pássaros",
  },
  {
    value: "13",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para pássaros > Snacks para pássaros",
  },
  {
    value: "14",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos",
  },
  {
    value: "15",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Roupa para gatos",
  },
  {
    value: "16",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Camas para gatos",
  },
  {
    value: "17",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Ração de gatos",
  },
  {
    value: "18",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Ração de gatos > Ração de gatos sem receita",
  },
  {
    value: "19",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Ração de gatos > Ração de gatos com receita",
  },
  {
    value: "20",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Mobiliário para gatos",
  },
  {
    value: "21",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Acessórios de mobiliário para gatos",
  },
  {
    value: "22",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Liteiras para gatos",
  },
  {
    value: "23",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Revestimentos de caixas de areia para gatos",
  },
  {
    value: "24",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Tapetes para caixas de areia para gatos",
  },
  {
    value: "25",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Caixas de areia para gatos",
  },
  {
    value: "26",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Brinquedos para gatos",
  },
  {
    value: "27",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para gatos > Snacks para gatos",
  },
  {
    value: "28",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães",
  },
  {
    value: "29",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Roupa para cães",
  },
  {
    value: "30",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Camas para cães",
  },
  {
    value: "31",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Resguardos e fraldas para cães",
  },
  {
    value: "32",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Fraldas para cães",
  },
  {
    value: "33",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Ração de cães",
  },
  {
    value: "34",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Ração de cães > Ração de cães sem receita",
  },
  {
    value: "35",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Ração de cães > Ração de cães com receita",
  },
  {
    value: "36",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Casotas",
  },
  {
    value: "37",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Acessórios para canis",
  },
  {
    value: "38",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Canis",
  },
  {
    value: "39",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Brinquedos para cães",
  },
  {
    value: "40",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Passadeiras para cães",
  },
  {
    value: "41",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para cães > Snacks para cães",
  },
  {
    value: "42",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes",
  },
  {
    value: "43",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Tubagem para lagos e aquários",
  },
  {
    value: "44",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Difusores e pedra do ar para aquários",
  },
  {
    value: "45",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Artigos de limpeza para aquários",
  },
  {
    value: "46",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Decoração para aquários",
  },
  {
    value: "47",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Filtros para aquários",
  },
  {
    value: "48",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Redes para aquários",
  },
  {
    value: "49",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Substratos e cascalho para aquários",
  },
  {
    value: "50",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Iluminação para aquários",
  },
  {
    value: "51",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Caixas de sobrefluxo para aquários",
  },
  {
    value: "52",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Suportes para aquários",
  },
  {
    value: "53",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Controladores de temperatura para aquários",
  },
  {
    value: "54",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Tratamentos de água para aquários",
  },
  {
    value: "55",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Aquários",
  },
  {
    value: "56",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Fertilizadores de plantas aquáticas",
  },
  {
    value: "57",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Comedouros para peixes",
  },
  {
    value: "58",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para peixes > Comida para peixes",
  },
  {
    value: "59",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Equipamento de agilidade para peixes",
  },
  {
    value: "60",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Cruzetas para roupa de animais de estimação",
  },
  {
    value: "61",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Acessórios para cama de animais de estimação",
  },
  {
    value: "62",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Chapas de identificação e guizos para animais de estimação",
  },
  {
    value: "63",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Monitores biométricos para animais de estimação",
  },
  {
    value: "64",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Monitores biométricos para animais de estimação > Medidores de glucose para animais de estimação",
  },
  {
    value: "65",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Monitores biométricos para animais de estimação > Pedómetros para animais de estimação",
  },
  {
    value: "66",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Monitores biométricos para animais de estimação > Termómetros para animais de estimação",
  },
  {
    value: "67",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Tapetes para tigelas para animais de estimação",
  },
  {
    value: "68",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Suportes parta tigelas para animais de estimação",
  },
  {
    value: "69",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Tigelas, comedouros e bebedouros para animais de estimação",
  },
  {
    value: "70",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Acessórios para transportadoras e gaiolas para animais de estimação",
  },
  {
    value: "71",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Transportadoras e gaiolas para animais de estimação",
  },
  {
    value: "72",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Arneses e coleiras para animais de estimação",
  },
  {
    value: "73",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Sistemas de contenção para animais de estimação",
  },
  {
    value: "74",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Acessórios de portas para animais de estimação",
  },
  {
    value: "75",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Portas para animais de estimação",
  },
  {
    value: "76",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Gotas oculares e lubrificantes para animais de estimação",
  },
  {
    value: "77",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Kits de emergência e de primeiros socorros para animais de estimação",
  },
  {
    value: "78",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Controlo de pulgas e carraças para animais de estimação",
  },
  {
    value: "79",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Comedouros para animais de estimação",
  },
  {
    value: "80",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Colheres para animais de estimação",
  },
  {
    value: "81",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos de tosquia para animais de estimação",
  },
  {
    value: "82",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos de tosquia para animais de estimação > Escovas e pentes para animais de estimação",
  },
  {
    value: "83",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos de tosquia para animais de estimação > Fragrâncias e sprays desodorizantes para animais de estimação",
  },
  {
    value: "84",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos de tosquia para animais de estimação > Aparadores e molas para animais de estimação",
  },
  {
    value: "85",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos de tosquia para animais de estimação > Secadores para animais de estimação",
  },
  {
    value: "86",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos de tosquia para animais de estimação > Verniz para animais de estimação",
  },
  {
    value: "87",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos de tosquia para animais de estimação > Artigos de unhas para animais de estimação",
  },
  {
    value: "88",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos de tosquia para animais de estimação > Champô e condicionador para animais de estimação",
  },
  {
    value: "89",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos de tosquia para animais de estimação > Toalhetes para animais de estimação",
  },
  {
    value: "90",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Acessórios de almofadas térmicas para animais de estimação",
  },
  {
    value: "91",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Almofadas térmicas para animais de estimação",
  },
  {
    value: "92",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Chapas de identificação para animais de estimação",
  },
  {
    value: "93",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Extensões de trela para animais de estimação",
  },
  {
    value: "94",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Trelas para animais de estimação",
  },
  {
    value: "95",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Colares isabelinos para animais de estimação",
  },
  {
    value: "96",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Fitas adesivas e ligaduras médicas para animais de estimação",
  },
  {
    value: "97",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Medicamentos para animais de estimação",
  },
  {
    value: "98",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Açaimes para animais de estimação",
  },
  {
    value: "99",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para higiene oral para animais de estimação",
  },
  {
    value: "100",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Parques para animais de estimação",
  },
  {
    value: "101",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Degraus e rampas para animais de estimação",
  },
  {
    value: "102",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Carrinhos para animais de estimação",
  },
  {
    value: "103",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Protetores solares para animais de estimação",
  },
  {
    value: "104",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Apoios ao treino para animais de estimação",
  },
  {
    value: "105",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Apoios ao treino para animais de estimação > Distribuidores de snacks e clickers de treino para animais de estimação",
  },
  {
    value: "106",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Apoios ao treino para animais de estimação > Fixadores de resguardos para animais de estimação",
  },
  {
    value: "107",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Apoios ao treino para animais de estimação > Resguardos para animais de estimação",
  },
  {
    value: "108",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Apoios ao treino para animais de estimação > Soluções e sprays para animais de estimação",
  },
  {
    value: "109",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Vitaminas e suplementos para animais de estimação",
  },
  {
    value: "110",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Suportes e distribuidores de sacos do lixo para animais de estimação",
  },
  {
    value: "111",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Sacos do lixo para animais de estimação",
  },
  {
    value: "112",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Equipamento e sistemas de eliminação de resíduos para animais de estimação",
  },
  {
    value: "113",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para répteis e anfíbios",
  },
  {
    value: "114",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para répteis e anfíbios > Comida para répteis e anfíbios",
  },
  {
    value: "115",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para répteis e anfíbios > Acessórios de habitat para répteis e anfíbios",
  },
  {
    value: "116",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para répteis e anfíbios > Iluminação e aquecimento de habitat para répteis e anfíbios",
  },
  {
    value: "117",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para répteis e anfíbios > Habitats para répteis e anfíbios",
  },
  {
    value: "118",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para répteis e anfíbios > Substratos para répteis e anfíbios",
  },
  {
    value: "119",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para animais pequenos",
  },
  {
    value: "120",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para animais pequenos > Camas para animais pequenos",
  },
  {
    value: "121",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para animais pequenos > Ração para animais pequenos",
  },
  {
    value: "122",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para animais pequenos > Acessórios de habitat para animais pequenos",
  },
  {
    value: "123",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para animais pequenos > Habitats e gaiolas para animais pequenos",
  },
  {
    value: "124",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Artigos para animais pequenos > Snacks para animais pequenos",
  },
  {
    value: "125",
    inputValue:
      "Artigos para animais e animais estimação > Artigos para animais > Barreiras de veículos para animais domésticos",
  },
  { value: "126", inputValue: "Acessórios e vestuário" },
  { value: "127", inputValue: "Acessórios e vestuário > Vestuário" },
  {
    value: "128",
    inputValue: "Acessórios e vestuário > Vestuário > Roupa desportiva",
  },
  {
    value: "129",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Roupa desportiva de ciclismo",
  },
  {
    value: "130",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Roupa desportiva de ciclismo > Calções Bibs de ciclismo",
  },
  {
    value: "131",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Roupa desportiva de ciclismo > Camisolas de ciclismo",
  },
  {
    value: "132",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Roupa desportiva de ciclismo > Calções e roupa interior de ciclismo",
  },
  {
    value: "133",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Roupa desportiva de ciclismo > Fatos de ciclismo",
  },
  {
    value: "134",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Roupa desportiva de ciclismo > Collants de ciclismo",
  },
  {
    value: "135",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Calções de boxe",
  },
  {
    value: "136",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Fatos, saias e vestidos de dança",
  },
  {
    value: "137",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Calças de Futebol Americano",
  },
  {
    value: "138",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Roupa de caça",
  },
  {
    value: "139",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Roupa de caça > Fatos Ghillie",
  },
  {
    value: "140",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Roupa de caça > Fatos de caça e pesca",
  },
  {
    value: "141",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Roupa de caça > Calças táticas e de caça",
  },
  {
    value: "142",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Calções de artes marciais",
  },
  {
    value: "143",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Vestuário de proteção para motociclos",
  },
  {
    value: "144",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Vestuário de proteção para motociclos > Casacos para motociclos",
  },
  {
    value: "145",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Vestuário de proteção para motociclos > Calças para motociclos",
  },
  {
    value: "146",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Vestuário de proteção para motociclos > Fatos para motociclos",
  },
  {
    value: "147",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa desportiva > Vestuário de Paintball",
  },
  {
    value: "148",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa para bebé e criança",
  },
  {
    value: "149",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa para bebé e criança > Partes de baixo para bebé e criança",
  },
  {
    value: "150",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa para bebé e criança > Tapa fraldas para bebé e criança",
  },
  {
    value: "151",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa para bebé e criança > Vestidos para bebé e criança",
  },
  {
    value: "152",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa para bebé e criança > Roupa de exterior para bebé e criança",
  },
  {
    value: "153",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa para bebé e criança > Fatos para bebé e criança",
  },
  {
    value: "154",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa para bebé e criança > Roupa para dormir para bebé e criança",
  },
  {
    value: "155",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa para bebé e criança > Meias calças e meias para bebé e criança",
  },
  {
    value: "156",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa para bebé e criança > Roupa de praia para bebé e criança",
  },
  {
    value: "157",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa para bebé e criança > Partes de cima para bebé e criança",
  },
  {
    value: "158",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa para bebé e criança > Macacões para bebé",
  },
  {
    value: "159",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa para bebé e criança > Roupa interior para bebé",
  },
  { value: "160", inputValue: "Acessórios e vestuário > Vestuário > Vestidos" },
  {
    value: "161",
    inputValue: "Acessórios e vestuário > Vestuário > Jumpsuits",
  },
  {
    value: "162",
    inputValue:
      "Acessórios e vestuário > Vestuário > Jumpsuits > Macacões compridos e curtos",
  },
  {
    value: "163",
    inputValue:
      "Acessórios e vestuário > Vestuário > Jumpsuits > Fatos leotards e unitards",
  },
  {
    value: "164",
    inputValue: "Acessórios e vestuário > Vestuário > Jumpsuits > Overalls",
  },
  {
    value: "165",
    inputValue: "Acessórios e vestuário > Vestuário > Vestuário de exterior",
  },
  {
    value: "166",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário de exterior > Calças chaps",
  },
  {
    value: "167",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário de exterior > Casacos e blusões",
  },
  {
    value: "168",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário de exterior > Calças para a chuva",
  },
  {
    value: "169",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário de exterior > Fatos para a chuva",
  },
  {
    value: "170",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário de exterior > Fatos e calças para a neve",
  },
  {
    value: "171",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário de exterior > Coletes",
  },
  {
    value: "172",
    inputValue: "Acessórios e vestuário > Vestuário > Conjuntos de vestuário",
  },
  { value: "173", inputValue: "Acessórios e vestuário > Vestuário > Calças" },
  {
    value: "174",
    inputValue: "Acessórios e vestuário > Vestuário > Camisas e tops",
  },
  { value: "175", inputValue: "Acessórios e vestuário > Vestuário > Calções" },
  { value: "176", inputValue: "Acessórios e vestuário > Vestuário > Saias" },
  { value: "177", inputValue: "Acessórios e vestuário > Vestuário > Skorts" },
  {
    value: "178",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa de dormir e roupa casual",
  },
  {
    value: "179",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa de dormir e roupa casual > Roupa casual",
  },
  {
    value: "180",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa de dormir e roupa casual > Camisas de dormir",
  },
  {
    value: "181",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa de dormir e roupa casual > Pijamas",
  },
  {
    value: "182",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa de dormir e roupa casual > Roupões",
  },
  { value: "183", inputValue: "Acessórios e vestuário > Vestuário > Fatos" },
  {
    value: "184",
    inputValue: "Acessórios e vestuário > Vestuário > Fatos > Fatos de calças",
  },
  {
    value: "185",
    inputValue: "Acessórios e vestuário > Vestuário > Fatos > Fatos de saia",
  },
  {
    value: "186",
    inputValue: "Acessórios e vestuário > Vestuário > Fatos > Smoking",
  },
  {
    value: "187",
    inputValue: "Acessórios e vestuário > Vestuário > Fatos de banho",
  },
  {
    value: "188",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia",
  },
  {
    value: "189",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Dirndls",
  },
  {
    value: "190",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Calças Hakama",
  },
  {
    value: "191",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Vestuário formal preto japonês",
  },
  {
    value: "192",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Kimono de exterior",
  },
  {
    value: "193",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Kimonos",
  },
  {
    value: "194",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Vestuário de cerimónias religiosas",
  },
  {
    value: "195",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Vestuário de cerimónias religiosas > Vestidos de comunhão e batismo",
  },
  {
    value: "196",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Saris e Lehengas",
  },
  {
    value: "197",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Calças de pele tradicionais",
  },
  {
    value: "198",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Yukata",
  },
  {
    value: "199",
    inputValue: "Acessórios e vestuário > Vestuário > Roupa interior e meias",
  },
  {
    value: "200",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Acessórios de sutiã",
  },
  {
    value: "201",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Acessórios de sutiã > Sutiãs com alças e almofadas",
  },
  {
    value: "202",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Acessórios de sutiã > Sutiãs com alças e extensores",
  },
  {
    value: "203",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Acessórios de sutiã > Sutiãs com inserções de aumento",
  },
  {
    value: "204",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Acessórios de sutiã > Pétalas e corretores de peito",
  },
  {
    value: "205",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Sutiãs",
  },
  {
    value: "206",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Malhas",
  },
  {
    value: "207",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Coquilhas",
  },
  {
    value: "208",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Lingerie",
  },
  {
    value: "209",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Acessórios de lingerie",
  },
  {
    value: "210",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Acessórios de lingerie > Cintos de ligas",
  },
  {
    value: "211",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Acessórios de lingerie > Ligueiros",
  },
  {
    value: "212",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Ceroulas",
  },
  {
    value: "213",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Petticoats e Pettipants",
  },
  {
    value: "214",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Vestuário modelador",
  },
  {
    value: "215",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Meias",
  },
  {
    value: "216",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Camisolas de interior",
  },
  {
    value: "217",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Roupa interior",
  },
  {
    value: "218",
    inputValue:
      "Acessórios e vestuário > Vestuário > Roupa interior e meias > Slips",
  },
  {
    value: "219",
    inputValue: "Acessórios e vestuário > Vestuário > Uniformes",
  },
  {
    value: "220",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Fatos de macaco e calças de trabalho",
  },
  {
    value: "221",
    inputValue: "Acessórios e vestuário > Vestuário > Uniformes > Fatos de voo",
  },
  {
    value: "222",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes de serviço alimentar",
  },
  {
    value: "223",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes de serviço alimentar > Chapéus de cozinheiro",
  },
  {
    value: "224",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes de serviço alimentar > Casaco de cozinheiro",
  },
  {
    value: "225",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes de serviço alimentar > Calças de cozinheiro",
  },
  {
    value: "226",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes militares",
  },
  {
    value: "227",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes escolares",
  },
  {
    value: "228",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes de segurança",
  },
  {
    value: "229",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes desportivos",
  },
  {
    value: "230",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes desportivos > Uniformes de basebol",
  },
  {
    value: "231",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes desportivos > Uniformes de basquetebol",
  },
  {
    value: "232",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes desportivos > Uniformes de claque",
  },
  {
    value: "233",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes desportivos > Uniformes de cricket",
  },
  {
    value: "234",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes desportivos > Equipamentos de Futebol Americano",
  },
  {
    value: "235",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes desportivos > Uniformes de hóquei",
  },
  {
    value: "236",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes desportivos > Uniformes de artes marciais",
  },
  {
    value: "237",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes desportivos > Uniformes de celebrações",
  },
  {
    value: "238",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes desportivos > Uniformes de futebol",
  },
  {
    value: "239",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes desportivos > Uniformes de softball",
  },
  {
    value: "240",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Uniformes desportivos > Uniformes de wrestling",
  },
  {
    value: "241",
    inputValue:
      "Acessórios e vestuário > Vestuário > Uniformes > Casacos brancos",
  },
  {
    value: "242",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestidos de casamento e de noivado",
  },
  {
    value: "243",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestidos de casamento e de noivado > Vestidos de noivado",
  },
  {
    value: "244",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestidos de casamento e de noivado > Vestidos de noiva",
  },
  {
    value: "245",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário",
  },
  {
    value: "246",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Manguitos e mangas",
  },
  {
    value: "247",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de vestuário para bebé e criança",
  },
  {
    value: "248",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de vestuário para bebé e criança > Cintos para bebé e criança",
  },
  {
    value: "249",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de vestuário para bebé e criança > Luvas e mitenes para bebé e criança",
  },
  {
    value: "250",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de vestuário para bebé e criança > Chapéus para bebé e criança",
  },
  {
    value: "251",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de vestuário para bebé e criança > Vestuário de proteção para bebé",
  },
  {
    value: "252",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Balaclavas",
  },
  {
    value: "253",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Bandanas e acessórios para o cabelo",
  },
  {
    value: "254",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Bandanas e acessórios para o cabelo > Bandanas",
  },
  {
    value: "255",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Bandanas e acessórios para o cabelo > Acessórios para o cabelo",
  },
  {
    value: "256",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Fivelas para cintos",
  },
  {
    value: "257",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Cintos",
  },
  {
    value: "258",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de noiva",
  },
  {
    value: "259",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de noiva > Véus de noiva",
  },
  {
    value: "260",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Botões de pressão",
  },
  {
    value: "261",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Barbatanas para colarinho",
  },
  {
    value: "262",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Botões de punho",
  },
  {
    value: "263",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Leques decorativos",
  },
  {
    value: "264",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Abafadores",
  },
  {
    value: "265",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Luvas e mitenes",
  },
  {
    value: "266",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo",
  },
  {
    value: "267",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Coques para cabelo e modeladores de volume",
  },
  {
    value: "268",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Pentes para o cabelo",
  },
  {
    value: "269",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Extensões de cabelo",
  },
  {
    value: "270",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Garfos e paus para cabelo",
  },
  {
    value: "271",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Redes para cabelo",
  },
  {
    value: "272",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Molas e ganchos para cabelo",
  },
  {
    value: "273",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Molas e ganchos para cabelo > Ganchos",
  },
  {
    value: "274",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Molas e ganchos para cabelo > Molas e ganchos para cabelo",
  },
  {
    value: "275",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Molas e ganchos para cabelo > Ganchos para cabelo",
  },
  {
    value: "276",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Grinaldas para cabelo",
  },
  {
    value: "277",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Bandoletes",
  },
  {
    value: "278",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Acessórios para rabo-de-cavalo",
  },
  {
    value: "279",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Tiaras",
  },
  {
    value: "280",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Acessórios de cabeleiras",
  },
  {
    value: "281",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Acessórios de cabeleiras > Toucas para cabeleiras",
  },
  {
    value: "282",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Acessórios de cabeleiras > Fita e cola para cabeleiras",
  },
  {
    value: "283",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de cabelo > Cabeleiras",
  },
  {
    value: "284",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Estolas",
  },
  {
    value: "285",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Lenços",
  },
  {
    value: "286",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Chapéus",
  },
  {
    value: "287",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Chapelaria",
  },
  {
    value: "288",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Chapelaria > Fascinadores",
  },
  {
    value: "289",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Chapelaria > Acessórios de cabeça",
  },
  {
    value: "290",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Chapelaria > Turbantes",
  },
  {
    value: "291",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Perneiras",
  },
  {
    value: "292",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Leis",
  },
  {
    value: "293",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Cintas de maternidade e faixas de apoio",
  },
  {
    value: "294",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Golas de pescoço",
  },
  {
    value: "295",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Gravatas",
  },
  {
    value: "296",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Crachás",
  },
  {
    value: "297",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Faixas",
  },
  {
    value: "298",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Lenços e xailes",
  },
  {
    value: "299",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Lenços e xailes > Lenços",
  },
  {
    value: "300",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Lenços e xailes > Xailes",
  },
  {
    value: "301",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Óculos de sol",
  },
  {
    value: "302",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Suspensórios",
  },
  {
    value: "303",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Clipes de gravata",
  },
  {
    value: "304",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de vestuário tradicional",
  },
  {
    value: "305",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de vestuário tradicional > Obis",
  },
  {
    value: "306",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de vestuário tradicional > Meias Tabi",
  },
  {
    value: "307",
    inputValue: "Acessórios e vestuário > Acessórios de vestuário > Punhos",
  },
  {
    value: "308",
    inputValue: "Acessórios e vestuário > Disfarce e acessórios",
  },
  {
    value: "309",
    inputValue:
      "Acessórios e vestuário > Disfarce e acessórios > Acessórios de trajes",
  },
  {
    value: "310",
    inputValue:
      "Acessórios e vestuário > Disfarce e acessórios > Acessórios de trajes > Toucas careca",
  },
  {
    value: "311",
    inputValue:
      "Acessórios e vestuário > Disfarce e acessórios > Acessórios de trajes > Conjuntos de acessórios de traje",
  },
  {
    value: "312",
    inputValue:
      "Acessórios e vestuário > Disfarce e acessórios > Acessórios de trajes > Capas de traje",
  },
  {
    value: "313",
    inputValue:
      "Acessórios e vestuário > Disfarce e acessórios > Acessórios de trajes > Luvas de traje",
  },
  {
    value: "314",
    inputValue:
      "Acessórios e vestuário > Disfarce e acessórios > Acessórios de trajes > Chapéus de traje",
  },
  {
    value: "315",
    inputValue:
      "Acessórios e vestuário > Disfarce e acessórios > Acessórios de trajes > Efeitos especiais de traje",
  },
  {
    value: "316",
    inputValue:
      "Acessórios e vestuário > Disfarce e acessórios > Acessórios de trajes > Produtos de tabaco de traje",
  },
  {
    value: "317",
    inputValue:
      "Acessórios e vestuário > Disfarce e acessórios > Acessórios de trajes > Joalharia de fantasia",
  },
  {
    value: "318",
    inputValue:
      "Acessórios e vestuário > Disfarce e acessórios > Sapatos de traje",
  },
  {
    value: "319",
    inputValue: "Acessórios e vestuário > Disfarce e acessórios > Trajes",
  },
  {
    value: "320",
    inputValue: "Acessórios e vestuário > Disfarce e acessórios > Máscaras",
  },
  {
    value: "321",
    inputValue: "Acessórios e vestuário > Acessórios de malas e carteiras",
  },
  {
    value: "322",
    inputValue:
      "Acessórios e vestuário > Acessórios de malas e carteiras > Capas para livro de cheques",
  },
  {
    value: "323",
    inputValue:
      "Acessórios e vestuário > Acessórios de malas e carteiras > Chaveiros",
  },
  {
    value: "324",
    inputValue:
      "Acessórios e vestuário > Acessórios de malas e carteiras > Lanyards",
  },
  {
    value: "325",
    inputValue:
      "Acessórios e vestuário > Acessórios de malas e carteiras > Correntes de carteira",
  },
  {
    value: "326",
    inputValue: "Acessórios e vestuário > Sacos de mão, carteiras e malas",
  },
  {
    value: "327",
    inputValue:
      "Acessórios e vestuário > Sacos de mão, carteiras e malas > Suportes de crachá e passe",
  },
  {
    value: "328",
    inputValue:
      "Acessórios e vestuário > Sacos de mão, carteiras e malas > Carteiras de cartão-de-visita",
  },
  {
    value: "329",
    inputValue:
      "Acessórios e vestuário > Sacos de mão, carteiras e malas > Sacos de mão",
  },
  {
    value: "330",
    inputValue:
      "Acessórios e vestuário > Sacos de mão, carteiras e malas > Carteiras e clipes de notas",
  },
  { value: "331", inputValue: "Acessórios e vestuário > Joalharia" },
  {
    value: "332",
    inputValue: "Acessórios e vestuário > Joalharia > Tornozeleiras",
  },
  {
    value: "333",
    inputValue: "Acessórios e vestuário > Joalharia > Joalharia corporal",
  },
  {
    value: "334",
    inputValue: "Acessórios e vestuário > Joalharia > Pulseiras",
  },
  {
    value: "335",
    inputValue: "Acessórios e vestuário > Joalharia > Broches e pregadeiras",
  },
  {
    value: "336",
    inputValue: "Acessórios e vestuário > Joalharia > Amuletos e pendentes",
  },
  { value: "337", inputValue: "Acessórios e vestuário > Joalharia > Brincos" },
  {
    value: "338",
    inputValue: "Acessórios e vestuário > Joalharia > Conjuntos de joalharia",
  },
  { value: "339", inputValue: "Acessórios e vestuário > Joalharia > Colares" },
  { value: "340", inputValue: "Acessórios e vestuário > Joalharia > Anéis" },
  {
    value: "341",
    inputValue: "Acessórios e vestuário > Joalharia > Acessórios de relógio",
  },
  {
    value: "342",
    inputValue:
      "Acessórios e vestuário > Joalharia > Acessórios de relógio > Braceletes de relógio",
  },
  {
    value: "343",
    inputValue:
      "Acessórios e vestuário > Joalharia > Acessórios de relógio > Autocolantes e decalques de relógio",
  },
  {
    value: "344",
    inputValue:
      "Acessórios e vestuário > Joalharia > Acessórios de relógio > Expositores de relógio",
  },
  { value: "345", inputValue: "Acessórios e vestuário > Joalharia > Relógios" },
  {
    value: "346",
    inputValue: "Acessórios e vestuário > Acessórios para calçado",
  },
  {
    value: "347",
    inputValue:
      "Acessórios e vestuário > Acessórios para calçado > Forros para botas",
  },
  {
    value: "348",
    inputValue: "Acessórios e vestuário > Acessórios para calçado > Polainas",
  },
  {
    value: "349",
    inputValue:
      "Acessórios e vestuário > Acessórios para calçado > Coberturas para calçado",
  },
  {
    value: "350",
    inputValue: "Acessórios e vestuário > Acessórios para calçado > Atacadores",
  },
  {
    value: "351",
    inputValue: "Acessórios e vestuário > Acessórios para calçado > Esporas",
  },
  { value: "352", inputValue: "Acessórios e vestuário > Calçado" },
  { value: "353", inputValue: "Artes e lazer" },
  { value: "354", inputValue: "Artes e lazer > Bilhetes de eventos" },
  { value: "355", inputValue: "Artes e lazer > Passatempos e artes criativas" },
  {
    value: "356",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais",
  },
  {
    value: "357",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Kits de artes e trabalhos manuais",
  },
  {
    value: "358",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Kits de artes e trabalhos manuais > Kits para fabricar velas",
  },
  {
    value: "359",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Kits de artes e trabalhos manuais > Kits para desenho e pintura",
  },
  {
    value: "360",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Kits de artes e trabalhos manuais > Kits para reparação de tecidos",
  },
  {
    value: "361",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Kits de artes e trabalhos manuais > Kits para fazer incenso",
  },
  {
    value: "362",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Kits de artes e trabalhos manuais > Kits para fazer joalharia",
  },
  {
    value: "363",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Kits de artes e trabalhos manuais > Kits para mosaicos",
  },
  {
    value: "364",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Kits de artes e trabalhos manuais > Kits para trabalhos com agulhas",
  },
  {
    value: "365",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Kits de artes e trabalhos manuais > Kits para álbum de recortes e carimbos",
  },
  {
    value: "366",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Kits de artes e trabalhos manuais > Kits para confeção de brinquedos",
  },
  {
    value: "367",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais",
  },
  {
    value: "368",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Papel para artes e trabalhos manuais",
  },
  {
    value: "369",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Papel para artes e trabalhos manuais > Papel para cartão e álbum de recortes",
  },
  {
    value: "370",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Papel para artes e trabalhos manuais > Papel para cartão e álbum de recortes > Cartão",
  },
  {
    value: "371",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Papel para artes e trabalhos manuais > Papel para cartão e álbum de recortes > Papel para álbum de recortes",
  },
  {
    value: "372",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Papel para artes e trabalhos manuais > Papel de construção",
  },
  {
    value: "373",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Papel para artes e trabalhos manuais > Películas para trabalhos manuais",
  },
  {
    value: "374",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Papel para artes e trabalhos manuais > Papel de desenho e pintura",
  },
  {
    value: "375",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Papel para artes e trabalhos manuais > Papel para Origami",
  },
  {
    value: "376",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Papel para artes e trabalhos manuais > Papel de transferência",
  },
  {
    value: "377",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Papel para artes e trabalhos manuais > Papel velino",
  },
  {
    value: "378",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fechos e tampas para trabalhos manuais",
  },
  {
    value: "379",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fechos e tampas para trabalhos manuais > Botões e encaixes",
  },
  {
    value: "380",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fechos e tampas para trabalhos manuais > Fivelas e ganchos",
  },
  {
    value: "381",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fechos e tampas para trabalhos manuais > Olhais e argolas",
  },
  {
    value: "382",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fechos e tampas para trabalhos manuais > Fixadores de velcro",
  },
  {
    value: "383",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fechos e tampas para trabalhos manuais > Puxadores de fecho de correr",
  },
  {
    value: "384",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fechos e tampas para trabalhos manuais > Fechos de correr",
  },
  {
    value: "385",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Tintas, pigmentos e revestimentos para trabalhos manuais",
  },
  {
    value: "386",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Tintas, pigmentos e revestimentos para trabalhos manuais > Tinta para artes e para trabalhos manuais",
  },
  {
    value: "387",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Tintas, pigmentos e revestimentos para trabalhos manuais > Fixadores para arte",
  },
  {
    value: "388",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Tintas, pigmentos e revestimentos para trabalhos manuais > Tinta para arte",
  },
  {
    value: "389",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Tintas, pigmentos e revestimentos para trabalhos manuais > Revestimentos para cerâmica e olaria",
  },
  {
    value: "390",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Tintas, pigmentos e revestimentos para trabalhos manuais > Tintas para trabalhos manuais",
  },
  {
    value: "391",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Tintas, pigmentos e revestimentos para trabalhos manuais > Almofadas de tinta",
  },
  {
    value: "392",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Tintas, pigmentos e revestimentos para trabalhos manuais > Meios de tinta",
  },
  {
    value: "393",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Bases e formas para trabalhos manuais",
  },
  {
    value: "394",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Bases e formas para trabalhos manuais > Espuma e isopor para trabalhos manuais",
  },
  {
    value: "395",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Bases e formas para trabalhos manuais > Madeira e formas para trabalhos manuais",
  },
  {
    value: "396",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Bases e formas para trabalhos manuais > Formas de papel maché",
  },
  {
    value: "397",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Bases e formas para trabalhos manuais > Estruturas de grinaldas e florais",
  },
  {
    value: "398",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Autocolantes e ímanes para trabalhos manuais",
  },
  {
    value: "399",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Autocolantes e ímanes para trabalhos manuais > Cola para escritório e para trabalhos manuais",
  },
  {
    value: "400",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Autocolantes e ímanes para trabalhos manuais > Ímanes para trabalhos manuais",
  },
  {
    value: "401",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Autocolantes e ímanes para trabalhos manuais > Fita decorativa",
  },
  {
    value: "402",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Autocolantes e ímanes para trabalhos manuais > Fita floral",
  },
  {
    value: "403",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Autocolantes e ímanes para trabalhos manuais > Fita fundível",
  },
  {
    value: "404",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fibras para trabalhos manuais",
  },
  {
    value: "405",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fibras para trabalhos manuais > Joalharia e fios para contas",
  },
  {
    value: "406",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fibras para trabalhos manuais > Fios e linhas",
  },
  {
    value: "407",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fibras para trabalhos manuais > Novelos de linha",
  },
  {
    value: "408",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fibras para trabalhos manuais > Fio",
  },
  {
    value: "409",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fio para trabalhos manuais",
  },
  {
    value: "410",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fio para trabalhos manuais > Limpa-cachimbos para trabalhos manuais",
  },
  {
    value: "411",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fio para trabalhos manuais > Fio floral",
  },
  {
    value: "412",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Fio para trabalhos manuais > Fio de joalharia e contas",
  },
  {
    value: "413",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições",
  },
  {
    value: "414",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições > Apliques e remendos",
  },
  {
    value: "415",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições > Contas",
  },
  {
    value: "416",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições > Arcos e ioiós",
  },
  {
    value: "417",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições > Autocolantes decorativos",
  },
  {
    value: "418",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições > Elásticos",
  },
  {
    value: "419",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições > Penas",
  },
  {
    value: "420",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições > Acessórios de joalharia",
  },
  {
    value: "421",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições > Pedras soltas",
  },
  {
    value: "422",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições > Strass e costas lisas",
  },
  {
    value: "423",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições > Fitas e enfeites",
  },
  {
    value: "424",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições > Lantejoulas e glitter",
  },
  {
    value: "425",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Adornos e guarnições > Etiquetas de coser",
  },
  {
    value: "426",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Pó de gravação",
  },
  {
    value: "427",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Material de enchimento e estofamento",
  },
  {
    value: "428",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Material de enchimento e estofamento > Batimento e enchimento",
  },
  {
    value: "429",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Material de enchimento e estofamento > Pellets de enchimento",
  },
  {
    value: "430",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Material de enchimento e estofamento > Formas de almofada",
  },
  {
    value: "431",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Pele e vinil",
  },
  {
    value: "432",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Materiais de olaria e escultura",
  },
  {
    value: "433",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Materiais de olaria e escultura > Barro e pasta de modelagem",
  },
  {
    value: "434",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Materiais de olaria e escultura > Barro e pasta de modelagem > Argila",
  },
  {
    value: "435",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Materiais de olaria e escultura > Barro e pasta de modelagem > Pasta de modelagem",
  },
  {
    value: "436",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Materiais de olaria e escultura > Misturas de papel maché",
  },
  {
    value: "437",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Materiais de olaria e escultura > Revestimento de gesso",
  },
  {
    value: "438",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Materiais de olaria e escultura > Pastas de argila",
  },
  {
    value: "439",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Cera para velas",
  },
  {
    value: "440",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Têxteis",
  },
  {
    value: "441",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Têxteis > Tela para trabalhos manuais",
  },
  {
    value: "442",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Têxteis > Tela para trabalhos manuais > Tela para trabalhos com agulhas",
  },
  {
    value: "443",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Têxteis > Tela para trabalhos manuais > Tela para pintura",
  },
  {
    value: "444",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Têxteis > Tela para trabalhos manuais > Tela plástica",
  },
  {
    value: "445",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Têxteis > Tecido",
  },
  {
    value: "446",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Têxteis > Entretela",
  },
  {
    value: "447",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Têxteis > Tecido para impressão",
  },
  {
    value: "448",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Separadores de pavio",
  },
  {
    value: "449",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Materiais para artes e trabalhos manuais > Pavios",
  },
  {
    value: "450",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Acessórios de ferramentas para artes e trabalhos manuais",
  },
  {
    value: "451",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Acessórios de ferramentas para artes e trabalhos manuais > Lâminas de X-ato para trabalhos manuais",
  },
  {
    value: "452",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Acessórios de ferramentas para artes e trabalhos manuais > Estojos e proteções de máquina para trabalhos manuais",
  },
  {
    value: "453",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Acessórios de ferramentas para artes e trabalhos manuais > Mesas de extensão de máquina de costura",
  },
  {
    value: "454",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Acessórios de ferramentas para artes e trabalhos manuais > Pés de máquina de costura",
  },
  {
    value: "455",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Acessórios de ferramentas para artes e trabalhos manuais > Peças de substituição de máquina de costura",
  },
  {
    value: "456",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Acessórios de ferramentas para artes e trabalhos manuais > Acessórios de roda giratória",
  },
  {
    value: "457",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Acessórios de ferramentas para artes e trabalhos manuais > Blocos de estampagem",
  },
  {
    value: "458",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais",
  },
  {
    value: "459",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Tapetes de blocos",
  },
  {
    value: "460",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Fios de blocos",
  },
  {
    value: "461",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de mistura de cores",
  },
  {
    value: "462",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de mistura de cores > Espátulas",
  },
  {
    value: "463",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de mistura de cores > Paletes",
  },
  {
    value: "464",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de corte e relevo para trabalhos manuais",
  },
  {
    value: "465",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de corte e relevo para trabalhos manuais > Tesouras de escritório e trabalhos manuais",
  },
  {
    value: "466",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de corte e relevo para trabalhos manuais > Ferramentas de corte e relevo para trabalhos manuais",
  },
  {
    value: "467",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de corte e relevo para trabalhos manuais > Facas para trabalhos manuais",
  },
  {
    value: "468",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de corte e relevo para trabalhos manuais > Ferramentas de marcador para trabalhos manuais",
  },
  {
    value: "469",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de corte e relevo para trabalhos manuais > Ferramentas de gravação quente",
  },
  {
    value: "470",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de corte e relevo para trabalhos manuais > Estiletes e canetas de gravação",
  },
  {
    value: "471",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de corte e relevo para trabalhos manuais > Descosedores",
  },
  {
    value: "472",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de corte e relevo para trabalhos manuais > Cortadores de linhas e fios",
  },
  {
    value: "473",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Decoradores para trabalhos manuais",
  },
  {
    value: "474",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de medição e marcação para trabalhos manuais",
  },
  {
    value: "475",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de medição e marcação para trabalhos manuais > Pincéis de arte",
  },
  {
    value: "476",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de medição e marcação para trabalhos manuais > Rolos de borracha",
  },
  {
    value: "477",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de medição e marcação para trabalhos manuais > Carimbos decorativos",
  },
  {
    value: "478",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de medição e marcação para trabalhos manuais > Compassos",
  },
  {
    value: "479",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de medição e marcação para trabalhos manuais > Rodos de impressão em telas",
  },
  {
    value: "480",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de medição e marcação para trabalhos manuais > Máquinas de stencil",
  },
  {
    value: "481",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de medição e marcação para trabalhos manuais > Stencils e corte e vinco",
  },
  {
    value: "482",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de medição e marcação para trabalhos manuais > Contadores e marcadores de pontos",
  },
  {
    value: "483",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de medição e marcação para trabalhos manuais > Réguas e medidores de arte têxtil",
  },
  {
    value: "484",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de medição e marcação para trabalhos manuais > Ferramentas para queimar madeira",
  },
  {
    value: "485",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Tapetes de corte",
  },
  {
    value: "486",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Manequins",
  },
  {
    value: "487",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Almofadas e tapetes de feltro",
  },
  {
    value: "488",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Esticadores, aros e estruturas",
  },
  {
    value: "489",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Pistolas de cola",
  },
  {
    value: "490",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Caixas de luz",
  },
  {
    value: "491",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Agulhas e ganchos",
  },
  {
    value: "492",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Agulhas e ganchos > Agulhas de crochet",
  },
  {
    value: "493",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Agulhas e ganchos > Agulhas de costura manual",
  },
  {
    value: "494",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Agulhas e ganchos > Agulhas de tricot",
  },
  {
    value: "495",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Agulhas e ganchos > Agulhas de Esmirna",
  },
  {
    value: "496",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Agulhas e ganchos > Agulhas de máquina de costura",
  },
  {
    value: "497",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Alfinetes de segurança",
  },
  {
    value: "498",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Alfinetes retos",
  },
  {
    value: "499",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Máquinas para trabalhos manuais têxteis",
  },
  {
    value: "500",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Máquinas para trabalhos manuais têxteis > Máquina e agulhas de feltro",
  },
  {
    value: "501",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Máquinas para trabalhos manuais têxteis > Teares manuais",
  },
  {
    value: "502",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Máquinas para trabalhos manuais têxteis > Teares mecânicos",
  },
  {
    value: "503",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Máquinas para trabalhos manuais têxteis > Máquinas de costura",
  },
  {
    value: "504",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Máquinas para trabalhos manuais têxteis > Rodas giratórias",
  },
  {
    value: "505",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Dedais e palmas de costura",
  },
  {
    value: "506",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Dedais e palmas de costura > Palmas de costura",
  },
  {
    value: "507",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Dedais e palmas de costura > Dedais",
  },
  {
    value: "508",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de linhas e fios",
  },
  {
    value: "509",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de linhas e fios > Cartões de fibra e escovas",
  },
  {
    value: "510",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de linhas e fios > Fusos de mão",
  },
  {
    value: "511",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de linhas e fios > Enfiadores de agulhas",
  },
  {
    value: "512",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de linhas e fios > Guias de linhas e fios",
  },
  {
    value: "513",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de linhas e fios > Bobinas de linhas e fios",
  },
  {
    value: "514",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de linhas e fios > Enroladores de bobina, linhas e fios",
  },
  {
    value: "515",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de linhas e fios > Batedores para teares",
  },
  {
    value: "516",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Ferramentas para artes e trabalhos manuais > Ferramentas de linhas e fios > Lançadeiras de tear",
  },
  {
    value: "517",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Organização de trabalhos manuais",
  },
  {
    value: "518",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Organização de trabalhos manuais > Organizadores de agulhas, alfinetes e ganchos",
  },
  {
    value: "519",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Organização de trabalhos manuais > Cestos e kits de costura",
  },
  {
    value: "520",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Organização de trabalhos manuais > Organizadores de linhas e fios",
  },
  {
    value: "521",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Moldes e padrões para trabalhos manuais",
  },
  {
    value: "522",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Moldes e padrões para trabalhos manuais > Padrões de contas",
  },
  {
    value: "523",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Moldes e padrões para trabalhos manuais > Moldes para trabalhos manuais",
  },
  {
    value: "524",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Moldes e padrões para trabalhos manuais > Moldes de feltros",
  },
  {
    value: "525",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Moldes e padrões para trabalhos manuais > Padrões para trabalhos com agulhas",
  },
  {
    value: "526",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artes e trabalhos manuais > Moldes e padrões para trabalhos manuais > Padrões de costura",
  },
  {
    value: "527",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção",
  },
  {
    value: "528",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Autógrafos",
  },
  {
    value: "529",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Moedas de coleção",
  },
  {
    value: "530",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Moedas de coleção > Notas de coleção",
  },
  {
    value: "531",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Moedas de coleção > Moedas de coleção",
  },
  {
    value: "532",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Cromos de coleção",
  },
  {
    value: "533",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Armas de coleção",
  },
  {
    value: "534",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Armas de coleção > Revólveres de coleção",
  },
  {
    value: "535",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Armas de coleção > Facas de coleção",
  },
  {
    value: "536",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Armas de coleção > Espadas de coleção",
  },
  {
    value: "537",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Armas de coleção > Apoios e expositores de espadas",
  },
  {
    value: "538",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Selos de correio",
  },
  {
    value: "539",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Rochas e fósseis",
  },
  {
    value: "540",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Acessórios de modelo à escala",
  },
  {
    value: "541",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Modelos à escala",
  },
  {
    value: "542",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Selos e carimbos",
  },
  {
    value: "543",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção",
  },
  {
    value: "544",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Equipamento desportivo autografado",
  },
  {
    value: "545",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Equipamento desportivo autografado > Equipamento autografado de corrida automóvel",
  },
  {
    value: "546",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Equipamento desportivo autografado > Equipamento autografado de basebol e softball",
  },
  {
    value: "547",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Equipamento desportivo autografado > Equipamento autografado de basquetebol",
  },
  {
    value: "548",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Equipamento desportivo autografado > Equipamento autografado de boxe",
  },
  {
    value: "549",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Equipamento desportivo autografado > Parafernália Autografada de Futebol Americano",
  },
  {
    value: "550",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Equipamento desportivo autografado > Equipamento autografado de hóquei",
  },
  {
    value: "551",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Equipamento desportivo autografado > Equipamento autografado de futebol",
  },
  {
    value: "552",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Equipamento desportivo autografado > Equipamento desportivo autografado de ténis",
  },
  {
    value: "553",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Acessórios de adeptos desportivos",
  },
  {
    value: "554",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Acessórios de adeptos desportivos > Acessórios adeptos de corridas automóveis",
  },
  {
    value: "555",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Acessórios de adeptos desportivos > Acessórios de adeptos de basebol e softball",
  },
  {
    value: "556",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Acessórios de adeptos desportivos > Acessórios de adeptos de basquetebol",
  },
  {
    value: "557",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Acessórios de adeptos desportivos > Acessórios para Fãs de Futebol Americano",
  },
  {
    value: "558",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Acessórios de adeptos desportivos > Acessórios de adeptos de hóquei",
  },
  {
    value: "559",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Acessórios de adeptos desportivos > Acessórios de adeptos de futebol",
  },
  {
    value: "560",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Objetos desportivos de coleção > Acessórios de adeptos desportivos > Acessórios de adeptos de ténis",
  },
  {
    value: "561",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Objetos de coleção > Publicidade vintage",
  },
  {
    value: "562",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artigos para fabrico em casa de vinho e cerveja",
  },
  {
    value: "563",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artigos para fabrico em casa de vinho e cerveja > Maltes e grãos para fabrico de cerveja",
  },
  {
    value: "564",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artigos para fabrico em casa de vinho e cerveja > Garrafas para engarrafamento",
  },
  {
    value: "565",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artigos para fabrico em casa de vinho e cerveja > Kits para fabrico em casa de vinho e cerveja",
  },
  {
    value: "566",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Artigos para fabrico em casa de vinho e cerveja > Fabrico de vinho",
  },
  {
    value: "567",
    inputValue: "Artes e lazer > Passatempos e artes criativas > Malabarismo",
  },
  {
    value: "568",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Magia e novidades",
  },
  {
    value: "569",
    inputValue: "Artes e lazer > Passatempos e artes criativas > Modelismo",
  },
  {
    value: "570",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Modelismo > Modelos de construção de foguetes",
  },
  {
    value: "571",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Modelismo > Acessórios de comboios modelo",
  },
  {
    value: "572",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Modelismo > Conjunto de comboio e modelos de comboio",
  },
  {
    value: "573",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Modelismo > Kits de modelo à escala",
  },
  {
    value: "574",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra",
  },
  {
    value: "575",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro",
  },
  {
    value: "576",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro > Limpeza e cuidado de instrumentos de sopro",
  },
  {
    value: "577",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro > Limpeza e cuidado de instrumentos de sopro > Kits de cuidado de instrumentos de sopro",
  },
  {
    value: "578",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro > Limpeza e cuidado de instrumentos de sopro > Produtos de higiene e limpeza de instrumentos de sopro",
  },
  {
    value: "579",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro > Limpeza e cuidado de instrumentos de sopro > Ferramentas de limpeza de instrumentos de sopro",
  },
  {
    value: "580",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro > Limpeza e cuidado de instrumentos de sopro > Proteções de instrumentos de sopro",
  },
  {
    value: "581",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro > Limpeza e cuidado de instrumentos de sopro > Lubrificantes de instrumentos de sopro",
  },
  {
    value: "582",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro > Limpeza e cuidado de instrumentos de sopro > Panos de polimento de instrumentos de sopro",
  },
  {
    value: "583",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro > Sacos e estojos para instrumentos de sopro",
  },
  {
    value: "584",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro > Boquilhas de instrumentos de sopro",
  },
  {
    value: "585",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro > Surdinas de instrumentos de sopro",
  },
  {
    value: "586",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro > Peças de substituição de instrumentos de sopro",
  },
  {
    value: "587",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de sopro > Alças e suportes de instrumentos de sopro",
  },
  {
    value: "588",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Batutas",
  },
  {
    value: "589",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Sintonizadores eletrónicos",
  },
  {
    value: "590",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Metrónomos",
  },
  {
    value: "591",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Bancos e cadeiras de música",
  },
  {
    value: "592",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Pastas de partituras e letras de músicas",
  },
  {
    value: "593",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios para suportes de música",
  },
  {
    value: "594",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios para suportes de música > Sacos para suportes de música",
  },
  {
    value: "595",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios para suportes de música > Luzes para suportes de música",
  },
  {
    value: "596",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios para suportes de música > Clipes para partituras musicais",
  },
  {
    value: "597",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Suportes de música",
  },
  {
    value: "598",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de amplificador de instrumento musical",
  },
  {
    value: "599",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de amplificador de instrumento musical > Cabines de amplificador de instrumento musical",
  },
  {
    value: "600",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de amplificador de instrumento musical > Estojos e proteções de amplificador de instrumento musical",
  },
  {
    value: "601",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de amplificador de instrumento musical > Interruptores de pé de amplificador de instrumento musical",
  },
  {
    value: "602",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de amplificador de instrumento musical > Botões de amplificador de instrumento musical",
  },
  {
    value: "603",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de amplificador de instrumento musical > Suportes de amplificador de instrumento musical",
  },
  {
    value: "604",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de amplificador de instrumento musical > Tubos de amplificador de instrumento musical",
  },
  {
    value: "605",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Amplificadores de instrumento musical",
  },
  {
    value: "606",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de teclado musical",
  },
  {
    value: "607",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de teclado musical > Sacos e estojos de teclado musical",
  },
  {
    value: "608",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de teclado musical > Suportes de teclado musical",
  },
  {
    value: "609",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de teclado musical > Pedais de sustentação",
  },
  {
    value: "610",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão",
  },
  {
    value: "611",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Estojos de bateria e címbalo",
  },
  {
    value: "612",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Surdinas de bateria e címbalo",
  },
  {
    value: "613",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Peles de bateria",
  },
  {
    value: "614",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Chaves de bateria",
  },
  {
    value: "615",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Equipamento de kit de bateria",
  },
  {
    value: "616",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Equipamento de kit de bateria > Macetas de bombo",
  },
  {
    value: "617",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Equipamento de kit de bateria > Equipamento de montagem de kit de bateria",
  },
  {
    value: "618",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Equipamento de kit de bateria > Pedais de bateria",
  },
  {
    value: "619",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Acessórios de baqueta e vassoura para bateria",
  },
  {
    value: "620",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Acessórios de baqueta e vassoura para bateria > Sacos e suportes de baqueta e vassoura para bateria",
  },
  {
    value: "621",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Baquetas e vassouras para bateria",
  },
  {
    value: "622",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Módulos de bateria eletrónica",
  },
  {
    value: "623",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Acessórios de percussão manual",
  },
  {
    value: "624",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Acessórios de percussão manual > Sacos e estojos de percussão manual",
  },
  {
    value: "625",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Acessórios de percussão manual > Suportes e apoios de percussão manual",
  },
  {
    value: "626",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Maletas de percussão",
  },
  {
    value: "627",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de percussão > Suportes de percussão",
  },
  {
    value: "628",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas",
  },
  {
    value: "629",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra",
  },
  {
    value: "630",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Captadores pickups de guitarra acústica",
  },
  {
    value: "631",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Transpositores",
  },
  {
    value: "632",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Captadores pickups de guitarra elétrica",
  },
  {
    value: "633",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Sacos e estojos de guitarra",
  },
  {
    value: "634",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Encaixes e peças de guitarra",
  },
  {
    value: "635",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Humidificadores de guitarra",
  },
  {
    value: "636",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Palhetas de guitarra",
  },
  {
    value: "637",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Slides de guitarra",
  },
  {
    value: "638",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Apoios de guitarra",
  },
  {
    value: "639",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Correias de guitarra",
  },
  {
    value: "640",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Enroladores de cordas de guitarra",
  },
  {
    value: "641",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Cordas de guitarra",
  },
  {
    value: "642",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de guitarra > Carrilhões de afinação de guitarra",
  },
  {
    value: "643",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de instrumento de cordas de orquestra",
  },
  {
    value: "644",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de instrumento de cordas de orquestra > Estojos para arcos de instrumento de cordas de orquestra",
  },
  {
    value: "645",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de instrumento de cordas de orquestra > Arcos de instrumento de cordas de orquestra",
  },
  {
    value: "646",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de instrumento de cordas de orquestra > Estojos de instrumento de cordas de orquestra",
  },
  {
    value: "647",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de instrumento de cordas de orquestra > Encaixes e peças de instrumento de cordas de orquestra",
  },
  {
    value: "648",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de instrumento de cordas de orquestra > Surdinas de instrumento de cordas de orquestra",
  },
  {
    value: "649",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de instrumento de cordas de orquestra > Captadores pickups de instrumento de cordas de orquestra",
  },
  {
    value: "650",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de instrumento de cordas de orquestra > Suportes de instrumento de cordas de orquestra",
  },
  {
    value: "651",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Acessórios de instrumento de cordas de orquestra > Cordas de instrumento de cordas de orquestra",
  },
  {
    value: "652",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Limpeza e cuidado de instrumento de cordas",
  },
  {
    value: "653",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Limpeza e cuidado de instrumento de cordas > Resina para arco",
  },
  {
    value: "654",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Limpeza e cuidado de instrumento de cordas > Panos de limpeza para instrumento de cordas",
  },
  {
    value: "655",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de instrumentos de cordas > Limpeza e cuidado de instrumento de cordas > Polimento para instrumento de cordas",
  },
  {
    value: "656",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras",
  },
  {
    value: "657",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para fagote",
  },
  {
    value: "658",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para fagote > Limpeza e cuidado de fagote",
  },
  {
    value: "659",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para fagote > Limpeza e cuidado de fagote > Toalhetes para fagote",
  },
  {
    value: "660",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para fagote > Sacos e estojos para fagote",
  },
  {
    value: "661",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para fagote > Peças para fagote",
  },
  {
    value: "662",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para fagote > Peças para fagote > Bocais para fagote",
  },
  {
    value: "663",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para fagote > Peças para fagote > Peças pequenas para fagote",
  },
  {
    value: "664",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para fagote > Palhetas para fagote",
  },
  {
    value: "665",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para fagote > Apoios para fagote",
  },
  {
    value: "666",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para fagote > Correias e suportes para fagote",
  },
  {
    value: "667",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete",
  },
  {
    value: "668",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Limpeza e cuidado de clarinete",
  },
  {
    value: "669",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Limpeza e cuidado de clarinete > Kits de cuidado para clarinete",
  },
  {
    value: "670",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Limpeza e cuidado de clarinete > Escovas para clarinete",
  },
  {
    value: "671",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Limpeza e cuidado de clarinete > Toalhetes para clarinete",
  },
  {
    value: "672",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Sacos e estojos para clarinete",
  },
  {
    value: "673",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Abraçadeiras e tapa boquilhas para clarinete",
  },
  {
    value: "674",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Peças para clarinete",
  },
  {
    value: "675",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Peças para clarinete > Tubos para clarinete",
  },
  {
    value: "676",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Peças para clarinete > Campanas para clarinete",
  },
  {
    value: "677",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Peças para clarinete > Boquilhas para clarinete",
  },
  {
    value: "678",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Peças para clarinete > Peças pequenas para clarinete",
  },
  {
    value: "679",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Suportes e apoios para clarinete",
  },
  {
    value: "680",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Palhetas para clarinete",
  },
  {
    value: "681",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para clarinete > Correias e suportes para clarinete",
  },
  {
    value: "682",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para flauta",
  },
  {
    value: "683",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para flauta > Limpeza e cuidado de flauta",
  },
  {
    value: "684",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para flauta > Limpeza e cuidado de flauta > Kits de cuidado para flauta",
  },
  {
    value: "685",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para flauta > Limpeza e cuidado de flauta > Escovilhões de limpeza para flauta",
  },
  {
    value: "686",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para flauta > Limpeza e cuidado de flauta > Toalhetes para flauta",
  },
  {
    value: "687",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para flauta > Sacos e estojos para flauta",
  },
  {
    value: "688",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para flauta > Peças para flauta",
  },
  {
    value: "689",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para flauta > Peças para flauta > Cabeças de flauta",
  },
  {
    value: "690",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para flauta > Peças para flauta > Peças pequenas para flauta",
  },
  {
    value: "691",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para flauta > Suportes e apoios para flauta",
  },
  {
    value: "692",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para harmónica",
  },
  {
    value: "693",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para harmónica > Estojos para harmónica",
  },
  {
    value: "694",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para harmónica > Suportes para harmónica",
  },
  {
    value: "695",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para oboé e corne inglês",
  },
  {
    value: "696",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para oboé e corne inglês > Limpeza e cuidado de oboé",
  },
  {
    value: "697",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para oboé e corne inglês > Limpeza e cuidado de oboé > Kits de cuidado de oboé",
  },
  {
    value: "698",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para oboé e corne inglês > Limpeza e cuidado de oboé > Toalhetes para oboé",
  },
  {
    value: "699",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para oboé e corne inglês > Sacos e estojos para oboé",
  },
  {
    value: "700",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para oboé e corne inglês > Peças de oboé",
  },
  {
    value: "701",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para oboé e corne inglês > Peças de oboé > Peças pequenas para oboé",
  },
  {
    value: "702",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para oboé e corne inglês > Suportes e apoios para oboé",
  },
  {
    value: "703",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para oboé e corne inglês > Palhetas para oboé",
  },
  {
    value: "704",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para oboé e corne inglês > Correias e suportes para oboé",
  },
  {
    value: "705",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para gravador",
  },
  {
    value: "706",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para gravador > Limpeza e cuidado de gravador",
  },
  {
    value: "707",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para gravador > Estojos para gravador",
  },
  {
    value: "708",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para gravador > Peças para gravador",
  },
  {
    value: "709",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone",
  },
  {
    value: "710",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Limpeza e cuidado de saxofone",
  },
  {
    value: "711",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Limpeza e cuidado de saxofone > Kits de cuidado para saxofone",
  },
  {
    value: "712",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Limpeza e cuidado de saxofone > Escovas para saxofone",
  },
  {
    value: "713",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Limpeza e cuidado de saxofone > Toalhetes para saxofone",
  },
  {
    value: "714",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Sacos e estojos para saxofone",
  },
  {
    value: "715",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Abraçadeiras e tapa boquilhas para saxofone",
  },
  {
    value: "716",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Peças para saxofone",
  },
  {
    value: "717",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Peças para saxofone > Boquilhas para saxofone",
  },
  {
    value: "718",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Peças para saxofone > Pescoços para saxofone",
  },
  {
    value: "719",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Peças para saxofone > Peças pequenas para saxofone",
  },
  {
    value: "720",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Suportes e apoios para saxofone",
  },
  {
    value: "721",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Palhetas para saxofone",
  },
  {
    value: "722",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Acessórios para saxofone > Correias e suportes para saxofone",
  },
  {
    value: "723",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Lubrificante para cortiças de madeiras",
  },
  {
    value: "724",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Panos de polimento de madeiras",
  },
  {
    value: "725",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Estojos para palhetas de madeiras",
  },
  {
    value: "726",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Acessórios de instrumentos musicais e orquestra > Acessórios de madeiras > Facas para palhetas de madeiras",
  },
  {
    value: "727",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais",
  },
  {
    value: "728",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Acordeões e concertinas",
  },
  {
    value: "729",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Gaitas de foles",
  },
  {
    value: "730",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de metais",
  },
  {
    value: "731",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de metais > Saxotrompas alto e barítono",
  },
  {
    value: "732",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de metais > Eufónios",
  },
  {
    value: "733",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de metais > Trompas",
  },
  {
    value: "734",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de metais > Trombones",
  },
  {
    value: "735",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de metais > Trompetes e cornetas",
  },
  {
    value: "736",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de metais > Tubas",
  },
  {
    value: "737",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos musicais eletrónicos",
  },
  {
    value: "738",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos musicais eletrónicos > Samplers de áudio",
  },
  {
    value: "739",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos musicais eletrónicos > Controladores MIDI",
  },
  {
    value: "740",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos musicais eletrónicos > Teclados musicais",
  },
  {
    value: "741",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos musicais eletrónicos > Sintetizadores de som",
  },
  {
    value: "742",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão",
  },
  {
    value: "743",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Bombos",
  },
  {
    value: "744",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Címbalos",
  },
  {
    value: "745",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Kits de bateria",
  },
  {
    value: "746",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Baterias eletrónicas",
  },
  {
    value: "747",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Glockenspiels e xilofones",
  },
  {
    value: "748",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Gongos",
  },
  {
    value: "749",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica",
  },
  {
    value: "750",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Claves e castanholas",
  },
  {
    value: "751",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Címbalos de dedo e de mão",
  },
  {
    value: "752",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Sinos e campainhas acústicos",
  },
  {
    value: "753",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Baterias acústicas",
  },
  {
    value: "754",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Baterias acústicas > Bongos",
  },
  {
    value: "755",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Baterias acústicas > Cajons",
  },
  {
    value: "756",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Baterias acústicas > Congas",
  },
  {
    value: "757",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Baterias acústicas > Tambores de estrutura",
  },
  {
    value: "758",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Baterias acústicas > Darkubas",
  },
  {
    value: "759",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Baterias acústicas > Tablas",
  },
  {
    value: "760",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Baterias acústicas > Tambores falantes",
  },
  {
    value: "761",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Blocos musicais",
  },
  {
    value: "762",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Chocalhos musicais",
  },
  {
    value: "763",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Raspadores e carracas musicais",
  },
  {
    value: "764",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Shakers musicais",
  },
  {
    value: "765",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Triângulos musicais",
  },
  {
    value: "766",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Tamborins",
  },
  {
    value: "767",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Percussão acústica > Vibraslaps",
  },
  {
    value: "768",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Hi-Hats",
  },
  {
    value: "769",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Pads de treino",
  },
  {
    value: "770",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Tarolas",
  },
  {
    value: "771",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Percussão > Timbalões",
  },
  {
    value: "772",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Pianos",
  },
  {
    value: "773",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de corda",
  },
  {
    value: "774",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de corda > Violoncelos",
  },
  {
    value: "775",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de corda > Guitarras",
  },
  {
    value: "776",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de corda > Arpas",
  },
  {
    value: "777",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de corda > Contrabaixos",
  },
  {
    value: "778",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de corda > Violas",
  },
  {
    value: "779",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Instrumentos de corda > Violinos",
  },
  {
    value: "780",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras",
  },
  {
    value: "781",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Fagotes",
  },
  {
    value: "782",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Clarinetes",
  },
  {
    value: "783",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Flautas",
  },
  {
    value: "784",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Flautas de bisel",
  },
  {
    value: "785",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Harmónicas",
  },
  {
    value: "786",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Berimbaus",
  },
  {
    value: "787",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Melódicas",
  },
  {
    value: "788",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Flautas de Pan",
  },
  {
    value: "789",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Oboés e corne inglês",
  },
  {
    value: "790",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Ocarinas",
  },
  {
    value: "791",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Gravadores",
  },
  {
    value: "792",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Saxofones",
  },
  {
    value: "793",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Flautins",
  },
  {
    value: "794",
    inputValue:
      "Artes e lazer > Passatempos e artes criativas > Instrumentos musicais > Madeiras > Apitos de efeitos",
  },
  { value: "795", inputValue: "Artes e lazer > Festas e celebrações" },
  {
    value: "796",
    inputValue: "Artes e lazer > Festas e celebrações > Oferta de presentes",
  },
  {
    value: "797",
    inputValue:
      "Artes e lazer > Festas e celebrações > Oferta de presentes > Alfinetes de cabeça e abotoadeiras",
  },
  {
    value: "798",
    inputValue:
      "Artes e lazer > Festas e celebrações > Oferta de presentes > Alfinetes e abotoadeiras",
  },
  {
    value: "799",
    inputValue:
      "Artes e lazer > Festas e celebrações > Oferta de presentes > Flores frescas",
  },
  {
    value: "800",
    inputValue:
      "Artes e lazer > Festas e celebrações > Oferta de presentes > Embrulho de presentes",
  },
  {
    value: "801",
    inputValue:
      "Artes e lazer > Festas e celebrações > Oferta de presentes > Embrulho de presentes > Sacos de prendas",
  },
  {
    value: "802",
    inputValue:
      "Artes e lazer > Festas e celebrações > Oferta de presentes > Embrulho de presentes > Caixas e latas de presente",
  },
  {
    value: "803",
    inputValue:
      "Artes e lazer > Festas e celebrações > Oferta de presentes > Embrulho de presentes > Etiquetas e identificadores de presentes",
  },
  {
    value: "804",
    inputValue:
      "Artes e lazer > Festas e celebrações > Oferta de presentes > Embrulho de presentes > Papel de tecido",
  },
  {
    value: "805",
    inputValue:
      "Artes e lazer > Festas e celebrações > Oferta de presentes > Embrulho de presentes > Papel de embrulho",
  },
  {
    value: "806",
    inputValue:
      "Artes e lazer > Festas e celebrações > Oferta de presentes > Cartões de festas e de notas",
  },
  {
    value: "807",
    inputValue: "Artes e lazer > Festas e celebrações > Artigos para festas",
  },
  {
    value: "808",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Cartões com conselhos",
  },
  {
    value: "809",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Kits de balões",
  },
  {
    value: "810",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Balões",
  },
  {
    value: "811",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Cartazes",
  },
  {
    value: "812",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Velas de aniversário",
  },
  {
    value: "813",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Faixas de cadeiras",
  },
  {
    value: "814",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Decorações de cocktail",
  },
  {
    value: "815",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Confetti",
  },
  {
    value: "816",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Pompons decorativos",
  },
  {
    value: "817",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Jogos de beber",
  },
  {
    value: "818",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Jogos de beber > Beer Pong",
  },
  {
    value: "819",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Jogos de beber > Beer Pong > Mesas de Beer Pong",
  },
  {
    value: "820",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Palhinhas e misturadores de bebidas",
  },
  {
    value: "821",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Selos de envelope",
  },
  {
    value: "822",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Programas de eventos",
  },
  {
    value: "823",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Fogo de artifício e estalinhos",
  },
  {
    value: "824",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Decorações de festa insufláveis",
  },
  {
    value: "825",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Convites",
  },
  {
    value: "826",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Cornetas e língua da sogra festivos",
  },
  {
    value: "827",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Brindes festivos",
  },
  {
    value: "828",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Brindes festivos > Brindes de casamento",
  },
  {
    value: "829",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Jogos de festa",
  },
  {
    value: "830",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Chapéus de festa",
  },
  {
    value: "831",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Fitas e cortinas de festa",
  },
  {
    value: "832",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Kits de artigos de festa",
  },
  {
    value: "833",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Pinhatas",
  },
  {
    value: "834",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Suportes de cartões de lugar",
  },
  {
    value: "835",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Cartões de lugar",
  },
  {
    value: "836",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Cartões de resposta",
  },
  {
    value: "837",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Velas sparklers",
  },
  {
    value: "838",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Suportes e caixas de cartão para ocasiões especiais",
  },
  {
    value: "839",
    inputValue:
      "Artes e lazer > Festas e celebrações > Artigos para festas > Spray String",
  },
  {
    value: "840",
    inputValue: "Artes e lazer > Festas e celebrações > Efeitos especiais",
  },
  {
    value: "841",
    inputValue:
      "Artes e lazer > Festas e celebrações > Efeitos especiais > Bolas de discoteca",
  },
  {
    value: "842",
    inputValue:
      "Artes e lazer > Festas e celebrações > Efeitos especiais > Máquinas de nevoeiro",
  },
  {
    value: "843",
    inputValue:
      "Artes e lazer > Festas e celebrações > Efeitos especiais > Controladores de efeitos especiais",
  },
  {
    value: "844",
    inputValue:
      "Artes e lazer > Festas e celebrações > Efeitos especiais > Suportes de luz de efeitos especiais",
  },
  {
    value: "845",
    inputValue:
      "Artes e lazer > Festas e celebrações > Efeitos especiais > Iluminação de efeitos especiais",
  },
  {
    value: "846",
    inputValue:
      "Artes e lazer > Festas e celebrações > Troféus e condecorações",
  },
  {
    value: "847",
    inputValue:
      "Artes e lazer > Festas e celebrações > Troféus e condecorações > Certificados de condecoração",
  },
  {
    value: "848",
    inputValue:
      "Artes e lazer > Festas e celebrações > Troféus e condecorações > Medalhas e alfinetes de condecoração",
  },
  {
    value: "849",
    inputValue:
      "Artes e lazer > Festas e celebrações > Troféus e condecorações > Placas de condecoração",
  },
  {
    value: "850",
    inputValue:
      "Artes e lazer > Festas e celebrações > Troféus e condecorações > Faixas de condecoração",
  },
  {
    value: "851",
    inputValue:
      "Artes e lazer > Festas e celebrações > Troféus e condecorações > Troféus",
  },
  { value: "852", inputValue: "Bebé e criança" },
  { value: "853", inputValue: "Bebé e criança > Banho do bebé" },
  {
    value: "854",
    inputValue:
      "Bebé e criança > Banho do bebé > Banheiras e suportes para banho do bebé",
  },
  {
    value: "855",
    inputValue: "Bebé e criança > Banho do bebé > Viseiras de duche",
  },
  {
    value: "856",
    inputValue: "Bebé e criança > Conjuntos de oferta para bebé",
  },
  { value: "857", inputValue: "Bebé e criança > Saúde do bebé" },
  {
    value: "858",
    inputValue:
      "Bebé e criança > Saúde do bebé > Kits de saúde e higiene do bebé",
  },
  {
    value: "859",
    inputValue: "Bebé e criança > Saúde do bebé > Aspiradores nasais",
  },
  {
    value: "860",
    inputValue: "Bebé e criança > Saúde do bebé > Porta-chupetas e molas",
  },
  {
    value: "861",
    inputValue: "Bebé e criança > Saúde do bebé > Toalhetes para chupetas",
  },
  {
    value: "862",
    inputValue: "Bebé e criança > Saúde do bebé > Chupetas e mordedores",
  },
  { value: "863", inputValue: "Bebé e criança > Segurança do bebé" },
  {
    value: "864",
    inputValue:
      "Bebé e criança > Segurança do bebé > Acessórios de barreiras de segurança para aninhais e bebés",
  },
  {
    value: "865",
    inputValue:
      "Bebé e criança > Segurança do bebé > Barreiras de segurança para aninhais e bebés",
  },
  {
    value: "866",
    inputValue: "Bebé e criança > Segurança do bebé > Monitores de bebé",
  },
  {
    value: "867",
    inputValue:
      "Bebé e criança > Segurança do bebé > Trelas e arneses para segurança do bebé",
  },
  {
    value: "868",
    inputValue:
      "Bebé e criança > Segurança do bebé > Bloqueios e proteções para segurança do bebé",
  },
  {
    value: "869",
    inputValue:
      "Bebé e criança > Segurança do bebé > Parques de segurança para bebés",
  },
  {
    value: "870",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé",
  },
  {
    value: "871",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Brinquedos com o alfabeto",
  },
  {
    value: "872",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Brinquedos de atividade para o bebé",
  },
  {
    value: "873",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Espreguiçadeiras e baloiços para bebé",
  },
  {
    value: "874",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Saltadores e baloiços para bebé",
  },
  {
    value: "875",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Acessórios móveis para bebé",
  },
  {
    value: "876",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Mobiles para bebé",
  },
  {
    value: "877",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Chupetas para bebé",
  },
  {
    value: "878",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Andarilhos e centros de atividades para bebé",
  },
  {
    value: "879",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Ginásios e tapetes de atividades",
  },
  {
    value: "880",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Ginásios e tapetes de atividades > Ginásios de atividades",
  },
  {
    value: "881",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Ginásios e tapetes de atividades > Tapetes de atividades",
  },
  {
    value: "882",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Parques infantis",
  },
  {
    value: "883",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Brinquedos para arrastar e empurrar",
  },
  {
    value: "884",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Rocas",
  },
  {
    value: "885",
    inputValue:
      "Bebé e criança > Equipamento de atividade e brinquedos para bebé > Brinquedos para ordenar e empilhar",
  },
  { value: "886", inputValue: "Bebé e criança > Transporte do bebé" },
  {
    value: "887",
    inputValue:
      "Bebé e criança > Transporte do bebé > Cadeirinhas automóveis para bebé e criança",
  },
  {
    value: "888",
    inputValue: "Bebé e criança > Transporte do bebé > Transportadoras de bebé",
  },
  {
    value: "889",
    inputValue:
      "Bebé e criança > Transporte do bebé > Carrinhos de passeio para bebé",
  },
  {
    value: "890",
    inputValue: "Bebé e criança > Acessórios de transporte para bebé",
  },
  {
    value: "891",
    inputValue:
      "Bebé e criança > Acessórios de transporte para bebé > Acessórios de cadeirinhas automóveis para bebé e criança",
  },
  {
    value: "892",
    inputValue:
      "Bebé e criança > Acessórios de transporte para bebé > Acessórios de transportadoras de bebé",
  },
  {
    value: "893",
    inputValue:
      "Bebé e criança > Acessórios de transporte para bebé > Acessórios de carrinhos de passeio para bebé",
  },
  {
    value: "894",
    inputValue:
      "Bebé e criança > Acessórios de transporte para bebé > Forros e alcofas para transporte do bebé",
  },
  {
    value: "895",
    inputValue:
      "Bebé e criança > Acessórios de transporte para bebé > Coberturas para carrinho de compras e cadeiras de refeição",
  },
  { value: "896", inputValue: "Bebé e criança > Fraldas" },
  {
    value: "897",
    inputValue:
      "Bebé e criança > Fraldas > Aquecedores e distribuidores de fraldas para bebé",
  },
  { value: "898", inputValue: "Bebé e criança > Fraldas > Fraldas para bebé" },
  {
    value: "899",
    inputValue:
      "Bebé e criança > Fraldas > Coberturas para tapetes de mudas e tabuleiros",
  },
  {
    value: "900",
    inputValue: "Bebé e criança > Fraldas > Tapetes de mudas e tabuleiros",
  },
  { value: "901", inputValue: "Bebé e criança > Fraldas > Kits de fraldas" },
  { value: "902", inputValue: "Bebé e criança > Fraldas > Forros de fraldas" },
  {
    value: "903",
    inputValue: "Bebé e criança > Fraldas > Organizadores de fraldas",
  },
  {
    value: "904",
    inputValue:
      "Bebé e criança > Fraldas > Acessórios de contentores de fraldas",
  },
  {
    value: "905",
    inputValue: "Bebé e criança > Fraldas > Contentores de fraldas",
  },
  {
    value: "906",
    inputValue:
      "Bebé e criança > Fraldas > Tratamentos para assaduras de fraldas",
  },
  {
    value: "907",
    inputValue: "Bebé e criança > Fraldas > Sacos impermeáveis para fraldas",
  },
  { value: "908", inputValue: "Bebé e criança > Fraldas > Fraldas" },
  { value: "909", inputValue: "Bebé e criança > Amamentação e alimentação" },
  {
    value: "910",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Comida para bebé e criança",
  },
  {
    value: "911",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Comida para bebé e criança > Cereais para bebé",
  },
  {
    value: "912",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Comida para bebé e criança > Bebidas para bebé",
  },
  {
    value: "913",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Comida para bebé e criança > Comida para bebé",
  },
  {
    value: "914",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Comida para bebé e criança > Fórmulas para bebé",
  },
  {
    value: "915",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Comida para bebé e criança > Snacks para bebé",
  },
  {
    value: "916",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Comida para bebé e criança > Batidos e bebidas de nutrição para criança",
  },
  {
    value: "917",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Forros e tetinas para biberões de bebé",
  },
  {
    value: "918",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Forros e tetinas para biberões de bebé > Forros de biberões de bebé",
  },
  {
    value: "919",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Forros e tetinas para biberões de bebé > Tetinas para biberões de bebé",
  },
  {
    value: "920",
    inputValue: "Bebé e criança > Amamentação e alimentação > Biberões de bebé",
  },
  {
    value: "921",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Temporizadores para bebés",
  },
  {
    value: "922",
    inputValue: "Bebé e criança > Amamentação e alimentação > Babetes",
  },
  {
    value: "923",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Esterilizadores e aquecedores de biberão",
  },
  {
    value: "924",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Recipientes para armazenamento de leite materno",
  },
  {
    value: "925",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Acessórios de bomba de aleitamento",
  },
  {
    value: "926",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Bombas de aleitamento",
  },
  {
    value: "927",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Panos para refluxo",
  },
  {
    value: "928",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Coberturas para amamentação",
  },
  {
    value: "929",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Almofadas e proteções para alimentação",
  },
  {
    value: "930",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Coberturas de almofadas de amamentação",
  },
  {
    value: "931",
    inputValue:
      "Bebé e criança > Amamentação e alimentação > Almofadas de amamentação",
  },
  {
    value: "932",
    inputValue: "Bebé e criança > Amamentação e alimentação > Copos para bebé",
  },
  { value: "933", inputValue: "Bebé e criança > Treino do penico" },
  { value: "934", inputValue: "Bebé e criança > Treino do penico > Penicos" },
  {
    value: "935",
    inputValue: "Bebé e criança > Treino do penico > Kits de treino de penico",
  },
  {
    value: "936",
    inputValue: "Bebé e criança > Faixas e cobertores de receção",
  },
  {
    value: "937",
    inputValue:
      "Bebé e criança > Faixas e cobertores de receção > Cobertores de receção",
  },
  {
    value: "938",
    inputValue:
      "Bebé e criança > Faixas e cobertores de receção > Cobertores para envolver",
  },
  { value: "939", inputValue: "Business e industrial" },
  {
    value: "940",
    inputValue: "Business e industrial > Publicidade e marketing",
  },
  {
    value: "941",
    inputValue: "Business e industrial > Publicidade e marketing > Brochuras",
  },
  {
    value: "942",
    inputValue:
      "Business e industrial > Publicidade e marketing > Contadores de feira comercial",
  },
  {
    value: "943",
    inputValue:
      "Business e industrial > Publicidade e marketing > Ecrãs de feira comercial",
  },
  { value: "944", inputValue: "Business e industrial > Agricultura" },
  {
    value: "945",
    inputValue: "Business e industrial > Agricultura > Criação de animais",
  },
  {
    value: "946",
    inputValue:
      "Business e industrial > Agricultura > Criação de animais > Incubadoras de ovos",
  },
  {
    value: "947",
    inputValue:
      "Business e industrial > Agricultura > Criação de animais > Alimentação animal",
  },
  {
    value: "948",
    inputValue:
      "Business e industrial > Agricultura > Criação de animais > Alimentação animal > Alimentação de gado",
  },
  {
    value: "949",
    inputValue:
      "Business e industrial > Agricultura > Criação de animais > Alimentação animal > Alimentação de galinhas",
  },
  {
    value: "950",
    inputValue:
      "Business e industrial > Agricultura > Criação de animais > Alimentação animal > Alimentação de cabras e ovelhas",
  },
  {
    value: "951",
    inputValue:
      "Business e industrial > Agricultura > Criação de animais > Alimentação animal > Alimentação de manadas mistas",
  },
  {
    value: "952",
    inputValue:
      "Business e industrial > Agricultura > Criação de animais > Alimentação animal > Alimentação de porcos",
  },
  {
    value: "953",
    inputValue:
      "Business e industrial > Agricultura > Criação de animais > Comedouros e bebedouros para pecuária",
  },
  {
    value: "954",
    inputValue:
      "Business e industrial > Agricultura > Criação de animais > Arneses para pecuária",
  },
  {
    value: "955",
    inputValue:
      "Business e industrial > Componentes de controlo de automatização",
  },
  {
    value: "956",
    inputValue:
      "Business e industrial > Componentes de controlo de automatização > Controladores de lógica programáveis",
  },
  {
    value: "957",
    inputValue:
      "Business e industrial > Componentes de controlo de automatização > Comandos de velocidade ajustável e frequência variável",
  },
  { value: "958", inputValue: "Business e industrial > Construção" },
  {
    value: "959",
    inputValue: "Business e industrial > Construção > Topografia",
  },
  {
    value: "960",
    inputValue:
      "Business e industrial > Construção > Cones e barreiras de trânsito",
  },
  { value: "961", inputValue: "Business e industrial > Odontologia" },
  {
    value: "962",
    inputValue: "Business e industrial > Odontologia > Cimento dentário",
  },
  {
    value: "963",
    inputValue:
      "Business e industrial > Odontologia > Ferramentas odontológicas",
  },
  {
    value: "964",
    inputValue:
      "Business e industrial > Odontologia > Ferramentas odontológicas > Pratos Dappen",
  },
  {
    value: "965",
    inputValue:
      "Business e industrial > Odontologia > Ferramentas odontológicas > Espelhos odontológicos",
  },
  {
    value: "966",
    inputValue:
      "Business e industrial > Odontologia > Ferramentas odontológicas > Conjuntos de ferramentas odontológicas",
  },
  {
    value: "967",
    inputValue:
      "Business e industrial > Odontologia > Ferramentas odontológicas > Copos de profilaxia",
  },
  {
    value: "968",
    inputValue:
      "Business e industrial > Odontologia > Ferramentas odontológicas > Cabeças de profilaxia",
  },
  {
    value: "969",
    inputValue: "Business e industrial > Odontologia > Pasta de profilaxia",
  },
  { value: "970", inputValue: "Business e industrial > Filme e televisão" },
  { value: "971", inputValue: "Business e industrial > Finanças e seguros" },
  {
    value: "972",
    inputValue:
      "Business e industrial > Finanças e seguros > Barras de metais valiosos",
  },
  { value: "973", inputValue: "Business e industrial > Serviço alimentar" },
  {
    value: "974",
    inputValue:
      "Business e industrial > Serviço alimentar > Caixas de confeitaria",
  },
  {
    value: "975",
    inputValue:
      "Business e industrial > Serviço alimentar > Tabuleiros de restauração",
  },
  {
    value: "976",
    inputValue:
      "Business e industrial > Serviço alimentar > Apresentadores de cheque",
  },
  {
    value: "977",
    inputValue:
      "Business e industrial > Serviço alimentar > Recipientes de alimentos de concessão",
  },
  {
    value: "978",
    inputValue:
      "Business e industrial > Serviço alimentar > Tampas descartáveis",
  },
  {
    value: "979",
    inputValue:
      "Business e industrial > Serviço alimentar > Louça de servir descartável",
  },
  {
    value: "980",
    inputValue:
      "Business e industrial > Serviço alimentar > Louça de servir descartável > Tabuleiros de servir descartáveis",
  },
  {
    value: "981",
    inputValue:
      "Business e industrial > Serviço alimentar > Louça de mesa descartável",
  },
  {
    value: "982",
    inputValue:
      "Business e industrial > Serviço alimentar > Louça de mesa descartável > Taças descartáveis",
  },
  {
    value: "983",
    inputValue:
      "Business e industrial > Serviço alimentar > Louça de mesa descartável > Copos descartáveis",
  },
  {
    value: "984",
    inputValue:
      "Business e industrial > Serviço alimentar > Louça de mesa descartável > Cutelaria descartável",
  },
  {
    value: "985",
    inputValue:
      "Business e industrial > Serviço alimentar > Louça de mesa descartável > Pratos descartáveis",
  },
  {
    value: "986",
    inputValue:
      "Business e industrial > Serviço alimentar > Cestos de serviço alimentar",
  },
  {
    value: "987",
    inputValue:
      "Business e industrial > Serviço alimentar > Carrinhos de serviço alimentar",
  },
  {
    value: "988",
    inputValue:
      "Business e industrial > Serviço alimentar > Máquinas de lavar e secar alimentos",
  },
  {
    value: "989",
    inputValue:
      "Business e industrial > Serviço alimentar > Máquinas para grelhar salsichas",
  },
  {
    value: "990",
    inputValue: "Business e industrial > Serviço alimentar > Caixas de gelo",
  },
  {
    value: "991",
    inputValue:
      "Business e industrial > Serviço alimentar > Aquecedores de pratos e travessas",
  },
  {
    value: "992",
    inputValue:
      "Business e industrial > Serviço alimentar > Barreiras protetoras",
  },
  {
    value: "993",
    inputValue:
      "Business e industrial > Serviço alimentar > Recipientes alimentares",
  },
  {
    value: "994",
    inputValue:
      "Business e industrial > Serviço alimentar > Frigideiras basculantes",
  },
  {
    value: "995",
    inputValue:
      "Business e industrial > Serviço alimentar > Máquinas de vending",
  },
  {
    value: "996",
    inputValue: "Business e industrial > Silvicultura e indústria madeireira",
  },
  {
    value: "997",
    inputValue: "Business e industrial > Cabeleireiro e cosmetologia",
  },
  {
    value: "998",
    inputValue:
      "Business e industrial > Cabeleireiro e cosmetologia > Capas de cabeleireiro e proteções de pescoço",
  },
  {
    value: "999",
    inputValue:
      "Business e industrial > Cabeleireiro e cosmetologia > Cadeiras de pedicure",
  },
  {
    value: "1000",
    inputValue:
      "Business e industrial > Cabeleireiro e cosmetologia > Cadeiras de cabeleireiro",
  },
  { value: "1001", inputValue: "Business e industrial > Maquinaria pesada" },
  {
    value: "1002",
    inputValue: "Business e industrial > Maquinaria pesada > Trituradores",
  },
  {
    value: "1003",
    inputValue: "Business e industrial > Hotel e hospitalidade",
  },
  {
    value: "1004",
    inputValue: "Business e industrial > Armazenamento industrial",
  },
  {
    value: "1005",
    inputValue:
      "Business e industrial > Armazenamento industrial > Armários industriais",
  },
  {
    value: "1006",
    inputValue:
      "Business e industrial > Armazenamento industrial > Prateleiras industriais",
  },
  {
    value: "1007",
    inputValue:
      "Business e industrial > Armazenamento industrial > Contentores de expedição",
  },
  {
    value: "1008",
    inputValue:
      "Business e industrial > Armazenamento industrial > Divisórias, recintos e portas metálicos",
  },
  {
    value: "1009",
    inputValue:
      "Business e industrial > Acessórios de armazenamento industrial",
  },
  {
    value: "1010",
    inputValue: "Business e industrial > Carros e carrinhos de limpeza",
  },
  { value: "1011", inputValue: "Business e industrial > Forças de segurança" },
  {
    value: "1012",
    inputValue: "Business e industrial > Forças de segurança > Algemas",
  },
  {
    value: "1013",
    inputValue:
      "Business e industrial > Forças de segurança > Detetores de metal",
  },
  { value: "1014", inputValue: "Business e industrial > Produção" },
  {
    value: "1015",
    inputValue: "Business e industrial > Manuseamento de material",
  },
  {
    value: "1016",
    inputValue:
      "Business e industrial > Manuseamento de material > Transportadores",
  },
  {
    value: "1017",
    inputValue:
      "Business e industrial > Manuseamento de material > Elevadores e guindastes",
  },
  {
    value: "1018",
    inputValue:
      "Business e industrial > Manuseamento de material > Elevadores e guindastes > Guindastes, gruas e tróleis",
  },
  {
    value: "1019",
    inputValue:
      "Business e industrial > Manuseamento de material > Elevadores e guindastes > Macacos e empilhadores",
  },
  {
    value: "1020",
    inputValue:
      "Business e industrial > Manuseamento de material > Elevadores e guindastes > Elevadores de pessoal",
  },
  {
    value: "1021",
    inputValue:
      "Business e industrial > Manuseamento de material > Elevadores e guindastes > Polias, blocos e roldanas",
  },
  {
    value: "1022",
    inputValue:
      "Business e industrial > Manuseamento de material > Elevadores e guindastes > Guinchos",
  },
  {
    value: "1023",
    inputValue:
      "Business e industrial > Manuseamento de material > Paletes e plataformas de carga",
  },
  { value: "1024", inputValue: "Business e industrial > Médico" },
  {
    value: "1025",
    inputValue: "Business e industrial > Médico > Cortinas hospitalares",
  },
  {
    value: "1026",
    inputValue: "Business e industrial > Médico > Fatos hospitalares",
  },
  {
    value: "1027",
    inputValue: "Business e industrial > Médico > Roupa de cama hospitalar",
  },
  {
    value: "1028",
    inputValue: "Business e industrial > Médico > Equipamento médico",
  },
  {
    value: "1029",
    inputValue:
      "Business e industrial > Médico > Equipamento médico > Desfibriladores externos automáticos",
  },
  {
    value: "1030",
    inputValue:
      "Business e industrial > Médico > Equipamento médico > Cintos de marcha",
  },
  {
    value: "1031",
    inputValue:
      "Business e industrial > Médico > Equipamento médico > Diapasões e martelos de reflexologia médicos",
  },
  {
    value: "1032",
    inputValue:
      "Business e industrial > Médico > Equipamento médico > Esticadores e macas médicos",
  },
  {
    value: "1033",
    inputValue:
      "Business e industrial > Médico > Equipamento médico > Otoscópios e oftalmoscópios",
  },
  {
    value: "1034",
    inputValue:
      "Business e industrial > Médico > Equipamento médico > Elevadores de pacientes",
  },
  {
    value: "1035",
    inputValue:
      "Business e industrial > Médico > Equipamento médico > Estetoscópios",
  },
  {
    value: "1036",
    inputValue:
      "Business e industrial > Médico > Equipamento médico > Acessório de monitorização de sinais vitais",
  },
  {
    value: "1037",
    inputValue:
      "Business e industrial > Médico > Equipamento médico > Monitorização de sinais vitais",
  },
  {
    value: "1038",
    inputValue: "Business e industrial > Médico > Mobiliário médico",
  },
  {
    value: "1039",
    inputValue:
      "Business e industrial > Médico > Mobiliário médico > Mesas quiropráticas",
  },
  {
    value: "1040",
    inputValue:
      "Business e industrial > Médico > Mobiliário médico > Mesas e cadeiras de exame",
  },
  {
    value: "1041",
    inputValue:
      "Business e industrial > Médico > Mobiliário médico > Saúde domiciliar e camas hospitalares",
  },
  {
    value: "1042",
    inputValue:
      "Business e industrial > Médico > Mobiliário médico > Gabinetes médicos",
  },
  {
    value: "1043",
    inputValue:
      "Business e industrial > Médico > Mobiliário médico > Carrinhos médicos",
  },
  {
    value: "1044",
    inputValue:
      "Business e industrial > Médico > Mobiliário médico > Carrinhos médicos > Carrinhos de emergência",
  },
  {
    value: "1045",
    inputValue:
      "Business e industrial > Médico > Mobiliário médico > Carrinhos médicos > Polos IV e carrinhos",
  },
  {
    value: "1046",
    inputValue:
      "Business e industrial > Médico > Mobiliário médico > Mesas cirúrgicas",
  },
  {
    value: "1047",
    inputValue: "Business e industrial > Médico > Instrumentos médicos",
  },
  {
    value: "1048",
    inputValue:
      "Business e industrial > Médico > Instrumentos médicos > Fórceps médicos",
  },
  {
    value: "1049",
    inputValue:
      "Business e industrial > Médico > Instrumentos médicos > Lâminas de bisturi",
  },
  {
    value: "1050",
    inputValue:
      "Business e industrial > Médico > Instrumentos médicos > Bisturis",
  },
  {
    value: "1051",
    inputValue:
      "Business e industrial > Médico > Instrumentos médicos > Agulhas e suturas cirúrgicas",
  },
  {
    value: "1052",
    inputValue: "Business e industrial > Médico > Artigos médicos",
  },
  {
    value: "1053",
    inputValue:
      "Business e industrial > Médico > Artigos médicos > Luvas descartáveis",
  },
  {
    value: "1054",
    inputValue: "Business e industrial > Médico > Artigos médicos > Dedeiras",
  },
  {
    value: "1055",
    inputValue:
      "Business e industrial > Médico > Artigos médicos > Agulhas e seringas médicas",
  },
  {
    value: "1056",
    inputValue:
      "Business e industrial > Médico > Artigos médicos > Agulhas e seringas médicas > Conjuntos de agulhas e seringas médicas",
  },
  {
    value: "1057",
    inputValue:
      "Business e industrial > Médico > Artigos médicos > Agulhas e seringas médicas > Agulhas médicas",
  },
  {
    value: "1058",
    inputValue:
      "Business e industrial > Médico > Artigos médicos > Agulhas e seringas médicas > Seringas médicas",
  },
  {
    value: "1059",
    inputValue:
      "Business e industrial > Médico > Artigos médicos > Artigos de ostomia",
  },
  {
    value: "1060",
    inputValue:
      "Business e industrial > Médico > Artigos médicos > Abaixa-línguas",
  },
  {
    value: "1061",
    inputValue: "Business e industrial > Médico > Equipamento de ensino médico",
  },
  {
    value: "1062",
    inputValue:
      "Business e industrial > Médico > Equipamento de ensino médico > Manequins de formação de resposta de emergência e médicos",
  },
  { value: "1063", inputValue: "Business e industrial > Médico > Toucas" },
  { value: "1064", inputValue: "Business e industrial > Médico > Uniformes" },
  {
    value: "1065",
    inputValue: "Business e industrial > Médico > Fatos cirúrgicos",
  },
  {
    value: "1066",
    inputValue: "Business e industrial > Exploração mineira e de pedras",
  },
  { value: "1067", inputValue: "Business e industrial > Piercing e tatuagens" },
  {
    value: "1068",
    inputValue:
      "Business e industrial > Piercing e tatuagens > Artigos para piercing",
  },
  {
    value: "1069",
    inputValue:
      "Business e industrial > Piercing e tatuagens > Artigos para piercing > Agulhas para piercing",
  },
  {
    value: "1070",
    inputValue:
      "Business e industrial > Piercing e tatuagens > Artigos para tatuagem",
  },
  {
    value: "1071",
    inputValue:
      "Business e industrial > Piercing e tatuagens > Artigos para tatuagem > Coberturas de tatuagens",
  },
  {
    value: "1072",
    inputValue:
      "Business e industrial > Piercing e tatuagens > Artigos para tatuagem > Tintas de tatuagem",
  },
  {
    value: "1073",
    inputValue:
      "Business e industrial > Piercing e tatuagens > Artigos para tatuagem > Máquinas de tatuagem",
  },
  {
    value: "1074",
    inputValue:
      "Business e industrial > Piercing e tatuagens > Artigos para tatuagem > Agulhas de tatuagem",
  },
  { value: "1075", inputValue: "Business e industrial > Retalho" },
  {
    value: "1076",
    inputValue: "Business e industrial > Retalho > Expositores de roupa",
  },
  {
    value: "1077",
    inputValue: "Business e industrial > Retalho > Manequins de expositor",
  },
  {
    value: "1078",
    inputValue: "Business e industrial > Retalho > Peças de manequim",
  },
  {
    value: "1079",
    inputValue: "Business e industrial > Retalho > Manuseamento de dinheiro",
  },
  {
    value: "1080",
    inputValue:
      "Business e industrial > Retalho > Manuseamento de dinheiro > Verificadores de notas",
  },
  {
    value: "1081",
    inputValue:
      "Business e industrial > Retalho > Manuseamento de dinheiro > Acessórios de caixas registadoras e terminais de POS",
  },
  {
    value: "1082",
    inputValue:
      "Business e industrial > Retalho > Manuseamento de dinheiro > Acessórios de caixas registadoras e terminais de POS > Tabuleiros e gavetas para dinheiro",
  },
  {
    value: "1083",
    inputValue:
      "Business e industrial > Retalho > Manuseamento de dinheiro > Acessórios de caixas registadoras e terminais de POS > Terminais de cartão de crédito",
  },
  {
    value: "1084",
    inputValue:
      "Business e industrial > Retalho > Manuseamento de dinheiro > Acessórios de caixas registadoras e terminais de POS > Pads de captura de assinatura",
  },
  {
    value: "1085",
    inputValue:
      "Business e industrial > Retalho > Manuseamento de dinheiro > Caixas registadoras e terminais de POS",
  },
  {
    value: "1086",
    inputValue:
      "Business e industrial > Retalho > Manuseamento de dinheiro > Caixas registadoras e terminais de POS > Caixas registadoras",
  },
  {
    value: "1087",
    inputValue:
      "Business e industrial > Retalho > Manuseamento de dinheiro > Caixas registadoras e terminais de POS > Terminais de POS",
  },
  {
    value: "1088",
    inputValue:
      "Business e industrial > Retalho > Manuseamento de dinheiro > Dispositivos de contagem de moedas e notas",
  },
  {
    value: "1089",
    inputValue:
      "Business e industrial > Retalho > Manuseamento de dinheiro > Trocadores de dinheiro",
  },
  {
    value: "1090",
    inputValue:
      "Business e industrial > Retalho > Manuseamento de dinheiro > Sacos de depósito de dinheiro",
  },
  {
    value: "1091",
    inputValue:
      "Business e industrial > Retalho > Manuseamento de dinheiro > Embrulhos de papel para moedas e fitas para notas",
  },
  {
    value: "1092",
    inputValue:
      "Business e industrial > Retalho > Sacos de compras de papel e de plástico",
  },
  {
    value: "1093",
    inputValue:
      "Business e industrial > Retalho > Pistolas de etiquetas de preço",
  },
  {
    value: "1094",
    inputValue: "Business e industrial > Retalho > Expositores",
  },
  {
    value: "1095",
    inputValue:
      "Business e industrial > Retalho > Modelos e acessórios de expositores",
  },
  {
    value: "1096",
    inputValue: "Business e industrial > Ciência e laboratório",
  },
  {
    value: "1097",
    inputValue: "Business e industrial > Ciência e laboratório > Bioquímica",
  },
  {
    value: "1098",
    inputValue:
      "Business e industrial > Ciência e laboratório > Kits de dissecação",
  },
  {
    value: "1099",
    inputValue:
      "Business e industrial > Ciência e laboratório > Laboratórios químicos",
  },
  {
    value: "1100",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório",
  },
  {
    value: "1101",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Autoclaves",
  },
  {
    value: "1102",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Centrífugo",
  },
  {
    value: "1103",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Máquinas de fabrico de gelo seco",
  },
  {
    value: "1104",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Máquinas de congelação-secagem",
  },
  {
    value: "1105",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Misturadoras de laboratório",
  },
  {
    value: "1106",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Congeladoras de laboratório",
  },
  {
    value: "1107",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Funis de laboratório",
  },
  {
    value: "1108",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Placas quentes de laboratório",
  },
  {
    value: "1109",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Fornos de laboratório",
  },
  {
    value: "1110",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Acessórios de microscópio",
  },
  {
    value: "1111",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Acessórios de microscópio > Câmaras de microscópio",
  },
  {
    value: "1112",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Acessórios de microscópio > Óculos e adaptadores de microscópio",
  },
  {
    value: "1113",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Acessórios de microscópio > Lentes de objetiva de microscópio",
  },
  {
    value: "1114",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Acessórios de microscópio > Lâmpadas de substituição de microscópio",
  },
  {
    value: "1115",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Acessórios de microscópio > Lamelas de microscópio",
  },
  {
    value: "1116",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Microscópios",
  },
  {
    value: "1117",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Micrótomos",
  },
  {
    value: "1118",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Acessórios de espectrómetro",
  },
  {
    value: "1119",
    inputValue:
      "Business e industrial > Ciência e laboratório > Equipamento de laboratório > Espectrómetros",
  },
  {
    value: "1120",
    inputValue:
      "Business e industrial > Ciência e laboratório > Amostras de laboratório",
  },
  {
    value: "1121",
    inputValue:
      "Business e industrial > Ciência e laboratório > Artigos de laboratório",
  },
  {
    value: "1122",
    inputValue:
      "Business e industrial > Ciência e laboratório > Artigos de laboratório > Copos de precipitação",
  },
  {
    value: "1123",
    inputValue:
      "Business e industrial > Ciência e laboratório > Artigos de laboratório > Cilindros graduados",
  },
  {
    value: "1124",
    inputValue:
      "Business e industrial > Ciência e laboratório > Artigos de laboratório > Frascos de laboratório",
  },
  {
    value: "1125",
    inputValue:
      "Business e industrial > Ciência e laboratório > Artigos de laboratório > Placas Petri",
  },
  {
    value: "1126",
    inputValue:
      "Business e industrial > Ciência e laboratório > Artigos de laboratório > Pipetas",
  },
  {
    value: "1127",
    inputValue:
      "Business e industrial > Ciência e laboratório > Artigos de laboratório > Suportes de tubos de ensaio",
  },
  {
    value: "1128",
    inputValue:
      "Business e industrial > Ciência e laboratório > Artigos de laboratório > Tubos de ensaio",
  },
  {
    value: "1129",
    inputValue:
      "Business e industrial > Ciência e laboratório > Artigos de laboratório > Garrafas de lavagem",
  },
  { value: "1130", inputValue: "Business e industrial > Sinalização" },
  {
    value: "1131",
    inputValue:
      "Business e industrial > Sinalização > Sinais de horário de funcionamento",
  },
  {
    value: "1132",
    inputValue: "Business e industrial > Sinalização > Sinais digitais",
  },
  {
    value: "1133",
    inputValue: "Business e industrial > Sinalização > Sinais elétricos",
  },
  {
    value: "1134",
    inputValue:
      "Business e industrial > Sinalização > Sinais elétricos > Sinais LED",
  },
  {
    value: "1135",
    inputValue:
      "Business e industrial > Sinalização > Sinais elétricos > Sinais de néon",
  },
  {
    value: "1136",
    inputValue:
      "Business e industrial > Sinalização > Sinais de emergência e de saída",
  },
  {
    value: "1137",
    inputValue:
      "Business e industrial > Sinalização > Sinais de identificação de instalações",
  },
  {
    value: "1138",
    inputValue: "Business e industrial > Sinalização > Sinais aberto e fechado",
  },
  {
    value: "1139",
    inputValue:
      "Business e industrial > Sinalização > Sinais de estacionamento e autorizações",
  },
  {
    value: "1140",
    inputValue: "Business e industrial > Sinalização > Sinais de polícia",
  },
  {
    value: "1141",
    inputValue:
      "Business e industrial > Sinalização > Sinais de retalho e vendas",
  },
  {
    value: "1142",
    inputValue:
      "Business e industrial > Sinalização > Sinais rodoviários e de trânsito",
  },
  {
    value: "1143",
    inputValue:
      "Business e industrial > Sinalização > Sinais de segurança e aviso",
  },
  {
    value: "1144",
    inputValue: "Business e industrial > Sinalização > Sinais de segurança",
  },
  {
    value: "1145",
    inputValue:
      "Business e industrial > Sinalização > Sinais de passeios e jardins",
  },
  {
    value: "1146",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho",
  },
  {
    value: "1147",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Coletes à prova de bala",
  },
  {
    value: "1148",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Acessórios de máscaras de gás e ventiladores",
  },
  {
    value: "1149",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Capacetes rígidos",
  },
  {
    value: "1150",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Fatos de material perigoso",
  },
  {
    value: "1151",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Aventais de proteção",
  },
  {
    value: "1152",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Equipamento ocular de proteção",
  },
  {
    value: "1153",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Máscaras de proteção",
  },
  {
    value: "1154",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Máscaras de proteção > Máscaras de pó",
  },
  {
    value: "1155",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Máscaras de proteção > Máscaras de bombeiros",
  },
  {
    value: "1156",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Máscaras de proteção > Máscaras de gás e ventiladores",
  },
  {
    value: "1157",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Máscaras de proteção > Máscaras médicas",
  },
  {
    value: "1158",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Luvas de segurança",
  },
  {
    value: "1159",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Almofadas de joelhos de segurança",
  },
  {
    value: "1160",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Capacetes de soldagem",
  },
  {
    value: "1161",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Arneses de segurança no trabalho",
  },
  {
    value: "1162",
    inputValue:
      "Business e industrial > Equipamento de proteção de segurança no trabalho > Cordas de segurança no trabalho",
  },
  { value: "1163", inputValue: "Câmaras e ótica" },
  {
    value: "1164",
    inputValue: "Câmaras e ótica > Acessórios de câmara e ótica",
  },
  {
    value: "1165",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Cabos de substituição de câmara e ótica",
  },
  {
    value: "1166",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Câmara e lentes de câmara de vídeo",
  },
  {
    value: "1167",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Câmara e lentes de câmara de vídeo > Lentes de câmara",
  },
  {
    value: "1168",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Câmara e lentes de câmara de vídeo > Lentes de câmara de vigilância",
  },
  {
    value: "1169",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Câmara e lentes de câmara de vídeo > Lentes de câmara de vídeo",
  },
  {
    value: "1170",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de lente de câmara",
  },
  {
    value: "1171",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de lente de câmara > Anéis de adaptador de filtro e lentes",
  },
  {
    value: "1172",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de lente de câmara > Sacos de lentes",
  },
  {
    value: "1173",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de lente de câmara > Proteções de lentes",
  },
  {
    value: "1174",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de lente de câmara > Conversores de lentes",
  },
  {
    value: "1175",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de lente de câmara > Filtros de lentes",
  },
  {
    value: "1176",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de lente de câmara > Coberturas de lentes",
  },
  {
    value: "1177",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara",
  },
  {
    value: "1178",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Conjuntos de acessório de câmara",
  },
  {
    value: "1179",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Sacos e estojos de câmara",
  },
  {
    value: "1180",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Painéis e entradas de substituição de corpo de câmara",
  },
  {
    value: "1181",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Partes de trás de câmaras digitais",
  },
  {
    value: "1182",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Película de câmara",
  },
  {
    value: "1183",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Acessórios de flash de câmara",
  },
  {
    value: "1184",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Flashes de câmara",
  },
  {
    value: "1185",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Dispositivos de foco de câmara",
  },
  {
    value: "1186",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Engrenagens de câmara",
  },
  {
    value: "1187",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Pegas de câmara",
  },
  {
    value: "1188",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Sensores de imagem de câmara",
  },
  {
    value: "1189",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Unidades de zoom de lentes de câmara",
  },
  {
    value: "1190",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Controlos remotos de câmara",
  },
  {
    value: "1191",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Botões e puxadores de substituição de câmara",
  },
  {
    value: "1192",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Ecrãs e visores de substituição de câmara",
  },
  {
    value: "1193",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Silenciadores de câmara e atenuadores de som",
  },
  {
    value: "1194",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Suportes e estabilizadores de câmara",
  },
  {
    value: "1195",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Fitas de câmara",
  },
  {
    value: "1196",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Acessórios para proteções solares e visores de câmara",
  },
  {
    value: "1197",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Suportes de flash",
  },
  {
    value: "1198",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Monitores integrados na câmara",
  },
  {
    value: "1199",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Acessórios de câmara de vigilância",
  },
  {
    value: "1200",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Acessórios de alojamento de câmara submarina",
  },
  {
    value: "1201",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Alojamentos de câmara submarina",
  },
  {
    value: "1202",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Peças e acessórios de câmara > Luzes de câmara vídeo",
  },
  {
    value: "1203",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios óticos",
  },
  {
    value: "1204",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios óticos > Acessórios de binóculos e monóculos",
  },
  {
    value: "1205",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios óticos > Sacos e estojos óticos",
  },
  {
    value: "1206",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios óticos > Acessórios de telémetro",
  },
  {
    value: "1207",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios óticos > Acessórios de telescópio terrestre",
  },
  {
    value: "1208",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios óticos > Acessórios de telescópio",
  },
  {
    value: "1209",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios óticos > Acessórios de ótica térmica",
  },
  {
    value: "1210",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios óticos > Acessórios de mira e alcance de arma",
  },
  {
    value: "1211",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de monopé e tripé",
  },
  {
    value: "1212",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de monopé e tripé > Estojos de monopé e tripé",
  },
  {
    value: "1213",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de monopé e tripé > Cabeças de monopé e tripé",
  },
  {
    value: "1214",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de monopé e tripé > Colares e suportes de tripé",
  },
  {
    value: "1215",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de monopé e tripé > Pegas de tripé",
  },
  {
    value: "1216",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Acessórios de monopé e tripé > Extensores de tripé",
  },
  {
    value: "1217",
    inputValue:
      "Câmaras e ótica > Acessórios de câmara e ótica > Tripés e monopés",
  },
  { value: "1218", inputValue: "Câmaras e ótica > Câmaras" },
  { value: "1219", inputValue: "Câmaras e ótica > Câmaras > Boroscópios" },
  { value: "1220", inputValue: "Câmaras e ótica > Câmaras > Câmaras digitais" },
  {
    value: "1221",
    inputValue: "Câmaras e ótica > Câmaras > Câmaras descartáveis",
  },
  {
    value: "1222",
    inputValue: "Câmaras e ótica > Câmaras > Câmara de película",
  },
  {
    value: "1223",
    inputValue: "Câmaras e ótica > Câmaras > Câmara de vigilância",
  },
  { value: "1224", inputValue: "Câmaras e ótica > Câmaras > Câmaras de caça" },
  { value: "1225", inputValue: "Câmaras e ótica > Câmaras > Câmaras de vídeo" },
  { value: "1226", inputValue: "Câmaras e ótica > Câmaras > Câmaras web" },
  { value: "1227", inputValue: "Câmaras e ótica > Ótica" },
  { value: "1228", inputValue: "Câmaras e ótica > Ótica > Binóculos" },
  { value: "1229", inputValue: "Câmaras e ótica > Ótica > Monóculos" },
  { value: "1230", inputValue: "Câmaras e ótica > Ótica > Telémetros" },
  { value: "1231", inputValue: "Câmaras e ótica > Ótica > Miras" },
  {
    value: "1232",
    inputValue: "Câmaras e ótica > Ótica > Miras > Miras de Spotting",
  },
  {
    value: "1233",
    inputValue: "Câmaras e ótica > Ótica > Miras > Telescópios",
  },
  {
    value: "1234",
    inputValue: "Câmaras e ótica > Ótica > Miras > Miras e alcances de armas",
  },
  { value: "1235", inputValue: "Câmaras e ótica > Fotografia" },
  { value: "1236", inputValue: "Câmaras e ótica > Fotografia > Câmara escura" },
  {
    value: "1237",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Equipamento de revelação e processamento",
  },
  {
    value: "1238",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Equipamento de revelação e processamento > Suportes de cópia",
  },
  {
    value: "1239",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Equipamento de revelação e processamento > Tinas de câmara escura",
  },
  {
    value: "1240",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Equipamento de revelação e processamento > Tanques e bobinas de revelação",
  },
  {
    value: "1241",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Equipamento de revelação e processamento > Máquinas de lavar e secar, tabuleiros de impressão",
  },
  {
    value: "1242",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Equipamento de revelação e processamento > Artigos e equipamento de retoque",
  },
  {
    value: "1243",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Equipamento de ampliação",
  },
  {
    value: "1244",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Equipamento de ampliação > Cavaletes de câmara escura",
  },
  {
    value: "1245",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Equipamento de ampliação > Temporizadores de câmara escura",
  },
  {
    value: "1246",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Equipamento de ampliação > Apoios de foco",
  },
  {
    value: "1247",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Equipamento de ampliação > Analisadores fotográficos",
  },
  {
    value: "1248",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Equipamento de ampliação > Ampliadores fotográficos",
  },
  {
    value: "1249",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Químicos fotográficos",
  },
  {
    value: "1250",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Papel fotográfico",
  },
  {
    value: "1251",
    inputValue:
      "Câmaras e ótica > Fotografia > Câmara escura > Luzes de segurança",
  },
  {
    value: "1252",
    inputValue: "Câmaras e ótica > Fotografia > Iluminação e estúdio",
  },
  {
    value: "1253",
    inputValue:
      "Câmaras e ótica > Fotografia > Iluminação e estúdio > Acessórios de medição de luz",
  },
  {
    value: "1254",
    inputValue:
      "Câmaras e ótica > Fotografia > Iluminação e estúdio > Medidores de luz",
  },
  {
    value: "1255",
    inputValue:
      "Câmaras e ótica > Fotografia > Iluminação e estúdio > Fundos de estúdio",
  },
  {
    value: "1256",
    inputValue:
      "Câmaras e ótica > Fotografia > Iluminação e estúdio > Acessórios de flash e iluminação de estúdio",
  },
  {
    value: "1257",
    inputValue:
      "Câmaras e ótica > Fotografia > Iluminação e estúdio > Controlos de iluminação de estúdio",
  },
  {
    value: "1258",
    inputValue:
      "Câmaras e ótica > Fotografia > Iluminação e estúdio > Controlos de iluminação de estúdio > Difusores de flash",
  },
  {
    value: "1259",
    inputValue:
      "Câmaras e ótica > Fotografia > Iluminação e estúdio > Controlos de iluminação de estúdio > Refletores de flash",
  },
  {
    value: "1260",
    inputValue:
      "Câmaras e ótica > Fotografia > Iluminação e estúdio > Controlos de iluminação de estúdio > Gobos e filtros de iluminação",
  },
  {
    value: "1261",
    inputValue:
      "Câmaras e ótica > Fotografia > Iluminação e estúdio > Controlos de iluminação de estúdio > Softboxes",
  },
  {
    value: "1262",
    inputValue:
      "Câmaras e ótica > Fotografia > Iluminação e estúdio > Flashes e luzes de estúdio",
  },
  {
    value: "1263",
    inputValue:
      "Câmaras e ótica > Fotografia > Iluminação e estúdio > Acessórios de suporte e montagem de estúdio",
  },
  {
    value: "1264",
    inputValue:
      "Câmaras e ótica > Fotografia > Iluminação e estúdio > Suportes e apoios de estúdio",
  },
  {
    value: "1265",
    inputValue:
      "Câmaras e ótica > Fotografia > Artigos de montagem fotográfica",
  },
  {
    value: "1266",
    inputValue:
      "Câmaras e ótica > Fotografia > Armazenamento de diapositivos e negativos de fotografias",
  },
  { value: "1267", inputValue: "Eletrónica" },
  { value: "1268", inputValue: "Eletrónica > Equipamento de máquinas de jogo" },
  {
    value: "1269",
    inputValue:
      "Eletrónica > Equipamento de máquinas de jogo > Máquinas de jogos de basquetebol",
  },
  {
    value: "1270",
    inputValue:
      "Eletrónica > Equipamento de máquinas de jogo > Acessórios de máquina de pinball",
  },
  {
    value: "1271",
    inputValue:
      "Eletrónica > Equipamento de máquinas de jogo > Máquinas de pinball",
  },
  {
    value: "1272",
    inputValue:
      "Eletrónica > Equipamento de máquinas de jogo > Máquinas de Skee-Ball",
  },
  {
    value: "1273",
    inputValue:
      "Eletrónica > Equipamento de máquinas de jogo > Acessórios de máquinas de videojogos",
  },
  {
    value: "1274",
    inputValue:
      "Eletrónica > Equipamento de máquinas de jogo > Máquinas de videojogos",
  },
  { value: "1275", inputValue: "Eletrónica > Áudio" },
  { value: "1276", inputValue: "Eletrónica > Áudio > Acessórios de áudio" },
  {
    value: "1277",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de recetor de áudio e vídeo",
  },
  {
    value: "1278",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de auscultadores e auriculares",
  },
  {
    value: "1279",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de auscultadores e auriculares > Pontas e almofadas para auscultadores",
  },
  {
    value: "1280",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de sistemas de karaoke",
  },
  {
    value: "1281",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de sistemas de karaoke > Cartuchos de Karaoke",
  },
  {
    value: "1282",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de leitor de MP3",
  },
  {
    value: "1283",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de leitor de MP3 > Conjuntos de acessórios de leitor de MP3 e telemóvel",
  },
  {
    value: "1284",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de leitor de MP3 > Estojos de leitor de MP3",
  },
  {
    value: "1285",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de microfone",
  },
  {
    value: "1286",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Apoios de microfone",
  },
  {
    value: "1287",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de rádio satélite",
  },
  {
    value: "1288",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de coluna",
  },
  {
    value: "1289",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de coluna > Sacos, estojos e proteções de colunas",
  },
  {
    value: "1290",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de coluna > Kits e componentes de coluna",
  },
  {
    value: "1291",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de coluna > Sacos para suportes de colunas",
  },
  {
    value: "1292",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de coluna > Suportes e apoios de coluna",
  },
  {
    value: "1293",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de coluna > Transdutores táteis",
  },
  {
    value: "1294",
    inputValue:
      "Eletrónica > Áudio > Acessórios de áudio > Acessórios de plataforma giratória",
  },
  { value: "1295", inputValue: "Eletrónica > Áudio > Componentes áudio" },
  {
    value: "1296",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Recetores áudio e vídeo",
  },
  {
    value: "1297",
    inputValue: "Eletrónica > Áudio > Componentes áudio > Amplificadores áudio",
  },
  {
    value: "1298",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Amplificadores áudio > Amplificadores de auscultador",
  },
  {
    value: "1299",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Amplificadores áudio > Amplificadores de potência",
  },
  {
    value: "1300",
    inputValue: "Eletrónica > Áudio > Componentes áudio > Misturadores áudio",
  },
  {
    value: "1301",
    inputValue: "Eletrónica > Áudio > Componentes áudio > Transmissores áudio",
  },
  {
    value: "1302",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Transmissores áudio > Transmissores Bluetooth",
  },
  {
    value: "1303",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Transmissores áudio > Transmissores FM",
  },
  {
    value: "1304",
    inputValue: "Eletrónica > Áudio > Componentes áudio > Pré-amplificador",
  },
  {
    value: "1305",
    inputValue: "Eletrónica > Áudio > Componentes áudio > Caixas diretas",
  },
  {
    value: "1306",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Auscultadores e auriculares",
  },
  {
    value: "1307",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Auscultadores e auriculares > Auscultadores",
  },
  {
    value: "1308",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Auscultadores e auriculares > Auriculares",
  },
  {
    value: "1309",
    inputValue: "Eletrónica > Áudio > Componentes áudio > Microfones",
  },
  {
    value: "1310",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Processadores de sinal",
  },
  {
    value: "1311",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Processadores de sinal > Separadores de frequências",
  },
  {
    value: "1312",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Processadores de sinal > Processadores de efeitos",
  },
  {
    value: "1313",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Processadores de sinal > Equalizadores",
  },
  {
    value: "1314",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Processadores de sinal > Sistemas de gestão de altifalante",
  },
  {
    value: "1315",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Processadores de sinal > Pré-amplificadores de microfone",
  },
  {
    value: "1316",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Processadores de sinal > Portões de ruído e compressores",
  },
  {
    value: "1317",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Processadores de sinal > Pré-amplificadores de phono",
  },
  {
    value: "1318",
    inputValue: "Eletrónica > Áudio > Componentes áudio > Colunas",
  },
  {
    value: "1319",
    inputValue:
      "Eletrónica > Áudio > Componentes áudio > Conjuntos de gravação de estúdio",
  },
  {
    value: "1320",
    inputValue: "Eletrónica > Áudio > Leitores e gravadores áudio",
  },
  {
    value: "1321",
    inputValue:
      "Eletrónica > Áudio > Leitores e gravadores áudio > Aparelhos de som portáteis",
  },
  {
    value: "1322",
    inputValue:
      "Eletrónica > Áudio > Leitores e gravadores áudio > Leitores e gravadores de CD",
  },
  {
    value: "1323",
    inputValue:
      "Eletrónica > Áudio > Leitores e gravadores áudio > Leitores e gravadores de cassete",
  },
  {
    value: "1324",
    inputValue:
      "Eletrónica > Áudio > Leitores e gravadores áudio > Sistemas de cinema em casa",
  },
  {
    value: "1325",
    inputValue: "Eletrónica > Áudio > Leitores e gravadores áudio > Jukeboxes",
  },
  {
    value: "1326",
    inputValue:
      "Eletrónica > Áudio > Leitores e gravadores áudio > Sistemas de Karaoke",
  },
  {
    value: "1327",
    inputValue:
      "Eletrónica > Áudio > Leitores e gravadores áudio > Leitores de MP3",
  },
  {
    value: "1328",
    inputValue:
      "Eletrónica > Áudio > Leitores e gravadores áudio > Leitores e gravadores de MiniDisc",
  },
  {
    value: "1329",
    inputValue:
      "Eletrónica > Áudio > Leitores e gravadores áudio > Gravadores multifaixas",
  },
  {
    value: "1330",
    inputValue: "Eletrónica > Áudio > Leitores e gravadores áudio > Rádios",
  },
  {
    value: "1331",
    inputValue:
      "Eletrónica > Áudio > Leitores e gravadores áudio > Leitores e gravadores de bobinas",
  },
  {
    value: "1332",
    inputValue:
      "Eletrónica > Áudio > Leitores e gravadores áudio > Sistemas estéreo",
  },
  {
    value: "1333",
    inputValue:
      "Eletrónica > Áudio > Leitores e gravadores áudio > Pratos e leitores de gravação",
  },
  {
    value: "1334",
    inputValue:
      "Eletrónica > Áudio > Leitores e gravadores áudio > Gravadores de voz",
  },
  { value: "1335", inputValue: "Eletrónica > Áudio > Megafones" },
  {
    value: "1336",
    inputValue: "Eletrónica > Áudio > DJ e especialidade áudio",
  },
  {
    value: "1337",
    inputValue:
      "Eletrónica > Áudio > DJ e especialidade áudio > Leitores DJ CD",
  },
  {
    value: "1338",
    inputValue: "Eletrónica > Áudio > DJ e especialidade áudio > Sistemas DJ",
  },
  {
    value: "1339",
    inputValue: "Eletrónica > Áudio > Sistemas de instalação sonora",
  },
  { value: "1340", inputValue: "Eletrónica > Áudio > Equipamento de palco" },
  {
    value: "1341",
    inputValue:
      "Eletrónica > Áudio > Equipamento de palco > Transmissores sem fios",
  },
  {
    value: "1342",
    inputValue: "Eletrónica > Placas de circuito e componentes",
  },
  {
    value: "1343",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Acessórios de placa de circuito",
  },
  {
    value: "1344",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Descodificadores e codificadores de circuito",
  },
  {
    value: "1345",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Protótipo de circuitos",
  },
  {
    value: "1346",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Protótipo de circuitos > Placas de ensaio",
  },
  {
    value: "1347",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Filtros eletrónicos",
  },
  {
    value: "1348",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Componentes de circuito passivo",
  },
  {
    value: "1349",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Componentes de circuito passivo > Condensadores",
  },
  {
    value: "1350",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Componentes de circuito passivo > Osciladores eletrónicos",
  },
  {
    value: "1351",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Componentes de circuito passivo > Indutores",
  },
  {
    value: "1352",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Componentes de circuito passivo > Resistências",
  },
  {
    value: "1353",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso",
  },
  {
    value: "1354",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso > Placas de circuito de câmara",
  },
  {
    value: "1355",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso > Placas de circuito de computador",
  },
  {
    value: "1356",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso > Placas de circuito de computador > Placas de inversor de computador",
  },
  {
    value: "1357",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso > Placas de circuito de computador > Placas de circuito de disco rígido",
  },
  {
    value: "1358",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso > Placas de circuito de computador > Placas-mãe",
  },
  {
    value: "1359",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso > Placas de desenvolvimento",
  },
  {
    value: "1360",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso > Placas de circuito de máquinas de exercício",
  },
  {
    value: "1361",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso > Placas de circuito de eletrodomésticos",
  },
  {
    value: "1362",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso > Placas de circuito de piscinas e Spa",
  },
  {
    value: "1363",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso > Placas de circuito de máquinas de impressão, cópia e fax",
  },
  {
    value: "1364",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso > Placas de circuito de digitalizadores",
  },
  {
    value: "1365",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Placas de circuito impresso > Placas de circuito de televisão",
  },
  {
    value: "1366",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Semicondutores",
  },
  {
    value: "1367",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Semicondutores > Díodos",
  },
  {
    value: "1368",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Semicondutores > Chips e circuitos integrados",
  },
  {
    value: "1369",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Semicondutores > Microcontroladores",
  },
  {
    value: "1370",
    inputValue:
      "Eletrónica > Placas de circuito e componentes > Semicondutores > Transístores",
  },
  { value: "1371", inputValue: "Eletrónica > Comunicações" },
  {
    value: "1372",
    inputValue: "Eletrónica > Comunicações > Atendedores de chamadas",
  },
  { value: "1373", inputValue: "Eletrónica > Comunicações > ID de chamador" },
  {
    value: "1374",
    inputValue: "Eletrónica > Comunicações > Acessórios de comunicação rádio",
  },
  {
    value: "1375",
    inputValue: "Eletrónica > Comunicações > Rádios de comunicação",
  },
  {
    value: "1376",
    inputValue: "Eletrónica > Comunicações > Rádios de comunicação > Rádios CB",
  },
  {
    value: "1377",
    inputValue:
      "Eletrónica > Comunicações > Rádios de comunicação > Leitores de rádio",
  },
  {
    value: "1378",
    inputValue:
      "Eletrónica > Comunicações > Rádios de comunicação > Rádios de duas vias",
  },
  {
    value: "1379",
    inputValue: "Eletrónica > Comunicações > Acessórios de intercomunicações",
  },
  {
    value: "1380",
    inputValue: "Eletrónica > Comunicações > Intercomunicações",
  },
  { value: "1381", inputValue: "Eletrónica > Comunicações > Pagers" },
  { value: "1382", inputValue: "Eletrónica > Comunicações > Telefonia" },
  {
    value: "1383",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Telefones de conferência",
  },
  {
    value: "1384",
    inputValue: "Eletrónica > Comunicações > Telefonia > Telefones com fio",
  },
  {
    value: "1385",
    inputValue: "Eletrónica > Comunicações > Telefonia > Telefones sem fio",
  },
  {
    value: "1386",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Acessórios para telemóvel",
  },
  {
    value: "1387",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Acessórios para telemóvel > Acessórios de câmara para telemóvel",
  },
  {
    value: "1388",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Acessórios para telemóvel > Capas para telemóvel",
  },
  {
    value: "1389",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Acessórios para telemóvel > Fitas e decorações para telemóvel",
  },
  {
    value: "1390",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Acessórios para telemóvel > Cartões pré-pagos e cartões SIM para telemóvel",
  },
  {
    value: "1391",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Acessórios para telemóvel > Cartões pré-pagos e cartões SIM para telemóvel > Cartões pré-pagos para telemóvel",
  },
  {
    value: "1392",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Acessórios para telemóvel > Cartões pré-pagos e cartões SIM para telemóvel > Cartões SIM",
  },
  {
    value: "1393",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Acessórios para telemóvel > Peças de substituição para telemóvel",
  },
  {
    value: "1394",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Acessórios para telemóvel > Suportes para telemóvel",
  },
  {
    value: "1395",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Acessórios para telemóvel > Ferramentas de ejeção de cartão SIM",
  },
  {
    value: "1396",
    inputValue: "Eletrónica > Comunicações > Telefonia > Telemóveis",
  },
  {
    value: "1397",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Telemóveis > Contratos de telemóvel",
  },
  {
    value: "1398",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Telemóveis > Telemóveis pré-pagos",
  },
  {
    value: "1399",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Telemóveis > Telemóveis desbloqueados",
  },
  {
    value: "1400",
    inputValue: "Eletrónica > Comunicações > Telefonia > Telefones satélite",
  },
  {
    value: "1401",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Acessórios de telefone",
  },
  {
    value: "1402",
    inputValue:
      "Eletrónica > Comunicações > Telefonia > Acessórios de telefone > Cartões de telefone",
  },
  { value: "1403", inputValue: "Eletrónica > Comunicações > Videoconferência" },
  { value: "1404", inputValue: "Eletrónica > Componentes" },
  { value: "1405", inputValue: "Eletrónica > Componentes > Acelerómetros" },
  { value: "1406", inputValue: "Eletrónica > Componentes > Conversores" },
  {
    value: "1407",
    inputValue: "Eletrónica > Componentes > Conversores > Conversores áudio",
  },
  {
    value: "1408",
    inputValue:
      "Eletrónica > Componentes > Conversores > Conversores de digitalização",
  },
  {
    value: "1409",
    inputValue:
      "Eletrónica > Componentes > Conetores de componentes de eletrónica",
  },
  { value: "1410", inputValue: "Eletrónica > Componentes > Moduladores" },
  { value: "1411", inputValue: "Eletrónica > Componentes > Splitters" },
  { value: "1412", inputValue: "Eletrónica > Computadores" },
  {
    value: "1413",
    inputValue: "Eletrónica > Computadores > Computadores Barebone",
  },
  {
    value: "1414",
    inputValue: "Eletrónica > Computadores > Servidores de computador",
  },
  {
    value: "1415",
    inputValue: "Eletrónica > Computadores > Computadores de secretária",
  },
  {
    value: "1416",
    inputValue: "Eletrónica > Computadores > Dispositivos portáteis",
  },
  {
    value: "1417",
    inputValue:
      "Eletrónica > Computadores > Dispositivos portáteis > Coletores de dados",
  },
  {
    value: "1418",
    inputValue:
      "Eletrónica > Computadores > Dispositivos portáteis > Leitores de E-Book",
  },
  {
    value: "1419",
    inputValue: "Eletrónica > Computadores > Dispositivos portáteis > PDA",
  },
  {
    value: "1420",
    inputValue: "Eletrónica > Computadores > Quiosques interativos",
  },
  { value: "1421", inputValue: "Eletrónica > Computadores > Portáteis" },
  {
    value: "1422",
    inputValue: "Eletrónica > Computadores > Óculos inteligentes",
  },
  { value: "1423", inputValue: "Eletrónica > Computadores > Tablets" },
  {
    value: "1424",
    inputValue: "Eletrónica > Computadores > Clientes Thin e clientes Zero",
  },
  {
    value: "1425",
    inputValue:
      "Eletrónica > Computadores > Clientes Thin e clientes Zero > Computadores de cliente Thin",
  },
  {
    value: "1426",
    inputValue:
      "Eletrónica > Computadores > Clientes Thin e clientes Zero > Computadores de cliente Zero",
  },
  {
    value: "1427",
    inputValue: "Eletrónica > Computadores > Computadores táteis",
  },
  { value: "1428", inputValue: "Eletrónica > Acessórios eletrónicos" },
  {
    value: "1429",
    inputValue: "Eletrónica > Acessórios eletrónicos > Adaptadores",
  },
  {
    value: "1430",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Adaptadores > Adaptadores e acopladores de cabo áudio e vídeo",
  },
  {
    value: "1431",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Adaptadores > Adaptadores de cartão de memória",
  },
  {
    value: "1432",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Adaptadores > Adaptadores USB",
  },
  {
    value: "1433",
    inputValue: "Eletrónica > Acessórios eletrónicos > Acessórios de antena",
  },
  {
    value: "1434",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de antena > Suportes e apoios de antena",
  },
  {
    value: "1435",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de antena > Rotores de antena",
  },
  {
    value: "1436",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de antena > LNB satélite",
  },
  {
    value: "1437",
    inputValue: "Eletrónica > Acessórios eletrónicos > Antenas",
  },
  {
    value: "1438",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Splitters e interrutores de áudio e vídeo",
  },
  {
    value: "1439",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Splitters e interrutores de áudio e vídeo > Splitters e interrutores de DVI",
  },
  {
    value: "1440",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Splitters e interrutores de áudio e vídeo > Splitters e interrutores de HDMI",
  },
  {
    value: "1441",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Splitters e interrutores de áudio e vídeo > Splitters e interrutores de VGA",
  },
  {
    value: "1442",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Suportes multimédia graváveis",
  },
  {
    value: "1443",
    inputValue: "Eletrónica > Acessórios eletrónicos > Gestão de cabos",
  },
  {
    value: "1444",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Gestão de cabos > Clipes de cabo",
  },
  {
    value: "1445",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Gestão de cabos > Pistolas de atilhos de cabos",
  },
  {
    value: "1446",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Gestão de cabos > Tabuleiros de cabos",
  },
  {
    value: "1447",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Gestão de cabos > Patch Panels",
  },
  {
    value: "1448",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Gestão de cabos > Marcadores de identificação de fios e cabos",
  },
  {
    value: "1449",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Gestão de cabos > Mangas de fios e cabos",
  },
  {
    value: "1450",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Gestão de cabos > Atilhos de fios e cabos",
  },
  { value: "1451", inputValue: "Eletrónica > Acessórios eletrónicos > Cabos" },
  {
    value: "1452",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Cabos > Cabos áudio e vídeo",
  },
  {
    value: "1453",
    inputValue: "Eletrónica > Acessórios eletrónicos > Cabos > Cabos KVM",
  },
  {
    value: "1454",
    inputValue: "Eletrónica > Acessórios eletrónicos > Cabos > Cabos de rede",
  },
  {
    value: "1455",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Cabos > Cabos de armazenamento e transferência de dados",
  },
  {
    value: "1456",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Cabos > Cabos de sistema e alimentação",
  },
  {
    value: "1457",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Cabos > Cabos de telefone",
  },
  {
    value: "1458",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador",
  },
  {
    value: "1459",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Conjuntos de acessórios de computador",
  },
  {
    value: "1460",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Capas e proteções de computador",
  },
  {
    value: "1461",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Apoios e extensores de computador",
  },
  {
    value: "1462",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Acessórios de dispositivo portátil",
  },
  {
    value: "1463",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Acessórios de dispositivo portátil > Acessórios de leitor de E-Book",
  },
  {
    value: "1464",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Acessórios de dispositivo portátil > Acessórios de leitor de E-Book > Capas de leitor de E-Book",
  },
  {
    value: "1465",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Acessórios de dispositivo portátil > Acessórios de PDA",
  },
  {
    value: "1466",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Acessórios de dispositivo portátil > Acessórios de PDA > Capas de PDA",
  },
  {
    value: "1467",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Apoios de pulso de teclado e rato",
  },
  {
    value: "1468",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Plataformas e tabuleiros de teclado",
  },
  {
    value: "1469",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Estações de ancoragem de portátil",
  },
  {
    value: "1470",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Tapetes de rato",
  },
  {
    value: "1471",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Pontas e recargas de estiletes",
  },
  {
    value: "1472",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Estiletes",
  },
  {
    value: "1473",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de computador > Apoios e docas de tablet",
  },
  {
    value: "1474",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos",
  },
  {
    value: "1475",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Armários de servidor blade",
  },
  {
    value: "1476",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Placas traseiras e protetores de E/S de computador",
  },
  {
    value: "1477",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Artigos de alimentação de computador",
  },
  {
    value: "1478",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Processadores de computador",
  },
  {
    value: "1479",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Bastidores e suportes de computador",
  },
  {
    value: "1480",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Kits de starter de computador",
  },
  {
    value: "1481",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de refrigeração de computador",
  },
  {
    value: "1482",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Caixas de servidor e de computador de secretária",
  },
  {
    value: "1483",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de leitor E-Book",
  },
  {
    value: "1484",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de leitor E-Book > Ecrãs de leitor de E-Book e ecrãs de digitadores",
  },
  {
    value: "1485",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Adaptadores e cartões de E/S",
  },
  {
    value: "1486",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Adaptadores e cartões de E/S > Adaptadores e cartões áudio",
  },
  {
    value: "1487",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Adaptadores e cartões de E/S > Adaptadores e cartões de interface de computador",
  },
  {
    value: "1488",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Adaptadores e cartões de E/S > Cartões riser",
  },
  {
    value: "1489",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Adaptadores e cartões de E/S > Adaptadores e cartões de sintonizador de TV",
  },
  {
    value: "1490",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Adaptadores e cartões de E/S > Adaptadores e cartões de vídeo",
  },
  {
    value: "1491",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Acessórios de dispositivo de entrada",
  },
  {
    value: "1492",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Acessórios de dispositivo de entrada > Suportes de leitor de código de barras",
  },
  {
    value: "1493",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Acessórios de dispositivo de entrada > Acessórios de controlador de jogos",
  },
  {
    value: "1494",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Acessórios de dispositivo de entrada > Tampas e teclas de teclado",
  },
  {
    value: "1495",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Acessórios de dispositivo de entrada > Acessórios de rato e trackball",
  },
  {
    value: "1496",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada",
  },
  {
    value: "1497",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Leitores de código de barras",
  },
  {
    value: "1498",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Canetas digitais",
  },
  {
    value: "1499",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Leitores de cartão eletrónico",
  },
  {
    value: "1500",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Leitores de impressões digitais",
  },
  {
    value: "1501",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Controladores de jogo",
  },
  {
    value: "1502",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Controladores de jogo > Volantes de jogos de corrida",
  },
  {
    value: "1503",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Controladores de jogo > Controlos remotos de jogos",
  },
  {
    value: "1504",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Controladores de jogo > Consolas de jogos",
  },
  {
    value: "1505",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Controladores de jogo > Controladores de joystick",
  },
  {
    value: "1506",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Controladores de jogo > Controladores de jogo de instrumentos musicais",
  },
  {
    value: "1507",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Dispositivos de entrada de controlo de gestos",
  },
  {
    value: "1508",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Tablets gráficos",
  },
  {
    value: "1509",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Interruptores KVM",
  },
  {
    value: "1510",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Teclados",
  },
  {
    value: "1511",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Leitores de cartão de memória",
  },
  {
    value: "1512",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Ratos e trackballs",
  },
  {
    value: "1513",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Teclados numéricos",
  },
  {
    value: "1514",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de entrada > Touchpads",
  },
  {
    value: "1515",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de portátil",
  },
  {
    value: "1516",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de portátil > Dobradiças de portátil",
  },
  {
    value: "1517",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de portátil > Caixas e acabamentos para portátil",
  },
  {
    value: "1518",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de portátil > Cabos de substituição de portátil",
  },
  {
    value: "1519",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de portátil > Teclados de substituição de portátil",
  },
  {
    value: "1520",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de portátil > Ecrãs de substituição de portátil",
  },
  {
    value: "1521",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de portátil > Colunas de substituição de portátil",
  },
  {
    value: "1522",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de portátil > Digitadores de ecrã de portátil",
  },
  {
    value: "1523",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento",
  },
  {
    value: "1524",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Duplicadores de disco",
  },
  {
    value: "1525",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Duplicadores de disco > Duplicadores de CD/DVD",
  },
  {
    value: "1526",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Duplicadores de disco > Duplicadores de disco rígido",
  },
  {
    value: "1527",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Duplicadores de disco > Duplicadores de USB",
  },
  {
    value: "1528",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Disquetes",
  },
  {
    value: "1529",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Acessórios de disco rígido",
  },
  {
    value: "1530",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Acessórios de disco rígido > Malas de transporte de disco rígido",
  },
  {
    value: "1531",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Acessórios de disco rígido > Docas de disco rígido",
  },
  {
    value: "1532",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Acessórios de disco rígido > Suportes e caixas de disco rígido",
  },
  {
    value: "1533",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Conjuntos de disco rígido",
  },
  {
    value: "1534",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Discos rígidos",
  },
  {
    value: "1535",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Sistemas de armazenamento de rede",
  },
  {
    value: "1536",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Unidades óticas",
  },
  {
    value: "1537",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Unidades de fita",
  },
  {
    value: "1538",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Dispositivos de armazenamento > Pens USB",
  },
  {
    value: "1539",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de tablet",
  },
  {
    value: "1540",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de tablet > Caixas e acabamentos para tablet",
  },
  {
    value: "1541",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de tablet > Colunas de substituição de tablet",
  },
  {
    value: "1542",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > Peças de tablet > Ecrãs de digitadores e ecrãs de tablet",
  },
  {
    value: "1543",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Componentes informáticos > USB e FireWire Hubs",
  },
  {
    value: "1544",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Produtos de limpeza de eletrónica",
  },
  {
    value: "1545",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Filmes e proteções de eletrónica",
  },
  {
    value: "1546",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Filmes e proteções de eletrónica > Decalques e autocolantes de eletrónica",
  },
  {
    value: "1547",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Filmes e proteções de eletrónica > Protetores de teclado",
  },
  {
    value: "1548",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Filmes e proteções de eletrónica > Filtros de privacidade",
  },
  {
    value: "1549",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Filmes e proteções de eletrónica > Protetores de ecrã",
  },
  {
    value: "1550",
    inputValue: "Eletrónica > Acessórios eletrónicos > Memória",
  },
  {
    value: "1551",
    inputValue: "Eletrónica > Acessórios eletrónicos > Memória > Memória Cache",
  },
  {
    value: "1552",
    inputValue: "Eletrónica > Acessórios eletrónicos > Memória > Memória Flash",
  },
  {
    value: "1553",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Memória > Memória Flash > Cartões de memória Flash",
  },
  {
    value: "1554",
    inputValue: "Eletrónica > Acessórios eletrónicos > Memória > RAM",
  },
  {
    value: "1555",
    inputValue: "Eletrónica > Acessórios eletrónicos > Memória > ROM",
  },
  {
    value: "1556",
    inputValue: "Eletrónica > Acessórios eletrónicos > Memória > Memória vídeo",
  },
  {
    value: "1557",
    inputValue: "Eletrónica > Acessórios eletrónicos > Acessórios de memória",
  },
  {
    value: "1558",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Acessórios de memória > Estojos de memória",
  },
  {
    value: "1559",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Monopé e tripés de telemóvel e tablet",
  },
  {
    value: "1560",
    inputValue: "Eletrónica > Acessórios eletrónicos > Alimentação",
  },
  {
    value: "1561",
    inputValue: "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias",
  },
  {
    value: "1562",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias > Baterias de câmara",
  },
  {
    value: "1563",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias > Baterias de telefone sem fio",
  },
  {
    value: "1564",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias > Baterias de leitor de E-Book",
  },
  {
    value: "1565",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias > Baterias polivalentes",
  },
  {
    value: "1566",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias > Baterias de portátil",
  },
  {
    value: "1567",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias > Baterias de leitor MP3",
  },
  {
    value: "1568",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias > Baterias de telemóvel",
  },
  {
    value: "1569",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias > Baterias de PDA",
  },
  {
    value: "1570",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias > Baterias de tablet",
  },
  {
    value: "1571",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias > Baterias UPS",
  },
  {
    value: "1572",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias > Baterias de câmara vídeo",
  },
  {
    value: "1573",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Baterias > Baterias de consola de videojogos e controlador",
  },
  {
    value: "1574",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Acessórios de bateria",
  },
  {
    value: "1575",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Acessórios de bateria > Controladores de carga de bateria",
  },
  {
    value: "1576",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Acessórios de bateria > Suportes de bateria",
  },
  {
    value: "1577",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Acessórios de bateria > Carregadores de bateria de câmara",
  },
  {
    value: "1578",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Acessórios de bateria > Carregadores de bateria polivalentes",
  },
  {
    value: "1579",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Acessórios de bateria > Dispositivos de teste de bateria polivalentes",
  },
  {
    value: "1580",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Células de combustível",
  },
  {
    value: "1581",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Acessórios de adaptador de potência e carregador",
  },
  {
    value: "1582",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Adaptadores de potência e carregadores",
  },
  {
    value: "1583",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Unidades de controlo de potência",
  },
  {
    value: "1584",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Tomadas múltiplas e supressores de picos",
  },
  {
    value: "1585",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Caixas de fonte de alimentação",
  },
  {
    value: "1586",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Dispositivos de proteção contra picos",
  },
  {
    value: "1587",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Adaptadores e conversores de viagem",
  },
  {
    value: "1588",
    inputValue: "Eletrónica > Acessórios eletrónicos > Alimentação > UPS",
  },
  {
    value: "1589",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Alimentação > Acessórios de UPS",
  },
  {
    value: "1590",
    inputValue: "Eletrónica > Acessórios eletrónicos > Controlos remotos",
  },
  {
    value: "1591",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Impulsionadores de sinal",
  },
  {
    value: "1592",
    inputValue: "Eletrónica > Acessórios eletrónicos > Jammers de sinal",
  },
  {
    value: "1593",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Jammers de sinal > Jammers de GPS",
  },
  {
    value: "1594",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Jammers de sinal > Jammers de telemóvel",
  },
  {
    value: "1595",
    inputValue:
      "Eletrónica > Acessórios eletrónicos > Jammers de sinal > Jammers de radar",
  },
  { value: "1596", inputValue: "Eletrónica > Acessórios de GPS" },
  {
    value: "1597",
    inputValue: "Eletrónica > Acessórios de GPS > Estojos de GPS",
  },
  {
    value: "1598",
    inputValue: "Eletrónica > Acessórios de GPS > Suportes de GPS",
  },
  { value: "1599", inputValue: "Eletrónica > Sistemas de navegação de GPS" },
  { value: "1600", inputValue: "Eletrónica > Dispositivos de rastreio de GPS" },
  { value: "1601", inputValue: "Eletrónica > Eletrónica marinha" },
  {
    value: "1602",
    inputValue: "Eletrónica > Eletrónica marinha > Localizadores de peixe",
  },
  {
    value: "1603",
    inputValue:
      "Eletrónica > Eletrónica marinha > Recetores áudio e vídeo marinhos",
  },
  {
    value: "1604",
    inputValue:
      "Eletrónica > Eletrónica marinha > Plotadores de carta marinhos e GPS",
  },
  {
    value: "1605",
    inputValue: "Eletrónica > Eletrónica marinha > Radar marinho",
  },
  {
    value: "1606",
    inputValue: "Eletrónica > Eletrónica marinha > Rádios marinhos",
  },
  {
    value: "1607",
    inputValue: "Eletrónica > Eletrónica marinha > Altifalantes marinhos",
  },
  { value: "1608", inputValue: "Eletrónica > Ligação em rede" },
  {
    value: "1609",
    inputValue: "Eletrónica > Ligação em rede > Pontes e routers",
  },
  {
    value: "1610",
    inputValue:
      "Eletrónica > Ligação em rede > Pontes e routers > Pontes de rede",
  },
  {
    value: "1611",
    inputValue:
      "Eletrónica > Ligação em rede > Pontes e routers > Gateways VoIP e routers",
  },
  {
    value: "1612",
    inputValue:
      "Eletrónica > Ligação em rede > Pontes e routers > Pontos de acesso sem fios",
  },
  {
    value: "1613",
    inputValue:
      "Eletrónica > Ligação em rede > Pontes e routers > Routers sem fios",
  },
  {
    value: "1614",
    inputValue:
      "Eletrónica > Ligação em rede > Concentradores e multiplexadores",
  },
  {
    value: "1615",
    inputValue: "Eletrónica > Ligação em rede > Cubos e interruptores",
  },
  {
    value: "1616",
    inputValue: "Eletrónica > Ligação em rede > Acessórios de modem",
  },
  { value: "1617", inputValue: "Eletrónica > Ligação em rede > Modems" },
  {
    value: "1618",
    inputValue: "Eletrónica > Ligação em rede > Cartões de rede e adaptadores",
  },
  {
    value: "1619",
    inputValue:
      "Eletrónica > Ligação em rede > Segurança de rede e dispositivos firewall",
  },
  {
    value: "1620",
    inputValue:
      "Eletrónica > Ligação em rede > Adaptadores de alimentação via internet POE",
  },
  {
    value: "1621",
    inputValue: "Eletrónica > Ligação em rede > Servidores de impressão",
  },
  {
    value: "1622",
    inputValue: "Eletrónica > Ligação em rede > Repetidores e transcetores",
  },
  {
    value: "1623",
    inputValue: "Eletrónica > Impressão, cópia, digitalização e fax",
  },
  {
    value: "1624",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora 3D",
  },
  {
    value: "1625",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Impressoras 3D",
  },
  {
    value: "1626",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax",
  },
  {
    value: "1627",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax > Consumíveis de impressora",
  },
  {
    value: "1628",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax > Consumíveis de impressora > Tambores de impressora e kits de tambor",
  },
  {
    value: "1629",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax > Consumíveis de impressora > Filtros de impressora",
  },
  {
    value: "1630",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax > Consumíveis de impressora > Kits de manutenção de impressora",
  },
  {
    value: "1631",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax > Consumíveis de impressora > Fitas de impressora",
  },
  {
    value: "1632",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax > Consumíveis de impressora > Cabeças de impressora",
  },
  {
    value: "1633",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax > Consumíveis de impressora > Recargas de cartuchos de tinta e toner",
  },
  {
    value: "1634",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax > Consumíveis de impressora > Cartuchos de tinta e toner",
  },
  {
    value: "1635",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax > Duplexadores de impressora",
  },
  {
    value: "1636",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax > Memória de impressora",
  },
  {
    value: "1637",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax > Suportes de impressora",
  },
  {
    value: "1638",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de impressora, copiadora e fax > Peças sobressalentes de impressora, copiadora e fax",
  },
  {
    value: "1639",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Impressoras, copiadoras e fax",
  },
  {
    value: "1640",
    inputValue:
      "Eletrónica > Impressão, cópia, digitalização e fax > Acessórios de leitor",
  },
  {
    value: "1641",
    inputValue: "Eletrónica > Impressão, cópia, digitalização e fax > Leitores",
  },
  { value: "1642", inputValue: "Eletrónica > Detetores de radar" },
  { value: "1643", inputValue: "Eletrónica > Radares de velocidade" },
  {
    value: "1644",
    inputValue: "Eletrónica > Dispositivos de recolha de portagens",
  },
  { value: "1645", inputValue: "Eletrónica > Vídeo" },
  { value: "1646", inputValue: "Eletrónica > Vídeo > Monitores de computador" },
  { value: "1647", inputValue: "Eletrónica > Vídeo > Projetores" },
  {
    value: "1648",
    inputValue: "Eletrónica > Vídeo > Projetores > Projetores multimédia",
  },
  {
    value: "1649",
    inputValue: "Eletrónica > Vídeo > Projetores > Projetores suspensos",
  },
  {
    value: "1650",
    inputValue: "Eletrónica > Vídeo > Projetores > Projetores de diapositivos",
  },
  { value: "1651", inputValue: "Eletrónica > Vídeo > TV por cabo e satélite" },
  {
    value: "1652",
    inputValue:
      "Eletrónica > Vídeo > TV por cabo e satélite > Recetores de TV por cabo",
  },
  {
    value: "1653",
    inputValue:
      "Eletrónica > Vídeo > TV por cabo e satélite > Recetores por satélite",
  },
  { value: "1654", inputValue: "Eletrónica > Vídeo > Televisões" },
  { value: "1655", inputValue: "Eletrónica > Vídeo > Acessórios vídeo" },
  {
    value: "1656",
    inputValue: "Eletrónica > Vídeo > Acessórios vídeo > Óculos 3D",
  },
  {
    value: "1657",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios de monitor de computador",
  },
  {
    value: "1658",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios de monitor de computador > Calibradores de cor",
  },
  {
    value: "1659",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios de projetor",
  },
  {
    value: "1660",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios de projetor > Projeção e saias de tripé",
  },
  {
    value: "1661",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios de projetor > Apoios de ecrã de projeção",
  },
  {
    value: "1662",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios de projetor > Ecrãs de projeção",
  },
  {
    value: "1663",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios de projetor > Suportes de projetor",
  },
  {
    value: "1664",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios de projetor > Lâmpadas de substituição de projetor",
  },
  {
    value: "1665",
    inputValue: "Eletrónica > Vídeo > Acessórios vídeo > Rebobinadores",
  },
  {
    value: "1666",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios e peças de televisão",
  },
  {
    value: "1667",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios e peças de televisão > Suportes de monitor e TV",
  },
  {
    value: "1668",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios e peças de televisão > Caixas de conversor de TV",
  },
  {
    value: "1669",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios e peças de televisão > Lâmpadas de substituição de TV",
  },
  {
    value: "1670",
    inputValue:
      "Eletrónica > Vídeo > Acessórios vídeo > Acessórios e peças de televisão > Altifalantes de substituição de TV",
  },
  {
    value: "1671",
    inputValue:
      "Eletrónica > Vídeo > Equipamento de produção e de edição de vídeo",
  },
  {
    value: "1672",
    inputValue: "Eletrónica > Vídeo > Multiplexadores de vídeo",
  },
  {
    value: "1673",
    inputValue: "Eletrónica > Vídeo > Leitores e gravadores de vídeo",
  },
  {
    value: "1674",
    inputValue:
      "Eletrónica > Vídeo > Leitores e gravadores de vídeo > Leitores de DVD e Blu-ray",
  },
  {
    value: "1675",
    inputValue:
      "Eletrónica > Vídeo > Leitores e gravadores de vídeo > Gravadores DVD",
  },
  {
    value: "1676",
    inputValue:
      "Eletrónica > Vídeo > Leitores e gravadores de vídeo > Gravadores de vídeo digital",
  },
  {
    value: "1677",
    inputValue:
      "Eletrónica > Vídeo > Leitores e gravadores de vídeo > Leitores multimédia de transmissão e domésticos",
  },
  {
    value: "1678",
    inputValue: "Eletrónica > Vídeo > Leitores e gravadores de vídeo > VCR",
  },
  { value: "1679", inputValue: "Eletrónica > Vídeo > Servidores vídeo" },
  { value: "1680", inputValue: "Eletrónica > Vídeo > Transmissores vídeo" },
  {
    value: "1681",
    inputValue: "Eletrónica > Acessórios de consola de videojogos",
  },
  {
    value: "1682",
    inputValue:
      "Eletrónica > Acessórios de consola de videojogos > Acessórios de consola de jogos em casa",
  },
  {
    value: "1683",
    inputValue:
      "Eletrónica > Acessórios de consola de videojogos > Acessórios de consola de jogos portáteis",
  },
  { value: "1684", inputValue: "Eletrónica > Consolas de videojogos" },
  { value: "1685", inputValue: "Comida, bebidas e tabaco" },
  { value: "1686", inputValue: "Comida, bebidas e tabaco > Bebidas" },
  {
    value: "1687",
    inputValue: "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas",
  },
  {
    value: "1688",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Cerveja",
  },
  {
    value: "1689",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Bitters",
  },
  {
    value: "1690",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Misturas de cocktail",
  },
  {
    value: "1691",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Misturas de cocktail > Misturas de cocktail congeladas",
  },
  {
    value: "1692",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Misturas de cocktail > Misturas de cocktail que não precisam de refrigeração",
  },
  {
    value: "1693",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Bebidas alcoólicas com sabor",
  },
  {
    value: "1694",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Cidra dura",
  },
  {
    value: "1695",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Licores e bebidas espirituosas",
  },
  {
    value: "1696",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Licores e bebidas espirituosas > Absinto",
  },
  {
    value: "1697",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Licores e bebidas espirituosas > Brandy",
  },
  {
    value: "1698",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Licores e bebidas espirituosas > Gin",
  },
  {
    value: "1699",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Licores e bebidas espirituosas > Licores",
  },
  {
    value: "1700",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Licores e bebidas espirituosas > Rum",
  },
  {
    value: "1701",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Licores e bebidas espirituosas > Shochu e Soju",
  },
  {
    value: "1702",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Licores e bebidas espirituosas > Shochu e Soju > Shochu",
  },
  {
    value: "1703",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Licores e bebidas espirituosas > Shochu e Soju > Soju",
  },
  {
    value: "1704",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Licores e bebidas espirituosas > Tequilha",
  },
  {
    value: "1705",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Licores e bebidas espirituosas > Vodka",
  },
  {
    value: "1706",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Licores e bebidas espirituosas > Uísque",
  },
  {
    value: "1707",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas alcoólicas > Vinho",
  },
  {
    value: "1708",
    inputValue: "Comida, bebidas e tabaco > Bebidas > Leitelho",
  },
  { value: "1709", inputValue: "Comida, bebidas e tabaco > Bebidas > Café" },
  { value: "1710", inputValue: "Comida, bebidas e tabaco > Bebidas > Eggnog" },
  {
    value: "1711",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas com sabor a fruta",
  },
  {
    value: "1712",
    inputValue: "Comida, bebidas e tabaco > Bebidas > Chocolate quente",
  },
  { value: "1713", inputValue: "Comida, bebidas e tabaco > Bebidas > Sumo" },
  { value: "1714", inputValue: "Comida, bebidas e tabaco > Bebidas > Leite" },
  {
    value: "1715",
    inputValue: "Comida, bebidas e tabaco > Bebidas > Leite não lácteo",
  },
  {
    value: "1716",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Misturas de bebidas em pó",
  },
  { value: "1717", inputValue: "Comida, bebidas e tabaco > Bebidas > Soda" },
  {
    value: "1718",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Bebidas desportivas e energéticas",
  },
  {
    value: "1719",
    inputValue: "Comida, bebidas e tabaco > Bebidas > Chá e infusões",
  },
  {
    value: "1720",
    inputValue: "Comida, bebidas e tabaco > Bebidas > Bebidas de vinagre",
  },
  { value: "1721", inputValue: "Comida, bebidas e tabaco > Bebidas > Água" },
  {
    value: "1722",
    inputValue: "Comida, bebidas e tabaco > Bebidas > Água > Água com gás",
  },
  {
    value: "1723",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Água > Água com gás > Água com gás com sabores",
  },
  {
    value: "1724",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Água > Água com gás > Água com gás sem sabores",
  },
  {
    value: "1725",
    inputValue: "Comida, bebidas e tabaco > Bebidas > Água > Água destilada",
  },
  {
    value: "1726",
    inputValue:
      "Comida, bebidas e tabaco > Bebidas > Água > Água mineral sem gás",
  },
  {
    value: "1727",
    inputValue: "Comida, bebidas e tabaco > Bebidas > Água > Água de nascente",
  },
  {
    value: "1728",
    inputValue: "Comida, bebidas e tabaco > Artigos alimentares",
  },
  {
    value: "1729",
    inputValue: "Comida, bebidas e tabaco > Artigos alimentares > Panificação",
  },
  {
    value: "1730",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Bagels",
  },
  {
    value: "1731",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Sortido de padaria",
  },
  {
    value: "1732",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Pães e pãezinhos",
  },
  {
    value: "1733",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Bolos e barras de sobremesa",
  },
  {
    value: "1734",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Bolos de café",
  },
  {
    value: "1735",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Bolachas",
  },
  {
    value: "1736",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Cupcakes",
  },
  {
    value: "1737",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Donuts",
  },
  {
    value: "1738",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Fudge",
  },
  {
    value: "1739",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Cones de gelado",
  },
  {
    value: "1740",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Muffins",
  },
  {
    value: "1741",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Pastelaria e scones",
  },
  {
    value: "1742",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Tortas e tartes",
  },
  {
    value: "1743",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Taco Shells e tostadas",
  },
  {
    value: "1744",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Panificação > Tortilhas e wraps",
  },
  {
    value: "1745",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Fruta cristalizada e com cobertura de chocolate",
  },
  {
    value: "1746",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Doces e chocolates",
  },
  {
    value: "1747",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos",
  },
  {
    value: "1748",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molho de cocktail",
  },
  {
    value: "1749",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molho de caril",
  },
  {
    value: "1750",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Cobertura de sobremesa",
  },
  {
    value: "1751",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Cobertura de sobremesa > Cobertura de fruta",
  },
  {
    value: "1752",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Cobertura de sobremesa > Xarope de gelado",
  },
  {
    value: "1753",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molho para peixe",
  },
  {
    value: "1754",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molhos",
  },
  {
    value: "1755",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Mel",
  },
  {
    value: "1756",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molho de rábano",
  },
  {
    value: "1757",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molho picante",
  },
  {
    value: "1758",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Ketchup",
  },
  {
    value: "1759",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Marinadas e molhos para grelhados",
  },
  {
    value: "1760",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Maionese",
  },
  {
    value: "1761",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Mostarda",
  },
  {
    value: "1762",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Azeitonas e alcaparras",
  },
  {
    value: "1763",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molho para massa",
  },
  {
    value: "1764",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Fruta e vegetais em conserva",
  },
  {
    value: "1765",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molho de piza",
  },
  {
    value: "1766",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Relish e chutney",
  },
  {
    value: "1767",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Coberturas para salada",
  },
  {
    value: "1768",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molho Satay",
  },
  {
    value: "1769",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molho de soja",
  },
  {
    value: "1770",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molhos agridoces",
  },
  {
    value: "1771",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Xarope",
  },
  {
    value: "1772",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Tahini",
  },
  {
    value: "1773",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molho tártaro",
  },
  {
    value: "1774",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molhos branco e cremes",
  },
  {
    value: "1775",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e molhos > Molho Worcestershire",
  },
  {
    value: "1776",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar",
  },
  {
    value: "1777",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Pepitas para cozinhar",
  },
  {
    value: "1778",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Chocolate para cozinhar",
  },
  {
    value: "1779",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Aromas e extratos para cozinhar",
  },
  {
    value: "1780",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Misturas para cozinhar",
  },
  {
    value: "1781",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Fermento em pó",
  },
  {
    value: "1782",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Bicarbonato de sódio",
  },
  {
    value: "1783",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Misturas para massas e revestimento",
  },
  {
    value: "1784",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Pasta de feijão",
  },
  {
    value: "1785",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Croutons",
  },
  {
    value: "1786",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Leite em lata e em pó",
  },
  {
    value: "1787",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Kits de decoração de bolachas",
  },
  {
    value: "1788",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Óleos de cozinha",
  },
  {
    value: "1789",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Amido de cozinha",
  },
  {
    value: "1790",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Vinho de cozinha",
  },
  {
    value: "1791",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Xarope de milho",
  },
  {
    value: "1792",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Massa",
  },
  {
    value: "1793",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Massa > Massa para pão e pastelaria",
  },
  {
    value: "1794",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Massa > Massa para bolachas e brownies",
  },
  {
    value: "1795",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Massa > Massas de tarte",
  },
  {
    value: "1796",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Decorações de cozinha comestíveis",
  },
  {
    value: "1797",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Substitutos de ovos",
  },
  {
    value: "1798",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Açúcar para algodão doce",
  },
  {
    value: "1799",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Farinha",
  },
  {
    value: "1800",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Corantes alimentares",
  },
  {
    value: "1801",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Congelados e refrigerados",
  },
  {
    value: "1802",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Sumo de limão e lima",
  },
  {
    value: "1803",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Marshmallows",
  },
  {
    value: "1804",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Refeições",
  },
  {
    value: "1805",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Melaços",
  },
  {
    value: "1806",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Recheios de tarte e pastelaria",
  },
  {
    value: "1807",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Reduções e banha",
  },
  {
    value: "1808",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Culturas-mãe de fermentação",
  },
  {
    value: "1809",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Açúcar e adoçantes",
  },
  {
    value: "1810",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Pérolas de tapioca",
  },
  {
    value: "1811",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Molho de tomate",
  },
  {
    value: "1812",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Gelatina sem sabor",
  },
  {
    value: "1813",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Vinagre",
  },
  {
    value: "1814",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Misturas de waffle e panquecas",
  },
  {
    value: "1815",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Ingredientes para cozinhar e assar > Fermento",
  },
  {
    value: "1816",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos lácteos",
  },
  {
    value: "1817",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos lácteos > Manteiga e margarina",
  },
  {
    value: "1818",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos lácteos > Queijo",
  },
  {
    value: "1819",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos lácteos > Natas/creme para café",
  },
  {
    value: "1820",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos lácteos > Coalhada",
  },
  {
    value: "1821",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos lácteos > Natas",
  },
  {
    value: "1822",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos lácteos > Natas ácidas",
  },
  {
    value: "1823",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos lácteos > Chantilly",
  },
  {
    value: "1824",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos lácteos > Iogurte",
  },
  {
    value: "1825",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Molhos e recheios",
  },
  {
    value: "1826",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Molhos e recheios > Manteiga de maçã",
  },
  {
    value: "1827",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Molhos e recheios > Molhos e recheios de queijo",
  },
  {
    value: "1828",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Molhos e recheios > Queijo creme",
  },
  {
    value: "1829",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Molhos e recheios > Guacamole",
  },
  {
    value: "1830",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Molhos e recheios > Húmus",
  },
  {
    value: "1831",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Molhos e recheios > Compotas e geleias",
  },
  {
    value: "1832",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Molhos e recheios > Manteigas de frutos rígidos",
  },
  {
    value: "1833",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Molhos e recheios > Molho",
  },
  {
    value: "1834",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Molhos e recheios > Tapenade",
  },
  {
    value: "1835",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Molhos e recheios > Molho de vegetais",
  },
  {
    value: "1836",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Cabazes alimentares",
  },
  {
    value: "1837",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Sobremesas e novidades congeladas",
  },
  {
    value: "1838",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Sobremesas e novidades congeladas > Gelado e iogurte congelado",
  },
  {
    value: "1839",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Sobremesas e novidades congeladas > Novidades de gelado",
  },
  {
    value: "1840",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Sobremesas e novidades congeladas > Gelados de gelo",
  },
  {
    value: "1841",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais",
  },
  {
    value: "1842",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Frutas em lata e frasco",
  },
  {
    value: "1843",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais em lata e frasco",
  },
  {
    value: "1844",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Feijões em lata e cozidos",
  },
  {
    value: "1845",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta seca",
  },
  {
    value: "1846",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais desidratados",
  },
  {
    value: "1847",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Feijões secos",
  },
  {
    value: "1848",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada",
  },
  {
    value: "1849",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Maçãs",
  },
  {
    value: "1850",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Atemoias",
  },
  {
    value: "1851",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Abacates",
  },
  {
    value: "1852",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Babacos",
  },
  {
    value: "1853",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Bananas",
  },
  {
    value: "1854",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Frutos vermelhos",
  },
  {
    value: "1855",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Fruta-pão",
  },
  {
    value: "1856",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Figo da Índia",
  },
  {
    value: "1857",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Anonas",
  },
  {
    value: "1858",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Citrinos",
  },
  {
    value: "1859",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Citrinos > Toranjas",
  },
  {
    value: "1860",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Citrinos > Kumquats",
  },
  {
    value: "1861",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Citrinos > Limões",
  },
  {
    value: "1862",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Citrinos > Limequats",
  },
  {
    value: "1863",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Citrinos > Limas",
  },
  {
    value: "1864",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Citrinos > Laranjas",
  },
  {
    value: "1865",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Citrinos > Tangelos",
  },
  {
    value: "1866",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Cocos",
  },
  {
    value: "1867",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Tâmaras",
  },
  {
    value: "1868",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Feijoas",
  },
  {
    value: "1869",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Figos",
  },
  {
    value: "1870",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Misturas de frutas",
  },
  {
    value: "1871",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Uvas",
  },
  {
    value: "1872",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Guavas",
  },
  {
    value: "1873",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Fruta caseira",
  },
  {
    value: "1874",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Kiwis",
  },
  {
    value: "1875",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Longan",
  },
  {
    value: "1876",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Nêsperas",
  },
  {
    value: "1877",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Líchias",
  },
  {
    value: "1878",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Medronhos",
  },
  {
    value: "1879",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Pouteria",
  },
  {
    value: "1880",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Mangostão",
  },
  {
    value: "1881",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Melões",
  },
  {
    value: "1882",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Papaias",
  },
  {
    value: "1883",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Maracujá",
  },
  {
    value: "1884",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Peras",
  },
  {
    value: "1885",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Dióspiros",
  },
  {
    value: "1886",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Fisális",
  },
  {
    value: "1887",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Ananás",
  },
  {
    value: "1888",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Pitaias",
  },
  {
    value: "1889",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Romãs",
  },
  {
    value: "1890",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Marmelo",
  },
  {
    value: "1891",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Rambutão",
  },
  {
    value: "1892",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Sapoti",
  },
  {
    value: "1893",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Sapote",
  },
  {
    value: "1894",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Graviolas",
  },
  {
    value: "1895",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Carambolas",
  },
  {
    value: "1896",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Drupa",
  },
  {
    value: "1897",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Drupa > Alperces",
  },
  {
    value: "1898",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Drupa > Cerejas",
  },
  {
    value: "1899",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Drupa > Mangas",
  },
  {
    value: "1900",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Drupa > Pêssegos e nectarinas",
  },
  {
    value: "1901",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Drupa > Ameixas vermelhas",
  },
  {
    value: "1902",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Drupa > Ameixas",
  },
  {
    value: "1903",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Maçã-do-açúcar",
  },
  {
    value: "1904",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Fruta fresca e congelada > Tamarindo",
  },
  {
    value: "1905",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados",
  },
  {
    value: "1906",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Arracachas",
  },
  {
    value: "1907",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Alcachofras",
  },
  {
    value: "1908",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Espargos",
  },
  {
    value: "1909",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Feijões",
  },
  {
    value: "1910",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Beterrabas",
  },
  {
    value: "1911",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Borragem",
  },
  {
    value: "1912",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Brócolos",
  },
  {
    value: "1913",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Couves de Bruxelas",
  },
  {
    value: "1914",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Couve",
  },
  {
    value: "1915",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Folhas de cato",
  },
  {
    value: "1916",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Cardo",
  },
  {
    value: "1917",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Cenouras",
  },
  {
    value: "1918",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Couve-flor",
  },
  {
    value: "1919",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Aipo",
  },
  {
    value: "1920",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Raiz de aipo",
  },
  {
    value: "1921",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Milho",
  },
  {
    value: "1922",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Pepinos",
  },
  {
    value: "1923",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Beringelas",
  },
  {
    value: "1924",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Cebolas de funcho",
  },
  {
    value: "1925",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Rebentos de samambaia",
  },
  {
    value: "1926",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Couve Gai Choy",
  },
  {
    value: "1927",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Couve Gai Lan",
  },
  {
    value: "1928",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Alho",
  },
  {
    value: "1929",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Raiz de gengibre",
  },
  {
    value: "1930",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Raiz de bardana",
  },
  {
    value: "1931",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes",
  },
  {
    value: "1932",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes > Rúcula",
  },
  {
    value: "1933",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes > Rebentos de beterraba",
  },
  {
    value: "1934",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes > Couve Pak-choi",
  },
  {
    value: "1935",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes > Acelga",
  },
  {
    value: "1936",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes > Chicória",
  },
  {
    value: "1937",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes > Soma Choy",
  },
  {
    value: "1938",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes > Couve Kale",
  },
  {
    value: "1939",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes > Alface",
  },
  {
    value: "1940",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes > Ong Choy",
  },
  {
    value: "1941",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes > Misturas de salada",
  },
  {
    value: "1942",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes > Espinafre",
  },
  {
    value: "1943",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Vegetais de folhas verdes > Yu Choy",
  },
  {
    value: "1944",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Raiz de rábano",
  },
  {
    value: "1945",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Jícama",
  },
  {
    value: "1946",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Couve-rábano",
  },
  {
    value: "1947",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Alho-francês",
  },
  {
    value: "1948",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Raiz de lótus",
  },
  {
    value: "1949",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Malangas",
  },
  {
    value: "1950",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Cogumelos",
  },
  {
    value: "1951",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Quiabos",
  },
  {
    value: "1952",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Alhos",
  },
  {
    value: "1953",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Raiz de salsa",
  },
  {
    value: "1954",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Pastinaca",
  },
  {
    value: "1955",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Ervilhas",
  },
  {
    value: "1956",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Pimentos",
  },
  {
    value: "1957",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Batatas",
  },
  {
    value: "1958",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Rabanetes",
  },
  {
    value: "1959",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Ruibarbo",
  },
  {
    value: "1960",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Chalotas",
  },
  {
    value: "1961",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Rebentos",
  },
  {
    value: "1962",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Aboborinhas e pepinos",
  },
  {
    value: "1963",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Cana-do-açúcar",
  },
  {
    value: "1964",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Topinambo",
  },
  {
    value: "1965",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Batata-doce",
  },
  {
    value: "1966",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Tamarillos",
  },
  {
    value: "1967",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Raiz de taro",
  },
  {
    value: "1968",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Tomates",
  },
  {
    value: "1969",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Nabos e couve-nabo",
  },
  {
    value: "1970",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Misturas de vegetais",
  },
  {
    value: "1971",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Castanha-d'água",
  },
  {
    value: "1972",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Agrião",
  },
  {
    value: "1973",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Erva de trigo",
  },
  {
    value: "1974",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Inhame",
  },
  {
    value: "1975",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Vegetais frescos e congelados > Raiz de iúca",
  },
  {
    value: "1976",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Frutas e vegetais > Molhos de fruta",
  },
  {
    value: "1977",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Grãos, arroz e cereal",
  },
  {
    value: "1978",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Grãos, arroz e cereal > Amaranto",
  },
  {
    value: "1979",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Grãos, arroz e cereal > Cevada",
  },
  {
    value: "1980",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Grãos, arroz e cereal > Trigo-sarraceno",
  },
  {
    value: "1981",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Grãos, arroz e cereal > Cereal e granola",
  },
  {
    value: "1982",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Grãos, arroz e cereal > Cuscuz",
  },
  {
    value: "1983",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Grãos, arroz e cereal > Milhete",
  },
  {
    value: "1984",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Grãos, arroz e cereal > Aveias, papas de milho e cereais quentes",
  },
  {
    value: "1985",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Grãos, arroz e cereal > Quinoa",
  },
  {
    value: "1986",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Grãos, arroz e cereal > Arroz",
  },
  {
    value: "1987",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Grãos, arroz e cereal > Centeio",
  },
  {
    value: "1988",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Grãos, arroz e cereal > Trigo",
  },
  {
    value: "1989",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos",
  },
  {
    value: "1990",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos > Ovos",
  },
  {
    value: "1991",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos > Ovos > Clara de ovo",
  },
  {
    value: "1992",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos > Ovos > Ovos líquidos e congelados",
  },
  {
    value: "1993",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos > Ovos > Ovos preparados",
  },
  {
    value: "1994",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos > Ovos > Ovos inteiros",
  },
  {
    value: "1995",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos > Carne",
  },
  {
    value: "1996",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos > Carne > Carne enlatada",
  },
  {
    value: "1997",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos > Carne > Carne fresca e congelada",
  },
  {
    value: "1998",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos > Carne > Carnes frias e enchidos",
  },
  {
    value: "1999",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos > Peixe/marisco",
  },
  {
    value: "2000",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos > Peixe/marisco > Peixe/marisco enlatado",
  },
  {
    value: "2001",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Carne, peixe/marisco e ovos > Peixe/marisco > Peixe/marisco fresco e congelado",
  },
  {
    value: "2002",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Fruta casca rígida e sementes",
  },
  {
    value: "2003",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Massa e noodles",
  },
  {
    value: "2004",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Comida preparada",
  },
  {
    value: "2005",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Comida preparada > Aperitivos e acompanhamentos preparados",
  },
  {
    value: "2006",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Comida preparada > Entradas e refeições preparadas",
  },
  {
    value: "2007",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e especiarias",
  },
  {
    value: "2008",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e especiarias > Ervas e especiarias",
  },
  {
    value: "2009",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e especiarias > MSG",
  },
  {
    value: "2010",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e especiarias > Pimenta",
  },
  {
    value: "2011",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Condimentos e especiarias > Sal",
  },
  {
    value: "2012",
    inputValue: "Comida, bebidas e tabaco > Artigos alimentares > Snacks",
  },
  {
    value: "2013",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Gressinos",
  },
  {
    value: "2014",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Barras de cereais e granola",
  },
  {
    value: "2015",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Barras de cereais e granola > Barras de cereais",
  },
  {
    value: "2016",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Barras de cereais e granola > Barras de granola",
  },
  {
    value: "2017",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Snacks de queijo",
  },
  {
    value: "2018",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Chips",
  },
  {
    value: "2019",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Crackers",
  },
  {
    value: "2020",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Croutons",
  },
  {
    value: "2021",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Snacks de fruta",
  },
  {
    value: "2022",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Carne desidratada",
  },
  {
    value: "2023",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Pipocas",
  },
  {
    value: "2024",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Torresmos",
  },
  {
    value: "2025",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Bretzels",
  },
  {
    value: "2026",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Pudins e snacks de gelatina",
  },
  {
    value: "2027",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Bolachas de arroz tufado",
  },
  {
    value: "2028",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Coberturas de salada",
  },
  {
    value: "2029",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Pauzinhos de sésamo",
  },
  {
    value: "2030",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Bolos de snack",
  },
  {
    value: "2031",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Bolinhos de arroz pegajoso",
  },
  {
    value: "2032",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Snacks > Misturas de frutas secas e snacks",
  },
  {
    value: "2033",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Sopas e caldos",
  },
  {
    value: "2034",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos de tofu, soja e vegetarianos",
  },
  {
    value: "2035",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos de tofu, soja e vegetarianos > Alternativas ao queijo",
  },
  {
    value: "2036",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos de tofu, soja e vegetarianos > Alternativas à carne",
  },
  {
    value: "2037",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos de tofu, soja e vegetarianos > Seitan",
  },
  {
    value: "2038",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos de tofu, soja e vegetarianos > Tempeh",
  },
  {
    value: "2039",
    inputValue:
      "Comida, bebidas e tabaco > Artigos alimentares > Produtos de tofu, soja e vegetarianos > Tofu",
  },
  {
    value: "2040",
    inputValue: "Comida, bebidas e tabaco > Produtos de tabaco",
  },
  {
    value: "2041",
    inputValue:
      "Comida, bebidas e tabaco > Produtos de tabaco > Tabaco de mascar",
  },
  {
    value: "2042",
    inputValue: "Comida, bebidas e tabaco > Produtos de tabaco > Cigarros",
  },
  {
    value: "2043",
    inputValue: "Comida, bebidas e tabaco > Produtos de tabaco > Charutos",
  },
  {
    value: "2044",
    inputValue: "Comida, bebidas e tabaco > Produtos de tabaco > Tabaco avulso",
  },
  {
    value: "2045",
    inputValue: "Comida, bebidas e tabaco > Produtos de tabaco > Cachimbo",
  },
  {
    value: "2046",
    inputValue:
      "Comida, bebidas e tabaco > Produtos de tabaco > Vaporizadores e cigarros eletrónicos",
  },
  {
    value: "2047",
    inputValue:
      "Comida, bebidas e tabaco > Produtos de tabaco > Vaporizadores e cigarros eletrónicos > Cigarros eletrónicos",
  },
  {
    value: "2048",
    inputValue:
      "Comida, bebidas e tabaco > Produtos de tabaco > Vaporizadores e cigarros eletrónicos > Vaporizadores",
  },
  { value: "2049", inputValue: "Mobiliário" },
  { value: "2050", inputValue: "Mobiliário > Mobiliário de bebé e criança" },
  {
    value: "2051",
    inputValue:
      "Mobiliário > Mobiliário de bebé e criança > Conjuntos de mobiliário de bebé e criança",
  },
  {
    value: "2052",
    inputValue:
      "Mobiliário > Mobiliário de bebé e criança > Acessórios para berços e alcofas",
  },
  {
    value: "2053",
    inputValue: "Mobiliário > Mobiliário de bebé e criança > Berços e alcofas",
  },
  {
    value: "2054",
    inputValue:
      "Mobiliário > Mobiliário de bebé e criança > Mesas muda-fraldas",
  },
  {
    value: "2055",
    inputValue:
      "Mobiliário > Mobiliário de bebé e criança > Acessórios para berço e cama de bebé",
  },
  {
    value: "2056",
    inputValue:
      "Mobiliário > Mobiliário de bebé e criança > Acessórios para berço e cama de bebé > Protetores e forros de berço",
  },
  {
    value: "2057",
    inputValue:
      "Mobiliário > Mobiliário de bebé e criança > Acessórios para berço e cama de bebé > Kits de conversão de berço",
  },
  {
    value: "2058",
    inputValue:
      "Mobiliário > Mobiliário de bebé e criança > Berços e camas de criança",
  },
  {
    value: "2059",
    inputValue:
      "Mobiliário > Mobiliário de bebé e criança > Acessórios para bancos elevatórios e cadeiras de refeição",
  },
  {
    value: "2060",
    inputValue:
      "Mobiliário > Mobiliário de bebé e criança > Assentos para bancos elevatórios e cadeiras de refeição",
  },
  { value: "2061", inputValue: "Mobiliário > Camas e acessórios" },
  {
    value: "2062",
    inputValue:
      "Mobiliário > Camas e acessórios > Acessórios para camas e estruturas de cama",
  },
  {
    value: "2063",
    inputValue: "Mobiliário > Camas e acessórios > Camas e estruturas de camas",
  },
  {
    value: "2064",
    inputValue: "Mobiliário > Camas e acessórios > Cabeceiras e pés de cama",
  },
  {
    value: "2065",
    inputValue: "Mobiliário > Camas e acessórios > Bases de colchão",
  },
  { value: "2066", inputValue: "Mobiliário > Camas e acessórios > Colchões" },
  { value: "2067", inputValue: "Mobiliário > Bancadas" },
  {
    value: "2068",
    inputValue: "Mobiliário > Bancadas > Bancadas de cozinha e jantar",
  },
  {
    value: "2069",
    inputValue: "Mobiliário > Bancadas > Bancadas de armazenamento e entrada",
  },
  { value: "2070", inputValue: "Mobiliário > Bancadas > Bancos de toucador" },
  { value: "2071", inputValue: "Mobiliário > Armários e armazenamento" },
  {
    value: "2072",
    inputValue:
      "Mobiliário > Armários e armazenamento > Roupeiros e guarda roupas",
  },
  {
    value: "2073",
    inputValue:
      "Mobiliário > Armários e armazenamento > Louceiros e aparadores",
  },
  {
    value: "2074",
    inputValue:
      "Mobiliário > Armários e armazenamento > Cristaleiras e estantes",
  },
  {
    value: "2075",
    inputValue: "Mobiliário > Armários e armazenamento > Cómodas",
  },
  {
    value: "2076",
    inputValue: "Mobiliário > Armários e armazenamento > Armários de ficheiros",
  },
  {
    value: "2077",
    inputValue: "Mobiliário > Armários e armazenamento > Centros de engomar",
  },
  {
    value: "2078",
    inputValue: "Mobiliário > Armários e armazenamento > Armários de cozinha",
  },
  {
    value: "2079",
    inputValue: "Mobiliário > Armários e armazenamento > Estantes de arrumação",
  },
  {
    value: "2080",
    inputValue:
      "Mobiliário > Armários e armazenamento > Armários e estantes de armazenamento multimédia",
  },
  {
    value: "2081",
    inputValue:
      "Mobiliário > Armários e armazenamento > Armários e cacifos de armazenamento",
  },
  { value: "2082", inputValue: "Mobiliário > Armários e armazenamento > Baús" },
  {
    value: "2083",
    inputValue: "Mobiliário > Armários e armazenamento > Baús > Arcas",
  },
  {
    value: "2084",
    inputValue:
      "Mobiliário > Armários e armazenamento > Baús > Arcas para brinquedos",
  },
  {
    value: "2085",
    inputValue: "Mobiliário > Armários e armazenamento > Toucadores",
  },
  {
    value: "2086",
    inputValue:
      "Mobiliário > Armários e armazenamento > Toucadores > Toucadores de casa de banho",
  },
  {
    value: "2087",
    inputValue:
      "Mobiliário > Armários e armazenamento > Toucadores > Toucadores de quarto",
  },
  {
    value: "2088",
    inputValue: "Mobiliário > Armários e armazenamento > Garrafeiras",
  },
  {
    value: "2089",
    inputValue: "Mobiliário > Armários e armazenamento > Adegas",
  },
  { value: "2090", inputValue: "Mobiliário > Carrinhos e ilhas" },
  {
    value: "2091",
    inputValue:
      "Mobiliário > Carrinhos e ilhas > Carrinhos de cozinha e de jantar",
  },
  {
    value: "2092",
    inputValue: "Mobiliário > Carrinhos e ilhas > Ilhas de cozinha",
  },
  { value: "2093", inputValue: "Mobiliário > Acessórios de cadeira" },
  {
    value: "2094",
    inputValue:
      "Mobiliário > Acessórios de cadeira > Peças de substituição de cadeira suspensa",
  },
  { value: "2095", inputValue: "Mobiliário > Cadeiras" },
  {
    value: "2096",
    inputValue:
      "Mobiliário > Cadeiras > Cadeiras de braços, poltronas e espreguiçadeiras",
  },
  { value: "2097", inputValue: "Mobiliário > Cadeiras > Pufes" },
  { value: "2098", inputValue: "Mobiliário > Cadeiras > Chaise longue" },
  {
    value: "2099",
    inputValue: "Mobiliário > Cadeiras > Cadeiras de massagem elétricas",
  },
  { value: "2100", inputValue: "Mobiliário > Cadeiras > Cadeiras de chão" },
  {
    value: "2101",
    inputValue: "Mobiliário > Cadeiras > Cadeiras e bancos dobráveis",
  },
  { value: "2102", inputValue: "Mobiliário > Cadeiras > Cadeiras de gaming" },
  { value: "2103", inputValue: "Mobiliário > Cadeiras > Cadeiras suspensas" },
  {
    value: "2104",
    inputValue:
      "Mobiliário > Cadeiras > Cadeiras de cozinha e de sala de jantar",
  },
  { value: "2105", inputValue: "Mobiliário > Cadeiras > Cadeiras de baloiço" },
  { value: "2106", inputValue: "Mobiliário > Cadeiras > Poltronas" },
  { value: "2107", inputValue: "Mobiliário > Cadeiras > Bancos de mesa e bar" },
  {
    value: "2108",
    inputValue: "Mobiliário > Centros de diversão e suporte de televisão",
  },
  { value: "2109", inputValue: "Mobiliário > Conjuntos de mobiliário" },
  {
    value: "2110",
    inputValue:
      "Mobiliário > Conjuntos de mobiliário > Conjuntos de mobiliário de casa de banho",
  },
  {
    value: "2111",
    inputValue:
      "Mobiliário > Conjuntos de mobiliário > Conjuntos de mobiliário de quarto",
  },
  {
    value: "2112",
    inputValue:
      "Mobiliário > Conjuntos de mobiliário > Conjuntos de mobiliário de cozinha e sala de jantar",
  },
  {
    value: "2113",
    inputValue:
      "Mobiliário > Conjuntos de mobiliário > Conjuntos de mobiliário de sala",
  },
  { value: "2114", inputValue: "Mobiliário > Estruturas de futon" },
  { value: "2115", inputValue: "Mobiliário > Almofadas de futon" },
  { value: "2116", inputValue: "Mobiliário > Futons" },
  { value: "2117", inputValue: "Mobiliário > Mobiliário de escritório" },
  {
    value: "2118",
    inputValue: "Mobiliário > Mobiliário de escritório > Secretárias",
  },
  {
    value: "2119",
    inputValue:
      "Mobiliário > Mobiliário de escritório > Cadeiras de escritório",
  },
  {
    value: "2120",
    inputValue:
      "Mobiliário > Mobiliário de escritório > Conjuntos de mobiliário de escritório",
  },
  {
    value: "2121",
    inputValue: "Mobiliário > Mobiliário de escritório > Mesas de trabalho",
  },
  {
    value: "2122",
    inputValue:
      "Mobiliário > Mobiliário de escritório > Mesas de trabalho > Estiradores",
  },
  {
    value: "2123",
    inputValue:
      "Mobiliário > Mobiliário de escritório > Mesas de trabalho > Mesas de salas de conferência",
  },
  {
    value: "2124",
    inputValue:
      "Mobiliário > Mobiliário de escritório > Estações de trabalho e cubículos",
  },
  {
    value: "2125",
    inputValue: "Mobiliário > Acessórios de mobiliário de escritório",
  },
  {
    value: "2126",
    inputValue:
      "Mobiliário > Acessórios de mobiliário de escritório > Acessórios e peças de secretária",
  },
  {
    value: "2127",
    inputValue:
      "Mobiliário > Acessórios de mobiliário de escritório > Acessórios de cadeira de escritório",
  },
  {
    value: "2128",
    inputValue:
      "Mobiliário > Acessórios de mobiliário de escritório > Acessórios de estações de trabalho e cubículos",
  },
  { value: "2129", inputValue: "Mobiliário > Otomanas" },
  { value: "2130", inputValue: "Mobiliário > Mobiliário de exterior" },
  {
    value: "2131",
    inputValue: "Mobiliário > Mobiliário de exterior > Camas de exterior",
  },
  {
    value: "2132",
    inputValue:
      "Mobiliário > Mobiliário de exterior > Conjuntos de mobiliário de exterior",
  },
  {
    value: "2133",
    inputValue: "Mobiliário > Mobiliário de exterior > Otomanas de exterior",
  },
  {
    value: "2134",
    inputValue: "Mobiliário > Mobiliário de exterior > Assentos de exterior",
  },
  {
    value: "2135",
    inputValue:
      "Mobiliário > Mobiliário de exterior > Assentos de exterior > Bancos de exterior",
  },
  {
    value: "2136",
    inputValue:
      "Mobiliário > Mobiliário de exterior > Assentos de exterior > Cadeiras de exterior",
  },
  {
    value: "2137",
    inputValue:
      "Mobiliário > Mobiliário de exterior > Assentos de exterior > Unidade de sofá de secções de exterior",
  },
  {
    value: "2138",
    inputValue:
      "Mobiliário > Mobiliário de exterior > Assentos de exterior > Sofás de exterior",
  },
  {
    value: "2139",
    inputValue:
      "Mobiliário > Mobiliário de exterior > Assentos de exterior > Espreguiçadeiras",
  },
  {
    value: "2140",
    inputValue:
      "Mobiliário > Mobiliário de exterior > Caixas de arrumação de exterior",
  },
  {
    value: "2141",
    inputValue: "Mobiliário > Mobiliário de exterior > Mesas de exterior",
  },
  {
    value: "2142",
    inputValue: "Mobiliário > Acessórios de mobiliário de exterior",
  },
  {
    value: "2143",
    inputValue:
      "Mobiliário > Acessórios de mobiliário de exterior > Coberturas de mobiliário de exterior",
  },
  { value: "2144", inputValue: "Mobiliário > Acessórios de biombos" },
  { value: "2145", inputValue: "Mobiliário > Biombos" },
  { value: "2146", inputValue: "Mobiliário > Prateleiras" },
  {
    value: "2147",
    inputValue: "Mobiliário > Prateleiras > Estantes de livros e prateleiras",
  },
  {
    value: "2148",
    inputValue: "Mobiliário > Prateleiras > Prateleiras de parede e apoios",
  },
  { value: "2149", inputValue: "Mobiliário > Acessórios para prateleiras" },
  {
    value: "2150",
    inputValue:
      "Mobiliário > Acessórios para prateleiras > Prateleiras de substituição",
  },
  { value: "2151", inputValue: "Mobiliário > Acessórios de sofá" },
  {
    value: "2152",
    inputValue: "Mobiliário > Acessórios de sofá > Suportes de cadeira e sofá",
  },
  {
    value: "2153",
    inputValue: "Mobiliário > Acessórios de sofá > Unidades de sofá de secções",
  },
  { value: "2154", inputValue: "Mobiliário > Sofás" },
  { value: "2155", inputValue: "Mobiliário > Acessórios de mesa" },
  {
    value: "2156",
    inputValue: "Mobiliário > Acessórios de mesa > Pernas de mesa",
  },
  {
    value: "2157",
    inputValue: "Mobiliário > Acessórios de mesa > Tampos de mesa",
  },
  { value: "2158", inputValue: "Mobiliário > Mesas" },
  { value: "2159", inputValue: "Mobiliário > Mesas > Mesas de centro" },
  {
    value: "2160",
    inputValue: "Mobiliário > Mesas > Mesas de centro > Mesas de café",
  },
  {
    value: "2161",
    inputValue: "Mobiliário > Mesas > Mesas de centro > Mesas de apoio",
  },
  {
    value: "2162",
    inputValue: "Mobiliário > Mesas > Mesas de centro > Mesas de sofá",
  },
  { value: "2163", inputValue: "Mobiliário > Mesas > Mesas de atividades" },
  { value: "2164", inputValue: "Mobiliário > Mesas > Mesas dobráveis" },
  {
    value: "2165",
    inputValue: "Mobiliário > Mesas > Mesas de cozinha e sala de jantar",
  },
  { value: "2166", inputValue: "Mobiliário > Mesas > Kotatsu" },
  { value: "2167", inputValue: "Mobiliário > Mesas > Mesas de cabeceira" },
  {
    value: "2168",
    inputValue: "Mobiliário > Mesas > Mesas de póquer e de jogo",
  },
  {
    value: "2169",
    inputValue: "Mobiliário > Mesas > Mesas de máquinas de costura",
  },
  { value: "2170", inputValue: "Equipamento" },
  { value: "2171", inputValue: "Equipamento > Consumíveis de construção" },
  {
    value: "2172",
    inputValue: "Equipamento > Consumíveis de construção > Químicos",
  },
  {
    value: "2173",
    inputValue:
      "Equipamento > Consumíveis de construção > Químicos > Neutralizadores de ácidos",
  },
  {
    value: "2174",
    inputValue: "Equipamento > Consumíveis de construção > Químicos > Amoníaco",
  },
  {
    value: "2175",
    inputValue:
      "Equipamento > Consumíveis de construção > Químicos > Limpadores de chaminé",
  },
  {
    value: "2176",
    inputValue:
      "Equipamento > Consumíveis de construção > Químicos > Limpadores de betão e alvenaria",
  },
  {
    value: "2177",
    inputValue:
      "Equipamento > Consumíveis de construção > Químicos > Descongeladores",
  },
  {
    value: "2178",
    inputValue:
      "Equipamento > Consumíveis de construção > Químicos > Limpadores de decks e cercas",
  },
  {
    value: "2179",
    inputValue:
      "Equipamento > Consumíveis de construção > Químicos > Limpadores de descargas",
  },
  {
    value: "2180",
    inputValue:
      "Equipamento > Consumíveis de construção > Químicos > Sprays de congelamento elétrico",
  },
  {
    value: "2181",
    inputValue:
      "Equipamento > Consumíveis de construção > Químicos > Fluido de isqueiros",
  },
  {
    value: "2182",
    inputValue:
      "Equipamento > Consumíveis de construção > Químicos > Tratamentos de fossas e tanques séticos",
  },
  {
    value: "2183",
    inputValue:
      "Equipamento > Consumíveis de construção > Colas e adesivos de equipamento",
  },
  {
    value: "2184",
    inputValue: "Equipamento > Consumíveis de construção > Fita de equipamento",
  },
  {
    value: "2185",
    inputValue: "Equipamento > Consumíveis de construção > Lubrificantes",
  },
  {
    value: "2186",
    inputValue:
      "Equipamento > Consumíveis de construção > Consumíveis de alvenaria",
  },
  {
    value: "2187",
    inputValue:
      "Equipamento > Consumíveis de construção > Consumíveis de alvenaria > Blocos de betão e tijolos",
  },
  {
    value: "2188",
    inputValue:
      "Equipamento > Consumíveis de construção > Consumíveis de alvenaria > Misturas de cimento, argamassa e betão",
  },
  {
    value: "2189",
    inputValue:
      "Equipamento > Consumíveis de construção > Consumíveis de alvenaria > Reboco",
  },
  {
    value: "2190",
    inputValue:
      "Equipamento > Consumíveis de construção > Consumíveis de pintura",
  },
  {
    value: "2191",
    inputValue:
      "Equipamento > Consumíveis de construção > Consumíveis de pintura > Tinta",
  },
  {
    value: "2192",
    inputValue:
      "Equipamento > Consumíveis de construção > Consumíveis de pintura > Aglutinantes de tinta",
  },
  {
    value: "2193",
    inputValue:
      "Equipamento > Consumíveis de construção > Consumíveis de pintura > Primários",
  },
  {
    value: "2194",
    inputValue:
      "Equipamento > Consumíveis de construção > Consumíveis de pintura > Corantes",
  },
  {
    value: "2195",
    inputValue:
      "Equipamento > Consumíveis de construção > Consumíveis de pintura > Vernizes e acabamentos",
  },
  {
    value: "2196",
    inputValue:
      "Equipamento > Consumíveis de construção > Primário de canalização",
  },
  {
    value: "2197",
    inputValue:
      "Equipamento > Consumíveis de construção > Selantes e revestimentos de proteção",
  },
  {
    value: "2198",
    inputValue: "Equipamento > Consumíveis de construção > Solda e fluxo",
  },
  {
    value: "2199",
    inputValue:
      "Equipamento > Consumíveis de construção > Solventes, decapantes e diluentes",
  },
  {
    value: "2200",
    inputValue:
      "Equipamento > Consumíveis de construção > Misturas para reparação de paredes e gesso",
  },
  { value: "2201", inputValue: "Equipamento > Materiais de construção" },
  {
    value: "2202",
    inputValue: "Equipamento > Materiais de construção > Balcões",
  },
  {
    value: "2203",
    inputValue: "Equipamento > Materiais de construção > Equipamento de portas",
  },
  {
    value: "2204",
    inputValue:
      "Equipamento > Materiais de construção > Equipamento de portas > Sinos e campainhas de porta",
  },
  {
    value: "2205",
    inputValue:
      "Equipamento > Materiais de construção > Equipamento de portas > Fechos de porta",
  },
  {
    value: "2206",
    inputValue:
      "Equipamento > Materiais de construção > Equipamento de portas > Estruturas de porta",
  },
  {
    value: "2207",
    inputValue:
      "Equipamento > Materiais de construção > Equipamento de portas > Espelhos de fechadura",
  },
  {
    value: "2208",
    inputValue:
      "Equipamento > Materiais de construção > Equipamento de portas > Pegas e puxadores de porta",
  },
  {
    value: "2209",
    inputValue:
      "Equipamento > Materiais de construção > Equipamento de portas > Aldrabas",
  },
  {
    value: "2210",
    inputValue:
      "Equipamento > Materiais de construção > Equipamento de portas > Pegas para puxar portas",
  },
  {
    value: "2211",
    inputValue:
      "Equipamento > Materiais de construção > Equipamento de portas > Batentes de porta",
  },
  {
    value: "2212",
    inputValue:
      "Equipamento > Materiais de construção > Equipamento de portas > Fechaduras",
  },
  {
    value: "2213",
    inputValue: "Equipamento > Materiais de construção > Portas",
  },
  {
    value: "2214",
    inputValue:
      "Equipamento > Materiais de construção > Portas > Portas de garagem",
  },
  {
    value: "2215",
    inputValue:
      "Equipamento > Materiais de construção > Portas > Portas de casa",
  },
  {
    value: "2216",
    inputValue: "Equipamento > Materiais de construção > Pladur",
  },
  {
    value: "2217",
    inputValue: "Equipamento > Materiais de construção > Tapetes e carpetes",
  },
  {
    value: "2218",
    inputValue: "Equipamento > Materiais de construção > Vidro",
  },
  {
    value: "2219",
    inputValue:
      "Equipamento > Materiais de construção > Sistemas de pegas e corrimões",
  },
  {
    value: "2220",
    inputValue: "Equipamento > Materiais de construção > Portinholas",
  },
  {
    value: "2221",
    inputValue: "Equipamento > Materiais de construção > Isolamento",
  },
  {
    value: "2222",
    inputValue:
      "Equipamento > Materiais de construção > Armazenamento de madeira e folhas",
  },
  {
    value: "2223",
    inputValue: "Equipamento > Materiais de construção > Moldagem",
  },
  {
    value: "2224",
    inputValue: "Equipamento > Materiais de construção > Varões e malhas",
  },
  {
    value: "2225",
    inputValue: "Equipamento > Materiais de construção > Cobertura de telhado",
  },
  {
    value: "2226",
    inputValue:
      "Equipamento > Materiais de construção > Cobertura de telhado > Acessórios de caleira",
  },
  {
    value: "2227",
    inputValue:
      "Equipamento > Materiais de construção > Cobertura de telhado > Caleiras",
  },
  {
    value: "2228",
    inputValue:
      "Equipamento > Materiais de construção > Cobertura de telhado > Remates de telhado",
  },
  {
    value: "2229",
    inputValue:
      "Equipamento > Materiais de construção > Cobertura de telhado > Telhas e telhas Shingle",
  },
  {
    value: "2230",
    inputValue: "Equipamento > Materiais de construção > Persianas/portadas",
  },
  {
    value: "2231",
    inputValue: "Equipamento > Materiais de construção > Revestimento",
  },
  {
    value: "2232",
    inputValue:
      "Equipamento > Materiais de construção > Painéis e espuma de amortecimento sonoro",
  },
  {
    value: "2233",
    inputValue: "Equipamento > Materiais de construção > Escadas",
  },
  {
    value: "2234",
    inputValue:
      "Equipamento > Materiais de construção > Ladrilhos de parede e telhas de teto",
  },
  {
    value: "2235",
    inputValue: "Equipamento > Materiais de construção > Painéis de parede",
  },
  {
    value: "2236",
    inputValue:
      "Equipamento > Materiais de construção > Artigos de calafetagem e isolamento térmico",
  },
  {
    value: "2237",
    inputValue:
      "Equipamento > Materiais de construção > Equipamento de janelas",
  },
  {
    value: "2238",
    inputValue:
      "Equipamento > Materiais de construção > Equipamento de janelas > Manivelas de janela",
  },
  {
    value: "2239",
    inputValue:
      "Equipamento > Materiais de construção > Equipamento de janelas > Estruturas de janela",
  },
  {
    value: "2240",
    inputValue: "Equipamento > Materiais de construção > Janelas",
  },
  { value: "2241", inputValue: "Equipamento > Cercas e barreiras" },
  {
    value: "2242",
    inputValue:
      "Equipamento > Cercas e barreiras > Acessórios de cercas e portões",
  },
  {
    value: "2243",
    inputValue: "Equipamento > Cercas e barreiras > Painéis de cercas",
  },
  {
    value: "2244",
    inputValue: "Equipamento > Cercas e barreiras > Estacas de cerca",
  },
  {
    value: "2245",
    inputValue: "Equipamento > Cercas e barreiras > Postes e calhas de cerca",
  },
  {
    value: "2246",
    inputValue:
      "Equipamento > Cercas e barreiras > Bordaduras e orlas de jardim",
  },
  { value: "2247", inputValue: "Equipamento > Cercas e barreiras > Portões" },
  {
    value: "2248",
    inputValue: "Equipamento > Cercas e barreiras > Reticulado",
  },
  {
    value: "2249",
    inputValue:
      "Equipamento > Cercas e barreiras > Barreiras de segurança e de controlo de multidões",
  },
  { value: "2250", inputValue: "Equipamento > Combustível" },
  {
    value: "2251",
    inputValue:
      "Equipamento > Combustível > Combustível de aquecimento doméstico",
  },
  { value: "2252", inputValue: "Equipamento > Combustível > Querosene" },
  {
    value: "2253",
    inputValue:
      "Equipamento > Combustível > Querosene > Querosene transparente",
  },
  {
    value: "2254",
    inputValue: "Equipamento > Combustível > Querosene > Querosene de cor",
  },
  { value: "2255", inputValue: "Equipamento > Combustível > Propano" },
  {
    value: "2256",
    inputValue: "Equipamento > Contentores e tanques de combustível",
  },
  { value: "2257", inputValue: "Equipamento > Acessórios de equipamento" },
  {
    value: "2258",
    inputValue:
      "Equipamento > Acessórios de equipamento > Suportes e apoios de reforço",
  },
  {
    value: "2259",
    inputValue:
      "Equipamento > Acessórios de equipamento > Equipamento de armário",
  },
  {
    value: "2260",
    inputValue:
      "Equipamento > Acessórios de equipamento > Equipamento de armário > Espelhos de fechadura de mobiliário e armários",
  },
  {
    value: "2261",
    inputValue:
      "Equipamento > Acessórios de equipamento > Equipamento de armário > Placas traseiras de armário",
  },
  {
    value: "2262",
    inputValue:
      "Equipamento > Acessórios de equipamento > Equipamento de armário > Trincos de armário",
  },
  {
    value: "2263",
    inputValue:
      "Equipamento > Acessórios de equipamento > Equipamento de armário > Portas de armário",
  },
  {
    value: "2264",
    inputValue:
      "Equipamento > Acessórios de equipamento > Equipamento de armário > Pegas e puxadores de armário",
  },
  {
    value: "2265",
    inputValue: "Equipamento > Acessórios de equipamento > Rodízios",
  },
  {
    value: "2266",
    inputValue:
      "Equipamento > Acessórios de equipamento > Correntes, fio e corda",
  },
  {
    value: "2267",
    inputValue:
      "Equipamento > Acessórios de equipamento > Correntes, fio e corda > Esticadores",
  },
  {
    value: "2268",
    inputValue:
      "Equipamento > Acessórios de equipamento > Correntes, fio e corda > Correntes",
  },
  {
    value: "2269",
    inputValue:
      "Equipamento > Acessórios de equipamento > Correntes, fio e corda > Corrente de puxar",
  },
  {
    value: "2270",
    inputValue:
      "Equipamento > Acessórios de equipamento > Correntes, fio e corda > Cordas e cabo de equipamento",
  },
  {
    value: "2271",
    inputValue:
      "Equipamento > Acessórios de equipamento > Correntes, fio e corda > Fitas de prender",
  },
  {
    value: "2272",
    inputValue:
      "Equipamento > Acessórios de equipamento > Correntes, fio e corda > Corda",
  },
  {
    value: "2273",
    inputValue:
      "Equipamento > Acessórios de equipamento > Correntes, fio e corda > Fio utilitário",
  },
  {
    value: "2274",
    inputValue: "Equipamento > Acessórios de equipamento > Bobinas",
  },
  {
    value: "2275",
    inputValue: "Equipamento > Acessórios de equipamento > Moldes de betão",
  },
  {
    value: "2276",
    inputValue:
      "Equipamento > Acessórios de equipamento > Pinos e varas de guia",
  },
  {
    value: "2277",
    inputValue: "Equipamento > Acessórios de equipamento > Laterais de gavetas",
  },
  {
    value: "2278",
    inputValue:
      "Equipamento > Acessórios de equipamento > Tecidos de revestimento",
  },
  {
    value: "2279",
    inputValue: "Equipamento > Acessórios de equipamento > Ecrãs e filtros",
  },
  {
    value: "2280",
    inputValue:
      "Equipamento > Acessórios de equipamento > Fita de sinalização e advertência",
  },
  {
    value: "2281",
    inputValue: "Equipamento > Acessórios de equipamento > Mangueiras de gás",
  },
  {
    value: "2282",
    inputValue: "Equipamento > Acessórios de equipamento > Estacas de enterrar",
  },
  {
    value: "2283",
    inputValue:
      "Equipamento > Acessórios de equipamento > Fixadores de equipamento",
  },
  {
    value: "2284",
    inputValue:
      "Equipamento > Acessórios de equipamento > Fixadores de equipamento > Fixadores de pladur",
  },
  {
    value: "2285",
    inputValue:
      "Equipamento > Acessórios de equipamento > Fixadores de equipamento > Pregos",
  },
  {
    value: "2286",
    inputValue:
      "Equipamento > Acessórios de equipamento > Fixadores de equipamento > Porcas e pinos",
  },
  {
    value: "2287",
    inputValue:
      "Equipamento > Acessórios de equipamento > Fixadores de equipamento > Rebites",
  },
  {
    value: "2288",
    inputValue:
      "Equipamento > Acessórios de equipamento > Fixadores de equipamento > Parafusos de ligação",
  },
  {
    value: "2289",
    inputValue:
      "Equipamento > Acessórios de equipamento > Fixadores de equipamento > Parafusos",
  },
  {
    value: "2290",
    inputValue:
      "Equipamento > Acessórios de equipamento > Fixadores de equipamento > Hastes roscadas",
  },
  {
    value: "2291",
    inputValue:
      "Equipamento > Acessórios de equipamento > Fixadores de equipamento > Anilhas",
  },
  {
    value: "2292",
    inputValue: "Equipamento > Acessórios de equipamento > Dobradiças",
  },
  {
    value: "2293",
    inputValue:
      "Equipamento > Acessórios de equipamento > Ganchos, fivelas e fixadores",
  },
  {
    value: "2294",
    inputValue:
      "Equipamento > Acessórios de equipamento > Ganchos, fivelas e fixadores > Conetores e ligações de corrente",
  },
  {
    value: "2295",
    inputValue:
      "Equipamento > Acessórios de equipamento > Ganchos, fivelas e fixadores > Atilhos de equipamento",
  },
  {
    value: "2296",
    inputValue:
      "Equipamento > Acessórios de equipamento > Ganchos, fivelas e fixadores > Ganchos, grampos e manilhas de elevação",
  },
  {
    value: "2297",
    inputValue:
      "Equipamento > Acessórios de equipamento > Ganchos, fivelas e fixadores > Fivelas utilitárias",
  },
  {
    value: "2298",
    inputValue:
      "Equipamento > Acessórios de equipamento > Mangueiras de lubrificação",
  },
  {
    value: "2299",
    inputValue:
      "Equipamento > Acessórios de equipamento > Moldes de fundição de metal",
  },
  {
    value: "2300",
    inputValue:
      "Equipamento > Acessórios de equipamento > Mantas e coberturas de movimentação e insonorização",
  },
  {
    value: "2301",
    inputValue:
      "Equipamento > Acessórios de equipamento > Mangueiras pneumáticas",
  },
  {
    value: "2302",
    inputValue:
      "Equipamento > Acessórios de equipamento > Placas de base de poste",
  },
  {
    value: "2303",
    inputValue: "Equipamento > Acessórios de equipamento > Molas",
  },
  {
    value: "2304",
    inputValue: "Equipamento > Acessórios de equipamento > Lonas",
  },
  {
    value: "2305",
    inputValue:
      "Equipamento > Acessórios de equipamento > Armazenamento e organização de ferramentas",
  },
  {
    value: "2306",
    inputValue:
      "Equipamento > Acessórios de equipamento > Armazenamento e organização de ferramentas > Armazenamento de mangueira de jardim",
  },
  {
    value: "2307",
    inputValue:
      "Equipamento > Acessórios de equipamento > Armazenamento e organização de ferramentas > Ferramentas e cintos de equipamento",
  },
  {
    value: "2308",
    inputValue:
      "Equipamento > Acessórios de equipamento > Armazenamento e organização de ferramentas > Sacos de ferramentas",
  },
  {
    value: "2309",
    inputValue:
      "Equipamento > Acessórios de equipamento > Armazenamento e organização de ferramentas > Caixas de ferramentas",
  },
  {
    value: "2310",
    inputValue:
      "Equipamento > Acessórios de equipamento > Armazenamento e organização de ferramentas > Armários e malas de ferramentas",
  },
  {
    value: "2311",
    inputValue:
      "Equipamento > Acessórios de equipamento > Armazenamento e organização de ferramentas > Forro s e inserções de organizador de ferramentas",
  },
  {
    value: "2312",
    inputValue:
      "Equipamento > Acessórios de equipamento > Armazenamento e organização de ferramentas > Bainhas de ferramentas",
  },
  {
    value: "2313",
    inputValue:
      "Equipamento > Acessórios de equipamento > Armazenamento e organização de ferramentas > Bancadas de trabalho",
  },
  {
    value: "2314",
    inputValue:
      "Equipamento > Acessórios de equipamento > Macacos e suportes de parede",
  },
  { value: "2315", inputValue: "Equipamento > Bombas de equipamento" },
  {
    value: "2316",
    inputValue:
      "Equipamento > Bombas de equipamento > Bombas de eletrodomésticos",
  },
  {
    value: "2317",
    inputValue:
      "Equipamento > Bombas de equipamento > Bombas de piscinas, fontes e lagos",
  },
  {
    value: "2318",
    inputValue:
      "Equipamento > Bombas de equipamento > Bombas de aspersores, sistemas de intensificador e de rega",
  },
  {
    value: "2319",
    inputValue:
      "Equipamento > Bombas de equipamento > Bombas de reservatório, esgotos e efluentes",
  },
  {
    value: "2320",
    inputValue: "Equipamento > Bombas de equipamento > Bombas utilitárias",
  },
  {
    value: "2321",
    inputValue:
      "Equipamento > Bombas de equipamento > Bombas e sistemas de poços",
  },
  {
    value: "2322",
    inputValue: "Equipamento > Aquecimento, ventilação e ar condicionado",
  },
  {
    value: "2323",
    inputValue:
      "Equipamento > Aquecimento, ventilação e ar condicionado > Secadores de filtro e ar",
  },
  {
    value: "2324",
    inputValue:
      "Equipamento > Aquecimento, ventilação e ar condicionado > Condutas de ar",
  },
  {
    value: "2325",
    inputValue:
      "Equipamento > Aquecimento, ventilação e ar condicionado > Controlos de AVC",
  },
  {
    value: "2326",
    inputValue:
      "Equipamento > Aquecimento, ventilação e ar condicionado > Controlos de AVC > Painéis de controlo",
  },
  {
    value: "2327",
    inputValue:
      "Equipamento > Aquecimento, ventilação e ar condicionado > Controlos de AVC > Higróstatos",
  },
  {
    value: "2328",
    inputValue:
      "Equipamento > Aquecimento, ventilação e ar condicionado > Controlos de AVC > Termóstatos",
  },
  {
    value: "2329",
    inputValue:
      "Equipamento > Aquecimento, ventilação e ar condicionado > Aberturas e dutos",
  },
  { value: "2330", inputValue: "Equipamento > Cadeados e chaves" },
  {
    value: "2331",
    inputValue: "Equipamento > Cadeados e chaves > Chaves em bruto",
  },
  {
    value: "2332",
    inputValue: "Equipamento > Cadeados e chaves > Tampas de chaves",
  },
  {
    value: "2333",
    inputValue:
      "Equipamento > Cadeados e chaves > Sistemas de entrada cartão chave",
  },
  {
    value: "2334",
    inputValue: "Equipamento > Cadeados e chaves > Fechos e trincos",
  },
  { value: "2335", inputValue: "Equipamento > Canalização" },
  {
    value: "2336",
    inputValue:
      "Equipamento > Canalização > Encaixes e suportes de canalização",
  },
  {
    value: "2337",
    inputValue:
      "Equipamento > Canalização > Encaixes e suportes de canalização > Juntas e O-Rings",
  },
  {
    value: "2338",
    inputValue:
      "Equipamento > Canalização > Encaixes e suportes de canalização > Condutores In-Wall e estruturas de montagem",
  },
  {
    value: "2339",
    inputValue:
      "Equipamento > Canalização > Encaixes e suportes de canalização > Bocais",
  },
  {
    value: "2340",
    inputValue:
      "Equipamento > Canalização > Encaixes e suportes de canalização > Buchas e adaptadores de tubo",
  },
  {
    value: "2341",
    inputValue:
      "Equipamento > Canalização > Encaixes e suportes de canalização > Bujões e tampas de tubo",
  },
  {
    value: "2342",
    inputValue:
      "Equipamento > Canalização > Encaixes e suportes de canalização > Conectores de tubo",
  },
  {
    value: "2343",
    inputValue:
      "Equipamento > Canalização > Encaixes e suportes de canalização > Flanges de canalização",
  },
  {
    value: "2344",
    inputValue:
      "Equipamento > Canalização > Encaixes e suportes de canalização > Abraçadeiras de tubo de canalização",
  },
  {
    value: "2345",
    inputValue:
      "Equipamento > Canalização > Encaixes e suportes de canalização > Reguladores de canalização",
  },
  {
    value: "2346",
    inputValue:
      "Equipamento > Canalização > Encaixes e suportes de canalização > Válvulas de canalização",
  },
  {
    value: "2347",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização",
  },
  {
    value: "2348",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de banheira",
  },
  {
    value: "2349",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de banheira > Bases e pés de banheira",
  },
  {
    value: "2350",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de banheira > Saias de banheira",
  },
  {
    value: "2351",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de banheira > Torneiras de banheira",
  },
  {
    value: "2352",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Componentes de drenagem",
  },
  {
    value: "2353",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Componentes de drenagem > Filtros e tampas de drenagem",
  },
  {
    value: "2354",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Componentes de drenagem > Estruturas de drenagem",
  },
  {
    value: "2355",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Componentes de drenagem > Revestimentos de drenagem",
  },
  {
    value: "2356",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Componentes de drenagem > Facilitadores de drenagem",
  },
  {
    value: "2357",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Componentes de drenagem > Varas de drenagem",
  },
  {
    value: "2358",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Componentes de drenagem > Coletores de canalização",
  },
  {
    value: "2359",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Componentes de drenagem > Acessórios para resíduos de canalizações",
  },
  {
    value: "2360",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Escoamentos",
  },
  {
    value: "2361",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios para torneiras",
  },
  {
    value: "2362",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios para torneiras > Aeradores para torneiras",
  },
  {
    value: "2363",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios para torneiras > Comandos e pegas para torneira",
  },
  {
    value: "2364",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Placas de fixação",
  },
  {
    value: "2365",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Peças de duche",
  },
  {
    value: "2366",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Peças de duche > Chuveiros de duche e banheira",
  },
  {
    value: "2367",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Peças de duche > Chuveiros de alta pressão e elétricos",
  },
  {
    value: "2368",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Peças de duche > Conectores e braços de duche",
  },
  {
    value: "2369",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Peças de duche > Bases de duche",
  },
  {
    value: "2370",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Peças de duche > Colunas de duche",
  },
  {
    value: "2371",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Peças de duche > Portas e estruturas de duche",
  },
  {
    value: "2372",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Peças de duche > Cabeças de chuveiro",
  },
  {
    value: "2373",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Peças de duche > Paredes e revestimentos de duche",
  },
  {
    value: "2374",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Peças de duche > Filtros de água de duche",
  },
  {
    value: "2375",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de lavatório",
  },
  {
    value: "2376",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de lavatório > Pernas de lavatório",
  },
  {
    value: "2377",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de bidé e sanita",
  },
  {
    value: "2378",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de bidé e sanita > Válvulas de boia e obturadores",
  },
  {
    value: "2379",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de bidé e sanita > Pulverizadores e torneiras de bidé",
  },
  {
    value: "2380",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de bidé e sanita > Assentos de bidé e sanita",
  },
  {
    value: "2381",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de bidé e sanita > Tampas de assento de sanita",
  },
  {
    value: "2382",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de bidé e sanita > Coberturas de tampa de assento de sanita",
  },
  {
    value: "2383",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de bidé e sanita > Coberturas de depósito de sanita",
  },
  {
    value: "2384",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de bidé e sanita > Niveladores de depósito de sanita",
  },
  {
    value: "2385",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de bidé e sanita > Depósitos de sanita",
  },
  {
    value: "2386",
    inputValue:
      "Equipamento > Canalização > Peças e equipamento de fixação de canalização > Acessórios de bidé e sanita > Rebordos de sanita",
  },
  {
    value: "2387",
    inputValue: "Equipamento > Canalização > Fixações de canalização",
  },
  {
    value: "2388",
    inputValue:
      "Equipamento > Canalização > Fixações de canalização > Casas de banho",
  },
  {
    value: "2389",
    inputValue:
      "Equipamento > Canalização > Fixações de canalização > Banheiras",
  },
  {
    value: "2390",
    inputValue:
      "Equipamento > Canalização > Fixações de canalização > Torneiras",
  },
  {
    value: "2391",
    inputValue:
      "Equipamento > Canalização > Fixações de canalização > Conjuntos e estruturas de duche",
  },
  {
    value: "2392",
    inputValue:
      "Equipamento > Canalização > Fixações de canalização > Lavatórios",
  },
  {
    value: "2393",
    inputValue:
      "Equipamento > Canalização > Fixações de canalização > Lavatórios > Lavatórios de WC",
  },
  {
    value: "2394",
    inputValue:
      "Equipamento > Canalização > Fixações de canalização > Lavatórios > Lava-louças de cozinha e utilitários",
  },
  {
    value: "2395",
    inputValue:
      "Equipamento > Canalização > Fixações de canalização > Sanitas e bidés",
  },
  {
    value: "2396",
    inputValue:
      "Equipamento > Canalização > Fixações de canalização > Sanitas e bidés > Bidés",
  },
  {
    value: "2397",
    inputValue:
      "Equipamento > Canalização > Fixações de canalização > Sanitas e bidés > Sanitas",
  },
  {
    value: "2398",
    inputValue:
      "Equipamento > Canalização > Fixações de canalização > Sanitas e bidés > Urinóis",
  },
  {
    value: "2399",
    inputValue:
      "Equipamento > Canalização > Linhas de abastecimento e mangueiras de canalização",
  },
  {
    value: "2400",
    inputValue: "Equipamento > Canalização > Tubos de canalização",
  },
  {
    value: "2401",
    inputValue: "Equipamento > Canalização > Kits de reparação de canalização",
  },
  {
    value: "2402",
    inputValue: "Equipamento > Canalização > Distribuição e filtragem de água",
  },
  {
    value: "2403",
    inputValue:
      "Equipamento > Canalização > Distribuição e filtragem de água > Filtros de água In-Line",
  },
  {
    value: "2404",
    inputValue:
      "Equipamento > Canalização > Distribuição e filtragem de água > Distribuidores de água",
  },
  {
    value: "2405",
    inputValue:
      "Equipamento > Canalização > Distribuição e filtragem de água > Distribuidores de água > Fontes de água",
  },
  {
    value: "2406",
    inputValue:
      "Equipamento > Canalização > Distribuição e filtragem de água > Distribuidores de água > Refrigeradores de água",
  },
  {
    value: "2407",
    inputValue:
      "Equipamento > Canalização > Distribuição e filtragem de água > Destiladores de água",
  },
  {
    value: "2408",
    inputValue:
      "Equipamento > Canalização > Distribuição e filtragem de água > Acessórios de filtragem de água",
  },
  {
    value: "2409",
    inputValue:
      "Equipamento > Canalização > Distribuição e filtragem de água > Acessórios de filtragem de água > Cartuchos de filtro de água",
  },
  {
    value: "2410",
    inputValue:
      "Equipamento > Canalização > Distribuição e filtragem de água > Acessórios de filtragem de água > Alojamentos de filtro de água",
  },
  {
    value: "2411",
    inputValue:
      "Equipamento > Canalização > Distribuição e filtragem de água > Abrandadores de sais de água",
  },
  {
    value: "2412",
    inputValue:
      "Equipamento > Canalização > Distribuição e filtragem de água > Abrandadores de água",
  },
  {
    value: "2413",
    inputValue: "Equipamento > Canalização > Niveladores de água",
  },
  {
    value: "2414",
    inputValue: "Equipamento > Canalização > Temporizadores de água",
  },
  {
    value: "2415",
    inputValue: "Equipamento > Canalização > Artigos para poços",
  },
  {
    value: "2416",
    inputValue: "Equipamento > Artigos de potência e alimentação elétrica",
  },
  {
    value: "2417",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Armaduras, rotores e estatores",
  },
  {
    value: "2418",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Balastros e arrancadores",
  },
  {
    value: "2419",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Escovas de carvão",
  },
  {
    value: "2420",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Painéis de disjuntor",
  },
  {
    value: "2421",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Condutores e alojamentos",
  },
  {
    value: "2422",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Condutores e alojamentos > Condutores elétricos",
  },
  {
    value: "2423",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Condutores e alojamentos > Tubagem termorretrátil",
  },
  {
    value: "2424",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Motores elétricos",
  },
  {
    value: "2425",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Caixas e suportes de montagem elétrica",
  },
  {
    value: "2426",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Tampas de bujões elétricos",
  },
  {
    value: "2427",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Interruptores elétricos",
  },
  {
    value: "2428",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Interruptores elétricos > Interruptores de iluminação",
  },
  {
    value: "2429",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Interruptores elétricos > Interruptores e relés elétricos",
  },
  {
    value: "2430",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Fios e cabos elétricos",
  },
  {
    value: "2431",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Acessórios de cabos de extensão",
  },
  {
    value: "2432",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Cabos de extensão",
  },
  {
    value: "2433",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Acessórios de gerador",
  },
  {
    value: "2434",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Geradores",
  },
  {
    value: "2435",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Kits de automatização domésticos",
  },
  {
    value: "2436",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Tomadas de telefone e de dados",
  },
  {
    value: "2437",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Conversores de potência",
  },
  {
    value: "2438",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Entradas de potência",
  },
  {
    value: "2439",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Inversores de potência",
  },
  {
    value: "2440",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Tomadas e saídas de potência",
  },
  {
    value: "2441",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Kits de energia solar",
  },
  {
    value: "2442",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Painéis solares",
  },
  {
    value: "2443",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Transformadores e reguladores de voltagem",
  },
  {
    value: "2444",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Tampas e placas de parede",
  },
  {
    value: "2445",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Controlos e sensores de tomada de parede",
  },
  {
    value: "2446",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Tampas e porcas de fios",
  },
  {
    value: "2447",
    inputValue:
      "Equipamento > Artigos de potência e alimentação elétrica > Terminais e conectores de fio",
  },
  { value: "2448", inputValue: "Equipamento > Motores pequenos" },
  { value: "2449", inputValue: "Equipamento > Tanques de armazenamento" },
  { value: "2450", inputValue: "Equipamento > Acessórios de ferramentas" },
  {
    value: "2451",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de jateadores abrasivos",
  },
  {
    value: "2452",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de jateadores abrasivos > Cabines de jato de areia",
  },
  {
    value: "2453",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de machado",
  },
  {
    value: "2454",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de machado > Cabos de machado",
  },
  {
    value: "2455",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de machado > Cabeças de machado",
  },
  {
    value: "2456",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de cortador",
  },
  {
    value: "2457",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de cortador > Matrizes e punções de corte",
  },
  {
    value: "2458",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de berbequim e chave de fendas",
  },
  {
    value: "2459",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de berbequim e chave de fendas > Pontas de berbequim e chave de fendas",
  },
  {
    value: "2460",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de berbequim e chave de fendas > Extensões de pontas de berbequim",
  },
  {
    value: "2461",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de berbequim e chave de fendas > Afiadores de pontas de berbequim",
  },
  {
    value: "2462",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de berbequim e chave de fendas > Mandris de berbequim",
  },
  {
    value: "2463",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de berbequim e chave de fendas > Guias e apoios de berbequim",
  },
  {
    value: "2464",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de berbequim e chave de fendas > Serras cranianas",
  },
  {
    value: "2465",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de acionador",
  },
  {
    value: "2466",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de lanterna",
  },
  {
    value: "2467",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de lixadora/esmeril",
  },
  {
    value: "2468",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de lixadora/esmeril > Pontas e discos de lixadora/esmeril",
  },
  {
    value: "2469",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de martelo",
  },
  {
    value: "2470",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de martelo > Acessórios de martelo pneumático",
  },
  {
    value: "2471",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de martelo > Cabos de martelo",
  },
  {
    value: "2472",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de martelo > Cabeças de martelo",
  },
  {
    value: "2473",
    inputValue: "Equipamento > Acessórios de ferramentas > Grampos industriais",
  },
  {
    value: "2474",
    inputValue: "Equipamento > Acessórios de ferramentas > Gabaritos",
  },
  {
    value: "2475",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Magnetizadores e desmagnetizadores",
  },
  {
    value: "2476",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de alvião e picareta",
  },
  {
    value: "2477",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de alvião e picareta > Cabos de alvião e picareta",
  },
  {
    value: "2478",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Ferramentas de medição e acessórios de sensores",
  },
  {
    value: "2479",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Ferramentas de medição e acessórios de sensores > Acessórios de ferramentas de teste elétricas",
  },
  {
    value: "2480",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Ferramentas de medição e acessórios de sensores > Acessórios de detetor de gás",
  },
  {
    value: "2481",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Ferramentas de medição e acessórios de sensores > Acessórios de balança",
  },
  {
    value: "2482",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Ferramentas de medição e acessórios de sensores > Acessórios de multímetro",
  },
  {
    value: "2483",
    inputValue: "Equipamento > Acessórios de ferramentas > Pás de mistura",
  },
  {
    value: "2484",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de pás de mistura",
  },
  {
    value: "2485",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de pás de mistura > Acessórios de aerógrafo",
  },
  {
    value: "2486",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de pás de mistura > Soluções de limpeza de trinchas/pincéis",
  },
  {
    value: "2487",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de pás de mistura > Acessórios de rolo de pintura",
  },
  {
    value: "2488",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Baterias de ferramenta elétrica",
  },
  {
    value: "2489",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Carregadores de ferramenta elétrica",
  },
  {
    value: "2490",
    inputValue: "Equipamento > Acessórios de ferramentas > Acessórios de tupia",
  },
  {
    value: "2491",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de tupia > Ponteiras de tupia",
  },
  {
    value: "2492",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de tupia > Mesas de tupia",
  },
  {
    value: "2493",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de lixagem",
  },
  {
    value: "2494",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de lixagem > Folhas de lixa e esponjas de lixa",
  },
  {
    value: "2495",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de serras",
  },
  {
    value: "2496",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de serras > Acessórios de serra de fita",
  },
  {
    value: "2497",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de serras > Acessórios de serra circular manual",
  },
  {
    value: "2498",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de serras > Acessórios de serra de recortes",
  },
  {
    value: "2499",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de serras > Acessórios de serra de esquadria",
  },
  {
    value: "2500",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de serras > Acessórios de serra de mesa",
  },
  {
    value: "2501",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de moldador",
  },
  {
    value: "2502",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de moldador > Cortadores de moldador",
  },
  {
    value: "2503",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de ferro de soldar",
  },
  {
    value: "2504",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de ferro de soldar > Apoios de ferro de soldar",
  },
  {
    value: "2505",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Acessórios de ferro de soldar > Pontas de ferro de soldar",
  },
  {
    value: "2506",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Lâminas para ferramentas",
  },
  {
    value: "2507",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Lâminas para ferramentas > Lâminas de cortador e raspador",
  },
  {
    value: "2508",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Lâminas para ferramentas > Lâminas de serra",
  },
  {
    value: "2509",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Cunhas de ferramentas manuais",
  },
  {
    value: "2510",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Correias de segurança de ferramentas",
  },
  {
    value: "2511",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Chaves de caixa de ferramentas",
  },
  {
    value: "2512",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Apoios de ferramentas",
  },
  {
    value: "2513",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Apoios de ferramentas > Suportes de serras",
  },
  {
    value: "2514",
    inputValue:
      "Equipamento > Acessórios de ferramentas > Ferramentas de cunhas",
  },
  {
    value: "2515",
    inputValue: "Equipamento > Acessórios de ferramentas > Acessórios de solda",
  },
  { value: "2516", inputValue: "Equipamento > Ferramentas" },
  {
    value: "2517",
    inputValue: "Equipamento > Ferramentas > Jateadores abrasivos",
  },
  { value: "2518", inputValue: "Equipamento > Ferramentas > Bigornas" },
  { value: "2519", inputValue: "Equipamento > Ferramentas > Machados" },
  { value: "2520", inputValue: "Equipamento > Ferramentas > Raspadeiras" },
  {
    value: "2521",
    inputValue: "Equipamento > Ferramentas > Formões e cinzéis",
  },
  {
    value: "2522",
    inputValue: "Equipamento > Ferramentas > Ferramentas de calafetagem",
  },
  {
    value: "2523",
    inputValue: "Equipamento > Ferramentas > Escovas limpa-chaminés",
  },
  { value: "2524", inputValue: "Equipamento > Ferramentas > Compactadores" },
  { value: "2525", inputValue: "Equipamento > Ferramentas > Compressores" },
  {
    value: "2526",
    inputValue: "Equipamento > Ferramentas > Vassouras para betão",
  },
  {
    value: "2527",
    inputValue: "Equipamento > Ferramentas > Alicates/cortadores",
  },
  {
    value: "2528",
    inputValue:
      "Equipamento > Ferramentas > Alicates/cortadores > Alicates para corte de vidros",
  },
  {
    value: "2529",
    inputValue:
      "Equipamento > Ferramentas > Alicates/cortadores > Cortadores de vidro",
  },
  {
    value: "2530",
    inputValue:
      "Equipamento > Ferramentas > Alicates/cortadores > Tesouras faca e roedoras de metal manuais",
  },
  {
    value: "2531",
    inputValue: "Equipamento > Ferramentas > Alicates/cortadores > Alicates",
  },
  {
    value: "2532",
    inputValue:
      "Equipamento > Ferramentas > Alicates/cortadores > Cortadores de tubos",
  },
  {
    value: "2533",
    inputValue:
      "Equipamento > Ferramentas > Alicates/cortadores > Alicates de corte",
  },
  {
    value: "2534",
    inputValue:
      "Equipamento > Ferramentas > Alicates/cortadores > Cortadores de cerâmica e telhas",
  },
  {
    value: "2535",
    inputValue: "Equipamento > Ferramentas > Alicates/cortadores > X-atos",
  },
  {
    value: "2536",
    inputValue: "Equipamento > Ferramentas > Removedores de rebarbas",
  },
  {
    value: "2537",
    inputValue:
      "Equipamento > Ferramentas > Plataformas móveis e carrinhos de mão",
  },
  { value: "2538", inputValue: "Equipamento > Ferramentas > Berbequins" },
  {
    value: "2539",
    inputValue: "Equipamento > Ferramentas > Berbequins > Trados",
  },
  {
    value: "2540",
    inputValue: "Equipamento > Ferramentas > Berbequins > Berbequim de bancada",
  },
  {
    value: "2541",
    inputValue: "Equipamento > Ferramentas > Berbequins > Berbequins manuais",
  },
  {
    value: "2542",
    inputValue: "Equipamento > Ferramentas > Berbequins > Furadores de coluna",
  },
  {
    value: "2543",
    inputValue:
      "Equipamento > Ferramentas > Berbequins > Berbequins pneumáticos",
  },
  {
    value: "2544",
    inputValue: "Equipamento > Ferramentas > Cabo de eletricista",
  },
  {
    value: "2545",
    inputValue: "Equipamento > Ferramentas > Lanternas e lanternas de cabeça",
  },
  {
    value: "2546",
    inputValue:
      "Equipamento > Ferramentas > Lanternas e lanternas de cabeça > Lanternas",
  },
  {
    value: "2547",
    inputValue:
      "Equipamento > Ferramentas > Lanternas e lanternas de cabeça > Lanternas de cabeça",
  },
  {
    value: "2548",
    inputValue: "Equipamento > Ferramentas > Bombas de lubrificação",
  },
  { value: "2549", inputValue: "Equipamento > Ferramentas > Esmeris" },
  { value: "2550", inputValue: "Equipamento > Ferramentas > Alicates" },
  { value: "2551", inputValue: "Equipamento > Ferramentas > Martelos" },
  {
    value: "2552",
    inputValue: "Equipamento > Ferramentas > Martelos > Martelos manuais",
  },
  {
    value: "2553",
    inputValue: "Equipamento > Ferramentas > Martelos > Martelos elétricos",
  },
  {
    value: "2554",
    inputValue: "Equipamento > Ferramentas > Misturadoras manuais",
  },
  { value: "2555", inputValue: "Equipamento > Ferramentas > Maçaricos" },
  {
    value: "2556",
    inputValue: "Equipamento > Ferramentas > Sopradores de ar quente",
  },
  {
    value: "2557",
    inputValue: "Equipamento > Ferramentas > Berbequins e chaves de impacto",
  },
  {
    value: "2558",
    inputValue: "Equipamento > Ferramentas > Vibradores industriais",
  },
  {
    value: "2559",
    inputValue: "Equipamento > Ferramentas > Espelhos de inspeção",
  },
  {
    value: "2560",
    inputValue: "Equipamento > Ferramentas > Escadas e andaimes",
  },
  {
    value: "2561",
    inputValue:
      "Equipamento > Ferramentas > Escadas e andaimes > Carrinhos escada",
  },
  {
    value: "2562",
    inputValue: "Equipamento > Ferramentas > Escadas e andaimes > Escadas",
  },
  {
    value: "2563",
    inputValue: "Equipamento > Ferramentas > Escadas e andaimes > Andaimes",
  },
  {
    value: "2564",
    inputValue: "Equipamento > Ferramentas > Escadas e andaimes > Escadotes",
  },
  {
    value: "2565",
    inputValue:
      "Equipamento > Ferramentas > Escadas e andaimes > Plataformas de trabalho",
  },
  { value: "2566", inputValue: "Equipamento > Ferramentas > Tornos" },
  {
    value: "2567",
    inputValue: "Equipamento > Ferramentas > Trocadores de lâmpadas",
  },
  {
    value: "2568",
    inputValue: "Equipamento > Ferramentas > Isqueiros e fósforos",
  },
  {
    value: "2569",
    inputValue: "Equipamento > Ferramentas > Rachador de lenha",
  },
  {
    value: "2570",
    inputValue: "Equipamento > Ferramentas > Varredores magnéticos",
  },
  {
    value: "2571",
    inputValue: "Equipamento > Ferramentas > Ferramentas para marcação",
  },
  {
    value: "2572",
    inputValue: "Equipamento > Ferramentas > Ferramentas para alvenaria",
  },
  {
    value: "2573",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas para alvenaria > Ferramentas para tijolos",
  },
  {
    value: "2574",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas para alvenaria > Misturadores de cimento",
  },
  {
    value: "2575",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas para alvenaria > Linhas de construção",
  },
  {
    value: "2576",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas para alvenaria > Flutuadores",
  },
  {
    value: "2577",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas para alvenaria > Esponjas para argamassa",
  },
  {
    value: "2578",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas para alvenaria > Raspadores e talochas de alvenaria",
  },
  {
    value: "2579",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas para alvenaria > Colherins para alvenaria",
  },
  {
    value: "2580",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas para alvenaria > Talochas para alvenaria",
  },
  {
    value: "2581",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas para alvenaria > Talochas elétricas",
  },
  {
    value: "2582",
    inputValue: "Equipamento > Ferramentas > Enxadas e picaretas",
  },
  {
    value: "2583",
    inputValue: "Equipamento > Ferramentas > Ferramentas e sensores de medição",
  },
  {
    value: "2584",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Medidores da qualidade do ar",
  },
  {
    value: "2585",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Altímetros",
  },
  {
    value: "2586",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Anemómetros",
  },
  {
    value: "2587",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Barómetros",
  },
  {
    value: "2588",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Paquímetros",
  },
  {
    value: "2589",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Medidores com marcação",
  },
  {
    value: "2590",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Telémetros",
  },
  {
    value: "2591",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Divisores",
  },
  {
    value: "2592",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Ferramentas de teste elétrico",
  },
  {
    value: "2593",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Medidores de fluxo e controladores",
  },
  {
    value: "2594",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Detetores de gás",
  },
  {
    value: "2595",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Indicadores",
  },
  {
    value: "2596",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Contadores Geiger",
  },
  {
    value: "2597",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Higrómetros",
  },
  {
    value: "2598",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Termómetros infravermelhos",
  },
  {
    value: "2599",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Guias de faca",
  },
  {
    value: "2600",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Niveladores",
  },
  {
    value: "2601",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Niveladores > Níveis de bolha",
  },
  {
    value: "2602",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Niveladores > Níveis de laser",
  },
  {
    value: "2603",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Niveladores > Níveis óticos",
  },
  {
    value: "2604",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Balanças de medição",
  },
  {
    value: "2605",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Rodas de medição",
  },
  {
    value: "2606",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Medidores de humidade",
  },
  {
    value: "2607",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Sondas e detetores",
  },
  {
    value: "2608",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Transferidores",
  },
  {
    value: "2609",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Detetores de metais",
  },
  {
    value: "2610",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Réguas",
  },
  {
    value: "2611",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Sismómetros",
  },
  {
    value: "2612",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Decibelímetros",
  },
  {
    value: "2613",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Esquadros",
  },
  {
    value: "2614",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Escalímetros",
  },
  {
    value: "2615",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Detetor de obstáculos",
  },
  {
    value: "2616",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Fitas de medição",
  },
  {
    value: "2617",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Teodolitos",
  },
  {
    value: "2618",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Câmaras termográficas",
  },
  {
    value: "2619",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Termopares e termopilhas",
  },
  {
    value: "2620",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Transdutores",
  },
  {
    value: "2621",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Medidores de luz UV",
  },
  {
    value: "2622",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Medidores de vibração",
  },
  {
    value: "2623",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Estações e dispositivos de previsão meteorológica",
  },
  {
    value: "2624",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas e sensores de medição > Medidores de pH",
  },
  { value: "2625", inputValue: "Equipamento > Ferramentas > Fresadoras" },
  {
    value: "2626",
    inputValue: "Equipamento > Ferramentas > Ferramentas elétricas multiuso",
  },
  { value: "2627", inputValue: "Equipamento > Ferramentas > Turqueses" },
  {
    value: "2628",
    inputValue: "Equipamento > Ferramentas > Pregadoras e agrafadores",
  },
  {
    value: "2629",
    inputValue: "Equipamento > Ferramentas > Drenos de filtro de óleo",
  },
  {
    value: "2630",
    inputValue: "Equipamento > Ferramentas > Ferramentas de pintura",
  },
  {
    value: "2631",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas de pintura > Aerógrafos",
  },
  {
    value: "2632",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas de pintura > Pincéis/trinchas de pintura",
  },
  {
    value: "2633",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas de pintura > Acessórios de pintura de cantos",
  },
  {
    value: "2634",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas de pintura > Rolos de pintura",
  },
  {
    value: "2635",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas de pintura > Agitadores de tinta",
  },
  {
    value: "2636",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas de pintura > Esponjas de pintura",
  },
  {
    value: "2637",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas de pintura > Pulverizadores de pintura",
  },
  {
    value: "2638",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas de pintura > Filtros de pintura",
  },
  {
    value: "2639",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas de pintura > Tabuleiros de pintura",
  },
  {
    value: "2640",
    inputValue: "Equipamento > Ferramentas > Ferramentas de recolha",
  },
  {
    value: "2641",
    inputValue: "Equipamento > Ferramentas > Curvador de tubos e barras",
  },
  {
    value: "2642",
    inputValue: "Equipamento > Ferramentas > Limpadores de tubos e barras",
  },
  {
    value: "2643",
    inputValue: "Equipamento > Ferramentas > Escovas helicoidais",
  },
  { value: "2644", inputValue: "Equipamento > Ferramentas > Plainas" },
  { value: "2645", inputValue: "Equipamento > Ferramentas > Desbastadores" },
  { value: "2646", inputValue: "Equipamento > Ferramentas > Tenazes" },
  { value: "2647", inputValue: "Equipamento > Ferramentas > Desentupidores" },
  {
    value: "2648",
    inputValue: "Equipamento > Ferramentas > Polidores e esmeris",
  },
  {
    value: "2649",
    inputValue: "Equipamento > Ferramentas > Perfuradores do solo",
  },
  { value: "2650", inputValue: "Equipamento > Ferramentas > Alçapremas" },
  {
    value: "2651",
    inputValue: "Equipamento > Ferramentas > Ponteiros e furadores",
  },
  {
    value: "2652",
    inputValue: "Equipamento > Ferramentas > Espátulas e raspadores",
  },
  { value: "2653", inputValue: "Equipamento > Ferramentas > Mandris" },
  {
    value: "2654",
    inputValue: "Equipamento > Ferramentas > Ferramentas de rebitagem",
  },
  {
    value: "2655",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas de rebitagem > Pistolas de rebites",
  },
  {
    value: "2656",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas de rebitagem > Alicates de rebites",
  },
  {
    value: "2657",
    inputValue: "Equipamento > Ferramentas > Ferramentas de freasagem",
  },
  { value: "2658", inputValue: "Equipamento > Ferramentas > Lixadoras" },
  {
    value: "2659",
    inputValue: "Equipamento > Ferramentas > Blocos de lixagem",
  },
  { value: "2660", inputValue: "Equipamento > Ferramentas > Cavaletes" },
  { value: "2661", inputValue: "Equipamento > Ferramentas > Serras" },
  {
    value: "2662",
    inputValue: "Equipamento > Ferramentas > Serras > Serras de fita",
  },
  {
    value: "2663",
    inputValue: "Equipamento > Ferramentas > Serras > Serras de meia esquadria",
  },
  {
    value: "2664",
    inputValue: "Equipamento > Ferramentas > Serras > Serras manuais",
  },
  {
    value: "2665",
    inputValue:
      "Equipamento > Ferramentas > Serras > Serras circulares manuais",
  },
  {
    value: "2666",
    inputValue: "Equipamento > Ferramentas > Serras > Serras de recorte",
  },
  {
    value: "2667",
    inputValue:
      "Equipamento > Ferramentas > Serras > Serras de alvenaria e telha",
  },
  {
    value: "2668",
    inputValue: "Equipamento > Ferramentas > Serras > Serras de esquadria",
  },
  {
    value: "2669",
    inputValue: "Equipamento > Ferramentas > Serras > Serras de painel",
  },
  {
    value: "2670",
    inputValue: "Equipamento > Ferramentas > Serras > Serras reciprocantes",
  },
  {
    value: "2671",
    inputValue: "Equipamento > Ferramentas > Serras > Serras pendulares",
  },
  {
    value: "2672",
    inputValue: "Equipamento > Ferramentas > Serras > Serras de mesa",
  },
  {
    value: "2673",
    inputValue: "Equipamento > Ferramentas > Chaves de parafuso",
  },
  {
    value: "2674",
    inputValue:
      "Equipamento > Ferramentas > Máquina de acabamento para madeira",
  },
  { value: "2675", inputValue: "Equipamento > Ferramentas > Afias" },
  {
    value: "2676",
    inputValue: "Equipamento > Ferramentas > Pegas para chaves de caixa",
  },
  { value: "2677", inputValue: "Equipamento > Ferramentas > Ferros de soldar" },
  {
    value: "2678",
    inputValue: "Equipamento > Ferramentas > Retificadores de torneiras",
  },
  {
    value: "2679",
    inputValue: "Equipamento > Ferramentas > Machos e caçonetes",
  },
  {
    value: "2680",
    inputValue: "Equipamento > Ferramentas > Máquinas de roscar",
  },
  { value: "2681", inputValue: "Equipamento > Ferramentas > Grampos e tornos" },
  { value: "2682", inputValue: "Equipamento > Ferramentas > Limas" },
  { value: "2683", inputValue: "Equipamento > Ferramentas > Jogos de chaves" },
  { value: "2684", inputValue: "Equipamento > Ferramentas > X-atos/canivetes" },
  {
    value: "2685",
    inputValue: "Equipamento > Ferramentas > Conjuntos de ferramentas",
  },
  {
    value: "2686",
    inputValue:
      "Equipamento > Ferramentas > Conjuntos de ferramentas > Conjuntos de ferramentas manuais",
  },
  {
    value: "2687",
    inputValue:
      "Equipamento > Ferramentas > Conjuntos de ferramentas > Conjuntos Combo de ferramentas",
  },
  {
    value: "2688",
    inputValue:
      "Equipamento > Ferramentas > Pistolas de soldar e cortadoras de plasma",
  },
  {
    value: "2689",
    inputValue:
      "Equipamento > Ferramentas > Ferramentas manuais para fios e cabos",
  },
  {
    value: "2690",
    inputValue: "Equipamento > Ferramentas > Iluminação de trabalho",
  },
  { value: "2691", inputValue: "Equipamento > Ferramentas > Bancadas" },
  { value: "2692", inputValue: "Saúde e beleza" },
  { value: "2693", inputValue: "Saúde e beleza > Cuidados de saúde" },
  {
    value: "2694",
    inputValue: "Saúde e beleza > Cuidados de saúde > Acupuntura",
  },
  {
    value: "2695",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Acupuntura > Modelos de acupuntura",
  },
  {
    value: "2696",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Acupuntura > Agulhas de acupuntura",
  },
  {
    value: "2697",
    inputValue: "Saúde e beleza > Cuidados de saúde > Arrastadeiras",
  },
  {
    value: "2698",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Acessórios de monitorização biométrica",
  },
  {
    value: "2699",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Acessórios de monitorização biométrica > Acessórios de monitorização de atividade",
  },
  {
    value: "2700",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Acessórios de monitorização biométrica > Acessórios de medição de glicose no sangue",
  },
  {
    value: "2701",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Acessórios de monitorização biométrica > Acessórios de medição de glicose no sangue > Solução de controlo de glicose no sangue",
  },
  {
    value: "2702",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Acessórios de monitorização biométrica > Acessórios de medição de glicose no sangue > Faixas de teste de glicose no sangue",
  },
  {
    value: "2703",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Acessórios de monitorização biométrica > Acessórios de medição de glicose no sangue > Dispositivos de lancetagem",
  },
  {
    value: "2704",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Acessórios de monitorização biométrica > Acessórios de monitorização da pressão arterial",
  },
  {
    value: "2705",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Acessórios de monitorização biométrica > Acessórios de monitorização da pressão arterial > Mangas de monitorização da pressão arterial",
  },
  {
    value: "2706",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Acessórios de monitorização biométrica > Acessórios de balança de peso corporal",
  },
  {
    value: "2707",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica",
  },
  {
    value: "2708",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Dispositivos de monitorização de atividade",
  },
  {
    value: "2709",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Dispositivos de medição de glicose no sangue",
  },
  {
    value: "2710",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Dispositivos de monitorização de pressão arterial",
  },
  {
    value: "2711",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Analisadores de gordura corporal",
  },
  {
    value: "2712",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Balanças de peso corporal",
  },
  {
    value: "2713",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Alcoolímetros",
  },
  {
    value: "2714",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Analisadores de colesterol",
  },
  {
    value: "2715",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Testes de ovulação e monitorização de fertilidade",
  },
  {
    value: "2716",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Testes de ovulação e monitorização de fertilidade > Monitores e testes de fertilidade",
  },
  {
    value: "2717",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Testes de ovulação e monitorização de fertilidade > Testes de ovulação",
  },
  {
    value: "2718",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Termómetros médicos",
  },
  {
    value: "2719",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Monitores de batimento cardíaco pré-natal",
  },
  {
    value: "2720",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Dispositivos de monitorização biométrica > Oxímetros de pulso",
  },
  {
    value: "2721",
    inputValue: "Saúde e beleza > Cuidados de saúde > Preservativos",
  },
  {
    value: "2722",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Loções e géis de condutividade",
  },
  {
    value: "2723",
    inputValue: "Saúde e beleza > Cuidados de saúde > Estojos de contracetivos",
  },
  {
    value: "2724",
    inputValue: "Saúde e beleza > Cuidados de saúde > Primeiros-socorros",
  },
  {
    value: "2725",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Primeiros-socorros > Artigos de limpeza e antisséticos",
  },
  {
    value: "2726",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Primeiros-socorros > Protetores de ligaduras e gesso",
  },
  {
    value: "2727",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Primeiros-socorros > Artigos para lavagem ocular",
  },
  {
    value: "2728",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Primeiros-socorros > Kits de primeiros-socorros",
  },
  {
    value: "2729",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Primeiros-socorros > Terapias do quente e do frio",
  },
  {
    value: "2730",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Primeiros-socorros > Terapias do quente e do frio > Cremes quentes",
  },
  {
    value: "2731",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Primeiros-socorros > Terapias do quente e do frio > Mantas térmicas",
  },
  {
    value: "2732",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Primeiros-socorros > Terapias do quente e do frio > Acumuladores de frio",
  },
  {
    value: "2733",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Primeiros-socorros > Fitas e ligaduras médicas",
  },
  {
    value: "2734",
    inputValue: "Saúde e beleza > Cuidados de saúde > Fitness e nutrição",
  },
  {
    value: "2735",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Fitness e nutrição > Barras de nutrição",
  },
  {
    value: "2736",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Fitness e nutrição > Bebidas e batidos de nutrição",
  },
  {
    value: "2737",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Fitness e nutrição > Géis e gomas de nutrição",
  },
  {
    value: "2738",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Fitness e nutrição > Purés de nutrição",
  },
  {
    value: "2739",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Fitness e nutrição > Suplementos alimentares em tubos",
  },
  {
    value: "2740",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Fitness e nutrição > Vitaminas e suplementos",
  },
  {
    value: "2741",
    inputValue: "Saúde e beleza > Cuidados de saúde > Auxiliares de audição",
  },
  {
    value: "2742",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Auxiliares de incontinência",
  },
  {
    value: "2743",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Lâmpadas de terapia de luz",
  },
  {
    value: "2744",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Sistemas de alarme médicos",
  },
  {
    value: "2745",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Joalharia e etiquetas de identificação médica",
  },
  {
    value: "2746",
    inputValue: "Saúde e beleza > Cuidados de saúde > Testes médicos",
  },
  {
    value: "2747",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Testes médicos > Kits de teste de alergia",
  },
  {
    value: "2748",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Testes médicos > Kits de teste de tipagem sanguínea",
  },
  {
    value: "2749",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Testes médicos > Testes de drogas",
  },
  {
    value: "2750",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Testes médicos > Testes de HIV/VIH",
  },
  {
    value: "2751",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Testes médicos > Testes de gravidez",
  },
  {
    value: "2752",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Testes médicos > Testes de infeção do trato urinário",
  },
  {
    value: "2753",
    inputValue: "Saúde e beleza > Cuidados de saúde > Fármacos e medicamentos",
  },
  {
    value: "2754",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade",
  },
  {
    value: "2755",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Equipamento de acessibilidade",
  },
  {
    value: "2756",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Equipamento de acessibilidade > Scooters de mobilidade",
  },
  {
    value: "2757",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Equipamento de acessibilidade > Elevadores de escadas",
  },
  {
    value: "2758",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Equipamento de acessibilidade > Resguardos e tábuas de transferência",
  },
  {
    value: "2759",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Equipamento de acessibilidade > Cadeiras de rodas",
  },
  {
    value: "2760",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Acessórios de equipamento de acessibilidade",
  },
  {
    value: "2761",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Fixações e mobiliário de acessibilidade",
  },
  {
    value: "2762",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Fixações e mobiliário de acessibilidade > Bancadas e assentos de duche",
  },
  {
    value: "2763",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Acessórios de auxílio à marcha",
  },
  {
    value: "2764",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Auxílios à marcha",
  },
  {
    value: "2765",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Auxílios à marcha > Bengalas e muletas",
  },
  {
    value: "2766",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Auxílios à marcha > Canadianas",
  },
  {
    value: "2767",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Mobilidade e acessibilidade > Auxílios à marcha > Andarilhos",
  },
  {
    value: "2768",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Equipamento de terapia física e ocupacional",
  },
  {
    value: "2769",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Equipamento de terapia física e ocupacional > Estimuladores musculares elétricos",
  },
  {
    value: "2770",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Equipamento de terapia física e ocupacional > Baloiços terapêuticos",
  },
  {
    value: "2771",
    inputValue: "Saúde e beleza > Cuidados de saúde > Caixas de comprimidos",
  },
  {
    value: "2772",
    inputValue: "Saúde e beleza > Cuidados de saúde > Cuidado respiratório",
  },
  {
    value: "2773",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Cuidado respiratório > Nebulizadores",
  },
  {
    value: "2774",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Cuidado respiratório > Garrafas de oxigénio",
  },
  {
    value: "2775",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Cuidado respiratório > Ventiladores de pressão positiva contínua",
  },
  {
    value: "2776",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Cuidado respiratório > Máscaras de pressão positiva contínua",
  },
  {
    value: "2777",
    inputValue:
      "Saúde e beleza > Cuidados de saúde > Cuidado respiratório > Inaladores de vapor",
  },
  {
    value: "2778",
    inputValue: "Saúde e beleza > Cuidados de saúde > Recipientes de amostras",
  },
  {
    value: "2779",
    inputValue: "Saúde e beleza > Cuidados de saúde > Espermicidas",
  },
  {
    value: "2780",
    inputValue: "Saúde e beleza > Cuidados de saúde > Protetores para coto",
  },
  {
    value: "2781",
    inputValue: "Saúde e beleza > Cuidados de saúde > Apoios e próteses",
  },
  {
    value: "2782",
    inputValue: "Saúde e beleza > Cuidados de saúde > Lubrificantes cirúrgicos",
  },
  {
    value: "2783",
    inputValue: "Saúde e beleza > Limpeza e cuidado de joalharia",
  },
  {
    value: "2784",
    inputValue:
      "Saúde e beleza > Limpeza e cuidado de joalharia > Polimentos e soluções de limpeza de joalharia",
  },
  {
    value: "2785",
    inputValue:
      "Saúde e beleza > Limpeza e cuidado de joalharia > Ferramentas de limpeza de joalharia",
  },
  {
    value: "2786",
    inputValue:
      "Saúde e beleza > Limpeza e cuidado de joalharia > Suportes de joalharia",
  },
  {
    value: "2787",
    inputValue:
      "Saúde e beleza > Limpeza e cuidado de joalharia > Limpadores a vapor de joalharia",
  },
  {
    value: "2788",
    inputValue:
      "Saúde e beleza > Limpeza e cuidado de joalharia > Kits de reparação de relógios",
  },
  { value: "2789", inputValue: "Saúde e beleza > Cuidado pessoal" },
  {
    value: "2790",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cuidado das costas",
  },
  {
    value: "2791",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado das costas > Almofadas de suporte lombar e das costas",
  },
  { value: "2792", inputValue: "Saúde e beleza > Cuidado pessoal > Cosmética" },
  {
    value: "2793",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cosmética > Banho e corpo",
  },
  {
    value: "2794",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Banho e corpo > Toalhitas higiénicas adulto",
  },
  {
    value: "2795",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Banho e corpo > Sabão em barra",
  },
  {
    value: "2796",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Banho e corpo > Aditivos para o banho",
  },
  {
    value: "2797",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Banho e corpo > Escovas para o banho",
  },
  {
    value: "2798",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Banho e corpo > Esponjas e lufas para o banho",
  },
  {
    value: "2799",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Banho e corpo > Lavagem de corpo",
  },
  {
    value: "2800",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Banho e corpo > Antisséticos e toalhitas de mãos",
  },
  {
    value: "2801",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Banho e corpo > Sabão líquido para as mãos",
  },
  {
    value: "2802",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Banho e corpo > Sabão em pó para as mãos",
  },
  {
    value: "2803",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Banho e corpo > Toucas de duche",
  },
  {
    value: "2804",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Conjuntos de oferta de banho e corpo",
  },
  {
    value: "2805",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Conjuntos cosméticos",
  },
  {
    value: "2806",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Produtos de limpeza para equipamento de cosmética",
  },
  {
    value: "2807",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética",
  },
  {
    value: "2808",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem",
  },
  {
    value: "2809",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Fita adesiva dupla face para pálpebras",
  },
  {
    value: "2810",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Stencils de sobrancelhas",
  },
  {
    value: "2811",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Recargas para modelador de pestanas",
  },
  {
    value: "2812",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Modeladores de pestanas",
  },
  {
    value: "2813",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Espelhos de rosto",
  },
  {
    value: "2814",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Papel de absorção facial",
  },
  {
    value: "2815",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Acessórios para pestanas falsas",
  },
  {
    value: "2816",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Acessórios para pestanas falsas > Adesivos para pestanas falsas",
  },
  {
    value: "2817",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Acessórios para pestanas falsas > Aplicadores de pestanas falsas",
  },
  {
    value: "2818",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Acessórios para pestanas falsas > Removedor de pestanas falsas",
  },
  {
    value: "2819",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Escovas de maquilhagem",
  },
  {
    value: "2820",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Esponjas de maquilhagem",
  },
  {
    value: "2821",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento de maquilhagem > Estojos e paletes de maquilhagem recarregáveis",
  },
  {
    value: "2822",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento para as unhas",
  },
  {
    value: "2823",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento para as unhas > Empurra-cutículas",
  },
  {
    value: "2824",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento para as unhas > Tesouras para cutículas",
  },
  {
    value: "2825",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento para as unhas > Espaçadores de manicura e pedicura",
  },
  {
    value: "2826",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento para as unhas > Conjuntos de ferramenta de manicura",
  },
  {
    value: "2827",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento para as unhas > Esponjas para unhas",
  },
  {
    value: "2828",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento para as unhas > Corta-unhas",
  },
  {
    value: "2829",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento para as unhas > Acessórios para limar as unhas",
  },
  {
    value: "2830",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento para as unhas > Dispositivos para limar as unhas",
  },
  {
    value: "2831",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento para as unhas > Secadores de unhas",
  },
  {
    value: "2832",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Equipamento para as unhas > Limas para unhas",
  },
  {
    value: "2833",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Ferramentas de cuidado da pele",
  },
  {
    value: "2834",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Ferramentas de cuidado da pele > Saunas faciais",
  },
  {
    value: "2835",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Ferramentas de cuidado da pele > Limas para pés",
  },
  {
    value: "2836",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Ferramentas de cuidado da pele > Loções e aplicadores de creme solar",
  },
  {
    value: "2837",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Ferramentas de cuidado da pele > Pedra-pomes",
  },
  {
    value: "2838",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Ferramentas de cuidado da pele > Extratores de cuidados da pele",
  },
  {
    value: "2839",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Ferramentas de cuidado da pele > Rolos de cuidados da pele",
  },
  {
    value: "2840",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Ferramentas de cuidado da pele > Cabeças de escovas de limpeza da pele",
  },
  {
    value: "2841",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Equipamento de cosmética > Ferramentas de cuidado da pele > Sistemas e escovas de limpeza da pele",
  },
  {
    value: "2842",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem",
  },
  {
    value: "2843",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem corporal",
  },
  {
    value: "2844",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem corporal > Corpo e glitter de corpo",
  },
  {
    value: "2845",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem corporal > Tinta e base corporal",
  },
  {
    value: "2846",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Trajes e maquilhagem de palco",
  },
  {
    value: "2847",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de olhos",
  },
  {
    value: "2848",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de olhos > Prime de olhos",
  },
  {
    value: "2849",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de olhos > Sombras de olhos",
  },
  {
    value: "2850",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de olhos > Intensificadores de sobrancelhas",
  },
  {
    value: "2851",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de olhos > Eyeliner",
  },
  {
    value: "2852",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de olhos > Pestanas falsas",
  },
  {
    value: "2853",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de olhos > Tratamentos de crescimento de pestanas e sobrancelhas",
  },
  {
    value: "2854",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de olhos > Máscara",
  },
  {
    value: "2855",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de olhos > Primer de máscara",
  },
  {
    value: "2856",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de rosto",
  },
  {
    value: "2857",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de rosto > Blushes e bronzeadores",
  },
  {
    value: "2858",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de rosto > Pó de rosto",
  },
  {
    value: "2859",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de rosto > Primer de rosto",
  },
  {
    value: "2860",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de rosto > Bases e corretores",
  },
  {
    value: "2861",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem de rosto > Destacadores e iluminizadores",
  },
  {
    value: "2862",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem para os lábios",
  },
  {
    value: "2863",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem para os lábios > Maquilhagem para os lábios e bochechas",
  },
  {
    value: "2864",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem para os lábios > Gloss de lábios",
  },
  {
    value: "2865",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem para os lábios > Delineador de lábios",
  },
  {
    value: "2866",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem para os lábios > Primer de lábios",
  },
  {
    value: "2867",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Maquilhagem para os lábios > Batom",
  },
  {
    value: "2868",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Sprays de acabamento de maquilhagem",
  },
  {
    value: "2869",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Maquilhagem > Tatuagens temporárias",
  },
  {
    value: "2870",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidados das unhas",
  },
  {
    value: "2871",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidados das unhas > Creme e óleo de cutículas",
  },
  {
    value: "2872",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidados das unhas > Unhas falsas",
  },
  {
    value: "2873",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidados das unhas > Cola de manicura",
  },
  {
    value: "2874",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidados das unhas > Kits e acessórios de Nail Art",
  },
  {
    value: "2875",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidados das unhas > Líquidos e sprays de secagem de verniz",
  },
  {
    value: "2876",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidados das unhas > Removedores de verniz",
  },
  {
    value: "2877",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidados das unhas > Solventes de verniz",
  },
  {
    value: "2878",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidados das unhas > Artigos para limar as unhas",
  },
  {
    value: "2879",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Perfume e colónia",
  },
  {
    value: "2880",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele",
  },
  {
    value: "2881",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Tratamentos e kits para o acne",
  },
  {
    value: "2882",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Kits de tratamento antienvelhecimento",
  },
  {
    value: "2883",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Óleo corporal",
  },
  {
    value: "2884",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Pó corporal",
  },
  {
    value: "2885",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Folhas comprimidas de máscara de cuidados da pele",
  },
  {
    value: "2886",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Produtos de limpeza facial",
  },
  {
    value: "2887",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Kits de limpeza facial",
  },
  {
    value: "2888",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Bandas faciais para os poros",
  },
  {
    value: "2889",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Tratamentos e bálsamos para os lábios",
  },
  {
    value: "2890",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Tratamentos e bálsamos para os lábios > Bálsamos para os lábios",
  },
  {
    value: "2891",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Tratamentos e bálsamos para os lábios > Tratamentos para os lábios médicos",
  },
  {
    value: "2892",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Loção e hidratante",
  },
  {
    value: "2893",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Removedores de maquilhagem",
  },
  {
    value: "2894",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Vaselina",
  },
  {
    value: "2895",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Máscaras e peels de cuidado da pele",
  },
  {
    value: "2896",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Repelente de insetos para aplicar na pele",
  },
  {
    value: "2897",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Proteção solar",
  },
  {
    value: "2898",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Produtos de bronzeamento",
  },
  {
    value: "2899",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Produtos de bronzeamento > Autobronzeador",
  },
  {
    value: "2900",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Produtos de bronzeamento > Óleo e loção de bronzeamento",
  },
  {
    value: "2901",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Tónicos e adstringentes",
  },
  {
    value: "2902",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Tónicos e adstringentes > Adstringentes",
  },
  {
    value: "2903",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Tónicos e adstringentes > Tónicos",
  },
  {
    value: "2904",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cosmética > Cuidado da pele > Removedores de verrugas",
  },
  {
    value: "2905",
    inputValue: "Saúde e beleza > Cuidado pessoal > Bolas de algodão",
  },
  {
    value: "2906",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cotonetes de algodão",
  },
  {
    value: "2907",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Desodorizantes e antiperspirantes",
  },
  {
    value: "2908",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Desodorizantes e antiperspirantes > Antiperspirantes",
  },
  {
    value: "2909",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Desodorizantes e antiperspirantes > Desodorizantes",
  },
  {
    value: "2910",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cuidados dos ouvidos",
  },
  {
    value: "2911",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos ouvidos > Velas para os ouvidos",
  },
  {
    value: "2912",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos ouvidos > Gotas para os ouvidos",
  },
  {
    value: "2913",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos ouvidos > Secadores de ouvidos",
  },
  {
    value: "2914",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos ouvidos > Colheres e removedores para os ouvidos",
  },
  {
    value: "2915",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos ouvidos > Seringas para os ouvidos",
  },
  {
    value: "2916",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos ouvidos > Kits de remoção de cera dos ouvidos",
  },
  {
    value: "2917",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos ouvidos > Distribuidores de tampões de ouvidos",
  },
  {
    value: "2918",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos ouvidos > Tampões de ouvidos",
  },
  {
    value: "2919",
    inputValue: "Saúde e beleza > Cuidado pessoal > Kits e artigos para enemas",
  },
  {
    value: "2920",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Artigos sanitários femininos",
  },
  {
    value: "2921",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Artigos sanitários femininos > Desodorizantes femininos",
  },
  {
    value: "2922",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Artigos sanitários femininos > Duches e cremes femininos",
  },
  {
    value: "2923",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Artigos sanitários femininos > Compressas e protetores femininos",
  },
  {
    value: "2924",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Artigos sanitários femininos > Copos menstruais",
  },
  {
    value: "2925",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Artigos sanitários femininos > Tampões",
  },
  {
    value: "2926",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cuidados dos pés",
  },
  {
    value: "2927",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos pés > Artigos para cuidado dos joanetes",
  },
  {
    value: "2928",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos pés > Artigos para cuidado de calos e calosidades",
  },
  {
    value: "2929",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos pés > Removedores de odores dos pés",
  },
  {
    value: "2930",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos pés > Palmilhas e protetores",
  },
  {
    value: "2931",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidados dos pés > Espaçadores de dedos dos pés",
  },
  {
    value: "2932",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo",
  },
  {
    value: "2933",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Kits de cuidado do cabelo",
  },
  {
    value: "2934",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Coloração do cabelo",
  },
  {
    value: "2935",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Removedores de coloração do cabelo",
  },
  {
    value: "2936",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Acessórios de coloração do cabelo",
  },
  {
    value: "2937",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Corretores de perda do cabelo",
  },
  {
    value: "2938",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Tratamentos de perda de cabelo",
  },
  {
    value: "2939",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Alisadores e permanentes de cabelo",
  },
  {
    value: "2940",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Tesouras para o cabelo",
  },
  {
    value: "2941",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Vaporizadores e toucas térmicas para o cabelo",
  },
  {
    value: "2942",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Produtos para pentear o cabelo",
  },
  {
    value: "2943",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Acessórios para pentear o cabelo",
  },
  {
    value: "2944",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Acessórios para pentear o cabelo > Molas e clipes para enrolar o cabelo",
  },
  {
    value: "2945",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Acessórios para pentear o cabelo > Acessórios para secador de cabelo",
  },
  {
    value: "2946",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Acessórios para pentear o cabelo > Acessórios para alisadores de cabelo",
  },
  {
    value: "2947",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Equipamento para pentear o cabelo",
  },
  {
    value: "2948",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Equipamento para pentear o cabelo > Pentes e escovas",
  },
  {
    value: "2949",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Equipamento para pentear o cabelo > Ferros de enrolar o cabelo",
  },
  {
    value: "2950",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Equipamento para pentear o cabelo > Dispositivos para enrolar o cabelo",
  },
  {
    value: "2951",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Equipamento para pentear o cabelo > Secadores do cabelo",
  },
  {
    value: "2952",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Equipamento para pentear o cabelo > Alisadores de cabelo",
  },
  {
    value: "2953",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Equipamento para pentear o cabelo > Conjuntos de dispositivos para pentear o cabelo",
  },
  {
    value: "2954",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Champô e condicionador",
  },
  {
    value: "2955",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Champô e condicionador > Condicionadores",
  },
  {
    value: "2956",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Champô e condicionador > Champô",
  },
  {
    value: "2957",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado do cabelo > Champô e condicionador > Conjuntos de champô e acondicionador",
  },
  {
    value: "2958",
    inputValue: "Saúde e beleza > Cuidado pessoal > Massagem e relaxamento",
  },
  {
    value: "2959",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Massagem e relaxamento > Esfrega-costas",
  },
  {
    value: "2960",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Massagem e relaxamento > Almofadas para os olhos",
  },
  {
    value: "2961",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Massagem e relaxamento > Cadeiras de massagem",
  },
  {
    value: "2962",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Massagem e relaxamento > Óleo de massagem",
  },
  {
    value: "2963",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Massagem e relaxamento > Aquecedores de pedras de massagem",
  },
  {
    value: "2964",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Massagem e relaxamento > Pedras de massagem",
  },
  {
    value: "2965",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Massagem e relaxamento > Mesas de massagem",
  },
  {
    value: "2966",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Massagem e relaxamento > Massajadores",
  },
  {
    value: "2967",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Massagem e relaxamento > Massajadores > Massajadores elétricos",
  },
  {
    value: "2968",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Massagem e relaxamento > Massajadores > Equipamento de massagem manual",
  },
  {
    value: "2969",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Massagem e relaxamento > Massajadores > Almofadas de massagem",
  },
  {
    value: "2970",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cuidado oral",
  },
  {
    value: "2971",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Spray para o hálito",
  },
  {
    value: "2972",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cuidado oral > Fio dental",
  },
  {
    value: "2973",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Protetores bucais",
  },
  {
    value: "2974",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Pontas de substituição de jato de água dentário",
  },
  {
    value: "2975",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Jatos de água dentário",
  },
  {
    value: "2976",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Adesivos para dentadura",
  },
  {
    value: "2977",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Produtos de limpeza para dentadura",
  },
  {
    value: "2978",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Kits de reparação para dentadura",
  },
  {
    value: "2979",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cuidado oral > Dentaduras",
  },
  {
    value: "2980",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Estimuladores das gengivas",
  },
  {
    value: "2981",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cuidado oral > Elixires",
  },
  {
    value: "2982",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Estojos de aparelhos ortodônticos",
  },
  {
    value: "2983",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Jatos dentários",
  },
  {
    value: "2984",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Branqueadores dentários",
  },
  {
    value: "2985",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Raspadores de língua",
  },
  {
    value: "2986",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Acessórios de escovas",
  },
  {
    value: "2987",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Acessórios de escovas > Tampas de escovas de dentes",
  },
  {
    value: "2988",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Acessórios de escovas > Cabeças de substituição de escovas de dentes",
  },
  {
    value: "2989",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Acessórios de escovas > Antisséticos de escovas de dentes",
  },
  {
    value: "2990",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Escovas de dentes",
  },
  {
    value: "2991",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Pastas de dentes",
  },
  {
    value: "2992",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Espremedores e distribuidores de pasta dos dentes",
  },
  {
    value: "2993",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado oral > Escovilhões dentários",
  },
  {
    value: "2994",
    inputValue: "Saúde e beleza > Cuidado pessoal > Lubrificantes íntimos",
  },
  {
    value: "2995",
    inputValue: "Saúde e beleza > Cuidado pessoal > Depilação e barbear",
  },
  {
    value: "2996",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Aftershave",
  },
  {
    value: "2997",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Descolorante corporal e facial",
  },
  {
    value: "2998",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Acessórios de máquinas de barbear",
  },
  {
    value: "2999",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Máquinas de barbear",
  },
  {
    value: "3000",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Acessórios de máquinas de cortar o cabelo",
  },
  {
    value: "3001",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Máquinas de cortar o cabelo",
  },
  {
    value: "3002",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Remoção de pelo",
  },
  {
    value: "3003",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Remoção de pelo > Depilatórios",
  },
  {
    value: "3004",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Remoção de pelo > Dispositivos de eletrólise",
  },
  {
    value: "3005",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Remoção de pelo > Depiladoras",
  },
  {
    value: "3006",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Remoção de pelo > Aquecedores de cera para depilação",
  },
  {
    value: "3007",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Remoção de pelo > Dispositivos de depilação a laser e IPL",
  },
  {
    value: "3008",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Remoção de pelo > Artigos e kits de remoção com cera",
  },
  {
    value: "3009",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Lâminas de barbear e recargas",
  },
  {
    value: "3010",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Taças e canecas de barbear",
  },
  {
    value: "3011",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Escovas de barbear",
  },
  {
    value: "3012",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Creme de barbear",
  },
  {
    value: "3013",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Kits de barbear",
  },
  {
    value: "3014",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Depilação e barbear > Lápis hemostáticos",
  },
  {
    value: "3015",
    inputValue: "Saúde e beleza > Cuidado pessoal > Auxiliares de sono",
  },
  {
    value: "3016",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Auxiliares de sono > Máscaras paras os olhos",
  },
  {
    value: "3017",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Auxiliares de sono > Auxiliares para apneia no sono e ressonar",
  },
  {
    value: "3018",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Auxiliares de sono > Almofadas de viagem",
  },
  {
    value: "3019",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Auxiliares de sono > Máquinas de ruído branco",
  },
  {
    value: "3020",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Tendas de bronzeamento a jato",
  },
  {
    value: "3021",
    inputValue: "Saúde e beleza > Cuidado pessoal > Camas de bronzeamento",
  },
  { value: "3022", inputValue: "Saúde e beleza > Cuidado pessoal > Pinças" },
  {
    value: "3023",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cuidado ocular",
  },
  {
    value: "3024",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Cuidado de lentes de contacto",
  },
  {
    value: "3025",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Cuidado de lentes de contacto > Kits de cuidado de lentes de contacto",
  },
  {
    value: "3026",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Cuidado de lentes de contacto > Estojos de lentes de contacto",
  },
  {
    value: "3027",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Cuidado de lentes de contacto > Soluções de lentes de contacto",
  },
  {
    value: "3028",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Lentes de contacto",
  },
  {
    value: "3029",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Lubrificantes e gotas oculares",
  },
  {
    value: "3030",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Lentes de óculos",
  },
  {
    value: "3031",
    inputValue: "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Óculos",
  },
  {
    value: "3032",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Acessórios para óculos",
  },
  {
    value: "3033",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Acessórios para óculos > Suportes e estojos para óculos",
  },
  {
    value: "3034",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Acessórios para óculos > Soluções de limpeza de lentes de óculos",
  },
  {
    value: "3035",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Acessórios para óculos > Peças de substituição de óculos",
  },
  {
    value: "3036",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Acessórios para óculos > Correias e fitas para óculos",
  },
  {
    value: "3037",
    inputValue:
      "Saúde e beleza > Cuidado pessoal > Cuidado ocular > Lentes de óculos de sol",
  },
  { value: "3038", inputValue: "Casa e jardim" },
  { value: "3039", inputValue: "Casa e jardim > Acessórios de casa de banho" },
  {
    value: "3040",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Suportes de casa de banho",
  },
  {
    value: "3041",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Tapetes e carpetes de casa de banho",
  },
  {
    value: "3042",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Almofadas de casa de banho",
  },
  {
    value: "3043",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Suportes de acessório de casa de banho",
  },
  {
    value: "3044",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Conjuntos de acessórios de casa de banho",
  },
  {
    value: "3045",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Suportes de lenços faciais",
  },
  {
    value: "3046",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Acessórios de secadores das mãos",
  },
  {
    value: "3047",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Secadores das mãos",
  },
  {
    value: "3048",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Armários de medicamentos",
  },
  {
    value: "3049",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Cabides para roupão",
  },
  {
    value: "3050",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Pegas de segurança",
  },
  {
    value: "3051",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Anéis de cortina de duche",
  },
  {
    value: "3052",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Cortinas de duche",
  },
  {
    value: "3053",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Varões de casa de banho",
  },
  {
    value: "3054",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Distribuidores de sabão e loção",
  },
  {
    value: "3055",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Suportes e pratos para sabonete",
  },
  {
    value: "3056",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Cabeças de substituição de piaçaba de casa de banho",
  },
  {
    value: "3057",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Suportes e piaçabas de casa de banho",
  },
  {
    value: "3058",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Suporte de papel de casa de banho",
  },
  {
    value: "3059",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Suportes de escovas de dentes",
  },
  {
    value: "3060",
    inputValue:
      "Casa e jardim > Acessórios de casa de banho > Suportes de toalhas e toalheiros",
  },
  {
    value: "3061",
    inputValue: "Casa e jardim > Segurança na empresa e em casa",
  },
  {
    value: "3062",
    inputValue:
      "Casa e jardim > Segurança na empresa e em casa > Câmaras de vigilância falsas",
  },
  {
    value: "3063",
    inputValue:
      "Casa e jardim > Segurança na empresa e em casa > Sistemas de alarme domésticos",
  },
  {
    value: "3064",
    inputValue:
      "Casa e jardim > Segurança na empresa e em casa > Sensores de movimento",
  },
  {
    value: "3065",
    inputValue:
      "Casa e jardim > Segurança na empresa e em casa > Espelhos de segurança e proteção",
  },
  {
    value: "3066",
    inputValue:
      "Casa e jardim > Segurança na empresa e em casa > Luzes de segurança",
  },
  {
    value: "3067",
    inputValue:
      "Casa e jardim > Segurança na empresa e em casa > Monitores e gravadores de segurança",
  },
  {
    value: "3068",
    inputValue:
      "Casa e jardim > Segurança na empresa e em casa > Acessórios de segurança",
  },
  {
    value: "3069",
    inputValue:
      "Casa e jardim > Segurança na empresa e em casa > Cofres de segurança",
  },
  {
    value: "3070",
    inputValue:
      "Casa e jardim > Segurança na empresa e em casa > Sensores de sistema de segurança",
  },
  { value: "3071", inputValue: "Casa e jardim > Decoração" },
  {
    value: "3072",
    inputValue: "Casa e jardim > Decoração > Sinais de placas de endereço",
  },
  { value: "3073", inputValue: "Casa e jardim > Decoração > Flora artificial" },
  {
    value: "3074",
    inputValue: "Casa e jardim > Decoração > Comida artificial",
  },
  {
    value: "3075",
    inputValue: "Casa e jardim > Decoração > Trabalhos artísticos",
  },
  {
    value: "3076",
    inputValue:
      "Casa e jardim > Decoração > Trabalhos artísticos > Tapeçaria decorativa",
  },
  {
    value: "3077",
    inputValue:
      "Casa e jardim > Decoração > Trabalhos artísticos > Pósteres, impressões e trabalhos de arte visual",
  },
  {
    value: "3078",
    inputValue:
      "Casa e jardim > Decoração > Trabalhos artísticos > Esculturas e estátuas",
  },
  {
    value: "3079",
    inputValue: "Casa e jardim > Decoração > Almofadas de encosto de costas",
  },
  { value: "3080", inputValue: "Casa e jardim > Decoração > Cestos" },
  {
    value: "3081",
    inputValue:
      "Casa e jardim > Decoração > Acessórios para comedouros de pássaros e vida selvagem",
  },
  {
    value: "3082",
    inputValue:
      "Casa e jardim > Decoração > Comedouros de pássaros e vida selvagem",
  },
  {
    value: "3083",
    inputValue:
      "Casa e jardim > Decoração > Comedouros de pássaros e vida selvagem > Comedouros de pássaros",
  },
  {
    value: "3084",
    inputValue:
      "Casa e jardim > Decoração > Comedouros de pássaros e vida selvagem > Comedouros para borboletas",
  },
  {
    value: "3085",
    inputValue:
      "Casa e jardim > Decoração > Comedouros de pássaros e vida selvagem > Comedouros para esquilos",
  },
  {
    value: "3086",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de casas para pássaros e vida selvagem",
  },
  {
    value: "3087",
    inputValue:
      "Casa e jardim > Decoração > Casas para pássaros e vida selvagem",
  },
  {
    value: "3088",
    inputValue:
      "Casa e jardim > Decoração > Casas para pássaros e vida selvagem > Casas para morcegos",
  },
  {
    value: "3089",
    inputValue:
      "Casa e jardim > Decoração > Casas para pássaros e vida selvagem > Casas para pássaros",
  },
  {
    value: "3090",
    inputValue:
      "Casa e jardim > Decoração > Casas para pássaros e vida selvagem > Casas para borboletas",
  },
  {
    value: "3091",
    inputValue: "Casa e jardim > Decoração > Acessórios para banho de pássaros",
  },
  { value: "3092", inputValue: "Casa e jardim > Decoração > Porta-livros" },
  {
    value: "3093",
    inputValue: "Casa e jardim > Decoração > Moldes de papelão",
  },
  {
    value: "3094",
    inputValue:
      "Casa e jardim > Decoração > Almofadas e coxins de sofás e cadeiras",
  },
  { value: "3095", inputValue: "Casa e jardim > Decoração > Peças de relógio" },
  { value: "3096", inputValue: "Casa e jardim > Decoração > Relógios" },
  {
    value: "3097",
    inputValue: "Casa e jardim > Decoração > Relógios > Despertadores",
  },
  {
    value: "3098",
    inputValue:
      "Casa e jardim > Decoração > Relógios > Relógios de secretária e estante",
  },
  {
    value: "3099",
    inputValue: "Casa e jardim > Decoração > Relógios > Relógios de chão e avô",
  },
  {
    value: "3100",
    inputValue: "Casa e jardim > Decoração > Relógios > Relógios de parede",
  },
  {
    value: "3101",
    inputValue: "Casa e jardim > Decoração > Cabides para chapéus e casacos",
  },
  {
    value: "3102",
    inputValue: "Casa e jardim > Decoração > Sinos decorativos",
  },
  {
    value: "3103",
    inputValue: "Casa e jardim > Decoração > Garrafas decorativas",
  },
  {
    value: "3104",
    inputValue: "Casa e jardim > Decoração > Taças decorativas",
  },
  {
    value: "3105",
    inputValue: "Casa e jardim > Decoração > Jarros decorativos",
  },
  {
    value: "3106",
    inputValue: "Casa e jardim > Decoração > Placas decorativas",
  },
  {
    value: "3107",
    inputValue: "Casa e jardim > Decoração > Pratos decorativos",
  },
  {
    value: "3108",
    inputValue: "Casa e jardim > Decoração > Tabuleiros decorativos",
  },
  { value: "3109", inputValue: "Casa e jardim > Decoração > Tapetes de porta" },
  {
    value: "3110",
    inputValue: "Casa e jardim > Decoração > Caçadores de sonhos",
  },
  { value: "3111", inputValue: "Casa e jardim > Decoração > Flores secas" },
  { value: "3112", inputValue: "Casa e jardim > Decoração > Ecosferas" },
  { value: "3113", inputValue: "Casa e jardim > Decoração > Estatuetas" },
  { value: "3114", inputValue: "Casa e jardim > Decoração > Finials" },
  {
    value: "3115",
    inputValue: "Casa e jardim > Decoração > Acessórios de bandeiras e birutas",
  },
  {
    value: "3116",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de bandeiras e birutas > Iluminação de bandeiras e postes de birutas",
  },
  {
    value: "3117",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de bandeiras e birutas > Kits e equipamento de montagem de bandeiras e postes de birutas",
  },
  {
    value: "3118",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de bandeiras e birutas > Bandeiras e postes de birutas",
  },
  {
    value: "3119",
    inputValue: "Casa e jardim > Decoração > Bandeiras e birutas",
  },
  { value: "3120", inputValue: "Casa e jardim > Decoração > Velas sem chama" },
  { value: "3121", inputValue: "Casa e jardim > Decoração > Fontes e lagos" },
  {
    value: "3122",
    inputValue:
      "Casa e jardim > Decoração > Fontes e lagos > Acessórios de fontes e lagos",
  },
  {
    value: "3123",
    inputValue:
      "Casa e jardim > Decoração > Fontes e lagos > Fontes e cascatas",
  },
  {
    value: "3124",
    inputValue: "Casa e jardim > Decoração > Fontes e lagos > Lagos",
  },
  {
    value: "3125",
    inputValue: "Casa e jardim > Decoração > Jardim e pedras decorativas",
  },
  {
    value: "3126",
    inputValue: "Casa e jardim > Decoração > Gráficos de crescimento",
  },
  {
    value: "3127",
    inputValue:
      "Casa e jardim > Decoração > Autocolantes de decoração para a casa",
  },
  {
    value: "3128",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de fragrância para a casa",
  },
  {
    value: "3129",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de fragrância para a casa > Velas e queimadores de óleo",
  },
  {
    value: "3130",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de fragrância para a casa > Suportes de velas",
  },
  {
    value: "3131",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de fragrância para a casa > Abafadores de velas",
  },
  {
    value: "3132",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de fragrância para a casa > Suportes de incenso",
  },
  {
    value: "3133",
    inputValue: "Casa e jardim > Decoração > Fragrâncias para a casa",
  },
  {
    value: "3134",
    inputValue:
      "Casa e jardim > Decoração > Fragrâncias para a casa > Ambientadores",
  },
  {
    value: "3135",
    inputValue: "Casa e jardim > Decoração > Fragrâncias para a casa > Velas",
  },
  {
    value: "3136",
    inputValue:
      "Casa e jardim > Decoração > Fragrâncias para a casa > Óleo de fragrância",
  },
  {
    value: "3137",
    inputValue: "Casa e jardim > Decoração > Fragrâncias para a casa > Incenso",
  },
  {
    value: "3138",
    inputValue:
      "Casa e jardim > Decoração > Fragrâncias para a casa > Pot-pourri",
  },
  {
    value: "3139",
    inputValue:
      "Casa e jardim > Decoração > Fragrâncias para a casa > Tartes de cera",
  },
  { value: "3140", inputValue: "Casa e jardim > Decoração > Ampulhetas" },
  {
    value: "3141",
    inputValue: "Casa e jardim > Decoração > Números e letras de casa",
  },
  {
    value: "3142",
    inputValue: "Casa e jardim > Decoração > Ornamentos e esculturas de jardim",
  },
  {
    value: "3143",
    inputValue: "Casa e jardim > Decoração > Tampas de caixas de correio",
  },
  {
    value: "3144",
    inputValue: "Casa e jardim > Decoração > Acessórios de caixas de correio",
  },
  {
    value: "3145",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de caixas de correio > Coberturas de caixas de correio",
  },
  {
    value: "3146",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de caixas de correio > Caixas de caixas de correio",
  },
  {
    value: "3147",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de caixas de correio > Bandeiras de caixas de correio",
  },
  {
    value: "3148",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de caixas de correio > Postes de caixas de correio",
  },
  {
    value: "3149",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de caixas de correio > Portas de substituição de caixas de correio",
  },
  {
    value: "3150",
    inputValue: "Casa e jardim > Decoração > Caixas de correio",
  },
  { value: "3151", inputValue: "Casa e jardim > Decoração > Espelhos" },
  { value: "3152", inputValue: "Casa e jardim > Decoração > Caixas de música" },
  {
    value: "3153",
    inputValue: "Casa e jardim > Decoração > Anéis de guardanapos",
  },
  {
    value: "3154",
    inputValue: "Casa e jardim > Decoração > Placas de avisos diversos",
  },
  { value: "3155", inputValue: "Casa e jardim > Decoração > Otomanas" },
  { value: "3156", inputValue: "Casa e jardim > Decoração > Molduras" },
  { value: "3157", inputValue: "Casa e jardim > Decoração > Mealheiros" },
  {
    value: "3158",
    inputValue: "Casa e jardim > Decoração > Correntes de chuva",
  },
  { value: "3159", inputValue: "Casa e jardim > Decoração > Pluviómetros" },
  {
    value: "3160",
    inputValue: "Casa e jardim > Decoração > Ímanes de frigorífico",
  },
  { value: "3161", inputValue: "Casa e jardim > Decoração > Tapetes" },
  {
    value: "3162",
    inputValue: "Casa e jardim > Decoração > Decorações sazonais e de férias",
  },
  {
    value: "3163",
    inputValue:
      "Casa e jardim > Decoração > Decorações sazonais e de férias > Calendários do advento",
  },
  {
    value: "3164",
    inputValue:
      "Casa e jardim > Decoração > Decorações sazonais e de férias > Saias de árvore de Natal",
  },
  {
    value: "3165",
    inputValue:
      "Casa e jardim > Decoração > Decorações sazonais e de férias > Suportes de árvore de Natal",
  },
  {
    value: "3166",
    inputValue:
      "Casa e jardim > Decoração > Decorações sazonais e de férias > Kits de decoração de ovos de Páscoa",
  },
  {
    value: "3167",
    inputValue:
      "Casa e jardim > Decoração > Decorações sazonais e de férias > Apoios e expositores de ornamentos festivos",
  },
  {
    value: "3168",
    inputValue:
      "Casa e jardim > Decoração > Decorações sazonais e de férias > Ganchos de ornamentos festivos",
  },
  {
    value: "3169",
    inputValue:
      "Casa e jardim > Decoração > Decorações sazonais e de férias > Ornamentos festivos",
  },
  {
    value: "3170",
    inputValue:
      "Casa e jardim > Decoração > Decorações sazonais e de férias > Suportes de meias de Natal",
  },
  {
    value: "3171",
    inputValue:
      "Casa e jardim > Decoração > Decorações sazonais e de férias > Meias de Natal",
  },
  {
    value: "3172",
    inputValue:
      "Casa e jardim > Decoração > Decorações sazonais e de férias > Bonecas tradicionais japonesas",
  },
  {
    value: "3173",
    inputValue:
      "Casa e jardim > Decoração > Decorações sazonais e de férias > Presépios",
  },
  {
    value: "3174",
    inputValue:
      "Casa e jardim > Decoração > Decorações sazonais e de férias > Acessórios e conjuntos de aldeia de Natal",
  },
  {
    value: "3175",
    inputValue: "Casa e jardim > Decoração > Molduras tipo vitrines",
  },
  {
    value: "3176",
    inputValue: "Casa e jardim > Decoração > Proteções de sofá",
  },
  { value: "3177", inputValue: "Casa e jardim > Decoração > Globos de neve" },
  {
    value: "3178",
    inputValue: "Casa e jardim > Decoração > Apanhadores de sol",
  },
  { value: "3179", inputValue: "Casa e jardim > Decoração > Relógios de sol" },
  {
    value: "3180",
    inputValue: "Casa e jardim > Decoração > Almofadas decorativas",
  },
  { value: "3181", inputValue: "Casa e jardim > Decoração > Baús" },
  {
    value: "3182",
    inputValue:
      "Casa e jardim > Decoração > Enchimentos para jarras e dispersão de mesa",
  },
  { value: "3183", inputValue: "Casa e jardim > Decoração > Jarras" },
  { value: "3184", inputValue: "Casa e jardim > Decoração > Papel de parede" },
  {
    value: "3185",
    inputValue:
      "Casa e jardim > Decoração > Cata-ventos e decoração de telhado",
  },
  {
    value: "3186",
    inputValue: "Casa e jardim > Decoração > Espanta espíritos",
  },
  { value: "3187", inputValue: "Casa e jardim > Decoração > Cataventos" },
  { value: "3188", inputValue: "Casa e jardim > Decoração > Ímanes de janela" },
  {
    value: "3189",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de tratamento de janelas",
  },
  {
    value: "3190",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de tratamento de janelas > Anéis de cortinas e reposteiros",
  },
  {
    value: "3191",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de tratamento de janelas > Varões de cortinas e reposteiros",
  },
  {
    value: "3192",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de tratamento de janelas > Borlas de cortinas e reposteiros",
  },
  {
    value: "3193",
    inputValue:
      "Casa e jardim > Decoração > Acessórios de tratamento de janelas > Peças de substituição de tratamento de janelas",
  },
  {
    value: "3194",
    inputValue: "Casa e jardim > Decoração > Tratamentos de janelas",
  },
  {
    value: "3195",
    inputValue:
      "Casa e jardim > Decoração > Tratamentos de janelas > Cortinas e reposteiros",
  },
  {
    value: "3196",
    inputValue:
      "Casa e jardim > Decoração > Tratamentos de janelas > Painéis de vitrais",
  },
  {
    value: "3197",
    inputValue:
      "Casa e jardim > Decoração > Tratamentos de janelas > Estores e venezianas",
  },
  {
    value: "3198",
    inputValue:
      "Casa e jardim > Decoração > Tratamentos de janelas > Películas de janelas",
  },
  {
    value: "3199",
    inputValue:
      "Casa e jardim > Decoração > Tratamentos de janelas > Caixilhos de janelas",
  },
  {
    value: "3200",
    inputValue:
      "Casa e jardim > Decoração > Tratamentos de janelas > Sanefas e cornijas de janelas",
  },
  {
    value: "3201",
    inputValue: "Casa e jardim > Decoração > Globos terrestres",
  },
  {
    value: "3202",
    inputValue: "Casa e jardim > Decoração > Coroas e grinaldas",
  },
  { value: "3203", inputValue: "Casa e jardim > Preparação de emergência" },
  {
    value: "3204",
    inputValue:
      "Casa e jardim > Preparação de emergência > Alarmes de terramotos",
  },
  {
    value: "3205",
    inputValue:
      "Casa e jardim > Preparação de emergência > Cobertores de emergência",
  },
  {
    value: "3206",
    inputValue:
      "Casa e jardim > Preparação de emergência > Kits de comida de emergência",
  },
  {
    value: "3207",
    inputValue:
      "Casa e jardim > Preparação de emergência > Kits e ferramentas de emergência",
  },
  {
    value: "3208",
    inputValue:
      "Casa e jardim > Preparação de emergência > Âncoras de mobiliário",
  },
  {
    value: "3209",
    inputValue: "Casa e jardim > Acessórios para lareiras e salamandras",
  },
  {
    value: "3210",
    inputValue:
      "Casa e jardim > Acessórios para lareiras e salamandras > Foles",
  },
  {
    value: "3211",
    inputValue:
      "Casa e jardim > Acessórios para lareiras e salamandras > Grelhas de lareiras e salamandras",
  },
  {
    value: "3212",
    inputValue:
      "Casa e jardim > Acessórios para lareiras e salamandras > Cães de lareira",
  },
  {
    value: "3213",
    inputValue:
      "Casa e jardim > Acessórios para lareiras e salamandras > Refletores de lareira",
  },
  {
    value: "3214",
    inputValue:
      "Casa e jardim > Acessórios para lareiras e salamandras > Ecrãs de lareira",
  },
  {
    value: "3215",
    inputValue:
      "Casa e jardim > Acessórios para lareiras e salamandras > Ferramentas de lareira",
  },
  {
    value: "3216",
    inputValue:
      "Casa e jardim > Acessórios para lareiras e salamandras > Lenha e combustível",
  },
  {
    value: "3217",
    inputValue:
      "Casa e jardim > Acessórios para lareiras e salamandras > Protetores de chão de lareira",
  },
  {
    value: "3218",
    inputValue:
      "Casa e jardim > Acessórios para lareiras e salamandras > Acessórios para transporte e armazenamento de lenha para lareira",
  },
  {
    value: "3219",
    inputValue:
      "Casa e jardim > Acessórios para lareiras e salamandras > Transporte e armazenamento de lenha para lareira",
  },
  {
    value: "3220",
    inputValue:
      "Casa e jardim > Acessórios para lareiras e salamandras > Ventiladores e lareiras de salamandras",
  },
  { value: "3221", inputValue: "Casa e jardim > Lareiras" },
  {
    value: "3222",
    inputValue:
      "Casa e jardim > Segurança em inundações, incêndios e fugas de gás",
  },
  {
    value: "3223",
    inputValue:
      "Casa e jardim > Segurança em inundações, incêndios e fugas de gás > Painéis de controlo de alarme de incêndio",
  },
  {
    value: "3224",
    inputValue:
      "Casa e jardim > Segurança em inundações, incêndios e fugas de gás > Alarmes de incêndio",
  },
  {
    value: "3225",
    inputValue:
      "Casa e jardim > Segurança em inundações, incêndios e fugas de gás > Armazenamento de equipamento e extintores",
  },
  {
    value: "3226",
    inputValue:
      "Casa e jardim > Segurança em inundações, incêndios e fugas de gás > Extintores",
  },
  {
    value: "3227",
    inputValue:
      "Casa e jardim > Segurança em inundações, incêndios e fugas de gás > Aspersores de incêndio",
  },
  {
    value: "3228",
    inputValue:
      "Casa e jardim > Segurança em inundações, incêndios e fugas de gás > Detetores de calor",
  },
  {
    value: "3229",
    inputValue:
      "Casa e jardim > Segurança em inundações, incêndios e fugas de gás > Detetores de fumo e monóxido de carbono",
  },
  {
    value: "3230",
    inputValue:
      "Casa e jardim > Segurança em inundações, incêndios e fugas de gás > Detetores de fumo e monóxido de carbono > Detetores de monóxido de carbono",
  },
  {
    value: "3231",
    inputValue:
      "Casa e jardim > Segurança em inundações, incêndios e fugas de gás > Detetores de fumo e monóxido de carbono > Detetores de fumo",
  },
  {
    value: "3232",
    inputValue:
      "Casa e jardim > Segurança em inundações, incêndios e fugas de gás > Detores de água e inundações",
  },
  {
    value: "3233",
    inputValue: "Casa e jardim > Acessórios de eletrodomésticos",
  },
  {
    value: "3234",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de ar condicionado",
  },
  {
    value: "3235",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de ar condicionado > Tampas de ar condicionado",
  },
  {
    value: "3236",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de ar condicionado > Filtros de ar condicionado",
  },
  {
    value: "3237",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de purificador de ar",
  },
  {
    value: "3238",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de purificador de ar > Filtros de purificador de ar",
  },
  {
    value: "3239",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de desumidificador",
  },
  {
    value: "3240",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de ventoinha",
  },
  {
    value: "3241",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de dispositivo de limpeza a vapor e do chão",
  },
  {
    value: "3242",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de fornos e caldeiras",
  },
  {
    value: "3243",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de radiador de calor",
  },
  {
    value: "3244",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de radiador de calor > Refletores de radiador de calor",
  },
  {
    value: "3245",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de humidificador",
  },
  {
    value: "3246",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de humidificador > Filtros de humidificador",
  },
  {
    value: "3247",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de equipamento para a roupa",
  },
  {
    value: "3248",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de equipamento para a roupa > Acessórios de dispositivo de vapor para a roupa",
  },
  {
    value: "3249",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de equipamento para a roupa > Acessórios de ferro de engomar",
  },
  {
    value: "3250",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de equipamento para a roupa > Acessórios de prensa de engomar a vapor",
  },
  {
    value: "3251",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de equipamento para a roupa > Acessórios de máquina de lavar e secar",
  },
  {
    value: "3252",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de aquecimento de exterior",
  },
  {
    value: "3253",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de aquecimento de exterior > Coberturas de aquecimento de exterior",
  },
  {
    value: "3254",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de vapor",
  },
  {
    value: "3255",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de dispositivo de aquecimento de água",
  },
  {
    value: "3256",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de dispositivo de aquecimento de água > Varetas de ânodo",
  },
  {
    value: "3257",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de dispositivo de aquecimento de água > Depósitos de água quente",
  },
  {
    value: "3258",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de dispositivo de aquecimento de água > Elementos de aquecimento de água",
  },
  {
    value: "3259",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de dispositivo de aquecimento de água > Tanques de expansão de aquecimento de água",
  },
  {
    value: "3260",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de dispositivo de aquecimento de água > Cárteres de aquecimento de água",
  },
  {
    value: "3261",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de dispositivo de aquecimento de água > Acumuladores de água quente",
  },
  {
    value: "3262",
    inputValue:
      "Casa e jardim > Acessórios de eletrodomésticos > Acessórios de dispositivo de aquecimento de água > Respiradouros de dispositivo de aquecimento de água",
  },
  { value: "3263", inputValue: "Casa e jardim > Eletrodomésticos" },
  {
    value: "3264",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático",
  },
  {
    value: "3265",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Sistemas de ar condicionado",
  },
  {
    value: "3266",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Purificadores de ar",
  },
  {
    value: "3267",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Desumidificadores",
  },
  {
    value: "3268",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Aquecedores de condutas",
  },
  {
    value: "3269",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Climatizadores por evaporação",
  },
  {
    value: "3270",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Ventoinhas",
  },
  {
    value: "3271",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Ventoinhas > Ventoinhas de teto",
  },
  {
    value: "3272",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Ventoinhas > Ventoinhas de secretária e de pé",
  },
  {
    value: "3273",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Ventoinhas > Ventoinhas e nebulizadores manuais",
  },
  {
    value: "3274",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Ventoinhas > Ventoinhas de ventilação",
  },
  {
    value: "3275",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Ventoinhas > Ventoinhas de montagem na parede",
  },
  {
    value: "3276",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Fornos e caldeiras",
  },
  {
    value: "3277",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Radiadores de aquecimento",
  },
  {
    value: "3278",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Humidificadores",
  },
  {
    value: "3279",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Sistema de nebulização de exterior",
  },
  {
    value: "3280",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Aquecedores de exterior",
  },
  {
    value: "3281",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Equipamento de controlo climático > Aquecedores",
  },
  {
    value: "3282",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Secadores de chão e carpetes",
  },
  {
    value: "3283",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Dispositivos de limpeza a vapor e de chão",
  },
  {
    value: "3284",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Dispositivos de limpeza a vapor e de chão > Dispositivos de aplicação de champô em carpetes",
  },
  {
    value: "3285",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Dispositivos de limpeza a vapor e de chão > Dispositivos de aplicação de vapor em carpetes",
  },
  {
    value: "3286",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Dispositivos de limpeza a vapor e de chão > Dispositivos para esfregar o chão",
  },
  {
    value: "3287",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Dispositivos de limpeza a vapor e de chão > Mopas a vapor",
  },
  {
    value: "3288",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Dispositivos de polimento do chão",
  },
  {
    value: "3289",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Dispositivos para secar futons",
  },
  {
    value: "3290",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Teclados e controlos de portões de garagem",
  },
  {
    value: "3291",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Dispositivos de abertura de portões de garagem",
  },
  {
    value: "3292",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Eletrodomésticos para a roupa",
  },
  {
    value: "3293",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Eletrodomésticos para a roupa > Secadores",
  },
  {
    value: "3294",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Eletrodomésticos para a roupa > Dispositivos de aplicação de vapor para a roupa",
  },
  {
    value: "3295",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Eletrodomésticos para a roupa > Ferros de engomar e sistemas de ferros de engomar",
  },
  {
    value: "3296",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Eletrodomésticos para a roupa > Unidades de combinação de roupa",
  },
  {
    value: "3297",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Eletrodomésticos para a roupa > Prensas de vapor",
  },
  {
    value: "3298",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Eletrodomésticos para a roupa > Máquinas de lavar",
  },
  {
    value: "3299",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Dispositivos de limpeza ultrassónica",
  },
  {
    value: "3300",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Dispositivos de limpeza a vapor",
  },
  {
    value: "3301",
    inputValue:
      "Casa e jardim > Eletrodomésticos > Removedores de papel de parede a vapor",
  },
  {
    value: "3302",
    inputValue: "Casa e jardim > Eletrodomésticos > Aquecedor de água",
  },
  { value: "3303", inputValue: "Casa e jardim > Artigos para o lar" },
  {
    value: "3304",
    inputValue:
      "Casa e jardim > Artigos para o lar > Revestimentos para gavetas e prateleiras",
  },
  {
    value: "3305",
    inputValue:
      "Casa e jardim > Artigos para o lar > Películas e passadeiras de proteção do pavimento",
  },
  {
    value: "3306",
    inputValue:
      "Casa e jardim > Artigos para o lar > Protetores do pavimento para mobília",
  },
  {
    value: "3307",
    inputValue: "Casa e jardim > Artigos para o lar > Tapetes para garagem",
  },
  {
    value: "3308",
    inputValue: "Casa e jardim > Artigos para o lar > Sacos do lixo",
  },
  {
    value: "3309",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar",
  },
  {
    value: "3310",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Cabos para vassoura e esfregona",
  },
  {
    value: "3311",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Cabeças para vassoura",
  },
  {
    value: "3312",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Vassouras",
  },
  {
    value: "3313",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Baldes",
  },
  {
    value: "3314",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Limpadores de alcatifa",
  },
  {
    value: "3315",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Luvas de limpeza",
  },
  {
    value: "3316",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Recargas para espanador",
  },
  {
    value: "3317",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Espanadores",
  },
  {
    value: "3318",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Apanhadores",
  },
  {
    value: "3319",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Protetores para tecido e estofos",
  },
  {
    value: "3320",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar",
  },
  {
    value: "3321",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza multifunções",
  },
  {
    value: "3322",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Detergentes para alcatifas",
  },
  {
    value: "3323",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos anticalcários e descalcificantes",
  },
  {
    value: "3324",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Detergente manual para louça",
  },
  {
    value: "3325",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza da máquina de lavar louça",
  },
  {
    value: "3326",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza para tecido e estofos",
  },
  {
    value: "3327",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza para o chão",
  },
  {
    value: "3328",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza para o pó e polimento",
  },
  {
    value: "3329",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza para vidro e superfícies",
  },
  {
    value: "3330",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza para vidro e superfícies > Produtos de limpeza para vidro",
  },
  {
    value: "3331",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza para vidro e superfícies > Produtos de limpeza multissuperfícies",
  },
  {
    value: "3332",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos desinfetantes",
  },
  {
    value: "3333",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza para forno e grelhador",
  },
  {
    value: "3334",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Removedores de odores de animais de estimação e nódoas",
  },
  {
    value: "3335",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Abrilhantadores",
  },
  {
    value: "3336",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza e polimento para aço inoxidável",
  },
  {
    value: "3337",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza para a sanita",
  },
  {
    value: "3338",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza para banheira e azulejos",
  },
  {
    value: "3339",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Produtos de limpeza para o lar > Produtos de limpeza para máquina de lavar a roupa",
  },
  {
    value: "3340",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Cabeças e recargas para esfregona",
  },
  {
    value: "3341",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Esfregonas",
  },
  {
    value: "3342",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Cabeças e recargas de escovas",
  },
  {
    value: "3343",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Escovas",
  },
  {
    value: "3344",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Comprar toalhas e panos limpeza polivalentes",
  },
  {
    value: "3345",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Esponjas e esfregões",
  },
  {
    value: "3346",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para o lar > Rodos",
  },
  {
    value: "3347",
    inputValue:
      "Casa e jardim > Artigos para o lar > Produtos de papel para o lar",
  },
  {
    value: "3348",
    inputValue:
      "Casa e jardim > Artigos para o lar > Produtos de papel para o lar > Toalhetes faciais",
  },
  {
    value: "3349",
    inputValue:
      "Casa e jardim > Artigos para o lar > Produtos de papel para o lar > Guardanapos de papel",
  },
  {
    value: "3350",
    inputValue:
      "Casa e jardim > Artigos para o lar > Produtos de papel para o lar > Toalhas de papel",
  },
  {
    value: "3351",
    inputValue:
      "Casa e jardim > Artigos para o lar > Produtos de papel para o lar > Papel higiénico",
  },
  {
    value: "3352",
    inputValue: "Casa e jardim > Artigos para o lar > Termómetros para casa",
  },
  {
    value: "3353",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa",
  },
  {
    value: "3354",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Lixívia",
  },
  {
    value: "3355",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Molas para a roupa",
  },
  {
    value: "3356",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Kits de limpeza a seco",
  },
  {
    value: "3357",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Estendais",
  },
  {
    value: "3358",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Ambientadores para tecidos",
  },
  {
    value: "3359",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Tira-borbotos",
  },
  {
    value: "3360",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Amaciadores para roupa e toalhetes para máquina de secar",
  },
  {
    value: "3361",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Removedores de nódoas",
  },
  {
    value: "3362",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Goma",
  },
  {
    value: "3363",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Protetores para vestuário",
  },
  {
    value: "3364",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Apoios de ferro de engomar",
  },
  {
    value: "3365",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Coberturas e esponjas para tábua de engomar",
  },
  {
    value: "3366",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Peças de substituição para tábua de engomar",
  },
  {
    value: "3367",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Tábuas de engomar",
  },
  {
    value: "3368",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Bolas para máquina de lavar roupa",
  },
  {
    value: "3369",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Cestos para roupa suja",
  },
  {
    value: "3370",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Detergente para a roupa",
  },
  {
    value: "3371",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Sacos e estruturas para roupa suja",
  },
  {
    value: "3372",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Rolos para remover pelo",
  },
  {
    value: "3373",
    inputValue:
      "Casa e jardim > Artigos para o lar > Artigos de limpeza para lavar roupa > Sprays antiestáticos e antivincos",
  },
  {
    value: "3374",
    inputValue: "Casa e jardim > Artigos para o lar > Absorventes de humidade",
  },
  {
    value: "3375",
    inputValue: "Casa e jardim > Artigos para o lar > Controlo de pragas",
  },
  {
    value: "3376",
    inputValue:
      "Casa e jardim > Artigos para o lar > Controlo de pragas > Mata-moscas",
  },
  {
    value: "3377",
    inputValue:
      "Casa e jardim > Artigos para o lar > Controlo de pragas > Armadilhas para controlo de pragas",
  },
  {
    value: "3378",
    inputValue:
      "Casa e jardim > Artigos para o lar > Controlo de pragas > Pesticidas",
  },
  {
    value: "3379",
    inputValue:
      "Casa e jardim > Artigos para o lar > Controlo de pragas > Repelentes",
  },
  {
    value: "3380",
    inputValue:
      "Casa e jardim > Artigos para o lar > Controlo de pragas > Repelentes > Repelentes para animais e animais de estimação",
  },
  {
    value: "3381",
    inputValue:
      "Casa e jardim > Artigos para o lar > Controlo de pragas > Repelentes > Repelentes de insetos",
  },
  {
    value: "3382",
    inputValue:
      "Casa e jardim > Artigos para o lar > Antiderrapantes para tapete",
  },
  {
    value: "3383",
    inputValue:
      "Casa e jardim > Artigos para o lar > Utensílios e cuidado de calçado",
  },
  {
    value: "3384",
    inputValue:
      "Casa e jardim > Artigos para o lar > Utensílios e cuidado de calçado > Calçadores de botas",
  },
  {
    value: "3385",
    inputValue:
      "Casa e jardim > Artigos para o lar > Utensílios e cuidado de calçado > Sacos para calçado",
  },
  {
    value: "3386",
    inputValue:
      "Casa e jardim > Artigos para o lar > Utensílios e cuidado de calçado > Escovas para calçado",
  },
  {
    value: "3387",
    inputValue:
      "Casa e jardim > Artigos para o lar > Utensílios e cuidado de calçado > Kits de cuidado de calçado",
  },
  {
    value: "3388",
    inputValue:
      "Casa e jardim > Artigos para o lar > Utensílios e cuidado de calçado > Secadores para calçado",
  },
  {
    value: "3389",
    inputValue:
      "Casa e jardim > Artigos para o lar > Utensílios e cuidado de calçado > Calçadeiras e auxiliares para calçar",
  },
  {
    value: "3390",
    inputValue:
      "Casa e jardim > Artigos para o lar > Utensílios e cuidado de calçado > Graxa para calçado",
  },
  {
    value: "3391",
    inputValue:
      "Casa e jardim > Artigos para o lar > Utensílios e cuidado de calçado > Graxas e ceras para calçado",
  },
  {
    value: "3392",
    inputValue:
      "Casa e jardim > Artigos para o lar > Utensílios e cuidado de calçado > Máquina de limpeza de sola do calçado",
  },
  {
    value: "3393",
    inputValue:
      "Casa e jardim > Artigos para o lar > Utensílios e cuidado de calçado > Tratamentos e tinta para calçado",
  },
  {
    value: "3394",
    inputValue:
      "Casa e jardim > Artigos para o lar > Utensílios e cuidado de calçado > Formas e moldes para calçado",
  },
  {
    value: "3395",
    inputValue: "Casa e jardim > Artigos para o lar > Degraus de escadas",
  },
  {
    value: "3396",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização",
  },
  {
    value: "3397",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Roupa e armazenamento de armário",
  },
  {
    value: "3398",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Roupa e armazenamento de armário > Valets de carregamento",
  },
  {
    value: "3399",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Roupa e armazenamento de armário > Organizadores e armário e prateleiras de vestuário",
  },
  {
    value: "3400",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Roupa e armazenamento de armário > Valets de vestuário",
  },
  {
    value: "3401",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Roupa e armazenamento de armário > Cabides",
  },
  {
    value: "3402",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Roupa e armazenamento de armário > Caixas para chapéus",
  },
  {
    value: "3403",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Roupa e armazenamento de armário > Prateleiras e organizadores para calçado",
  },
  {
    value: "3404",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Arcas para talheres",
  },
  {
    value: "3405",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Divisórias organizadoras para gavetas",
  },
  {
    value: "3406",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Sacos de armazenamento doméstico",
  },
  {
    value: "3407",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Organizadores de armazenamento doméstico",
  },
  {
    value: "3408",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Recipientes para armazenamento doméstico",
  },
  {
    value: "3409",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Gavetas para armazenamento doméstico",
  },
  {
    value: "3410",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Armazenamento de fotografias",
  },
  {
    value: "3411",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Armazenamento de fotografias > Álbuns de fotografias",
  },
  {
    value: "3412",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Armazenamento de fotografias > Caixas para guardar fotografias",
  },
  {
    value: "3413",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Ganchos e prateleiras para armazenamento",
  },
  {
    value: "3414",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Ganchos e prateleiras para armazenamento > Ganchos e prateleiras para tábua de engomar",
  },
  {
    value: "3415",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Ganchos e prateleiras para armazenamento > Porta guarda-chuvas",
  },
  {
    value: "3416",
    inputValue:
      "Casa e jardim > Artigos para o lar > Armazenamento e organização > Ganchos e prateleiras para armazenamento > Ganchos diversos",
  },
  {
    value: "3417",
    inputValue:
      "Casa e jardim > Artigos para o lar > Acessórios de compactador de lixo",
  },
  {
    value: "3418",
    inputValue: "Casa e jardim > Artigos para o lar > Eliminação de resíduos",
  },
  {
    value: "3419",
    inputValue:
      "Casa e jardim > Artigos para o lar > Eliminação de resíduos > Contentores",
  },
  {
    value: "3420",
    inputValue:
      "Casa e jardim > Artigos para o lar > Eliminação de resíduos > Contentores de resíduos perigosos",
  },
  {
    value: "3421",
    inputValue:
      "Casa e jardim > Artigos para o lar > Eliminação de resíduos > Contentores de reciclagem",
  },
  {
    value: "3422",
    inputValue:
      "Casa e jardim > Artigos para o lar > Eliminação de resíduos > Caixotes e cestos do lixo",
  },
  {
    value: "3423",
    inputValue:
      "Casa e jardim > Artigos para o lar > Acessórios para eliminação de resíduos",
  },
  {
    value: "3424",
    inputValue:
      "Casa e jardim > Artigos para o lar > Acessórios para eliminação de resíduos > Contentores de resíduos com rodas",
  },
  {
    value: "3425",
    inputValue:
      "Casa e jardim > Artigos para o lar > Acessórios para eliminação de resíduos > Vedações para contentor de resíduos",
  },
  {
    value: "3426",
    inputValue:
      "Casa e jardim > Artigos para o lar > Acessórios para eliminação de resíduos > Rótulos e sinalização para contentor de resíduos",
  },
  {
    value: "3427",
    inputValue:
      "Casa e jardim > Artigos para o lar > Acessórios para eliminação de resíduos > Tampas para contentor de resíduos",
  },
  {
    value: "3428",
    inputValue:
      "Casa e jardim > Artigos para o lar > Acessórios para eliminação de resíduos > Rodas para contentor de resíduos",
  },
  { value: "3429", inputValue: "Casa e jardim > Cozinha e refeições" },
  {
    value: "3430",
    inputValue: "Casa e jardim > Cozinha e refeições > Artigos para bar",
  },
  {
    value: "3431",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Fontes de absinto",
  },
  {
    value: "3432",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Dispensadores e torneiras de cerveja",
  },
  {
    value: "3433",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Cubos e varas para refrigerar bebidas",
  },
  {
    value: "3434",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Cubas e recipientes para refrigeração de bebidas",
  },
  {
    value: "3435",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Tampas para garrafas",
  },
  {
    value: "3436",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Rolhas para garrafas",
  },
  {
    value: "3437",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Suportes para bases de copos",
  },
  {
    value: "3438",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Bases de copos",
  },
  {
    value: "3439",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Conjuntos de ferramentas de artigos para bar e cocktail",
  },
  {
    value: "3440",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Shakers e ferramentas para cocktail",
  },
  {
    value: "3441",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Shakers e ferramentas para cocktail > Picadores de gelo de bar",
  },
  {
    value: "3442",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Shakers e ferramentas para cocktail > Saca-rolhas",
  },
  {
    value: "3443",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Shakers e ferramentas para cocktail > Shakers para cocktail",
  },
  {
    value: "3444",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Shakers e ferramentas para cocktail > Coadores para cocktail",
  },
  {
    value: "3445",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Shakers e ferramentas para cocktail > Esmagadores",
  },
  {
    value: "3446",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Saca-rolhas",
  },
  {
    value: "3447",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Decantadores",
  },
  {
    value: "3448",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Cortadores de película",
  },
  {
    value: "3449",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Arejadores de vinho",
  },
  {
    value: "3450",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Suportes de garrafa de vinho",
  },
  {
    value: "3451",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Artigos para bar > Marcadores de copo de vinho",
  },
  {
    value: "3452",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos",
  },
  {
    value: "3453",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para fazer bolos",
  },
  {
    value: "3454",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para fazer bolos > Conjuntos de utensílios para fazer bolos",
  },
  {
    value: "3455",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para fazer bolos > Folhas para fazer bolos e bolachas",
  },
  {
    value: "3456",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para fazer bolos > Panelas e formas para pão",
  },
  {
    value: "3457",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para fazer bolos > Tabuleiros para forno",
  },
  {
    value: "3458",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para fazer bolos > Formas para bolos",
  },
  {
    value: "3459",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para fazer bolos > Formas para muffins e doces",
  },
  {
    value: "3460",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para fazer bolos > Formas para tartes e quiches",
  },
  {
    value: "3461",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para fazer bolos > Formas para pizza",
  },
  {
    value: "3462",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para fazer bolos > Pedras para pizza",
  },
  {
    value: "3463",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para fazer bolos > Formas e pratos para suflé",
  },
  {
    value: "3464",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para fazer bolos > Assadeiras",
  },
  {
    value: "3465",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Acessórios de artigos para fazer bolos",
  },
  {
    value: "3466",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Acessórios de artigos para fazer bolos > Tapetes e revestimentos para bolos",
  },
  {
    value: "3467",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Acessórios de artigos para fazer bolos > Pesos para tartes",
  },
  {
    value: "3468",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Acessórios de artigos para fazer bolos > Tabuleiros para assadeiras",
  },
  {
    value: "3469",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar",
  },
  {
    value: "3470",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Conjuntos combinados de utensílios para cozinhar e fazer bolos",
  },
  {
    value: "3471",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Caçarolas",
  },
  {
    value: "3472",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Conjuntos de utensílios para cozinhar",
  },
  {
    value: "3473",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Frigideiras para crepes e blini",
  },
  {
    value: "3474",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Panelas duplas",
  },
  {
    value: "3475",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Caçarolas de ferro fundido",
  },
  {
    value: "3476",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Recipiente em barro para fermentação e pickles",
  },
  {
    value: "3477",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Grelhadores e frigideiras",
  },
  {
    value: "3478",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Prensas para grelhar",
  },
  {
    value: "3479",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Frigideiras para paelha",
  },
  {
    value: "3480",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Panelas de pressão e enlatadores de pressão",
  },
  {
    value: "3481",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Tachos",
  },
  {
    value: "3482",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Frigideiras para saltear",
  },
  {
    value: "3483",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Sertãs e frigideiras",
  },
  {
    value: "3484",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Panelas altas",
  },
  {
    value: "3485",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Chaleiras de fogão",
  },
  {
    value: "3486",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Terrinas e panelas de barro",
  },
  {
    value: "3487",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Utensílios para cozinhar > Woks",
  },
  {
    value: "3488",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Acessórios de utensílios para cozinhar",
  },
  {
    value: "3489",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Acessórios de utensílios para cozinhar > Pegas para tachos e panelas",
  },
  {
    value: "3490",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Acessórios de utensílios para cozinhar > Tampas para tachos e panelas",
  },
  {
    value: "3491",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Acessórios de utensílios para cozinhar > Acessórios para panelas de pressão e enlatadores de pressão",
  },
  {
    value: "3492",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Acessórios de utensílios para cozinhar > Cestos para cozinhar a vapor",
  },
  {
    value: "3493",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Acessórios de utensílios para cozinhar > Acessórios para wok",
  },
  {
    value: "3494",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Acessórios de utensílios para cozinhar > Acessórios para wok > Escovas para wok",
  },
  {
    value: "3495",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Utensílios para cozinhar e fazer bolos > Acessórios de utensílios para cozinhar > Acessórios para wok > Aros para wok",
  },
  {
    value: "3496",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida",
  },
  {
    value: "3497",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Termos Air Pot",
  },
  {
    value: "3498",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Cantis",
  },
  {
    value: "3499",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Refrigeradores",
  },
  {
    value: "3500",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Mangas para bebidas",
  },
  {
    value: "3501",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Mangas para bebidas > Mangas para latas e garrafas",
  },
  {
    value: "3502",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Mangas para bebidas > Mangas para copos",
  },
  {
    value: "3503",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Garrafas de bolso",
  },
  {
    value: "3504",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Sacos com isolamento",
  },
  {
    value: "3505",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Lancheiras e sacolas",
  },
  {
    value: "3506",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Cestos de piquenique",
  },
  {
    value: "3507",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Tampas de substituição para bebidas",
  },
  {
    value: "3508",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Termos",
  },
  {
    value: "3509",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Garrafas para água",
  },
  {
    value: "3510",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Transportadores de comida e bebida > Sacos para transporte de vinho",
  },
  {
    value: "3511",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos",
  },
  {
    value: "3512",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos > Caixas e sacos para pão",
  },
  {
    value: "3513",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos > Baldes para doces",
  },
  {
    value: "3514",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos > Frascos para bolachas",
  },
  {
    value: "3515",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos > Tampas para recipientes de alimentos",
  },
  {
    value: "3516",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos > Sacos para armazenamento de alimentos",
  },
  {
    value: "3517",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos > Recipientes para armazenamento de alimentos",
  },
  {
    value: "3518",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos > Invólucros para alimentos",
  },
  {
    value: "3519",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos > Invólucros para alimentos > Papel de alumínio",
  },
  {
    value: "3520",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos > Invólucros para alimentos > Papel vegetal",
  },
  {
    value: "3521",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos > Invólucros para alimentos > Película aderente",
  },
  {
    value: "3522",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos > Invólucros para alimentos > Papel manteiga",
  },
  {
    value: "3523",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Armazenamento de alimentos > Frascos para mel",
  },
  {
    value: "3524",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de armazenamento de alimentos",
  },
  {
    value: "3525",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de armazenamento de alimentos > Rótulos para comida e bebida",
  },
  {
    value: "3526",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de armazenamento de alimentos > Dispensadores de invólucros para alimentos",
  },
  {
    value: "3527",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de armazenamento de alimentos > Saquetas absorventes de humidade",
  },
  {
    value: "3528",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de armazenamento de alimentos > Atilhos e molas para sacos",
  },
  {
    value: "3529",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos",
  },
  {
    value: "3530",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de pão",
  },
  {
    value: "3531",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de café e expresso",
  },
  {
    value: "3532",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de café e expresso > Placas de aquecimento de máquina de café com filtro",
  },
  {
    value: "3533",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de café e expresso > Máquinas de café com filtro",
  },
  {
    value: "3534",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de café e expresso > Cestos para filtro de café",
  },
  {
    value: "3535",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de café e expresso > Filtros de café",
  },
  {
    value: "3536",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de café e expresso > Acessórios para moinho de café",
  },
  {
    value: "3537",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de café e expresso > Moinhos de café",
  },
  {
    value: "3538",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de café e expresso > Peças sobresselentes de máquina de café e expresso",
  },
  {
    value: "3539",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de café e expresso > Filtros de água para máquina de café",
  },
  {
    value: "3540",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de café e expresso > Jarros de espuma de leite",
  },
  {
    value: "3541",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de café e expresso > Portafiltros",
  },
  {
    value: "3542",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para placa de fogão, forno e fogão",
  },
  {
    value: "3543",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquina de algodão doce",
  },
  {
    value: "3544",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para fritadeira",
  },
  {
    value: "3545",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Peças e acessórios para máquina de lavar louça",
  },
  {
    value: "3546",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para chaleira elétrica",
  },
  {
    value: "3547",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para frigideira e wok elétricos",
  },
  {
    value: "3548",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para conjunto de fondue",
  },
  {
    value: "3549",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para conjunto de fondue > Géis combustíveis",
  },
  {
    value: "3550",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para conjunto de fondue > Garfos para fondue",
  },
  {
    value: "3551",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para conjunto de fondue > Suportes para panelas de fondue",
  },
  {
    value: "3552",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para desidratadores de alimentos",
  },
  {
    value: "3553",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para desidratadores de alimentos > Folhas para desidratador de alimentos",
  },
  {
    value: "3554",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para desidratadores de alimentos > Bandejas para desidratador de alimentos",
  },
  {
    value: "3555",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para picadoras de alimentos",
  },
  {
    value: "3556",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para misturadoras e liquidificadores de alimentos",
  },
  {
    value: "3557",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para congelador",
  },
  {
    value: "3558",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para eliminação de lixo",
  },
  {
    value: "3559",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquina de gelados",
  },
  {
    value: "3560",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquina de gelados > Tigelas para congelador de máquina de gelado",
  },
  {
    value: "3561",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para picadores e trituradores de gelo",
  },
  {
    value: "3562",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquina de gelo",
  },
  {
    value: "3563",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquina de sumo",
  },
  {
    value: "3564",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para forno micro-ondas",
  },
  {
    value: "3565",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para grelhador de exterior",
  },
  {
    value: "3566",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para grelhador de exterior > Briquetes de carvão",
  },
  {
    value: "3567",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para grelhador de exterior > Chaminés de acendimento",
  },
  {
    value: "3568",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para grelhador de exterior > Carrinhos de apoio de grelhador de exterior",
  },
  {
    value: "3569",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para grelhador de exterior > Coberturas para grelhador de exterior",
  },
  {
    value: "3570",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para grelhador de exterior > Bandejas e grelhas para grelhador de exterior",
  },
  {
    value: "3571",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para grelhador de exterior > Peças de substituição para grelhador de exterior",
  },
  {
    value: "3572",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para grelhador de exterior > Espetos e cestos para grelhador de exterior",
  },
  {
    value: "3573",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para grelhador de exterior > Tábuas para grelhador de exterior",
  },
  {
    value: "3574",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para grelhador de exterior > Lascas de madeira e pellets para fumar carne",
  },
  {
    value: "3575",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquina de pasta",
  },
  {
    value: "3576",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquina de pipocas",
  },
  {
    value: "3577",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para fogão portátil",
  },
  {
    value: "3578",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para exaustor",
  },
  {
    value: "3579",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para frigorífico",
  },
  {
    value: "3580",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquinas de gaseificação",
  },
  {
    value: "3581",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para mesas de vapor",
  },
  {
    value: "3582",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para mesas de vapor > Tampas para panelas de mesa a vapor",
  },
  {
    value: "3583",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para mesas de vapor > Panelas de mesas a vapor",
  },
  {
    value: "3584",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para torradeira",
  },
  {
    value: "3585",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquina de selar",
  },
  {
    value: "3586",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquina de selar > Sacos para máquina de selar",
  },
  {
    value: "3587",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para máquina de waffles",
  },
  {
    value: "3588",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para refrigerador de água",
  },
  {
    value: "3589",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para refrigerador de água > Garrafas para refrigerador de água",
  },
  {
    value: "3590",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para frigorífico de vinho",
  },
  {
    value: "3591",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Acessórios de aparelhos domésticos > Acessórios para iogurteira",
  },
  {
    value: "3592",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha",
  },
  {
    value: "3593",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Aquecedores de bebidas",
  },
  {
    value: "3594",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de pão",
  },
  {
    value: "3595",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas para temperar chocolate",
  },
  {
    value: "3596",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de café e expresso",
  },
  {
    value: "3597",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de café e expresso > Máquinas de café com filtro",
  },
  {
    value: "3598",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de café e expresso > Cafeteiras elétricas e italianas",
  },
  {
    value: "3599",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de café e expresso > Máquinas expresso",
  },
  {
    value: "3600",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de café e expresso > Cafeteiras de prensa francesa",
  },
  {
    value: "3601",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de café e expresso > Cafeteiras",
  },
  {
    value: "3602",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de café e expresso > Máquinas de café a vácuo",
  },
  {
    value: "3603",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Placas de fogão",
  },
  {
    value: "3604",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de algodão doce",
  },
  {
    value: "3605",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Fritadeiras",
  },
  {
    value: "3606",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de fatiar",
  },
  {
    value: "3607",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de lavar louça",
  },
  {
    value: "3608",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Grelhas e grelhadores elétricos",
  },
  {
    value: "3609",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Chaleiras elétricas",
  },
  {
    value: "3610",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Frigideiras e woks elétricos",
  },
  {
    value: "3611",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Panelas e conjunto de fondue",
  },
  {
    value: "3612",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Panelas elétricas e a vapor",
  },
  {
    value: "3613",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Panelas elétricas e a vapor > Máquinas para cozinhar ovos",
  },
  {
    value: "3614",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Panelas elétricas e a vapor > Máquinas para cozinhar a vapor",
  },
  {
    value: "3615",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Panelas elétricas e a vapor > Arrozeiras",
  },
  {
    value: "3616",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Panelas elétricas e a vapor > Panelas de cozimento lento",
  },
  {
    value: "3617",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Panelas elétricas e a vapor > Panelas térmicas",
  },
  {
    value: "3618",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Panelas elétricas e a vapor > Fornos à base de água",
  },
  {
    value: "3619",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Desidratadores de alimentos",
  },
  {
    value: "3620",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Picadores e trituradores de alimentos",
  },
  {
    value: "3621",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Misturadoras e liquidificadoras",
  },
  {
    value: "3622",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Defumador de alimentos",
  },
  {
    value: "3623",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Aquecedores de alimentos",
  },
  {
    value: "3624",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Aquecedores de alimentos > Tabuleiros de aquecimento com vela",
  },
  {
    value: "3625",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Aquecedores de alimentos > Lâmpadas de aquecimento de alimentos",
  },
  {
    value: "3626",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Aquecedores de alimentos > Conservadores de arroz",
  },
  {
    value: "3627",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Aquecedores de alimentos > Mesas de vapor",
  },
  {
    value: "3628",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Congeladores",
  },
  {
    value: "3629",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de granizados",
  },
  {
    value: "3630",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Trituradores de lixo",
  },
  {
    value: "3631",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Grelhadores a gás",
  },
  {
    value: "3632",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de bebidas quentes",
  },
  {
    value: "3633",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Placas elétricas",
  },
  {
    value: "3634",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de fazer gelados",
  },
  {
    value: "3635",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Picadores e trituradores de gelo",
  },
  {
    value: "3636",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de gelo",
  },
  {
    value: "3637",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de sumo",
  },
  {
    value: "3638",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Afiadores de facas",
  },
  {
    value: "3639",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Fornos micro-ondas",
  },
  {
    value: "3640",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Espumadores e vaporizadores de leite",
  },
  {
    value: "3641",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas para mochi",
  },
  {
    value: "3642",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Grelhadores de exterior",
  },
  {
    value: "3643",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Fornos",
  },
  {
    value: "3644",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas para pasta",
  },
  {
    value: "3645",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de pipocas",
  },
  {
    value: "3646",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Fogões portáteis",
  },
  {
    value: "3647",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Exaustores",
  },
  {
    value: "3648",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Fogões",
  },
  {
    value: "3649",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Frigoríficos",
  },
  {
    value: "3650",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Fornos para assar e fornos com espeto rotativo",
  },
  {
    value: "3651",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de gaseificação",
  },
  {
    value: "3652",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Máquinas de leite de soja",
  },
  {
    value: "3653",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Jarros elétricos para chá",
  },
  {
    value: "3654",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Torradeiras e grelhadores",
  },
  {
    value: "3655",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Torradeiras e grelhadores > Minifornos/torradeiras de bancada",
  },
  {
    value: "3656",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Torradeiras e grelhadores > Máquinas de donuts",
  },
  {
    value: "3657",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Torradeiras e grelhadores > Máquinas de muffins e cupcakes",
  },
  {
    value: "3658",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Torradeiras e grelhadores > Máquinas e fornos de pizza",
  },
  {
    value: "3659",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Torradeiras e grelhadores > Máquinas de pizzelle",
  },
  {
    value: "3660",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Torradeiras e grelhadores > Máquinas de pretzel",
  },
  {
    value: "3661",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Torradeiras e grelhadores > Sanduicheiras",
  },
  {
    value: "3662",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Torradeiras e grelhadores > Torradeiras",
  },
  {
    value: "3663",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Torradeiras e grelhadores > Máquinas de tortilha e pão achatado",
  },
  {
    value: "3664",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Torradeiras e grelhadores > Máquinas de waffles",
  },
  {
    value: "3665",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Compactadores de lixo",
  },
  {
    value: "3666",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Seladores a vácuo",
  },
  {
    value: "3667",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Refrigeradores de água",
  },
  {
    value: "3668",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Filtros de água",
  },
  {
    value: "3669",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Frigoríficos de vinho",
  },
  {
    value: "3670",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Eletrodomésticos de cozinha > Iogurteiras",
  },
  {
    value: "3671",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha",
  },
  {
    value: "3672",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Aventais",
  },
  {
    value: "3673",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Pás de forno",
  },
  {
    value: "3674",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Pipetas conta-gotas",
  },
  {
    value: "3675",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Pincéis de cozinha",
  },
  {
    value: "3676",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Dispensadores de bebidas",
  },
  {
    value: "3677",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Acessórios para decoração de bolos",
  },
  {
    value: "3678",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Talheres para servir bolo",
  },
  {
    value: "3679",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Esmagadores de latas",
  },
  {
    value: "3680",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Abre-latas",
  },
  {
    value: "3681",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Garfos de trinchar",
  },
  {
    value: "3682",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Cortador tipo descascador (fitas)",
  },
  {
    value: "3683",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Escorredores e coadores",
  },
  {
    value: "3684",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Dispensador de condimentos",
  },
  {
    value: "3685",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Cortadores de bolachas",
  },
  {
    value: "3686",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Prensas para bolachas",
  },
  {
    value: "3687",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Acessórios de termómetro de cozinha",
  },
  {
    value: "3688",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Termómetros de cozinha",
  },
  {
    value: "3689",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Cronómetros de cozinha",
  },
  {
    value: "3690",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Maçaricos de cozinha",
  },
  {
    value: "3691",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Tabuleiros de arrefecimento",
  },
  {
    value: "3692",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Tábuas de cortar",
  },
  {
    value: "3693",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Escorredores de louça e bandejas de escorrer",
  },
  {
    value: "3694",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Rolos corta-massas",
  },
  {
    value: "3695",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Acessórios para facas elétricas",
  },
  {
    value: "3696",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Acessórios para facas elétricas > Lâminas de substituição de facas elétricas",
  },
  {
    value: "3697",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Facas elétricas",
  },
  {
    value: "3698",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Peneiras para farinha",
  },
  {
    value: "3699",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Stencils de comida e bebida",
  },
  {
    value: "3700",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Abridores/descascadores",
  },
  {
    value: "3701",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Abridores/descascadores > Descascador de lagosta e caranguejo",
  },
  {
    value: "3702",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Abridores/descascadores > Quebra-nozes",
  },
  {
    value: "3703",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Abridores/descascadores > Quebra-nozes > Quebra-nozes decorativo",
  },
  {
    value: "3704",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Dispensador de alimentos",
  },
  {
    value: "3705",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Raladores e raspadores de alimentos",
  },
  {
    value: "3706",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Descascadores e descaroçadores",
  },
  {
    value: "3707",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Sacos para cozinhar a vapor",
  },
  {
    value: "3708",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Pauzinhos e espetos para alimentos",
  },
  {
    value: "3709",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Funis",
  },
  {
    value: "3710",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Espremedor de alho",
  },
  {
    value: "3711",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Moldes para gelatina",
  },
  {
    value: "3712",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Bandejas para cubos de gelo",
  },
  {
    value: "3713",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Pistolas para carne seca",
  },
  {
    value: "3714",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Facas de cozinha",
  },
  {
    value: "3715",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Formas de bolos",
  },
  {
    value: "3716",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha",
  },
  {
    value: "3717",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Organizadores para latas",
  },
  {
    value: "3718",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Suportes para copos/chávenas",
  },
  {
    value: "3719",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Organizadores para armários da cozinha",
  },
  {
    value: "3720",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Organizadores para balcão de cozinha e zona de bebidas",
  },
  {
    value: "3721",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Suportes e prateleiras de utensílios de cozinha",
  },
  {
    value: "3722",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Blocos e suportes de facas",
  },
  {
    value: "3723",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Suportes e dispensadores de guardanapos",
  },
  {
    value: "3724",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Suportes e dispensadores de rolos de papel",
  },
  {
    value: "3725",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Prateleiras para panelas",
  },
  {
    value: "3726",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Organizadores para especiarias",
  },
  {
    value: "3727",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Suportes e dispensadores de palhas",
  },
  {
    value: "3728",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Suportes para pacotes de açúcar",
  },
  {
    value: "3729",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Suportes e dispensadores para palitos",
  },
  {
    value: "3730",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para cozinha > Tabuleiros para utensílios e talheres",
  },
  {
    value: "3731",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Espátulas de cozinha",
  },
  {
    value: "3732",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Espátulas de cozinha > Raspadores para bancada",
  },
  {
    value: "3733",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Espátulas de cozinha > Raspadores para tigela",
  },
  {
    value: "3734",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Espátulas de cozinha > Raspadores para grelhador",
  },
  {
    value: "3735",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Tesoura de cozinha",
  },
  {
    value: "3736",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Cortadores de cozinha",
  },
  {
    value: "3737",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Conjuntos de utensílios de cozinha",
  },
  {
    value: "3738",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Conchas",
  },
  {
    value: "3739",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Esmagadores",
  },
  {
    value: "3740",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Chávenas e colheres medidoras",
  },
  {
    value: "3741",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Amaciadores de carne",
  },
  {
    value: "3742",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Tigelas para misturar",
  },
  {
    value: "3743",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Almofarizes e pilões",
  },
  {
    value: "3744",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Dispensadores de azeite e vinagre",
  },
  {
    value: "3745",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Sacos para cozinhar no forno",
  },
  {
    value: "3746",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Luvas para forno e pegas para panelas",
  },
  {
    value: "3747",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Moldes e carimbos para pasta",
  },
  {
    value: "3748",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Misturadores de massa",
  },
  {
    value: "3749",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Panos para massa",
  },
  {
    value: "3750",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Acessórios para cortador de pizza",
  },
  {
    value: "3751",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Cortadores de pizza",
  },
  {
    value: "3752",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Esmagador de batatas",
  },
  {
    value: "3753",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Acessórios de rolo de cozinha",
  },
  {
    value: "3754",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Acessórios de rolo de cozinha > Coberturas e mangas para rolo de cozinha",
  },
  {
    value: "3755",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Acessórios de rolo de cozinha > Argolas para rolo de cozinha",
  },
  {
    value: "3756",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Rolos de cozinha",
  },
  {
    value: "3757",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Misturadores e agitadores de molho de salada",
  },
  {
    value: "3758",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Escorredores rotativos de salada",
  },
  {
    value: "3759",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Colheres",
  },
  {
    value: "3760",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Colheres > Colheres para gelado",
  },
  {
    value: "3761",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Colheres > Colheres para gelo",
  },
  {
    value: "3762",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Colheres > Corta bolas de melão",
  },
  {
    value: "3763",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Colheres > Pás para batatas fritas e pipocas",
  },
  {
    value: "3764",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Organizadores para lava-louça",
  },
  {
    value: "3765",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Tapetes e grelhas para lava-louça",
  },
  {
    value: "3766",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Escumadeiras",
  },
  {
    value: "3767",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Espátulas",
  },
  {
    value: "3768",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Acessórios para moinho de especiarias",
  },
  {
    value: "3769",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Moinhos de especiarias",
  },
  {
    value: "3770",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Suportes para colher",
  },
  {
    value: "3771",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Açucareiros",
  },
  {
    value: "3772",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Tapetes para sushi",
  },
  {
    value: "3773",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Filtros de chá",
  },
  {
    value: "3774",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Pinças",
  },
  {
    value: "3775",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Ferramentas e utensílios de cozinha > Batedores",
  },
  {
    value: "3776",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Cozinhas e kitchenettes pré-fabricadas",
  },
  {
    value: "3777",
    inputValue: "Casa e jardim > Cozinha e refeições > Louça de mesa",
  },
  {
    value: "3778",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Conjuntos de café e chá",
  },
  {
    value: "3779",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Jarros de café e bules de chá",
  },
  {
    value: "3780",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Serviços de jantar",
  },
  {
    value: "3781",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Serviços de jantar > Taças",
  },
  {
    value: "3782",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Serviços de jantar > Conjuntos de jantar",
  },
  {
    value: "3783",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Serviços de jantar > Pratos",
  },
  {
    value: "3784",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Copos e similares",
  },
  {
    value: "3785",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Copos e similares > Copos de cerveja",
  },
  {
    value: "3786",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Copos e similares > Chávenas de café e chá",
  },
  {
    value: "3787",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Copos e similares > Pires de café e chá",
  },
  {
    value: "3788",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Copos e similares > Conjuntos de copos e similares",
  },
  {
    value: "3789",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Copos e similares > Canecas",
  },
  {
    value: "3790",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Copos e similares > Copos de shot",
  },
  {
    value: "3791",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Copos e similares > Copos com pé",
  },
  {
    value: "3792",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Copos e similares > Copos rasos",
  },
  {
    value: "3793",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Talheres",
  },
  {
    value: "3794",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Talheres > Acessórios para pauzinhos",
  },
  {
    value: "3795",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Talheres > Pauzinhos",
  },
  {
    value: "3796",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Talheres > Conjuntos de talheres",
  },
  {
    value: "3797",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Talheres > Garfos",
  },
  {
    value: "3798",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Talheres > Colheres",
  },
  {
    value: "3799",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Talheres > Facas de mesa",
  },
  {
    value: "3800",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Saleiros e pimenteiros",
  },
  {
    value: "3801",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Loiça para servir",
  },
  {
    value: "3802",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Loiça para servir > Manteigueiras",
  },
  {
    value: "3803",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Loiça para servir > Bases de cartão para bolos",
  },
  {
    value: "3804",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Loiça para servir > Suportes para bolos",
  },
  {
    value: "3805",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Loiça para servir > Copos para ovo",
  },
  {
    value: "3806",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Loiça para servir > Molheiras",
  },
  {
    value: "3807",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Loiça para servir > Taças para punch",
  },
  {
    value: "3808",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Loiça para servir > Jarros",
  },
  {
    value: "3809",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Loiça para servir > Travessas",
  },
  {
    value: "3810",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Loiça para servir > Tabuleiros",
  },
  {
    value: "3811",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Loiça para servir > Açucareiros e leiteiras",
  },
  {
    value: "3812",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Loiça para servir > Terrinas",
  },
  {
    value: "3813",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Acessórios de servir",
  },
  {
    value: "3814",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Acessórios de servir > Suportes para taças de punch",
  },
  {
    value: "3815",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Acessórios de servir > Tampas para terrina",
  },
  {
    value: "3816",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Acessórios de servir > Suportes para terrina",
  },
  {
    value: "3817",
    inputValue:
      "Casa e jardim > Cozinha e refeições > Louça de mesa > Clipes e pesos para toalha de mesa",
  },
  {
    value: "3818",
    inputValue: "Casa e jardim > Cozinha e refeições > Louça de mesa > Bases",
  },
  { value: "3819", inputValue: "Casa e jardim > Relva e jardim" },
  { value: "3820", inputValue: "Casa e jardim > Relva e jardim > Jardinagem" },
  {
    value: "3821",
    inputValue: "Casa e jardim > Relva e jardim > Jardinagem > Compostagem",
  },
  {
    value: "3822",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Compostagem > Composto",
  },
  {
    value: "3823",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Compostagem > Arejadores para composto",
  },
  {
    value: "3824",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Compostagem > Compostores",
  },
  {
    value: "3825",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Controlo de pragas",
  },
  {
    value: "3826",
    inputValue: "Casa e jardim > Relva e jardim > Jardinagem > Fertilizadores",
  },
  {
    value: "3827",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Pratos e bandejas para vasos",
  },
  {
    value: "3828",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Acessórios de jardinagem",
  },
  {
    value: "3829",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Acessórios de jardinagem > Assento de jardinagem com rodas, bancos e joelheiras",
  },
  {
    value: "3830",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Acessórios de jardinagem > Sacolas para jardinagem",
  },
  {
    value: "3831",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Acessórios de jardinagem > Bancadas para envasar",
  },
  {
    value: "3832",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Acessórios de ferramenta de jardinagem",
  },
  {
    value: "3833",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Acessórios de ferramenta de jardinagem > Pegas para ferramenta de jardinagem",
  },
  {
    value: "3834",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Acessórios de ferramenta de jardinagem > Cabeças para ferramenta de jardinagem",
  },
  {
    value: "3835",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Acessórios de ferramenta de jardinagem > Peças para carrinho de mão",
  },
  {
    value: "3836",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem",
  },
  {
    value: "3837",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Ferramentas para plantar bolbos",
  },
  {
    value: "3838",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Ferramentas de cultivo",
  },
  {
    value: "3839",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Forquilhas de jardinagem",
  },
  {
    value: "3840",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Foices e machetes para jardinagem",
  },
  {
    value: "3841",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Pá de mão para jardinagem",
  },
  {
    value: "3842",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Pulverizadores para relvado e jardim",
  },
  {
    value: "3843",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Rolos para relvado",
  },
  {
    value: "3844",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Serras de poda",
  },
  {
    value: "3845",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Tesouras de poda",
  },
  {
    value: "3846",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Ancinhos",
  },
  {
    value: "3847",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Pás curvas e planas",
  },
  {
    value: "3848",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Semeadoras",
  },
  {
    value: "3849",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Ferramentas de jardinagem > Carrinhos de mão",
  },
  {
    value: "3850",
    inputValue: "Casa e jardim > Relva e jardim > Jardinagem > Estufas",
  },
  {
    value: "3851",
    inputValue: "Casa e jardim > Relva e jardim > Jardinagem > Herbicidas",
  },
  {
    value: "3852",
    inputValue: "Casa e jardim > Relva e jardim > Jardinagem > Malha geotêxtil",
  },
  {
    value: "3853",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Acessórios para malha geotêxtil",
  },
  {
    value: "3854",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Acessórios para malha geotêxtil > Agrafes e pinos para malha geotêxtil",
  },
  {
    value: "3855",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Acessórios para malha geotêxtil > Fita para malha geotêxtil",
  },
  {
    value: "3856",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Matéria orgânica",
  },
  {
    value: "3857",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Gaiolas e suportes para plantas",
  },
  {
    value: "3858",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Apoios para plantas",
  },
  {
    value: "3859",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Forros para vasos e canteiros",
  },
  {
    value: "3860",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Vasos e canteiros",
  },
  {
    value: "3861",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Barris para recolher chuva",
  },
  {
    value: "3862",
    inputValue: "Casa e jardim > Relva e jardim > Jardinagem > Areias e solos",
  },
  {
    value: "3863",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Areias e solos > Areia",
  },
  {
    value: "3864",
    inputValue:
      "Casa e jardim > Relva e jardim > Jardinagem > Areias e solos > Solo",
  },
  {
    value: "3865",
    inputValue: "Casa e jardim > Relva e jardim > Vida ao ar livre",
  },
  {
    value: "3866",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Acessórios para toldos",
  },
  {
    value: "3867",
    inputValue: "Casa e jardim > Relva e jardim > Vida ao ar livre > Toldos",
  },
  {
    value: "3868",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Peças e acessórios para redes",
  },
  {
    value: "3869",
    inputValue: "Casa e jardim > Relva e jardim > Vida ao ar livre > Redes",
  },
  {
    value: "3870",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Mantas para exterior",
  },
  {
    value: "3871",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Mantas para exterior > Esteiras de praia",
  },
  {
    value: "3872",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Mantas para exterior > Mantas para piquenique",
  },
  {
    value: "3873",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Mantas para exterior > Poncho/saco de dormir",
  },
  {
    value: "3874",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Estruturas para exterior",
  },
  {
    value: "3875",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Estruturas para exterior > Tendas e gazebos",
  },
  {
    value: "3876",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Estruturas para exterior > Acessórios para tendas e gazebos",
  },
  {
    value: "3877",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Estruturas para exterior > Acessórios para tendas e gazebos > Kits para fechar tendas e gazebos",
  },
  {
    value: "3878",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Estruturas para exterior > Acessórios para tendas e gazebos > Estruturas para tendas e gazebos",
  },
  {
    value: "3879",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Estruturas para exterior > Acessórios para tendas e gazebos > Partes superiores para tendas e gazebos",
  },
  {
    value: "3880",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Estruturas para exterior > Acessórios para tendas e gazebos > Estacas para tendas",
  },
  {
    value: "3881",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Estruturas para exterior > Acessórios para tendas e gazebos > Pesos para tendas",
  },
  {
    value: "3882",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Estruturas para exterior > Arcos, treliças, arcos trabalhados e pérgolas para jardim",
  },
  {
    value: "3883",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Estruturas para exterior > Pontes para jardim",
  },
  {
    value: "3884",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Estruturas para exterior > Abrigos, garagens e abrigos para automóvel",
  },
  {
    value: "3885",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Guarda-sol e acessórios para sombra",
  },
  {
    value: "3886",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Guarda-sol e acessórios para sombra > Tecido para guarda-sol e toldos",
  },
  {
    value: "3887",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Guarda-sol e acessórios para sombra > Bases para guarda-sol",
  },
  {
    value: "3888",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Guarda-sol e acessórios para sombra > Coberturas para guarda-sol",
  },
  {
    value: "3889",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Guarda-sol e acessórios para sombra > Kits para fechar guarda-sóis",
  },
  {
    value: "3890",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Guarda-sol e acessórios para sombra > Iluminação para guarda-sóis",
  },
  {
    value: "3891",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Guarda-sóis e toldos",
  },
  {
    value: "3892",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Acessórios para baloiço de alpendre",
  },
  {
    value: "3893",
    inputValue:
      "Casa e jardim > Relva e jardim > Vida ao ar livre > Baloiços de alpendre",
  },
  {
    value: "3894",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior",
  },
  {
    value: "3895",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Motosserras",
  },
  {
    value: "3896",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Aparadores de relva",
  },
  {
    value: "3897",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Corta-sebes",
  },
  {
    value: "3898",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Arejadores e escarificadores",
  },
  {
    value: "3899",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Corta-relvas",
  },
  {
    value: "3900",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Corta-relvas > Trator corta-relva",
  },
  {
    value: "3901",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Corta-relvas > Corta-relvas robóticos",
  },
  {
    value: "3902",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Corta-relvas > Corta-relvas de reboque",
  },
  {
    value: "3903",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Corta-relvas > Corta-relvas de empurrar",
  },
  {
    value: "3904",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Aspiradores de relva",
  },
  {
    value: "3905",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Sopradores de folhas",
  },
  {
    value: "3906",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Unidades de base de equipamento elétrico de exterior",
  },
  {
    value: "3907",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Conjuntos de equipamento elétrico de exterior",
  },
  {
    value: "3908",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Varredoras mecânicas",
  },
  {
    value: "3909",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Motocultivadores e cultivadores",
  },
  {
    value: "3910",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Lavadoras de alta pressão",
  },
  {
    value: "3911",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Sopradores de neve",
  },
  {
    value: "3912",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Tratores",
  },
  {
    value: "3913",
    inputValue:
      "Casa e jardim > Relva e jardim > Equipamento elétrico para exterior > Aparadores de erva",
  },
  {
    value: "3914",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior",
  },
  {
    value: "3915",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para motosserra",
  },
  {
    value: "3916",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para motosserra > Barras para motosserra",
  },
  {
    value: "3917",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para motosserra > Correntes para motosserra",
  },
  {
    value: "3918",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para aparador de relva",
  },
  {
    value: "3919",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-sebes",
  },
  {
    value: "3920",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas",
  },
  {
    value: "3921",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Fixações para roçadeiras",
  },
  {
    value: "3922",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Sacos para corta-relva",
  },
  {
    value: "3923",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Cintas para corta-relva",
  },
  {
    value: "3924",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Lâminas para corta-relva",
  },
  {
    value: "3925",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Coberturas para corta-relva",
  },
  {
    value: "3926",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Kits de matéria orgânica para corta-relva",
  },
  {
    value: "3927",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Conexões e placas de corta-relva para matéria orgânica",
  },
  {
    value: "3928",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Polias e engrenagens para corta-relva",
  },
  {
    value: "3929",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Câmaras-de-ar para corta-relva",
  },
  {
    value: "3930",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Pneus para corta-relva",
  },
  {
    value: "3931",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Rodas para corta-relva",
  },
  {
    value: "3932",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Kits de corta-relva para riscas",
  },
  {
    value: "3933",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para corta-relvas > Varredores de relva",
  },
  {
    value: "3934",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para soprador de folhas",
  },
  {
    value: "3935",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para soprador de folhas > Tubos de soprador de folhas",
  },
  {
    value: "3936",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Fixações para equipamento elétrico de exterior multifunções",
  },
  {
    value: "3937",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Fixações para equipamento elétrico de exterior multifunções > Fixações para aparador de relva",
  },
  {
    value: "3938",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Fixações para equipamento elétrico de exterior multifunções > Fixações para soprador de folhas e solo",
  },
  {
    value: "3939",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Fixações para equipamento elétrico de exterior multifunções > Fixações para corta-sebes",
  },
  {
    value: "3940",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Fixações para equipamento elétrico de exterior multifunções > Fixações para serra de vara",
  },
  {
    value: "3941",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Fixações para equipamento elétrico de exterior multifunções > Fixações para motocultivador e cultivador",
  },
  {
    value: "3942",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Fixações para equipamento elétrico de exterior multifunções > Fixações para corta-ervas",
  },
  {
    value: "3943",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Baterias para equipamento elétrico de exterior",
  },
  {
    value: "3944",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para lavadora de alta pressão",
  },
  {
    value: "3945",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios para soprador de neve",
  },
  {
    value: "3946",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Peças e acessórios para trator",
  },
  {
    value: "3947",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Peças e acessórios para trator > Pneus para trator",
  },
  {
    value: "3948",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Peças e acessórios para trator > Rodas para trator",
  },
  {
    value: "3949",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios corta-ervas",
  },
  {
    value: "3950",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios corta-ervas > Lâminas e bobinas para aparador de relva",
  },
  {
    value: "3951",
    inputValue:
      "Casa e jardim > Relva e jardim > Acessórios de equipamento elétrico de exterior > Acessórios corta-ervas > Coberturas para bobina de aparador de relva",
  },
  {
    value: "3952",
    inputValue: "Casa e jardim > Relva e jardim > Remoção de neve",
  },
  {
    value: "3953",
    inputValue:
      "Casa e jardim > Relva e jardim > Remoção de neve > Raspadores de gelo e escovas para neve",
  },
  {
    value: "3954",
    inputValue:
      "Casa e jardim > Relva e jardim > Remoção de neve > Pás para neve",
  },
  {
    value: "3955",
    inputValue: "Casa e jardim > Relva e jardim > Água e irrigação",
  },
  {
    value: "3956",
    inputValue:
      "Casa e jardim > Relva e jardim > Água e irrigação > Encaixes e válvulas para mangueira de jardim",
  },
  {
    value: "3957",
    inputValue:
      "Casa e jardim > Relva e jardim > Água e irrigação > Bocais de aspersão para mangueira de jardim",
  },
  {
    value: "3958",
    inputValue:
      "Casa e jardim > Relva e jardim > Água e irrigação > Mangueiras de jardim",
  },
  {
    value: "3959",
    inputValue:
      "Casa e jardim > Relva e jardim > Água e irrigação > Acessórios de aspersor",
  },
  {
    value: "3960",
    inputValue:
      "Casa e jardim > Relva e jardim > Água e irrigação > Acessórios de aspersor > Controlos de aspersor",
  },
  {
    value: "3961",
    inputValue:
      "Casa e jardim > Relva e jardim > Água e irrigação > Acessórios de aspersor > Válvulas de aspersor",
  },
  {
    value: "3962",
    inputValue:
      "Casa e jardim > Relva e jardim > Água e irrigação > Aspersores e cabeças de aspersor",
  },
  {
    value: "3963",
    inputValue:
      "Casa e jardim > Relva e jardim > Água e irrigação > Acessórios para regador",
  },
  {
    value: "3964",
    inputValue: "Casa e jardim > Relva e jardim > Água e irrigação > Regadores",
  },
  {
    value: "3965",
    inputValue:
      "Casa e jardim > Relva e jardim > Água e irrigação > Globos e estacas de rega",
  },
  { value: "3966", inputValue: "Casa e jardim > Iluminação" },
  {
    value: "3967",
    inputValue: "Casa e jardim > Iluminação > Iluminação de emergência",
  },
  {
    value: "3968",
    inputValue:
      "Casa e jardim > Iluminação > Iluminação flutuante e submersível",
  },
  {
    value: "3969",
    inputValue: "Casa e jardim > Iluminação > Holofotes e focos",
  },
  {
    value: "3970",
    inputValue: "Casa e jardim > Iluminação > Iluminação encastrável no solo",
  },
  { value: "3971", inputValue: "Casa e jardim > Iluminação > Candeeiros" },
  {
    value: "3972",
    inputValue: "Casa e jardim > Iluminação > Iluminação de caminhos",
  },
  { value: "3973", inputValue: "Casa e jardim > Iluminação > Lâmpadas" },
  {
    value: "3974",
    inputValue:
      "Casa e jardim > Iluminação > Lâmpadas > Lâmpadas fluorescentes compactas",
  },
  {
    value: "3975",
    inputValue:
      "Casa e jardim > Iluminação > Lâmpadas > Lâmpadas fluorescentes",
  },
  {
    value: "3976",
    inputValue:
      "Casa e jardim > Iluminação > Lâmpadas > Lâmpadas incandescentes",
  },
  {
    value: "3977",
    inputValue: "Casa e jardim > Iluminação > Lâmpadas > Lâmpadas LED",
  },
  {
    value: "3978",
    inputValue: "Casa e jardim > Iluminação > Cordas e fitas de iluminação",
  },
  { value: "3979", inputValue: "Casa e jardim > Iluminação > Luminárias" },
  {
    value: "3980",
    inputValue:
      "Casa e jardim > Iluminação > Luminárias > Iluminação para armários",
  },
  {
    value: "3981",
    inputValue: "Casa e jardim > Iluminação > Luminárias > Iluminação de teto",
  },
  {
    value: "3982",
    inputValue: "Casa e jardim > Iluminação > Luminárias > Candelabros",
  },
  {
    value: "3983",
    inputValue:
      "Casa e jardim > Iluminação > Luminárias > Iluminação de parede",
  },
  {
    value: "3984",
    inputValue: "Casa e jardim > Iluminação > Iluminação noturna e ambiente",
  },
  {
    value: "3985",
    inputValue: "Casa e jardim > Iluminação > Iluminação para quadros",
  },
  {
    value: "3986",
    inputValue: "Casa e jardim > Iluminação > Tochas e lanternas a petróleo",
  },
  {
    value: "3987",
    inputValue: "Casa e jardim > Iluminação > Iluminação em trilho",
  },
  {
    value: "3988",
    inputValue:
      "Casa e jardim > Iluminação > Iluminação em trilho > Acessórios para iluminação em trilho",
  },
  {
    value: "3989",
    inputValue:
      "Casa e jardim > Iluminação > Iluminação em trilho > Fixações para iluminação em trilho",
  },
  {
    value: "3990",
    inputValue:
      "Casa e jardim > Iluminação > Iluminação em trilho > Calhas para iluminação em trilho",
  },
  { value: "3991", inputValue: "Casa e jardim > Acessórios de iluminação" },
  {
    value: "3992",
    inputValue:
      "Casa e jardim > Acessórios de iluminação > Bases de poste de luz",
  },
  {
    value: "3993",
    inputValue:
      "Casa e jardim > Acessórios de iluminação > Suportes de poste de luz",
  },
  {
    value: "3994",
    inputValue: "Casa e jardim > Acessórios de iluminação > Abajures",
  },
  {
    value: "3995",
    inputValue:
      "Casa e jardim > Acessórios de iluminação > Temporizadores de iluminação",
  },
  {
    value: "3996",
    inputValue:
      "Casa e jardim > Acessórios de iluminação > Combustível para lanterna a petróleo",
  },
  { value: "3997", inputValue: "Casa e jardim > Atoalhados e roupa de cama" },
  {
    value: "3998",
    inputValue: "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama",
  },
  {
    value: "3999",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama > Dosséis para cama",
  },
  {
    value: "4000",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama > Lençóis",
  },
  {
    value: "4001",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama > Capa para base da cama",
  },
  {
    value: "4002",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama > Cobertores",
  },
  {
    value: "4003",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama > Capas de edredão",
  },
  {
    value: "4004",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama > Protetores de colchão",
  },
  {
    value: "4005",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama > Protetores de colchão > Capas de colchão",
  },
  {
    value: "4006",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama > Protetores de colchão > Resguardos de colchão",
  },
  {
    value: "4007",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama > Colchões de chão",
  },
  {
    value: "4008",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama > Fronhas e capas decorativas para almofadas",
  },
  {
    value: "4009",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama > Almofadas",
  },
  {
    value: "4010",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Roupa de cama > Colchas e mantas",
  },
  {
    value: "4011",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Conjuntos de atoalhados",
  },
  {
    value: "4012",
    inputValue: "Casa e jardim > Atoalhados e roupa de cama > Atoalhados",
  },
  {
    value: "4013",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Atoalhados > Guardanapos de papel",
  },
  {
    value: "4014",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Atoalhados > Naperons",
  },
  {
    value: "4015",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Atoalhados > Marcadores",
  },
  {
    value: "4016",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Atoalhados > Caminhos de mesa",
  },
  {
    value: "4017",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Atoalhados > Saias para mesa",
  },
  {
    value: "4018",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Atoalhados > Toalhas de mesa",
  },
  {
    value: "4019",
    inputValue: "Casa e jardim > Atoalhados e roupa de cama > Toalhas",
  },
  {
    value: "4020",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Toalhas > Toalhas de banho e rosto",
  },
  {
    value: "4021",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Toalhas > Toalhas de praia",
  },
  {
    value: "4022",
    inputValue:
      "Casa e jardim > Atoalhados e roupa de cama > Toalhas > Toalhas de cozinha",
  },
  { value: "4023", inputValue: "Casa e jardim > Guarda-sóis e guarda-chuvas" },
  { value: "4024", inputValue: "Casa e jardim > Plantas" },
  { value: "4025", inputValue: "Casa e jardim > Plantas > Plantas aquáticas" },
  { value: "4026", inputValue: "Casa e jardim > Plantas > Flores" },
  {
    value: "4027",
    inputValue: "Casa e jardim > Plantas > Plantas interiores e exteriores",
  },
  {
    value: "4028",
    inputValue:
      "Casa e jardim > Plantas > Plantas interiores e exteriores > Árvores e arbustos",
  },
  {
    value: "4029",
    inputValue:
      "Casa e jardim > Plantas > Plantas interiores e exteriores > Jardinagem e plantas de jardim",
  },
  {
    value: "4030",
    inputValue:
      "Casa e jardim > Plantas > Plantas interiores e exteriores > Plantas domésticas em vaso",
  },
  {
    value: "4031",
    inputValue: "Casa e jardim > Plantas > Kits de plantas e ervas",
  },
  { value: "4032", inputValue: "Casa e jardim > Plantas > Sementes" },
  {
    value: "4033",
    inputValue:
      "Casa e jardim > Plantas > Sementes > Bolbos de plantas e flores",
  },
  {
    value: "4034",
    inputValue:
      "Casa e jardim > Plantas > Sementes > Sementes e fita de sementes",
  },
  { value: "4035", inputValue: "Casa e jardim > Plantas > Árvores" },
  { value: "4036", inputValue: "Casa e jardim > Piscina e spa" },
  {
    value: "4037",
    inputValue: "Casa e jardim > Piscina e spa > Acessórios de piscina e spa",
  },
  {
    value: "4038",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Pranchas de mergulho",
  },
  {
    value: "4039",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Geradores de cloro para piscina e spa",
  },
  {
    value: "4040",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Filtros para piscina e spa",
  },
  {
    value: "4041",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Kits de manutenção para piscina e spa",
  },
  {
    value: "4042",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Escovas e vassouras de piscina",
  },
  {
    value: "4043",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Mangueiras de limpeza de piscina",
  },
  {
    value: "4044",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Agentes de limpeza e químicos para piscina",
  },
  {
    value: "4045",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Acessórios de cobertura de piscina",
  },
  {
    value: "4046",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Coberturas de piscina e de pavimento",
  },
  {
    value: "4047",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Kits para deck de piscina",
  },
  {
    value: "4048",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Boias e colchões de piscina",
  },
  {
    value: "4049",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Aquecedores de piscina",
  },
  {
    value: "4050",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Escadas, degraus e rampas de piscina",
  },
  {
    value: "4051",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Revestimentos de piscina",
  },
  {
    value: "4052",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Escumadores de piscina",
  },
  {
    value: "4053",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Varredores e aspiradores de piscina",
  },
  {
    value: "4054",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Brinquedos de piscina",
  },
  {
    value: "4055",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios de piscina e spa > Escorregas de piscina",
  },
  {
    value: "4056",
    inputValue: "Casa e jardim > Piscina e spa > Acessórios para sauna",
  },
  {
    value: "4057",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios para sauna > Baldes e conchas para sauna",
  },
  {
    value: "4058",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios para sauna > Aquecedores para sauna",
  },
  {
    value: "4059",
    inputValue:
      "Casa e jardim > Piscina e spa > Acessórios para sauna > Kits para sauna",
  },
  { value: "4060", inputValue: "Casa e jardim > Piscina e spa > Saunas" },
  { value: "4061", inputValue: "Casa e jardim > Piscina e spa > Spas" },
  { value: "4062", inputValue: "Casa e jardim > Piscina e spa > Piscinas" },
  { value: "4063", inputValue: "Casa e jardim > Acessórios para fumadores" },
  {
    value: "4064",
    inputValue: "Casa e jardim > Acessórios para fumadores > Cinzeiros",
  },
  {
    value: "4065",
    inputValue:
      "Casa e jardim > Acessórios para fumadores > Caixas para charutos",
  },
  {
    value: "4066",
    inputValue:
      "Casa e jardim > Acessórios para fumadores > Cortadores e furadores de charutos",
  },
  {
    value: "4067",
    inputValue:
      "Casa e jardim > Acessórios para fumadores > Caixas para cigarros",
  },
  {
    value: "4068",
    inputValue:
      "Casa e jardim > Acessórios para fumadores > Boquilhas para cigarros",
  },
  {
    value: "4069",
    inputValue:
      "Casa e jardim > Acessórios para fumadores > Acessórios para humidificador",
  },
  {
    value: "4070",
    inputValue: "Casa e jardim > Acessórios para fumadores > Humidificadores",
  },
  {
    value: "4071",
    inputValue: "Casa e jardim > Resguardos e estojos para guarda-chuvas",
  },
  { value: "4072", inputValue: "Casa e jardim > Fogões de lenha" },
  { value: "4073", inputValue: "Bagagem e malas" },
  { value: "4074", inputValue: "Bagagem e malas > Mochilas" },
  { value: "4075", inputValue: "Bagagem e malas > Pastas" },
  {
    value: "4076",
    inputValue: "Bagagem e malas > Bolsas de cosméticos e produtos de higiene",
  },
  { value: "4077", inputValue: "Bagagem e malas > Sacos de fraldas" },
  { value: "4078", inputValue: "Bagagem e malas > Sacos impermeáveis" },
  { value: "4079", inputValue: "Bagagem e malas > Sacos de lona" },
  { value: "4080", inputValue: "Bagagem e malas > Bolsas de cintura" },
  { value: "4081", inputValue: "Bagagem e malas > Porta-fatos" },
  { value: "4082", inputValue: "Bagagem e malas > Acessórios de bagagem" },
  {
    value: "4083",
    inputValue:
      "Bagagem e malas > Acessórios de bagagem > Revestimentos e encaixes para sacos impermeáveis",
  },
  {
    value: "4084",
    inputValue:
      "Bagagem e malas > Acessórios de bagagem > Coberturas para bagagem",
  },
  {
    value: "4085",
    inputValue:
      "Bagagem e malas > Acessórios de bagagem > Prateleiras e suportes para bagagem",
  },
  {
    value: "4086",
    inputValue: "Bagagem e malas > Acessórios de bagagem > Faixas para bagagem",
  },
  {
    value: "4087",
    inputValue:
      "Bagagem e malas > Acessórios de bagagem > Etiquetas para bagagem",
  },
  {
    value: "4088",
    inputValue:
      "Bagagem e malas > Acessórios de bagagem > Organizadores de malas",
  },
  {
    value: "4089",
    inputValue:
      "Bagagem e malas > Acessórios de bagagem > Garrafas e recipientes de viagem",
  },
  {
    value: "4090",
    inputValue: "Bagagem e malas > Acessórios de bagagem > Bolsas de viagem",
  },
  { value: "4091", inputValue: "Bagagem e malas > Sacos a tiracolo" },
  { value: "4092", inputValue: "Bagagem e malas > Sacolas" },
  { value: "4093", inputValue: "Bagagem e malas > Malas" },
  { value: "4094", inputValue: "Bagagem e malas > Malotes" },
  { value: "4095", inputValue: "Adultos" },
  { value: "4096", inputValue: "Adultos > Erótico" },
  { value: "4097", inputValue: "Adultos > Erótico > Livros eróticos" },
  { value: "4098", inputValue: "Adultos > Erótico > Vestuário erótico" },
  { value: "4099", inputValue: "Adultos > Erótico > DVDs e vídeos eróticos" },
  { value: "4100", inputValue: "Adultos > Erótico > Comestíveis eróticos" },
  { value: "4101", inputValue: "Adultos > Erótico > Jogos eróticos" },
  { value: "4102", inputValue: "Adultos > Erótico > Revistas eróticas" },
  { value: "4103", inputValue: "Adultos > Erótico > Kits de dança do varão" },
  { value: "4104", inputValue: "Adultos > Erótico > Brinquedos sexuais" },
  { value: "4105", inputValue: "Adultos > Armas" },
  { value: "4106", inputValue: "Adultos > Armas > Soqueiras" },
  { value: "4107", inputValue: "Adultos > Armas > Tacos e bastões" },
  { value: "4108", inputValue: "Adultos > Armas > Facas de combate" },
  {
    value: "4109",
    inputValue: "Adultos > Armas > Cuidado de armas e acessórios",
  },
  {
    value: "4110",
    inputValue: "Adultos > Armas > Cuidado de armas e acessórios > Munições",
  },
  {
    value: "4111",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Estojos e suportes de munições",
  },
  {
    value: "4112",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Estojos de armas e malas para armas e acessórios",
  },
  {
    value: "4113",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Limpeza de armas",
  },
  {
    value: "4114",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Limpeza de armas > Toalhetes e panos de limpeza para armas",
  },
  {
    value: "4115",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Limpeza de armas > Lenços para limpeza de armas",
  },
  {
    value: "4116",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Limpeza de armas > Solventes para limpeza de armas",
  },
  {
    value: "4117",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Punhos para armas",
  },
  {
    value: "4118",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Coldres para armas",
  },
  {
    value: "4119",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Lanternas para armas",
  },
  {
    value: "4120",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Calhas para armas",
  },
  {
    value: "4121",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Correias para armas",
  },
  {
    value: "4122",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Consumíveis de recarga e equipamento",
  },
  {
    value: "4123",
    inputValue:
      "Adultos > Armas > Cuidado de armas e acessórios > Consumíveis de recarga e equipamento > Prensas de recarga de munições",
  },
  { value: "4124", inputValue: "Adultos > Armas > Armas" },
  { value: "4125", inputValue: "Adultos > Armas > Mace e gás pimenta" },
  { value: "4126", inputValue: "Adultos > Armas > Matracas" },
  { value: "4127", inputValue: "Adultos > Armas > Lanças" },
  { value: "4128", inputValue: "Adultos > Armas > Bastões e varas" },
  {
    value: "4129",
    inputValue: "Adultos > Armas > Pistola paralisante e tasers",
  },
  { value: "4130", inputValue: "Adultos > Armas > Espadas" },
  { value: "4131", inputValue: "Adultos > Armas > Estrelas da morte" },
  { value: "4132", inputValue: "Adultos > Armas > Chicotes" },
  { value: "4133", inputValue: "Multimédia" },
  { value: "4134", inputValue: "Multimédia > Livros" },
  { value: "4135", inputValue: "Multimédia > Livros > Audiolivro" },
  { value: "4136", inputValue: "Multimédia > Livros > Livro eletrónico" },
  { value: "4137", inputValue: "Multimédia > Livros > Livros impressos" },
  {
    value: "4138",
    inputValue: "Multimédia > Planos de projetos de carpintaria e marcenaria",
  },
  { value: "4139", inputValue: "Multimédia > DVDs e vídeos" },
  {
    value: "4140",
    inputValue:
      "Multimédia > DVDs e vídeos > DVDs de filmes e séries de televisão",
  },
  {
    value: "4141",
    inputValue:
      "Multimédia > DVDs e vídeos > Digital Downloads de filmes e séries de televisão",
  },
  {
    value: "4142",
    inputValue:
      "Multimédia > DVDs e vídeos > Cassetes VHS de filmes e séries de televisão",
  },
  { value: "4143", inputValue: "Multimédia > Revistas e jornais" },
  { value: "4144", inputValue: "Multimédia > Revistas e jornais > Revistas" },
  { value: "4145", inputValue: "Multimédia > Revistas e jornais > Jornais" },
  { value: "4146", inputValue: "Multimédia > Gravações de música e áudio" },
  {
    value: "4147",
    inputValue:
      "Multimédia > Gravações de música e áudio > Transferências de música digital",
  },
  {
    value: "4148",
    inputValue: "Multimédia > Gravações de música e áudio > CDs de música",
  },
  {
    value: "4149",
    inputValue: "Multimédia > Gravações de música e áudio > Cassetes de música",
  },
  {
    value: "4150",
    inputValue: "Multimédia > Gravações de música e áudio > Discos e LPs",
  },
  {
    value: "4151",
    inputValue:
      "Multimédia > Gravações de música e áudio > Gravações de voz e em campo",
  },
  { value: "4152", inputValue: "Multimédia > Manuais de produto" },
  {
    value: "4153",
    inputValue: "Multimédia > Manuais de produto > Manuais de câmaras e óticas",
  },
  {
    value: "4154",
    inputValue: "Multimédia > Manuais de produto > Manuais de eletrónica",
  },
  {
    value: "4155",
    inputValue:
      "Multimédia > Manuais de produto > Manuais de exercício e equipamento de fitness",
  },
  {
    value: "4156",
    inputValue: "Multimédia > Manuais de produto > Manuais de eletrodomésticos",
  },
  {
    value: "4157",
    inputValue:
      "Multimédia > Manuais de produto > Manuais de eletrodomésticos de cozinha",
  },
  {
    value: "4158",
    inputValue:
      "Multimédia > Manuais de produto > Manuais de modelos e brinquedos",
  },
  {
    value: "4159",
    inputValue:
      "Multimédia > Manuais de produto > Manuais de materiais de escritório",
  },
  {
    value: "4160",
    inputValue:
      "Multimédia > Manuais de produto > Manuais de ferramentas e equipamentos elétricos",
  },
  {
    value: "4161",
    inputValue:
      "Multimédia > Manuais de produto > Manuais de manutenção de veículos",
  },
  { value: "4162", inputValue: "Multimédia > Partituras" },
  { value: "4163", inputValue: "Materiais de escritório" },
  {
    value: "4164",
    inputValue: "Materiais de escritório > Acessórios para livros",
  },
  {
    value: "4165",
    inputValue:
      "Materiais de escritório > Acessórios para livros > Capas para livros",
  },
  {
    value: "4166",
    inputValue:
      "Materiais de escritório > Acessórios para livros > Iluminação para livros",
  },
  {
    value: "4167",
    inputValue:
      "Materiais de escritório > Acessórios para livros > Prateleiras e apoios para livros",
  },
  {
    value: "4168",
    inputValue:
      "Materiais de escritório > Acessórios para livros > Marcadores de livros",
  },
  {
    value: "4169",
    inputValue: "Materiais de escritório > Proteções e bases para secretárias",
  },
  {
    value: "4170",
    inputValue: "Materiais de escritório > Arquivo e organização",
  },
  {
    value: "4171",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Livros de endereços",
  },
  {
    value: "4172",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Acessórios de encadernação",
  },
  {
    value: "4173",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Acessórios de encadernação > Acessórios para pastas",
  },
  {
    value: "4174",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Acessórios de encadernação > Acessórios para pastas > Argolas para pastas",
  },
  {
    value: "4175",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Acessórios de encadernação > Acessórios para pastas > Divisores de indexação",
  },
  {
    value: "4176",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Acessórios de encadernação > Acessórios para pastas > Protetores de folhas",
  },
  {
    value: "4177",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Acessórios de encadernação > Pastas",
  },
  {
    value: "4178",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Acessórios de encadernação > Combinações e espirais de união",
  },
  {
    value: "4179",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Acessórios de encadernação > Encadernadoras",
  },
  {
    value: "4180",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Caderno para cartões-de-visita",
  },
  {
    value: "4181",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Expositores de cartões-de-visita",
  },
  {
    value: "4182",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Estojos e organizadores para CD/DVD",
  },
  {
    value: "4183",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Calendários, organizadores e planificadores",
  },
  {
    value: "4184",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Arquivos para cartões",
  },
  {
    value: "4185",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Bolsas para cartões",
  },
  {
    value: "4186",
    inputValue: "Materiais de escritório > Arquivo e organização > Cofres",
  },
  {
    value: "4187",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Organizadores de secretária",
  },
  {
    value: "4188",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Caixas para arquivos",
  },
  {
    value: "4189",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Pastas de arquivo",
  },
  {
    value: "4190",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Capas para pastas e relatórios",
  },
  {
    value: "4191",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Capas para pastas e relatórios > Pastas com divisórias",
  },
  {
    value: "4192",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Capas para pastas e relatórios > Capas para relatórios",
  },
  {
    value: "4193",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Organizadores para cartões de felicitações",
  },
  {
    value: "4194",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Organizadores de correio",
  },
  {
    value: "4195",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Estojos para canetas e lápis",
  },
  {
    value: "4196",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Porta-documentos e porta-documentos com bloco",
  },
  {
    value: "4197",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Porta-documentos e porta-documentos com bloco > Porta-documentos com bloco",
  },
  {
    value: "4198",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Porta-documentos e porta-documentos com bloco > Porta-documentos",
  },
  {
    value: "4199",
    inputValue:
      "Materiais de escritório > Arquivo e organização > Caixas para recibos",
  },
  {
    value: "4200",
    inputValue: "Materiais de escritório > Consumíveis de escritório gerais",
  },
  {
    value: "4201",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Tachas em latão",
  },
  {
    value: "4202",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Corretores líquidos, em canetas ou fita",
  },
  {
    value: "4203",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Corretores líquidos, em canetas ou fita > Corretores líquidos",
  },
  {
    value: "4204",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Corretores líquidos, em canetas ou fita > Canetas corretoras",
  },
  {
    value: "4205",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Corretores líquidos, em canetas ou fita > Fitas corretoras",
  },
  {
    value: "4206",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Borrachas",
  },
  {
    value: "4207",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Rótulos e etiquetas",
  },
  {
    value: "4208",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Rótulos e etiquetas > Rótulos para endereços",
  },
  {
    value: "4209",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Rótulos e etiquetas > Separadores de arquivo",
  },
  {
    value: "4210",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Rótulos e etiquetas > Clipes com rótulo",
  },
  {
    value: "4211",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Rótulos e etiquetas > Fitas de rótulos e rolos de recarga",
  },
  {
    value: "4212",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Rótulos e etiquetas > Rótulos de envio",
  },
  {
    value: "4213",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Rótulos e etiquetas > Etiquetas de envio",
  },
  {
    value: "4214",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Película, bolsas e folhas laminadas",
  },
  {
    value: "4215",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Pastilhas de cola",
  },
  {
    value: "4216",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Fita adesiva",
  },
  {
    value: "4217",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Clipes e clipes dobráveis",
  },
  {
    value: "4218",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Clipes e clipes dobráveis > Molas de orelhas",
  },
  {
    value: "4219",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Clipes e clipes dobráveis > Clipes de papel",
  },
  {
    value: "4220",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel",
  },
  {
    value: "4221",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Folhas para dossiers",
  },
  {
    value: "4222",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Cartões de ID em branco",
  },
  {
    value: "4223",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Cartões-de-visita",
  },
  {
    value: "4224",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Formulários de negócio e recibos",
  },
  {
    value: "4225",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Cheques",
  },
  {
    value: "4226",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Papel para capa",
  },
  {
    value: "4227",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Envelopes",
  },
  {
    value: "4228",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Cartões de anotação",
  },
  {
    value: "4229",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Cadernos e blocos de notas",
  },
  {
    value: "4230",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Postais",
  },
  {
    value: "4231",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Papel de impressão e de fotocopiadora",
  },
  {
    value: "4232",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Rolos de papel para recebidos e máquina de calcular",
  },
  {
    value: "4233",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Artigos de papelaria",
  },
  {
    value: "4234",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Produtos de papel > Notas aderentes",
  },
  {
    value: "4235",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Elásticos de borracha",
  },
  {
    value: "4236",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Agrafes",
  },
  {
    value: "4237",
    inputValue:
      "Materiais de escritório > Consumíveis de escritório gerais > Tachas e pioneses",
  },
  { value: "4238", inputValue: "Materiais de escritório > Máquinas de selar" },
  {
    value: "4239",
    inputValue: "Materiais de escritório > Mesas para computadores portáteis",
  },
  {
    value: "4240",
    inputValue: "Materiais de escritório > Placas de identificação",
  },
  {
    value: "4241",
    inputValue: "Materiais de escritório > Tapetes para escritório e cadeiras",
  },
  {
    value: "4242",
    inputValue:
      "Materiais de escritório > Tapetes para escritório e cadeiras > Tapetes antifadiga",
  },
  {
    value: "4243",
    inputValue:
      "Materiais de escritório > Tapetes para escritório e cadeiras > Tapetes para cadeiras",
  },
  {
    value: "4244",
    inputValue:
      "Materiais de escritório > Tapetes para escritório e cadeiras > Tapetes para escritório",
  },
  {
    value: "4245",
    inputValue: "Materiais de escritório > Carrinhos para escritório",
  },
  {
    value: "4246",
    inputValue:
      "Materiais de escritório > Carrinhos para escritório > Carrinhos para audiovisual",
  },
  {
    value: "4247",
    inputValue:
      "Materiais de escritório > Carrinhos para escritório > Carrinhos para livros",
  },
  {
    value: "4248",
    inputValue:
      "Materiais de escritório > Carrinhos para escritório > Carrinhos para dossiers",
  },
  {
    value: "4249",
    inputValue:
      "Materiais de escritório > Carrinhos para escritório > Carrinhos para correio",
  },
  {
    value: "4250",
    inputValue:
      "Materiais de escritório > Carrinhos para escritório > Carrinhos para acessórios",
  },
  {
    value: "4251",
    inputValue: "Materiais de escritório > Equipamento de escritório",
  },
  {
    value: "4252",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Acessórios para calculadora",
  },
  {
    value: "4253",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Calculadoras",
  },
  {
    value: "4254",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Calculadoras > Calculadoras básicas",
  },
  {
    value: "4255",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Calculadoras > Calculadoras para construção",
  },
  {
    value: "4256",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Calculadoras > Calculadoras financeiras",
  },
  {
    value: "4257",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Calculadoras > Calculadoras gráficas",
  },
  {
    value: "4258",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Calculadoras > Calculadoras científicas",
  },
  {
    value: "4259",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Dicionários e tradutores eletrónicos",
  },
  {
    value: "4260",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Rotuladoras",
  },
  {
    value: "4261",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Laminadoras",
  },
  {
    value: "4262",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Trituradoras de papel",
  },
  {
    value: "4263",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Máquina de franquear",
  },
  {
    value: "4264",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Sistemas de controlo de presença e acesso",
  },
  {
    value: "4265",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Sistemas de transcrição e gravação",
  },
  {
    value: "4266",
    inputValue:
      "Materiais de escritório > Equipamento de escritório > Máquinas de escrever",
  },
  {
    value: "4267",
    inputValue: "Materiais de escritório > Material de escritório",
  },
  {
    value: "4268",
    inputValue:
      "Materiais de escritório > Material de escritório > Campainhas de chamada",
  },
  {
    value: "4269",
    inputValue: "Materiais de escritório > Material de escritório > Pranchetas",
  },
  {
    value: "4270",
    inputValue:
      "Materiais de escritório > Material de escritório > Abre-cartas",
  },
  {
    value: "4271",
    inputValue: "Materiais de escritório > Material de escritório > Lupas",
  },
  {
    value: "4272",
    inputValue:
      "Materiais de escritório > Material de escritório > Carimbos de borracha",
  },
  {
    value: "4273",
    inputValue: "Materiais de escritório > Material de escritório > Afia-lápis",
  },
  {
    value: "4274",
    inputValue:
      "Materiais de escritório > Material de escritório > Removedores de agrafes",
  },
  {
    value: "4275",
    inputValue: "Materiais de escritório > Material de escritório > Agrafes",
  },
  {
    value: "4276",
    inputValue:
      "Materiais de escritório > Material de escritório > Suportes para fita-cola",
  },
  {
    value: "4277",
    inputValue:
      "Materiais de escritório > Material de escritório > Acessórios para instrumentos de escrita e desenho",
  },
  {
    value: "4278",
    inputValue:
      "Materiais de escritório > Material de escritório > Acessórios para instrumentos de escrita e desenho > Recargas de tinta para marcadores e sublinhadores",
  },
  {
    value: "4279",
    inputValue:
      "Materiais de escritório > Material de escritório > Acessórios para instrumentos de escrita e desenho > Recargas de tinta para marcadores e sublinhadores > Recargas para sublinhador",
  },
  {
    value: "4280",
    inputValue:
      "Materiais de escritório > Material de escritório > Acessórios para instrumentos de escrita e desenho > Recargas de tinta para marcadores e sublinhadores > Recargas para marcador",
  },
  {
    value: "4281",
    inputValue:
      "Materiais de escritório > Material de escritório > Acessórios para instrumentos de escrita e desenho > Esferográficas e recargas",
  },
  {
    value: "4282",
    inputValue:
      "Materiais de escritório > Material de escritório > Acessórios para instrumentos de escrita e desenho > Lapiseiras e recargas",
  },
  {
    value: "4283",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho",
  },
  {
    value: "4284",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Carvão vegetal para desenho",
  },
  {
    value: "4285",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Giz",
  },
  {
    value: "4286",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Lápis de cor",
  },
  {
    value: "4287",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Marcadores e sublinhadores",
  },
  {
    value: "4288",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Marcadores e sublinhadores > Sublinhadores",
  },
  {
    value: "4289",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Marcadores e sublinhadores > Marcadores",
  },
  {
    value: "4290",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Instrumentos de escrita multifuncionais",
  },
  {
    value: "4291",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Pastéis",
  },
  {
    value: "4292",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Canetas e lápis",
  },
  {
    value: "4293",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Canetas e lápis > Conjuntos de canetas e lápis",
  },
  {
    value: "4294",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Canetas e lápis > Lápis",
  },
  {
    value: "4295",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Canetas e lápis > Lápis > Lápis técnicos",
  },
  {
    value: "4296",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Canetas e lápis > Lápis > Lápis de escrita",
  },
  {
    value: "4297",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Canetas e lápis > Lápis > Lápis de escrita > Lapiseiras",
  },
  {
    value: "4298",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Canetas e lápis > Lápis > Lápis de escrita > Lápis de madeira",
  },
  {
    value: "4299",
    inputValue:
      "Materiais de escritório > Material de escritório > Instrumentos de escrita e desenho > Canetas e lápis > Canetas",
  },
  {
    value: "4300",
    inputValue: "Materiais de escritório > Processamento de papel",
  },
  {
    value: "4301",
    inputValue: "Materiais de escritório > Processamento de papel > Dedeiras",
  },
  {
    value: "4302",
    inputValue: "Materiais de escritório > Processamento de papel > Furadores",
  },
  {
    value: "4303",
    inputValue:
      "Materiais de escritório > Processamento de papel > Dobradora de papel",
  },
  {
    value: "4304",
    inputValue:
      "Materiais de escritório > Processamento de papel > Agitadora de papel",
  },
  {
    value: "4305",
    inputValue:
      "Materiais de escritório > Processamento de papel > Pisa-papéis",
  },
  {
    value: "4306",
    inputValue:
      "Materiais de escritório > Processamento de papel > Prancha para escrita",
  },
  {
    value: "4307",
    inputValue: "Materiais de escritório > Materiais de apresentação",
  },
  {
    value: "4308",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Quadros negros",
  },
  {
    value: "4309",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Quadros de apresentação",
  },
  {
    value: "4310",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Quadros de apresentação > Acessórios para quadros informativos",
  },
  {
    value: "4311",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Quadros de apresentação > Acessórios para quadros informativos > Guarnição para quadros informativos",
  },
  {
    value: "4312",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Quadros de apresentação > Acessórios para quadros informativos > Conjuntos de guarnição para quadros informativos",
  },
  {
    value: "4313",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Quadros de apresentação > Quadros de avisos",
  },
  {
    value: "4314",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Quadros de apresentação > Quadros de esponja",
  },
  {
    value: "4315",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Quadros de apresentação > Placas de montagem",
  },
  {
    value: "4316",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Quadros de apresentação > Quadros para pósteres",
  },
  {
    value: "4317",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Câmaras para documentos",
  },
  {
    value: "4318",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Quadros brancos",
  },
  {
    value: "4319",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Blocos para cavalete",
  },
  {
    value: "4320",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Cavaletes",
  },
  {
    value: "4321",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Apontadores laser",
  },
  {
    value: "4322",
    inputValue: "Materiais de escritório > Materiais de apresentação > Atris",
  },
  {
    value: "4323",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Acetatos",
  },
  {
    value: "4324",
    inputValue:
      "Materiais de escritório > Materiais de apresentação > Apresentadores sem fios",
  },
  { value: "4325", inputValue: "Materiais de escritório > Materiais de envio" },
  {
    value: "4326",
    inputValue:
      "Materiais de escritório > Materiais de envio > Caixas de mudanças e envio",
  },
  {
    value: "4327",
    inputValue:
      "Materiais de escritório > Materiais de envio > Materiais de embalamento",
  },
  {
    value: "4328",
    inputValue:
      "Materiais de escritório > Materiais de envio > Fita de embalamento",
  },
  { value: "4329", inputValue: "Cerimónias e eventos religiosos" },
  {
    value: "4330",
    inputValue: "Cerimónias e eventos religiosos > Materiais para funerais",
  },
  {
    value: "4331",
    inputValue:
      "Cerimónias e eventos religiosos > Materiais para funerais > Urnas funerárias",
  },
  {
    value: "4332",
    inputValue: "Cerimónias e eventos religiosos > Itens religiosos",
  },
  {
    value: "4333",
    inputValue: "Cerimónias e eventos religiosos > Itens religiosos > Rosários",
  },
  {
    value: "4334",
    inputValue: "Cerimónias e eventos religiosos > Itens religiosos > Pagelas",
  },
  {
    value: "4335",
    inputValue:
      "Cerimónias e eventos religiosos > Itens religiosos > Altares religiosos",
  },
  {
    value: "4336",
    inputValue:
      "Cerimónias e eventos religiosos > Itens religiosos > Véus religiosos",
  },
  {
    value: "4337",
    inputValue:
      "Cerimónias e eventos religiosos > Itens religiosos > Cartas de tarot",
  },
  {
    value: "4338",
    inputValue: "Cerimónias e eventos religiosos > Materiais para casamento",
  },
  {
    value: "4339",
    inputValue:
      "Cerimónias e eventos religiosos > Materiais para casamento > Passadeiras para casamento",
  },
  {
    value: "4340",
    inputValue:
      "Cerimónias e eventos religiosos > Materiais para casamento > Cestos para meninas das flores",
  },
  {
    value: "4341",
    inputValue:
      "Cerimónias e eventos religiosos > Materiais para casamento > Suportes e almofadas para alianças",
  },
  { value: "4342", inputValue: "Software" },
  { value: "4343", inputValue: "Software > Software de computador" },
  {
    value: "4344",
    inputValue:
      "Software > Software de computador > Software de segurança e antivírus",
  },
  {
    value: "4345",
    inputValue:
      "Software > Software de computador > Software de produtividade e negócios",
  },
  {
    value: "4346",
    inputValue:
      "Software > Software de computador > Ferramentas de programação e compiladores",
  },
  {
    value: "4347",
    inputValue:
      "Software > Software de computador > Software de manutenção e utilitários informáticos",
  },
  {
    value: "4348",
    inputValue:
      "Software > Software de computador > Software de tradução e dicionários",
  },
  {
    value: "4349",
    inputValue: "Software > Software de computador > Software educativo",
  },
  {
    value: "4350",
    inputValue:
      "Software > Software de computador > Software de contabilidade, fiscal e financeiro",
  },
  {
    value: "4351",
    inputValue:
      "Software > Software de computador > Software e dados de mapas de GPS",
  },
  {
    value: "4352",
    inputValue:
      "Software > Software de computador > Software de PDA e portáteis",
  },
  {
    value: "4353",
    inputValue:
      "Software > Software de computador > Software de design e multimédia",
  },
  {
    value: "4354",
    inputValue:
      "Software > Software de computador > Software de design e multimédia > Software de modelação 3D",
  },
  {
    value: "4355",
    inputValue:
      "Software > Software de computador > Software de design e multimédia > Software de edição de animação",
  },
  {
    value: "4356",
    inputValue:
      "Software > Software de computador > Software de design e multimédia > Software de ilustração e design gráfico",
  },
  {
    value: "4357",
    inputValue:
      "Software > Software de computador > Software de design e multimédia > Software de design de interiores e de habitação",
  },
  {
    value: "4358",
    inputValue:
      "Software > Software de computador > Software de design e multimédia > Software de uso pessoal",
  },
  {
    value: "4359",
    inputValue:
      "Software > Software de computador > Software de design e multimédia > Software de visualização de conteúdo multimédia",
  },
  {
    value: "4360",
    inputValue:
      "Software > Software de computador > Software de design e multimédia > Software de composição musical",
  },
  {
    value: "4361",
    inputValue:
      "Software > Software de computador > Software de design e multimédia > Software de edição de som",
  },
  {
    value: "4362",
    inputValue:
      "Software > Software de computador > Software de design e multimédia > Software de edição de vídeo",
  },
  {
    value: "4363",
    inputValue:
      "Software > Software de computador > Software de design e multimédia > Software de design web",
  },
  {
    value: "4364",
    inputValue: "Software > Software de computador > Software de redes",
  },
  {
    value: "4365",
    inputValue:
      "Software > Software de computador > Software de aplicação empresarial",
  },
  {
    value: "4366",
    inputValue: "Software > Software de computador > Sistemas operativos",
  },
  {
    value: "4367",
    inputValue: "Software > Software de computador > Discos de restauro",
  },
  { value: "4368", inputValue: "Software > Moedas e bens digitais" },
  {
    value: "4369",
    inputValue: "Software > Moedas e bens digitais > Ícones de computador",
  },
  {
    value: "4370",
    inputValue:
      "Software > Moedas e bens digitais > Fundo de ambiente de trabalho",
  },
  {
    value: "4371",
    inputValue: "Software > Moedas e bens digitais > Arte digital",
  },
  {
    value: "4372",
    inputValue: "Software > Moedas e bens digitais > Modelos de documentos",
  },
  {
    value: "4373",
    inputValue: "Software > Moedas e bens digitais > Tipos de letra",
  },
  {
    value: "4374",
    inputValue:
      "Software > Moedas e bens digitais > Fotografias e vídeos de banco de imagens",
  },
  {
    value: "4375",
    inputValue: "Software > Moedas e bens digitais > Moeda virtual",
  },
  { value: "4376", inputValue: "Software > Software de videojogos" },
  { value: "4377", inputValue: "Artigos de desporto" },
  { value: "4378", inputValue: "Artigos de desporto > Atletismo" },
  {
    value: "4379",
    inputValue: "Artigos de desporto > Atletismo > Basebol e softbol",
  },
  {
    value: "4380",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Placas e bases para basebol e softbol",
  },
  {
    value: "4381",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Luvas de rebatida para basebol e softbol",
  },
  {
    value: "4382",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Luvas de lançamento/receção para basebol e softbol",
  },
  {
    value: "4383",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Tapetes de lançamento para basebol e softbol",
  },
  {
    value: "4384",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Montes de lançamento para basebol e softbol",
  },
  {
    value: "4385",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Equipamento de proteção para basebol e softbol",
  },
  {
    value: "4386",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Equipamento de proteção para basebol e softbol > Capacetes de rebatida para basebol e softbol",
  },
  {
    value: "4387",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Equipamento de proteção para basebol e softbol > Protetores de peito para basebol e softbol",
  },
  {
    value: "4388",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Equipamento de proteção para basebol e softbol > Proteções de pernas para basebol e softbol",
  },
  {
    value: "4389",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Equipamento de proteção para basebol e softbol > Conjuntos de equipamento para apanhadores",
  },
  {
    value: "4390",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Equipamento de proteção para basebol e softbol > Máscaras e capacetes para apanhadores",
  },
  {
    value: "4391",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Tacos de basebol",
  },
  {
    value: "4392",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Bolas de basebol",
  },
  {
    value: "4393",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Máquinas de lançamento",
  },
  {
    value: "4394",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Tacos de softbol",
  },
  {
    value: "4395",
    inputValue:
      "Artigos de desporto > Atletismo > Basebol e softbol > Bolas de softbol",
  },
  {
    value: "4396",
    inputValue: "Artigos de desporto > Atletismo > Basquetebol",
  },
  {
    value: "4397",
    inputValue:
      "Artigos de desporto > Atletismo > Basquetebol > Acessórios e peças para aro de basquetebol",
  },
  {
    value: "4398",
    inputValue:
      "Artigos de desporto > Atletismo > Basquetebol > Acessórios e peças para aro de basquetebol > Tabelas para basquetebol",
  },
  {
    value: "4399",
    inputValue:
      "Artigos de desporto > Atletismo > Basquetebol > Acessórios e peças para aro de basquetebol > Revestimento de aro para basquetebol",
  },
  {
    value: "4400",
    inputValue:
      "Artigos de desporto > Atletismo > Basquetebol > Acessórios e peças para aro de basquetebol > Postes de aro para basquetebol",
  },
  {
    value: "4401",
    inputValue:
      "Artigos de desporto > Atletismo > Basquetebol > Acessórios e peças para aro de basquetebol > Redes para basquetebol",
  },
  {
    value: "4402",
    inputValue:
      "Artigos de desporto > Atletismo > Basquetebol > Acessórios e peças para aro de basquetebol > Arcos para basquetebol",
  },
  {
    value: "4403",
    inputValue:
      "Artigos de desporto > Atletismo > Basquetebol > Aros para basquetebol",
  },
  {
    value: "4404",
    inputValue:
      "Artigos de desporto > Atletismo > Basquetebol > Auxiliares de treino para basquetebol",
  },
  {
    value: "4405",
    inputValue:
      "Artigos de desporto > Atletismo > Basquetebol > Bolas de basquetebol",
  },
  {
    value: "4406",
    inputValue: "Artigos de desporto > Atletismo > Boxe e artes marciais",
  },
  {
    value: "4407",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de proteção para boxe e artes marciais",
  },
  {
    value: "4408",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de proteção para boxe e artes marciais > Ligaduras para boxe e MMA",
  },
  {
    value: "4409",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de proteção para boxe e artes marciais > Proteções de braços para boxe e artes marciais",
  },
  {
    value: "4410",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de proteção para boxe e artes marciais > Protetores de corpo para boxe e artes marciais",
  },
  {
    value: "4411",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de proteção para boxe e artes marciais > Proteções de cabeça para boxe e artes marciais",
  },
  {
    value: "4412",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de proteção para boxe e artes marciais > Mitenes e luvas de boxe",
  },
  {
    value: "4413",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de proteção para boxe e artes marciais > Caneleiras para MMA",
  },
  {
    value: "4414",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de treino para boxe e artes marciais",
  },
  {
    value: "4415",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de treino para boxe e artes marciais > Mitenes para boxe e MMA",
  },
  {
    value: "4416",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de treino para boxe e artes marciais > Bonecos de luta corpo a corpo",
  },
  {
    value: "4417",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de treino para boxe e artes marciais > Acessórios para sacos de treino e de boxe",
  },
  {
    value: "4418",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de treino para boxe e artes marciais > Sacos de treino e de boxe",
  },
  {
    value: "4419",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Equipamento de treino para boxe e artes marciais > Plastrons",
  },
  {
    value: "4420",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Peças para ringues de boxe",
  },
  {
    value: "4421",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Ringues de boxe",
  },
  {
    value: "4422",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Cintos para artes marciais",
  },
  {
    value: "4423",
    inputValue:
      "Artigos de desporto > Atletismo > Boxe e artes marciais > Armas para artes marciais",
  },
  {
    value: "4424",
    inputValue: "Artigos de desporto > Atletismo > Equipamento para broomball",
  },
  { value: "4425", inputValue: "Artigos de desporto > Atletismo > Claques" },
  {
    value: "4426",
    inputValue:
      "Artigos de desporto > Atletismo > Claques > Pompons para claques",
  },
  {
    value: "4427",
    inputValue:
      "Artigos de desporto > Atletismo > Itens para treinadores e árbitros",
  },
  {
    value: "4428",
    inputValue:
      "Artigos de desporto > Atletismo > Itens para treinadores e árbitros > Braçadeiras de capitão",
  },
  {
    value: "4429",
    inputValue:
      "Artigos de desporto > Atletismo > Itens para treinadores e árbitros > Delimitadores de campo",
  },
  {
    value: "4430",
    inputValue:
      "Artigos de desporto > Atletismo > Itens para treinadores e árbitros > Discos e moedas para lançamento",
  },
  {
    value: "4431",
    inputValue:
      "Artigos de desporto > Atletismo > Itens para treinadores e árbitros > Bandeiras de fiscal de linha",
  },
  {
    value: "4432",
    inputValue:
      "Artigos de desporto > Atletismo > Itens para treinadores e árbitros > Bandeiras e cartões de faltas",
  },
  {
    value: "4433",
    inputValue:
      "Artigos de desporto > Atletismo > Itens para treinadores e árbitros > Contadores de arremessos",
  },
  {
    value: "4434",
    inputValue:
      "Artigos de desporto > Atletismo > Itens para treinadores e árbitros > Cadeiras e plataformas para árbitro",
  },
  {
    value: "4435",
    inputValue:
      "Artigos de desporto > Atletismo > Itens para treinadores e árbitros > Carteiras para árbitro",
  },
  {
    value: "4436",
    inputValue:
      "Artigos de desporto > Atletismo > Itens para treinadores e árbitros > Placares",
  },
  {
    value: "4437",
    inputValue:
      "Artigos de desporto > Atletismo > Itens para treinadores e árbitros > Apitos de segurança e desporto",
  },
  {
    value: "4438",
    inputValue:
      "Artigos de desporto > Atletismo > Itens para treinadores e árbitros > Indicadores para árbitro",
  },
  { value: "4439", inputValue: "Artigos de desporto > Atletismo > Críquete" },
  {
    value: "4440",
    inputValue:
      "Artigos de desporto > Atletismo > Críquete > Bolas de críquete",
  },
  {
    value: "4441",
    inputValue:
      "Artigos de desporto > Atletismo > Críquete > Acessórios para taco de críquete",
  },
  {
    value: "4442",
    inputValue:
      "Artigos de desporto > Atletismo > Críquete > Acessórios para taco de críquete > Punhos para taco de críquete",
  },
  {
    value: "4443",
    inputValue:
      "Artigos de desporto > Atletismo > Críquete > Tacos de críquete",
  },
  {
    value: "4444",
    inputValue:
      "Artigos de desporto > Atletismo > Críquete > Conjuntos de equipamento para críquete",
  },
  {
    value: "4445",
    inputValue:
      "Artigos de desporto > Atletismo > Críquete > Equipamento de proteção para críquete",
  },
  {
    value: "4446",
    inputValue:
      "Artigos de desporto > Atletismo > Críquete > Equipamento de proteção para críquete > Luvas para críquete",
  },
  {
    value: "4447",
    inputValue:
      "Artigos de desporto > Atletismo > Críquete > Equipamento de proteção para críquete > Capacetes para críquete",
  },
  {
    value: "4448",
    inputValue:
      "Artigos de desporto > Atletismo > Críquete > Equipamento de proteção para críquete > Proteções de pernas para críquete",
  },
  {
    value: "4449",
    inputValue:
      "Artigos de desporto > Atletismo > Críquete > Postes de críquete",
  },
  { value: "4450", inputValue: "Artigos de desporto > Atletismo > Dança" },
  {
    value: "4451",
    inputValue: "Artigos de desporto > Atletismo > Dança > Barras de ballet",
  },
  { value: "4452", inputValue: "Artigos de desporto > Atletismo > Esgrima" },
  {
    value: "4453",
    inputValue:
      "Artigos de desporto > Atletismo > Esgrima > Equipamento de proteção para esgrima",
  },
  {
    value: "4454",
    inputValue:
      "Artigos de desporto > Atletismo > Esgrima > Equipamento de proteção para esgrima > Punhos e luvas para esgrima",
  },
  {
    value: "4455",
    inputValue:
      "Artigos de desporto > Atletismo > Esgrima > Equipamento de proteção para esgrima > Casacos e lamés para esgrima",
  },
  {
    value: "4456",
    inputValue:
      "Artigos de desporto > Atletismo > Esgrima > Equipamento de proteção para esgrima > Máscaras para esgrima",
  },
  {
    value: "4457",
    inputValue:
      "Artigos de desporto > Atletismo > Esgrima > Armas para esgrima",
  },
  {
    value: "4458",
    inputValue: "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo",
  },
  {
    value: "4459",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Equipamento de proteção para lacrosse e hóquei em campo",
  },
  {
    value: "4460",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Equipamento de proteção para lacrosse e hóquei em campo > Luvas para lacrosse e hóquei em campo",
  },
  {
    value: "4461",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Equipamento de proteção para lacrosse e hóquei em campo > Capacetes para lacrosse e hóquei em campo",
  },
  {
    value: "4462",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Equipamento de proteção para lacrosse e hóquei em campo > Óculos e máscaras para lacrosse e hóquei em campo",
  },
  {
    value: "4463",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Equipamento de proteção para lacrosse e hóquei em campo > Proteções para lacrosse e hóquei em campo",
  },
  {
    value: "4464",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Bolas de hóquei em campo",
  },
  {
    value: "4465",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Balizas para hóquei em campo",
  },
  {
    value: "4466",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Sticks de hóquei em campo",
  },
  {
    value: "4467",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Bolas de lacrosse",
  },
  {
    value: "4468",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Conjuntos de equipamento para lacrosse",
  },
  {
    value: "4469",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Balizas para lacrosse",
  },
  {
    value: "4470",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Peças para sticks de lacrosse",
  },
  {
    value: "4471",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Peças para sticks de lacrosse > Fita e rede para lacrosse",
  },
  {
    value: "4472",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Peças para sticks de lacrosse > Cabeças para sticks de lacrosse",
  },
  {
    value: "4473",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Peças para sticks de lacrosse > Hastes para sticks de lacrosse",
  },
  {
    value: "4474",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Sticks de lacrosse",
  },
  {
    value: "4475",
    inputValue:
      "Artigos de desporto > Atletismo > Lacrosse e hóquei em campo > Auxiliares de treino para lacrosse",
  },
  {
    value: "4476",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística",
  },
  {
    value: "4477",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Discos e bolas para hóquei",
  },
  {
    value: "4478",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Balizas para hóquei",
  },
  {
    value: "4479",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Equipamento de proteção para hóquei",
  },
  {
    value: "4480",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Equipamento de proteção para hóquei > Cotoveleiras para hóquei",
  },
  {
    value: "4481",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Equipamento de proteção para hóquei > Luvas para hóquei",
  },
  {
    value: "4482",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Equipamento de proteção para hóquei > Conjuntos de equipamento de guarda-redes para hóquei",
  },
  {
    value: "4483",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Equipamento de proteção para hóquei > Capacetes para hóquei",
  },
  {
    value: "4484",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Equipamento de proteção para hóquei > Calças para hóquei",
  },
  {
    value: "4485",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Equipamento de proteção para hóquei > Proteções de pernas e caneleiras para hóquei",
  },
  {
    value: "4486",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Equipamento de proteção para hóquei > Protetores de peito e de ombros para hóquei",
  },
  {
    value: "4487",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Equipamento de proteção para hóquei > Cintos e suspensórios para hóquei",
  },
  {
    value: "4488",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Trenós de hóquei",
  },
  {
    value: "4489",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Cuidados com stick de hóquei",
  },
  {
    value: "4490",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Peças para stick de hóquei",
  },
  {
    value: "4491",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Peças para stick de hóquei > Lâminas para stick de hóquei",
  },
  {
    value: "4492",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Peças para stick de hóquei > Hastes para stick de hóquei",
  },
  {
    value: "4493",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Sticks de hóquei",
  },
  {
    value: "4494",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Acessórios e peças para patins de gelo",
  },
  {
    value: "4495",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Acessórios e peças para patins de gelo > Botas de patinagem artística",
  },
  {
    value: "4496",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Acessórios e peças para patins de gelo > Lâminas para patins de gelo",
  },
  {
    value: "4497",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Acessórios e peças para patins de gelo > Afiadores de patins de gelo",
  },
  {
    value: "4498",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Acessórios e peças para patins de gelo > Protetores de lâmina para patins",
  },
  {
    value: "4499",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Acessórios e peças para patins de gelo > Dispositivos de aperto de cordões de patins",
  },
  {
    value: "4500",
    inputValue:
      "Artigos de desporto > Atletismo > Hóquei e patinagem artística > Patins de gelo",
  },
  {
    value: "4501",
    inputValue: "Artigos de desporto > Atletismo > Futebol Americano",
  },
  {
    value: "4502",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Luvas de Futebol Americano",
  },
  {
    value: "4503",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Postes de Golo de Futebol Americano",
  },
  {
    value: "4504",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Kicking Tees e Suportes de Bola de Futebol Americano",
  },
  {
    value: "4505",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Proteção de Futebol Americano",
  },
  {
    value: "4506",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Proteção de Futebol Americano > Calções Interiores de Proteção de Futebol Americano",
  },
  {
    value: "4507",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Proteção de Futebol Americano > Acessórios de Capacete de Futebol Americano",
  },
  {
    value: "4508",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Proteção de Futebol Americano > Acessórios de Capacete de Futebol Americano > Protetores de Queixo de Futebol Americano",
  },
  {
    value: "4509",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Proteção de Futebol Americano > Acessórios de Capacete de Futebol Americano > Máscaras Faciais de Futebol Americano",
  },
  {
    value: "4510",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Proteção de Futebol Americano > Acessórios de Capacete de Futebol Americano > Forro de Capacete de Futebol Americano",
  },
  {
    value: "4511",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Proteção de Futebol Americano > Acessórios de Capacete de Futebol Americano > Viseiras de Capacete de Futebol Americano",
  },
  {
    value: "4512",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Proteção de Futebol Americano > Capacetes de Futebol Americano",
  },
  {
    value: "4513",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Proteção de Futebol Americano > Suportes de Pescoço de Futebol Americano",
  },
  {
    value: "4514",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Proteção de Futebol Americano > Camisas e Coletes de Proteção de Costelas de Futebol Americano",
  },
  {
    value: "4515",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Proteção de Futebol Americano > Proteções de Ombros de Futebol Americano",
  },
  {
    value: "4516",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Treino de Futebol Americano",
  },
  {
    value: "4517",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Equipamento de Treino de Futebol Americano > Trenós e Bonecos de Futebol Americano",
  },
  {
    value: "4518",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol Americano > Bolas de Futebol Americano",
  },
  {
    value: "4519",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral",
  },
  {
    value: "4520",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Máscaras de treino para altitude",
  },
  {
    value: "4521",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Coquilhas para desporto",
  },
  {
    value: "4522",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Carrinhos e sacos de transporte de bolas",
  },
  {
    value: "4523",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Acessórios de bombas para bolas",
  },
  {
    value: "4524",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Acessórios de bombas para bolas > Agulhas de bombas para bolas",
  },
  {
    value: "4525",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Bombas para bolas",
  },
  {
    value: "4526",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Carrinhos e suportes de armazenamento para tapetes de exercício e ginástica",
  },
  {
    value: "4527",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Giz e spray de aderência",
  },
  {
    value: "4528",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Tapetes de ginástica",
  },
  {
    value: "4529",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Redes de treino",
  },
  {
    value: "4530",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Obstáculos e escadas de velocidade e agilidade",
  },
  {
    value: "4531",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Cones de agilidade e desporto",
  },
  {
    value: "4532",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Megafones para desporto",
  },
  {
    value: "4533",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Protetores de boca para desporto",
  },
  {
    value: "4534",
    inputValue:
      "Artigos de desporto > Atletismo > Equipamento desportivo geral > Almofadas e assentos para estádio",
  },
  { value: "4535", inputValue: "Artigos de desporto > Atletismo > Ginástica" },
  {
    value: "4536",
    inputValue:
      "Artigos de desporto > Atletismo > Ginástica > Traves de equilíbrio e barras de ginástica",
  },
  {
    value: "4537",
    inputValue:
      "Artigos de desporto > Atletismo > Ginástica > Equipamento de proteção para ginástica",
  },
  {
    value: "4538",
    inputValue:
      "Artigos de desporto > Atletismo > Ginástica > Equipamento de proteção para ginástica > Estafas para ginástica",
  },
  {
    value: "4539",
    inputValue:
      "Artigos de desporto > Atletismo > Ginástica > Anéis para ginástica",
  },
  {
    value: "4540",
    inputValue:
      "Artigos de desporto > Atletismo > Ginástica > Rampa de partida para ginástica",
  },
  {
    value: "4541",
    inputValue:
      "Artigos de desporto > Atletismo > Ginástica > Cavalo com arções",
  },
  {
    value: "4542",
    inputValue: "Artigos de desporto > Atletismo > Ginástica > Volteio",
  },
  {
    value: "4543",
    inputValue: "Artigos de desporto > Atletismo > Squash e raquetebol",
  },
  {
    value: "4544",
    inputValue:
      "Artigos de desporto > Atletismo > Squash e raquetebol > Bolas de squash e raquetebol",
  },
  {
    value: "4545",
    inputValue:
      "Artigos de desporto > Atletismo > Squash e raquetebol > Óculos para squash e raquetebol",
  },
  {
    value: "4546",
    inputValue:
      "Artigos de desporto > Atletismo > Squash e raquetebol > Luvas para squash e raquetebol",
  },
  {
    value: "4547",
    inputValue:
      "Artigos de desporto > Atletismo > Squash e raquetebol > Raquetes para raquetebol",
  },
  {
    value: "4548",
    inputValue:
      "Artigos de desporto > Atletismo > Squash e raquetebol > Raquetes para squash",
  },
  { value: "4549", inputValue: "Artigos de desporto > Atletismo > Rounders" },
  {
    value: "4550",
    inputValue:
      "Artigos de desporto > Atletismo > Rounders > Tacos para rounders",
  },
  {
    value: "4551",
    inputValue:
      "Artigos de desporto > Atletismo > Rounders > Luvas para rounders",
  },
  { value: "4552", inputValue: "Artigos de desporto > Atletismo > Rugby" },
  {
    value: "4553",
    inputValue: "Artigos de desporto > Atletismo > Rugby > Bolas de râguebi",
  },
  {
    value: "4554",
    inputValue: "Artigos de desporto > Atletismo > Rugby > Luvas de râguebi",
  },
  {
    value: "4555",
    inputValue: "Artigos de desporto > Atletismo > Rugby > Postes de râguebi",
  },
  {
    value: "4556",
    inputValue:
      "Artigos de desporto > Atletismo > Rugby > Equipamento de proteção para râguebi",
  },
  {
    value: "4557",
    inputValue:
      "Artigos de desporto > Atletismo > Rugby > Equipamento de proteção para râguebi > Proteções de cabeça para râguebi",
  },
  {
    value: "4558",
    inputValue:
      "Artigos de desporto > Atletismo > Rugby > Auxiliares de treino para râguebi",
  },
  { value: "4559", inputValue: "Artigos de desporto > Atletismo > Futebol" },
  {
    value: "4560",
    inputValue: "Artigos de desporto > Atletismo > Futebol > Bolas de futebol",
  },
  {
    value: "4561",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol > Bandeirolas de canto para futebol",
  },
  {
    value: "4562",
    inputValue: "Artigos de desporto > Atletismo > Futebol > Luvas de futebol",
  },
  {
    value: "4563",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol > Acessórios para baliza de futebol",
  },
  {
    value: "4564",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol > Balizas para futebol",
  },
  {
    value: "4565",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol > Equipamento de proteção para futebol",
  },
  {
    value: "4566",
    inputValue:
      "Artigos de desporto > Atletismo > Futebol > Equipamento de proteção para futebol > Caneleiras para futebol",
  },
  { value: "4567", inputValue: "Artigos de desporto > Atletismo > Andebol" },
  {
    value: "4568",
    inputValue: "Artigos de desporto > Atletismo > Andebol > Bolas de andebol",
  },
  { value: "4569", inputValue: "Artigos de desporto > Atletismo > Ténis" },
  {
    value: "4570",
    inputValue:
      "Artigos de desporto > Atletismo > Ténis > Carrinhos e cestos para bolas de ténis",
  },
  {
    value: "4571",
    inputValue:
      "Artigos de desporto > Atletismo > Ténis > Máquinas de bolas de ténis",
  },
  {
    value: "4572",
    inputValue:
      "Artigos de desporto > Atletismo > Ténis > Restauradores de bolas de ténis",
  },
  {
    value: "4573",
    inputValue: "Artigos de desporto > Atletismo > Ténis > Bolas de ténis",
  },
  {
    value: "4574",
    inputValue: "Artigos de desporto > Atletismo > Ténis > Redes para ténis",
  },
  {
    value: "4575",
    inputValue:
      "Artigos de desporto > Atletismo > Ténis > Acessórios para raquetes de ténis",
  },
  {
    value: "4576",
    inputValue:
      "Artigos de desporto > Atletismo > Ténis > Acessórios para raquetes de ténis > Amortecedores de vibração para raquetes",
  },
  {
    value: "4577",
    inputValue:
      "Artigos de desporto > Atletismo > Ténis > Acessórios para raquetes de ténis > Sacos para raquetes de ténis",
  },
  {
    value: "4578",
    inputValue:
      "Artigos de desporto > Atletismo > Ténis > Acessórios para raquetes de ténis > Fitas e punhos para raquetes de ténis",
  },
  {
    value: "4579",
    inputValue:
      "Artigos de desporto > Atletismo > Ténis > Acessórios para raquetes de ténis > Anilhas para raquetes de ténis",
  },
  {
    value: "4580",
    inputValue:
      "Artigos de desporto > Atletismo > Ténis > Acessórios para raquetes de ténis > Corda para raquetes de ténis",
  },
  {
    value: "4581",
    inputValue: "Artigos de desporto > Atletismo > Ténis > Raquetes de ténis",
  },
  { value: "4582", inputValue: "Artigos de desporto > Atletismo > Atletismo" },
  {
    value: "4583",
    inputValue:
      "Artigos de desporto > Atletismo > Atletismo > Lançamento do disco",
  },
  {
    value: "4584",
    inputValue:
      "Artigos de desporto > Atletismo > Atletismo > Barras de salto em altura",
  },
  {
    value: "4585",
    inputValue:
      "Artigos de desporto > Atletismo > Atletismo > Poços de salto em altura",
  },
  {
    value: "4586",
    inputValue: "Artigos de desporto > Atletismo > Atletismo > Dardos",
  },
  {
    value: "4587",
    inputValue:
      "Artigos de desporto > Atletismo > Atletismo > Poços de salto com vara",
  },
  {
    value: "4588",
    inputValue:
      "Artigos de desporto > Atletismo > Atletismo > Testemunhos para corrida de estafetas",
  },
  {
    value: "4589",
    inputValue:
      "Artigos de desporto > Atletismo > Atletismo > Lançamento do peso",
  },
  {
    value: "4590",
    inputValue:
      "Artigos de desporto > Atletismo > Atletismo > Pistolas de partida",
  },
  {
    value: "4591",
    inputValue:
      "Artigos de desporto > Atletismo > Atletismo > Lançamento de martelo",
  },
  {
    value: "4592",
    inputValue:
      "Artigos de desporto > Atletismo > Atletismo > Barreiras para pista",
  },
  {
    value: "4593",
    inputValue:
      "Artigos de desporto > Atletismo > Atletismo > Blocos de partida para pista",
  },
  {
    value: "4594",
    inputValue: "Artigos de desporto > Atletismo > Atletismo > Varas",
  },
  { value: "4595", inputValue: "Artigos de desporto > Atletismo > Voleibol" },
  {
    value: "4596",
    inputValue:
      "Artigos de desporto > Atletismo > Voleibol > Redes de voleibol",
  },
  {
    value: "4597",
    inputValue:
      "Artigos de desporto > Atletismo > Voleibol > Equipamento de proteção para voleibol",
  },
  {
    value: "4598",
    inputValue:
      "Artigos de desporto > Atletismo > Voleibol > Equipamento de proteção para voleibol > Joelheiras para voleibol",
  },
  {
    value: "4599",
    inputValue:
      "Artigos de desporto > Atletismo > Voleibol > Auxiliares de treino para voleibol",
  },
  {
    value: "4600",
    inputValue:
      "Artigos de desporto > Atletismo > Voleibol > Bolas de voleibol",
  },
  {
    value: "4601",
    inputValue: "Artigos de desporto > Atletismo > Equipamento de wallyball",
  },
  {
    value: "4602",
    inputValue: "Artigos de desporto > Atletismo > Polo-aquático",
  },
  {
    value: "4603",
    inputValue:
      "Artigos de desporto > Atletismo > Polo-aquático > Bolas de polo-aquático",
  },
  {
    value: "4604",
    inputValue:
      "Artigos de desporto > Atletismo > Polo-aquático > Toucas de polo-aquático",
  },
  {
    value: "4605",
    inputValue:
      "Artigos de desporto > Atletismo > Polo-aquático > Balizas de polo-aquático",
  },
  { value: "4606", inputValue: "Artigos de desporto > Atletismo > Luta livre" },
  {
    value: "4607",
    inputValue:
      "Artigos de desporto > Atletismo > Luta livre > Equipamento de proteção para luta livre",
  },
  {
    value: "4608",
    inputValue:
      "Artigos de desporto > Atletismo > Luta livre > Equipamento de proteção para luta livre > Protetores de cabeça para luta livre",
  },
  {
    value: "4609",
    inputValue:
      "Artigos de desporto > Atletismo > Luta livre > Equipamento de proteção para luta livre > Joelheiras para luta livre",
  },
  { value: "4610", inputValue: "Artigos de desporto > Exercício e fitness" },
  {
    value: "4611",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Rolos e rodas para abdominais",
  },
  {
    value: "4612",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Degraus para aeróbica",
  },
  {
    value: "4613",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Equipamentos de treino de equilíbrio",
  },
  {
    value: "4614",
    inputValue: "Artigos de desporto > Exercício e fitness > Cardiofitness",
  },
  {
    value: "4615",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Acessórios para máquina de cardiofitness",
  },
  {
    value: "4616",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Acessórios para máquina de cardiofitness > Acessórios para bicicleta elíptica",
  },
  {
    value: "4617",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Acessórios para máquina de cardiofitness > Acessórios para bicicleta de exercício",
  },
  {
    value: "4618",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Acessórios para máquina de cardiofitness > Acessórios para máquina de remo",
  },
  {
    value: "4619",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Acessórios para máquina de cardiofitness > Acessórios para máquina de escadas e stepper",
  },
  {
    value: "4620",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Acessórios para máquina de cardiofitness > Acessórios para passadeiras rolantes",
  },
  {
    value: "4621",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Máquinas de cardiofitness",
  },
  {
    value: "4622",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Máquinas de cardiofitness > Bicicletas elípticas",
  },
  {
    value: "4623",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Máquinas de cardiofitness > Bicicletas de exercício",
  },
  {
    value: "4624",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Máquinas de cardiofitness > Máquinas de remo",
  },
  {
    value: "4625",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Máquinas de cardiofitness > Máquinas de escadas e steppers",
  },
  {
    value: "4626",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Máquinas de cardiofitness > Máquinas de escadas e steppers > Máquinas de escadas",
  },
  {
    value: "4627",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Máquinas de cardiofitness > Máquinas de escadas e steppers > Steppers de escadas",
  },
  {
    value: "4628",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Máquinas de cardiofitness > Passadeiras",
  },
  {
    value: "4629",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cardiofitness > Cordas de saltar",
  },
  {
    value: "4630",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Bolas de exercício",
  },
  {
    value: "4631",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Faixas de exercício",
  },
  {
    value: "4632",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Bancos de exercício",
  },
  {
    value: "4633",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Tapetes para equipamento de exercício",
  },
  {
    value: "4634",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Conjuntos de equipamento e máquinas de exercício",
  },
  {
    value: "4635",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Cunhas de exercício",
  },
  {
    value: "4636",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Acessórios para rolos de esponja",
  },
  {
    value: "4637",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Acessórios para rolos de esponja > Sacos de armazenamento para rolos de esponja",
  },
  {
    value: "4638",
    inputValue: "Artigos de desporto > Exercício e fitness > Rolos de esponja",
  },
  {
    value: "4639",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Exercitadores de mãos",
  },
  {
    value: "4640",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Sistemas e tábuas de inversão",
  },
  {
    value: "4641",
    inputValue: "Artigos de desporto > Exercício e fitness > Bolas medicinais",
  },
  {
    value: "4642",
    inputValue: "Artigos de desporto > Exercício e fitness > Power Towers",
  },
  {
    value: "4643",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Barras de elevações",
  },
  {
    value: "4644",
    inputValue: "Artigos de desporto > Exercício e fitness > Bolas de reflexos",
  },
  {
    value: "4645",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Paraquedas para velocidade e resistência",
  },
  {
    value: "4646",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Refletores e luzes de segurança para desporto",
  },
  {
    value: "4647",
    inputValue: "Artigos de desporto > Exercício e fitness > Cronómetros",
  },
  {
    value: "4648",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Equipamentos de treino de suspensão",
  },
  {
    value: "4649",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Máquinas de exercício de vibração",
  },
  {
    value: "4650",
    inputValue: "Artigos de desporto > Exercício e fitness > Halterofilismo",
  },
  {
    value: "4651",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Halterofilismo > Acessórios de pesos livres",
  },
  {
    value: "4652",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Halterofilismo > Acessórios de pesos livres > Suportes de armazenamento de pesos livres",
  },
  {
    value: "4653",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Halterofilismo > Acessórios de pesos livres > Grampos para barra de musculação",
  },
  {
    value: "4654",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Halterofilismo > Acessórios de pesos livres > Barras de musculação",
  },
  {
    value: "4655",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Halterofilismo > Pesos livres",
  },
  {
    value: "4656",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Halterofilismo > Cintos para halterofilismo",
  },
  {
    value: "4657",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Halterofilismo > Suportes de mãos e luvas para halterofilismo",
  },
  {
    value: "4658",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Halterofilismo > Acessórios para banco de exercício e máquina de halterofilismo",
  },
  {
    value: "4659",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Halterofilismo > Racks e máquinas de halterofilismo",
  },
  {
    value: "4660",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Vestuário com pesos",
  },
  {
    value: "4661",
    inputValue: "Artigos de desporto > Exercício e fitness > Ioga e pilates",
  },
  {
    value: "4662",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Ioga e pilates > Máquinas de pilates",
  },
  {
    value: "4663",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Ioga e pilates > Blocos de ioga e pilates",
  },
  {
    value: "4664",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Ioga e pilates > Tapetes de ioga e pilates",
  },
  {
    value: "4665",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Ioga e pilates > Toalhas de ioga e pilates",
  },
  {
    value: "4666",
    inputValue:
      "Artigos de desporto > Exercício e fitness > Ioga e pilates > Fitas e sacos para tapetes de ioga",
  },
  { value: "4667", inputValue: "Artigos de desporto > Jogos em interior" },
  {
    value: "4668",
    inputValue: "Artigos de desporto > Jogos em interior > Hóquei de ar",
  },
  {
    value: "4669",
    inputValue:
      "Artigos de desporto > Jogos em interior > Hóquei de ar > Equipamento de hóquei de ar",
  },
  {
    value: "4670",
    inputValue:
      "Artigos de desporto > Jogos em interior > Hóquei de ar > Peças de mesa de hóquei de ar",
  },
  {
    value: "4671",
    inputValue:
      "Artigos de desporto > Jogos em interior > Hóquei de ar > Mesas de hóquei de ar",
  },
  {
    value: "4672",
    inputValue: "Artigos de desporto > Jogos em interior > Bilhares",
  },
  {
    value: "4673",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Suportes de bolas de bilhar",
  },
  {
    value: "4674",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Bolas de bilhar",
  },
  {
    value: "4675",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Acessórios para tacos de bilhar",
  },
  {
    value: "4676",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Acessórios para tacos de bilhar > Caixas para tacos de bilhar",
  },
  {
    value: "4677",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Acessórios para tacos de bilhar > Giz para taco de bilhar",
  },
  {
    value: "4678",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Acessórios para tacos de bilhar > Taqueiras",
  },
  {
    value: "4679",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Rabecas e tacos de bilhar",
  },
  {
    value: "4680",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Luvas de bilhar",
  },
  {
    value: "4681",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Luzes para mesa de bilhar",
  },
  {
    value: "4682",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Acessórios e peças para mesas de bilhar",
  },
  {
    value: "4683",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Acessórios e peças para mesas de bilhar > Bolsas para mesa de bilhar",
  },
  {
    value: "4684",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Acessórios e peças para mesas de bilhar > Escovas para mesa de bilhar",
  },
  {
    value: "4685",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Acessórios e peças para mesas de bilhar > Pano para mesa de bilhar",
  },
  {
    value: "4686",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Acessórios e peças para mesas de bilhar > Coberturas para mesa de bilhar",
  },
  {
    value: "4687",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bilhares > Mesas de bilhar",
  },
  {
    value: "4688",
    inputValue: "Artigos de desporto > Jogos em interior > Bowling",
  },
  {
    value: "4689",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bowling > Sacos para bolas de bilhar",
  },
  {
    value: "4690",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bowling > Bolas de bowling",
  },
  {
    value: "4691",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bowling > Luvas de bowling",
  },
  {
    value: "4692",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bowling > Pinos de bowling",
  },
  {
    value: "4693",
    inputValue:
      "Artigos de desporto > Jogos em interior > Bowling > Suportes de pulso para bowling",
  },
  {
    value: "4694",
    inputValue: "Artigos de desporto > Jogos em interior > Matraquilhos",
  },
  {
    value: "4695",
    inputValue:
      "Artigos de desporto > Jogos em interior > Matraquilhos > Bolas de matraquilhos",
  },
  {
    value: "4696",
    inputValue:
      "Artigos de desporto > Jogos em interior > Matraquilhos > Acessórios e peças para mesa de matraquilhos",
  },
  {
    value: "4697",
    inputValue:
      "Artigos de desporto > Jogos em interior > Matraquilhos > Mesas de matraquilhos",
  },
  {
    value: "4698",
    inputValue: "Artigos de desporto > Jogos em interior > Mesas multi-jogos",
  },
  {
    value: "4699",
    inputValue: "Artigos de desporto > Jogos em interior > Pingue Pongue",
  },
  {
    value: "4700",
    inputValue:
      "Artigos de desporto > Jogos em interior > Pingue Pongue > Bolas de pingue pongue",
  },
  {
    value: "4701",
    inputValue:
      "Artigos de desporto > Jogos em interior > Pingue Pongue > Postes e redes de pingue-pongue",
  },
  {
    value: "4702",
    inputValue:
      "Artigos de desporto > Jogos em interior > Pingue Pongue > Acessórios de raquetes de pingue-pongue",
  },
  {
    value: "4703",
    inputValue:
      "Artigos de desporto > Jogos em interior > Pingue Pongue > Conjuntos e raquetes de pingue-pongue",
  },
  {
    value: "4704",
    inputValue:
      "Artigos de desporto > Jogos em interior > Pingue Pongue > Acessórios de robô de pingue-pongue",
  },
  {
    value: "4705",
    inputValue:
      "Artigos de desporto > Jogos em interior > Pingue Pongue > Robôs de pingue-pongue",
  },
  {
    value: "4706",
    inputValue:
      "Artigos de desporto > Jogos em interior > Pingue Pongue > Mesas de pingue-pongue",
  },
  {
    value: "4707",
    inputValue:
      "Artigos de desporto > Jogos em interior > Shuffleboard de mesa",
  },
  {
    value: "4708",
    inputValue:
      "Artigos de desporto > Jogos em interior > Shuffleboard de mesa > Mesas de shuffleboard",
  },
  {
    value: "4709",
    inputValue:
      "Artigos de desporto > Jogos em interior > Shuffleboard de mesa > Pó para shuffleboard de mesa",
  },
  {
    value: "4710",
    inputValue:
      "Artigos de desporto > Jogos em interior > Shuffleboard de mesa > Discos para shuffleboard de mesa",
  },
  {
    value: "4711",
    inputValue: "Artigos de desporto > Jogos em interior > Dardos",
  },
  {
    value: "4712",
    inputValue:
      "Artigos de desporto > Jogos em interior > Dardos > Proteções traseiras para dardos",
  },
  {
    value: "4713",
    inputValue:
      "Artigos de desporto > Jogos em interior > Dardos > Peças para dardos",
  },
  {
    value: "4714",
    inputValue:
      "Artigos de desporto > Jogos em interior > Dardos > Peças para dardos > Penas para dardos",
  },
  {
    value: "4715",
    inputValue:
      "Artigos de desporto > Jogos em interior > Dardos > Peças para dardos > Hastes para dardos",
  },
  {
    value: "4716",
    inputValue:
      "Artigos de desporto > Jogos em interior > Dardos > Peças para dardos > Pontas para dardos",
  },
  {
    value: "4717",
    inputValue:
      "Artigos de desporto > Jogos em interior > Dardos > Alvos para dardos",
  },
  {
    value: "4718",
    inputValue: "Artigos de desporto > Jogos em interior > Dardos > Dardos",
  },
  {
    value: "4719",
    inputValue: "Artigos de desporto > Atividades recreativas no exterior",
  },
  {
    value: "4720",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos",
  },
  {
    value: "4721",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos náuticos e rafting",
  },
  {
    value: "4722",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos náuticos e rafting > Luvas para desportos náuticos",
  },
  {
    value: "4723",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos náuticos e rafting > Acessórios para canoas",
  },
  {
    value: "4724",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos náuticos e rafting > Canoas",
  },
  {
    value: "4725",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos náuticos e rafting > Acessórios para caiaques",
  },
  {
    value: "4726",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos náuticos e rafting > Caiaques",
  },
  {
    value: "4727",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos náuticos e rafting > Fitas para pás",
  },
  {
    value: "4728",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos náuticos e rafting > Pás e remos",
  },
  {
    value: "4729",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos náuticos e rafting > Barcos a pedais",
  },
  {
    value: "4730",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos náuticos e rafting > Barcos a remos",
  },
  {
    value: "4731",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos náuticos e rafting > Embarcações de rafting",
  },
  {
    value: "4732",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Vestuário para desportos náuticos e aquáticos",
  },
  {
    value: "4733",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Vestuário para desportos náuticos e aquáticos > Fatos impermeáveis",
  },
  {
    value: "4734",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Vestuário para desportos náuticos e aquáticos > Acessórios de coletes salva-vidas",
  },
  {
    value: "4735",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Vestuário para desportos náuticos e aquáticos > Coletes salva-vidas",
  },
  {
    value: "4736",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Vestuário para desportos náuticos e aquáticos > Proteções e camisolas de mergulho",
  },
  {
    value: "4737",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Vestuário para desportos náuticos e aquáticos > Capacetes para desportos aquáticos",
  },
  {
    value: "4738",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Vestuário para desportos náuticos e aquáticos > Combinações de mergulho",
  },
  {
    value: "4739",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Vestuário para desportos náuticos e aquáticos > Combinações de mergulho > Calças de mergulho",
  },
  {
    value: "4740",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Vestuário para desportos náuticos e aquáticos > Combinações de mergulho > Botas, luvas e capuzes de mergulho",
  },
  {
    value: "4741",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Vestuário para desportos náuticos e aquáticos > Combinações de mergulho > Casacos de mergulho",
  },
  {
    value: "4742",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Vestuário para desportos náuticos e aquáticos > Fatos de mergulho",
  },
  {
    value: "4743",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Mergulho e snorkeling",
  },
  {
    value: "4744",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Mergulho e snorkeling > Compensadores de flutuabilidade",
  },
  {
    value: "4745",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Mergulho e snorkeling > Computadores de mergulho",
  },
  {
    value: "4746",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Mergulho e snorkeling > Conjuntos de equipamento de mergulho e snorkeling",
  },
  {
    value: "4747",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Mergulho e snorkeling > Barbatanas de mergulho e snorkeling",
  },
  {
    value: "4748",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Mergulho e snorkeling > Máscaras de mergulho e snorkeling",
  },
  {
    value: "4749",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Mergulho e snorkeling > Cintos de mergulho",
  },
  {
    value: "4750",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Mergulho e snorkeling > Facas de mergulho",
  },
  {
    value: "4751",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Mergulho e snorkeling > Reguladores de mergulho",
  },
  {
    value: "4752",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Mergulho e snorkeling > Snorkels",
  },
  {
    value: "4753",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Kitesurf",
  },
  {
    value: "4754",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Kitesurf > Caixas para pranchas de kitesurf",
  },
  {
    value: "4755",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Kitesurf > Peças para pranchas de kitesurf",
  },
  {
    value: "4756",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Kitesurf > Kiteboards",
  },
  {
    value: "4757",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Kitesurf > Arneses para kitesurf e windsurf",
  },
  {
    value: "4758",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Kitesurf > Asas para kitesurf",
  },
  {
    value: "4759",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Surfe",
  },
  {
    value: "4760",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Surfe > Pranchas de bodyboard",
  },
  {
    value: "4761",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Surfe > Pranchas de paddleboard",
  },
  {
    value: "4762",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Surfe > Pranchas de skimboard",
  },
  {
    value: "4763",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Surfe > Fitas para surfe",
  },
  {
    value: "4764",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Surfe > Sacos e estojos para pranchas de surfe",
  },
  {
    value: "4765",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Surfe > Quilhas para pranchas de surfe",
  },
  {
    value: "4766",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Surfe > Cera para pranchas de surfe",
  },
  {
    value: "4767",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Surfe > Pranchas de surfe",
  },
  {
    value: "4768",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Surfe > Luvas para surfe",
  },
  {
    value: "4769",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Surfe > Pads traseiros para surfe",
  },
  {
    value: "4770",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação",
  },
  {
    value: "4771",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Auxiliares de natação para crianças",
  },
  {
    value: "4772",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Pás para mãos",
  },
  {
    value: "4773",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Pranchas de natação",
  },
  {
    value: "4774",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Boias de tração",
  },
  {
    value: "4775",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Cintos de natação",
  },
  {
    value: "4776",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Toucas de natação",
  },
  {
    value: "4777",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Luvas de natação",
  },
  {
    value: "4778",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Acessórios para máscaras e óculos de natação",
  },
  {
    value: "4779",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Máscaras e óculos de natação",
  },
  {
    value: "4780",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Pesos de natação",
  },
  {
    value: "4781",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Barbatanas de natação",
  },
  {
    value: "4782",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Barbatanas de natação > Mono barbatanas",
  },
  {
    value: "4783",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Barbatanas de natação > Barbatanas de treino",
  },
  {
    value: "4784",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Máquinas de natação",
  },
  {
    value: "4785",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Natação > Molas de nariz para natação",
  },
  {
    value: "4786",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque",
  },
  {
    value: "4787",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Kneeboard",
  },
  {
    value: "4788",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Kneeboard > Pranchas de kneeboard",
  },
  {
    value: "4789",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Tubos e jangadas para reboque",
  },
  {
    value: "4790",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Luvas para desportos aquáticos de reboque",
  },
  {
    value: "4791",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Wakeboard",
  },
  {
    value: "4792",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Wakeboard > Fixadores para pranchas de wakeboard e kiteboard",
  },
  {
    value: "4793",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Wakeboard > Peças para pranchas de wakeboard",
  },
  {
    value: "4794",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Wakeboard > Pranchas de wakeboard",
  },
  {
    value: "4795",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Esqui aquático",
  },
  {
    value: "4796",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Esqui aquático > Hidrofólio em posição sentada",
  },
  {
    value: "4797",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Esqui aquático > Fixadores para esqui aquático",
  },
  {
    value: "4798",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Esqui aquático > Sacos e estojos para esqui aquático",
  },
  {
    value: "4799",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Esqui aquático > Esquis aquáticos",
  },
  {
    value: "4800",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Desportos aquáticos de reboque > Cabos para desportos aquáticos de reboque",
  },
  {
    value: "4801",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Suportes de armazenamento de embarcações",
  },
  {
    value: "4802",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Suportes de armazenamento de embarcações > Suportes de armazenamento de barcos",
  },
  {
    value: "4803",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Suportes de armazenamento de embarcações > Suportes de armazenamento de pranchas de desportos aquáticos",
  },
  {
    value: "4804",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Windsurf",
  },
  {
    value: "4805",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Windsurf > Peças para pranchas de windsurf",
  },
  {
    value: "4806",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Windsurf > Peças para pranchas de windsurf > Quilhas para pranchas de windsurf",
  },
  {
    value: "4807",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Windsurf > Peças para pranchas de windsurf > Mastros para pranchas de windsurf",
  },
  {
    value: "4808",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Windsurf > Pranchas de windsurf",
  },
  {
    value: "4809",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Desportos náuticos e aquáticos > Windsurf > Velas de windsurf",
  },
  {
    value: "4810",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada",
  },
  {
    value: "4811",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Mobiliário de campismo",
  },
  {
    value: "4812",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Mobiliário de campismo > Acessórios de colchonete e colchão de ar",
  },
  {
    value: "4813",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Mobiliário de campismo > Colchões de ar",
  },
  {
    value: "4814",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Mobiliário de campismo > Camas de campismo",
  },
  {
    value: "4815",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Utensílios e louça de campismo",
  },
  {
    value: "4816",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Lanternas e luzes de campismo",
  },
  {
    value: "4817",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Ferramentas de campismo",
  },
  {
    value: "4818",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Ferramentas de campismo > Facas de caça e sobrevivência",
  },
  {
    value: "4819",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Ferramentas de campismo > Facas e ferramentas multifunções",
  },
  {
    value: "4820",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Aquecedores de mãos químicos",
  },
  {
    value: "4821",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Sacos de compressão",
  },
  {
    value: "4822",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Acessórios de bastões de caminhada",
  },
  {
    value: "4823",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Bastões de caminhada",
  },
  {
    value: "4824",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Mosquiteiros e redes para insetos",
  },
  {
    value: "4825",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Bússolas",
  },
  {
    value: "4826",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Chuveiros e sanitas portáteis",
  },
  {
    value: "4827",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Chuveiros e sanitas portáteis > Chuveiros portáteis e divisórias de privacidade",
  },
  {
    value: "4828",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Chuveiros e sanitas portáteis > Sanitas portáteis e dispositivos para urinação",
  },
  {
    value: "4829",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Purificadores e filtros de água portáteis",
  },
  {
    value: "4830",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Forros de sacos-cama",
  },
  {
    value: "4831",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Sacos-cama",
  },
  {
    value: "4832",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Colchonetes",
  },
  {
    value: "4833",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Acessórios para tenda",
  },
  {
    value: "4834",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Acessórios para tenda > Tendas interiores",
  },
  {
    value: "4835",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Acessórios para tenda > Tela de chão para tenda",
  },
  {
    value: "4836",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Acessórios para tenda > Estacas e postes para tenda",
  },
  {
    value: "4837",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Acessórios para tenda > Vestíbulos para tenda",
  },
  {
    value: "4838",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Tendas",
  },
  {
    value: "4839",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Campismo e caminhada > Para-ventos",
  },
  {
    value: "4840",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada",
  },
  {
    value: "4841",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Asseguradores",
  },
  {
    value: "4842",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Mosquetões",
  },
  {
    value: "4843",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Acessórios e vestuário de escalada",
  },
  {
    value: "4844",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Acessórios e vestuário de escalada > Luvas de escalada",
  },
  {
    value: "4845",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Acessórios e vestuário de escalada > Capacetes de escalada",
  },
  {
    value: "4846",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Acessórios e vestuário de escalada > Crampons",
  },
  {
    value: "4847",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Ascensores e descensores para escalada",
  },
  {
    value: "4848",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Sacos de magnésio para escalada",
  },
  {
    value: "4849",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Proteções de queda para escalada",
  },
  {
    value: "4850",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Arneses de escalada",
  },
  {
    value: "4851",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Dispositivos de proteção para escalada",
  },
  {
    value: "4852",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Corda de escalada",
  },
  {
    value: "4853",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Sacos para corda de escalada",
  },
  {
    value: "4854",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Cintas para escalada",
  },
  {
    value: "4855",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Ferramentas de escalada no gelo",
  },
  {
    value: "4856",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Parafusos para gelo",
  },
  {
    value: "4857",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Presas de escalada em interior",
  },
  {
    value: "4858",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Escalada > Fitas expresso",
  },
  {
    value: "4859",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo",
  },
  {
    value: "4860",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta",
  },
  {
    value: "4861",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Sacos e alforges para bicicleta",
  },
  {
    value: "4862",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Cestos para bicicleta",
  },
  {
    value: "4863",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Buzinas e campainhas para bicicleta",
  },
  {
    value: "4864",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Porta-bidões para bicicleta",
  },
  {
    value: "4865",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Acessórios de assento para crianças para bicicleta",
  },
  {
    value: "4866",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Assentos para criança para bicicleta",
  },
  {
    value: "4867",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Acessórios de computador para bicicleta",
  },
  {
    value: "4868",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Computadores para bicicleta",
  },
  {
    value: "4869",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Coberturas para bicicleta",
  },
  {
    value: "4870",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Guarda-lamas para bicicleta",
  },
  {
    value: "4871",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Estruturas dianteiras e traseiras para bicicleta",
  },
  {
    value: "4872",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Decoração e punhos de guiador para bicicleta",
  },
  {
    value: "4873",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Cadeados para bicicleta",
  },
  {
    value: "4874",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Espelhos para bicicleta",
  },
  {
    value: "4875",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Bombas para bicicleta",
  },
  {
    value: "4876",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Coberturas de assento e proteções de selim para bicicleta",
  },
  {
    value: "4877",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Bombas de suspensão para bicicleta",
  },
  {
    value: "4878",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Esferas de raios para bicicleta",
  },
  {
    value: "4879",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Armazenamento e suportes para bicicleta",
  },
  {
    value: "4880",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Kits e artigos de reparação de pneus para bicicleta",
  },
  {
    value: "4881",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Encaixes e fitas de pés para bicicleta",
  },
  {
    value: "4882",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Ferramentas para bicicleta",
  },
  {
    value: "4883",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Atrelados para bicicleta",
  },
  {
    value: "4884",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Dispositivos de treino para bicicleta",
  },
  {
    value: "4885",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Rodas de treino para bicicleta",
  },
  {
    value: "4886",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Sacos e estojos de transporte de bicicletas",
  },
  {
    value: "4887",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Suportes de pranchas de desportos aquáticos para bicicleta",
  },
  {
    value: "4888",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para bicicleta > Conjuntos de conversão para bicicleta elétrica",
  },
  {
    value: "4889",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta",
  },
  {
    value: "4890",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de travões para bicicleta",
  },
  {
    value: "4891",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de travões para bicicleta > Pinças de travão para bicicleta",
  },
  {
    value: "4892",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de travões para bicicleta > Alavancas de travão para bicicleta",
  },
  {
    value: "4893",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de travões para bicicleta > Rotores de travão para bicicleta",
  },
  {
    value: "4894",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de travões para bicicleta > Conjuntos de travão para bicicleta",
  },
  {
    value: "4895",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Revestimento de cabo para bicicleta",
  },
  {
    value: "4896",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Cabos para bicicleta",
  },
  {
    value: "4897",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de transmissão para bicicleta",
  },
  {
    value: "4898",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de transmissão para bicicleta > Eixos de pedaleiro para bicicleta",
  },
  {
    value: "4899",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de transmissão para bicicleta > Cassetes e rodas livres para bicicleta",
  },
  {
    value: "4900",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de transmissão para bicicleta > Pratos para bicicleta",
  },
  {
    value: "4901",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de transmissão para bicicleta > Correntes para bicicleta",
  },
  {
    value: "4902",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de transmissão para bicicleta > Pedaleiras para bicicleta",
  },
  {
    value: "4903",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de transmissão para bicicleta > Desviadores para bicicleta",
  },
  {
    value: "4904",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de transmissão para bicicleta > Pedais para bicicleta",
  },
  {
    value: "4905",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de transmissão para bicicleta > Mudanças para bicicleta",
  },
  {
    value: "4906",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Forquilhas para bicicleta",
  },
  {
    value: "4907",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Quadros para bicicleta",
  },
  {
    value: "4908",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Grupos para bicicleta",
  },
  {
    value: "4909",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Extensões de guiador para bicicleta",
  },
  {
    value: "4910",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Guiadores para bicicleta",
  },
  {
    value: "4911",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de caixa de direção para bicicleta",
  },
  {
    value: "4912",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de caixa de direção para bicicleta > Rolamentos de caixa de direção para bicicleta",
  },
  {
    value: "4913",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de caixa de direção para bicicleta > Espaçadores de caixa de direção para bicicleta",
  },
  {
    value: "4914",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Caixas de direção para bicicleta",
  },
  {
    value: "4915",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Descansos para bicicleta",
  },
  {
    value: "4916",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Selins para bicicleta",
  },
  {
    value: "4917",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Grampos de espigão de selim para bicicleta",
  },
  {
    value: "4918",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Espigões de selim para bicicleta",
  },
  {
    value: "4919",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças pequenas para bicicleta",
  },
  {
    value: "4920",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Avanços para bicicleta",
  },
  {
    value: "4921",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Adaptadores de válvula de pneu para bicicleta",
  },
  {
    value: "4922",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Tampas de válvula de pneu para bicicleta",
  },
  {
    value: "4923",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Válvulas de pneu para bicicleta",
  },
  {
    value: "4924",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Pneus para bicicleta",
  },
  {
    value: "4925",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Câmaras-de-ar para bicicleta",
  },
  {
    value: "4926",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de rodas para bicicleta",
  },
  {
    value: "4927",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de rodas para bicicleta > Peseiras para bicicleta",
  },
  {
    value: "4928",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de rodas para bicicleta > Peças de cubo para bicicleta",
  },
  {
    value: "4929",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de rodas para bicicleta > Cubos para bicicleta",
  },
  {
    value: "4930",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de rodas para bicicleta > Fitas de jante para bicicleta",
  },
  {
    value: "4931",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de rodas para bicicleta > Raios para bicicleta",
  },
  {
    value: "4932",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de rodas para bicicleta > Eixos e apertos rápidos de roda para bicicleta",
  },
  {
    value: "4933",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de rodas para bicicleta > Bicos de roda para bicicleta",
  },
  {
    value: "4934",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Peças de rodas para bicicleta > Jantes de roda para bicicleta",
  },
  {
    value: "4935",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Peças para bicicleta > Rodas para bicicleta",
  },
  {
    value: "4936",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Bicicletas",
  },
  {
    value: "4937",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios e vestuário para ciclismo",
  },
  {
    value: "4938",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios e vestuário para ciclismo > Acessórios de travessas para bicicleta",
  },
  {
    value: "4939",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios e vestuário para ciclismo > Acessórios de travessas para bicicleta > Parafusos de travessas para bicicleta",
  },
  {
    value: "4940",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios e vestuário para ciclismo > Acessórios de travessas para bicicleta > Coberturas de travessas para bicicleta",
  },
  {
    value: "4941",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios e vestuário para ciclismo > Acessórios de travessas para bicicleta > Cunhas e calços de travessas para bicicleta",
  },
  {
    value: "4942",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios e vestuário para ciclismo > Travessas para bicicleta",
  },
  {
    value: "4943",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios e vestuário para ciclismo > Luvas para bicicleta",
  },
  {
    value: "4944",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios e vestuário para ciclismo > Acessórios e peças de capacetes para bicicleta",
  },
  {
    value: "4945",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios e vestuário para ciclismo > Capacetes para bicicleta",
  },
  {
    value: "4946",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios e vestuário para ciclismo > Proteções para bicicleta",
  },
  {
    value: "4947",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios e vestuário para ciclismo > Capas de sapatos para bicicleta",
  },
  {
    value: "4948",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para triciclos",
  },
  {
    value: "4949",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Triciclos",
  },
  {
    value: "4950",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Acessórios para uniciclos",
  },
  {
    value: "4951",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Ciclismo > Uniciclos",
  },
  {
    value: "4952",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre",
  },
  {
    value: "4953",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Cuidados com cavalos",
  },
  {
    value: "4954",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Cuidados com cavalos > Lençóis e cobertores para cavalos",
  },
  {
    value: "4955",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Cuidados com cavalos > Faixas e botas para cavalos",
  },
  {
    value: "4956",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Cuidados com cavalos > Alimentação para cavalos",
  },
  {
    value: "4957",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Cuidados com cavalos > Máscaras antimoscas para cavalos",
  },
  {
    value: "4958",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Cuidados com cavalos > Escovagem de cavalos",
  },
  {
    value: "4959",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Cuidados com cavalos > Escovagem de cavalos > Aparadores para cavalos",
  },
  {
    value: "4960",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Cuidados com cavalos > Escovagem de cavalos > Luvas, escovas e pentes para escovagem de cavalos",
  },
  {
    value: "4961",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Cuidados com cavalos > Recompensas para cavalos",
  },
  {
    value: "4962",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Cuidados com cavalos > Suplementos e vitaminas para cavalos",
  },
  {
    value: "4963",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Cuidados com cavalos > Vermífugos para cavalos",
  },
  {
    value: "4964",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Equipamentos para cavalos",
  },
  {
    value: "4965",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Equipamentos para cavalos > Bridões",
  },
  {
    value: "4966",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Equipamentos para cavalos > Cabeções",
  },
  {
    value: "4967",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Equipamentos para cavalos > Cilhas",
  },
  {
    value: "4968",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Equipamentos para cavalos > Cabrestos para cavalos",
  },
  {
    value: "4969",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Equipamentos para cavalos > Arneses para cavalos",
  },
  {
    value: "4970",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Equipamentos para cavalos > Guias de trabalho para cavalos",
  },
  {
    value: "4971",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Equipamentos para cavalos > Rédeas",
  },
  {
    value: "4972",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Equipamentos para cavalos > Selas",
  },
  {
    value: "4973",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Equipamentos para cavalos > Estribos",
  },
  {
    value: "4974",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Acessórios de equipamento para cavalos",
  },
  {
    value: "4975",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Acessórios de equipamento para cavalos > Caixas de equipamento para cavalos",
  },
  {
    value: "4976",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Acessórios de equipamento para cavalos > Acessórios para selas",
  },
  {
    value: "4977",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Acessórios de equipamento para cavalos > Acessórios para selas > Bolsas e sacos para selas",
  },
  {
    value: "4978",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Acessórios de equipamento para cavalos > Acessórios para selas > Estojos e proteções de sela",
  },
  {
    value: "4979",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Acessórios de equipamento para cavalos > Acessórios para selas > Cobertores e proteções para selas",
  },
  {
    value: "4980",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Acessórios de equipamento para cavalos > Acessórios para selas > Suportes para selas",
  },
  {
    value: "4981",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Acessórios e vestuário de equitação",
  },
  {
    value: "4982",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Acessórios e vestuário de equitação > Luvas equestres",
  },
  {
    value: "4983",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Acessórios e vestuário de equitação > Capacetes equestres",
  },
  {
    value: "4984",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Acessórios e vestuário de equitação > Chicotes e chibatas de equitação",
  },
  {
    value: "4985",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Equestre > Acessórios e vestuário de equitação > Calças de equitação",
  },
  {
    value: "4986",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca",
  },
  {
    value: "4987",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Indicadores de mordida",
  },
  {
    value: "4988",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Perneiras de pesca e caça",
  },
  {
    value: "4989",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Recipientes de isco para pesca",
  },
  {
    value: "4990",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Arpões de pesca",
  },
  {
    value: "4991",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Ferramentas de remoção de anzóis de pesca",
  },
  {
    value: "4992",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Guias e linhas para pesca",
  },
  {
    value: "4993",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Redes para pesca",
  },
  {
    value: "4994",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Acessórios para carretos de pesca",
  },
  {
    value: "4995",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Acessórios para carretos de pesca > Sacos e estojos de carretos de pesca",
  },
  {
    value: "4996",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Acessórios para carretos de pesca > Lubrificantes para carretos de pesca",
  },
  {
    value: "4997",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Acessórios para carretos de pesca > Rolos de substituição para carretos de pesca",
  },
  {
    value: "4998",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Carretéis de pesca",
  },
  {
    value: "4999",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Acessórios para canas de pesca",
  },
  {
    value: "5000",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Acessórios para canas de pesca > Sacos e estojos de canas de pesca",
  },
  {
    value: "5001",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Acessórios para canas de pesca > Suportes para canas de pesca",
  },
  {
    value: "5002",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Canas de pesca",
  },
  {
    value: "5003",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Lanças de pesca",
  },
  {
    value: "5004",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Equipamento de pesca",
  },
  {
    value: "5005",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Equipamento de pesca > Engodos e iscos de pesca",
  },
  {
    value: "5006",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Equipamento de pesca > Flutuadores de pesca",
  },
  {
    value: "5007",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Equipamento de pesca > Anzóis de pesca",
  },
  {
    value: "5008",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Equipamento de pesca > Chumbos de pesca",
  },
  {
    value: "5009",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Equipamento de pesca > Destorcedores de pesca",
  },
  {
    value: "5010",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Armadilhas de pesca",
  },
  {
    value: "5011",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Materiais de fixação de mosca",
  },
  {
    value: "5012",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Materiais de fixação de mosca > Esferas para pesca",
  },
  {
    value: "5013",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Materiais de fixação de mosca > Fio de pesca",
  },
  {
    value: "5014",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Isco vivo",
  },
  {
    value: "5015",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Pesca > Caixas e sacos para equipamento de pesca",
  },
  {
    value: "5016",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe",
  },
  {
    value: "5017",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Ferramentas para divots",
  },
  {
    value: "5018",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Conjuntos de acessórios para golfe",
  },
  {
    value: "5019",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Acessórios para sacos de golfe",
  },
  {
    value: "5020",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Acessórios para sacos de golfe > Carrinhos para sacos de golfe",
  },
  {
    value: "5021",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Acessórios para sacos de golfe > Estojos e proteções de saco de golfe",
  },
  {
    value: "5022",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Sacos de golfe",
  },
  {
    value: "5023",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Marcadores de bolas de golfe",
  },
  {
    value: "5024",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Bolas de golfe",
  },
  {
    value: "5025",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Acessórios e peças para tacos de golfe",
  },
  {
    value: "5026",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Acessórios e peças para tacos de golfe > Punhos para tacos de golfe",
  },
  {
    value: "5027",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Acessórios e peças para tacos de golfe > Coberturas de cabeça para tacos de golfe",
  },
  {
    value: "5028",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Acessórios e peças para tacos de golfe > Hastes para tacos de golfe",
  },
  {
    value: "5029",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Tacos de golfe",
  },
  {
    value: "5030",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Bandeiras de golfe",
  },
  {
    value: "5031",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Luvas de golfe",
  },
  {
    value: "5032",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Tees de golfe",
  },
  {
    value: "5033",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Toalhas de golfe",
  },
  {
    value: "5034",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Golfe > Equipamentos de treino para golfe",
  },
  {
    value: "5035",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Asa-delta e paraquedismo",
  },
  {
    value: "5036",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Asa-delta e paraquedismo > Fatos de queda-livre",
  },
  {
    value: "5037",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Asa-delta e paraquedismo > Asas-delta",
  },
  {
    value: "5038",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Asa-delta e paraquedismo > Paraquedas",
  },
  {
    value: "5039",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro",
  },
  {
    value: "5040",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco",
  },
  {
    value: "5041",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Proteções de braço para tiro com arco",
  },
  {
    value: "5042",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Luvas e sistemas de libertação para tiro com arco",
  },
  {
    value: "5043",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Alvos para tiro com arco",
  },
  {
    value: "5044",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Acessórios e peças para flechas",
  },
  {
    value: "5045",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Acessórios e peças para flechas > Aletas para flecha",
  },
  {
    value: "5046",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Acessórios e peças para flechas > Nocks para flecha",
  },
  {
    value: "5047",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Acessórios e peças para flechas > Pontas de treino e broadheads",
  },
  {
    value: "5048",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Flechas e virotes",
  },
  {
    value: "5049",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Acessórios para arco e besta",
  },
  {
    value: "5050",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Arcos e bestas",
  },
  {
    value: "5051",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Arcos e bestas > Arcos compostos",
  },
  {
    value: "5052",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Arcos e bestas > Bestas",
  },
  {
    value: "5053",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Arcos e bestas > Arcos recurvos e longos",
  },
  {
    value: "5054",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro com arco > Aljavas",
  },
  {
    value: "5055",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro ao prato",
  },
  {
    value: "5056",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro ao prato > Lançadores para tiro ao prato",
  },
  {
    value: "5057",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Tiro ao prato > Pratos para tiro ao prato",
  },
  {
    value: "5058",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Caça",
  },
  {
    value: "5059",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Equipamento de proteção para caça e tiro",
  },
  {
    value: "5060",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Equipamento de proteção para caça e tiro > Luvas para caça e tiro",
  },
  {
    value: "5061",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Equipamento de proteção para caça e tiro > Casacos para caça e tiro",
  },
  {
    value: "5062",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Caça > Armadilhas para animais",
  },
  {
    value: "5063",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Caça > Intensificador de audição",
  },
  {
    value: "5064",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Caça > Abrigos e esconderijos para caça",
  },
  {
    value: "5065",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Caça > Equipamento para cães de caça",
  },
  {
    value: "5066",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Caça > Cadeira para árvore",
  },
  {
    value: "5067",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Caça > Alimentadores para animais selvagens",
  },
  {
    value: "5068",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Caça > Atrator para animais selvagens",
  },
  {
    value: "5069",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Caça > Atrator para animais selvagens > Atratores e atenuadores de odor",
  },
  {
    value: "5070",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Caça > Atrator para animais selvagens > Chamarizes para animais selvagens e caça",
  },
  {
    value: "5071",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Caça > Atrator para animais selvagens > Engodos para animais selvagens e caça",
  },
  {
    value: "5072",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Caça > Atrator para animais selvagens > Minerais, alimentos e iscos para animais selvagens",
  },
  {
    value: "5073",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft",
  },
  {
    value: "5074",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Airsoft",
  },
  {
    value: "5075",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Airsoft > Acessórios e peças para réplicas de airsoft",
  },
  {
    value: "5076",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Airsoft > Acessórios e peças para réplicas de airsoft > Baterias para réplicas de airsoft",
  },
  {
    value: "5077",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Airsoft > Réplicas de airsoft",
  },
  {
    value: "5078",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Airsoft > BBs para airsoft",
  },
  {
    value: "5079",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Paintball",
  },
  {
    value: "5080",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Equipamento de proteção para paintball e airsoft",
  },
  {
    value: "5081",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Equipamento de proteção para paintball e airsoft > Luvas para paintball e airsoft",
  },
  {
    value: "5082",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Equipamento de proteção para paintball e airsoft > Máscaras e óculos para paintball e airsoft",
  },
  {
    value: "5083",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Equipamento de proteção para paintball e airsoft > Proteções para paintball e airsoft",
  },
  {
    value: "5084",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Equipamento de proteção para paintball e airsoft > Coletes para paintball e airsoft",
  },
  {
    value: "5085",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Paintball > Lança-granadas para paintball",
  },
  {
    value: "5086",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Paintball > Granadas para paintball",
  },
  {
    value: "5087",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Paintball > Acessórios e peças para réplicas de paintball",
  },
  {
    value: "5088",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Paintball > Acessórios e peças para réplicas de paintball > Botijas de ar para paintball",
  },
  {
    value: "5089",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Paintball > Acessórios e peças para réplicas de paintball > Canos para réplicas de paintball",
  },
  {
    value: "5090",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Paintball > Acessórios e peças para réplicas de paintball > Aproximadores de botijas para réplicas de paintball",
  },
  {
    value: "5091",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Paintball > Acessórios e peças para réplicas de paintball > Loaders para paintball",
  },
  {
    value: "5092",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Paintball > Réplicas para paintball",
  },
  {
    value: "5093",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Paintball > Conjuntos e arneses para paintball",
  },
  {
    value: "5094",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Paintball e airsoft > Paintball > Bolas de paintball",
  },
  {
    value: "5095",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Acessórios de tiro e carreira de tiro",
  },
  {
    value: "5096",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Acessórios de tiro e carreira de tiro > Apoios para tiro",
  },
  {
    value: "5097",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Acessórios de tiro e carreira de tiro > Bipés e monopés para tiro",
  },
  {
    value: "5098",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Caça e tiro > Acessórios de tiro e carreira de tiro > Alvos para tiro",
  },
  {
    value: "5099",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Acessórios para sistema de hidratação",
  },
  {
    value: "5100",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Sistemas de hidratação",
  },
  {
    value: "5101",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Patinagem com patins em linha e de 4 rodas",
  },
  {
    value: "5102",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Patinagem com patins em linha e de 4 rodas > Equipamento de proteção para patinagem com patins em linha e de 4 rodas",
  },
  {
    value: "5103",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Patinagem com patins em linha e de 4 rodas > Equipamento de proteção para patinagem com patins em linha e de 4 rodas > Proteções para patinagem com patins de 4 rodas",
  },
  {
    value: "5104",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Patinagem com patins em linha e de 4 rodas > Proteções para patinagem com patins em linha",
  },
  {
    value: "5105",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Patinagem com patins em linha e de 4 rodas > Patins em linha",
  },
  {
    value: "5106",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Patinagem com patins em linha e de 4 rodas > Peças para patins de 4 rodas",
  },
  {
    value: "5107",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Patinagem com patins em linha e de 4 rodas > Patins de 4 rodas",
  },
  {
    value: "5108",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Patinagem com patins em linha e de 4 rodas > Esquis com rodas",
  },
  {
    value: "5109",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Kite Buggying",
  },
  {
    value: "5110",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Kite Buggying > Kite Buggies",
  },
  {
    value: "5111",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Kite Buggying > Acessórios para Kite Buggy",
  },
  {
    value: "5112",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior",
  },
  {
    value: "5113",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Badminton",
  },
  {
    value: "5114",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Badminton > Redes de badminton",
  },
  {
    value: "5115",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Badminton > Conjuntos e raquetes de badminton",
  },
  {
    value: "5116",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Badminton > Volantes",
  },
  {
    value: "5117",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Shuffleboard",
  },
  {
    value: "5118",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Shuffleboard > Tacos de Shuffleboard",
  },
  {
    value: "5119",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Shuffleboard > Discos de Shuffleboard",
  },
  {
    value: "5120",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Disc Golf",
  },
  {
    value: "5121",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Disc Golf > Sacos para Disc Golf",
  },
  {
    value: "5122",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Disc Golf > Cestos para Disc Golf",
  },
  {
    value: "5123",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Jogos de relvado",
  },
  {
    value: "5124",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Conjuntos de padbol",
  },
  {
    value: "5125",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Pickleball",
  },
  {
    value: "5126",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Pickleball > Raquetes de Pickleball",
  },
  {
    value: "5127",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Pickleball > Bolas de Pickleball",
  },
  {
    value: "5128",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Padel e ténis em plataforma",
  },
  {
    value: "5129",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Padel e ténis em plataforma > Raquetes de padel e ténis em plataforma",
  },
  {
    value: "5130",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Padel e ténis em plataforma > Bolas de ténis em plataforma",
  },
  {
    value: "5131",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Tetherball",
  },
  {
    value: "5132",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Tetherball > Postes de Tetherball",
  },
  {
    value: "5133",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Tetherball > Conjuntos de Tetherball",
  },
  {
    value: "5134",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Jogos de exterior > Tetherball > Bolas de Tetherball",
  },
  {
    value: "5135",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Scooters",
  },
  {
    value: "5136",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate",
  },
  {
    value: "5137",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate > Carril para skate",
  },
  {
    value: "5138",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate > Rampas para skate",
  },
  {
    value: "5139",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate > Peças para skate",
  },
  {
    value: "5140",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate > Peças para skate > Decks para skate",
  },
  {
    value: "5141",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate > Peças para skate > Peças pequenas para skate",
  },
  {
    value: "5142",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate > Peças para skate > Trucks de skate",
  },
  {
    value: "5143",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate > Peças para skate > Rodas de skate",
  },
  {
    value: "5144",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate > Equipamento de proteção para skate",
  },
  {
    value: "5145",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate > Equipamento de proteção para skate > Capacetes para skate",
  },
  {
    value: "5146",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate > Equipamento de proteção para skate > Luvas para skate",
  },
  {
    value: "5147",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate > Equipamento de proteção para skate > Proteções para skate",
  },
  {
    value: "5148",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Skate > Skates",
  },
  {
    value: "5149",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno",
  },
  {
    value: "5150",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Segurança contra avalanche",
  },
  {
    value: "5151",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Segurança contra avalanche > Sondas de avalanche",
  },
  {
    value: "5152",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Segurança contra avalanche > Airbags de segurança para avalanche",
  },
  {
    value: "5153",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard",
  },
  {
    value: "5154",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Sacos para esqui e snowboard",
  },
  {
    value: "5155",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Acessórios de óculos para esqui e snowboard",
  },
  {
    value: "5156",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Acessórios de óculos para esqui e snowboard > Lentes de óculos para esqui e snowboard",
  },
  {
    value: "5157",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Óculos para esqui e snowboard",
  },
  {
    value: "5158",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Capacetes para esqui e snowboard",
  },
  {
    value: "5159",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Cabos para esqui e snowboard",
  },
  {
    value: "5160",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Barras de armazenamento para esqui e snowboard",
  },
  {
    value: "5161",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Ferramentas de ajuste para esqui e snowboard",
  },
  {
    value: "5162",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Cera para esqui e snowboard",
  },
  {
    value: "5163",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Peças para fixadores de esqui",
  },
  {
    value: "5164",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Fixadores de esqui",
  },
  {
    value: "5165",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Botas para esqui",
  },
  {
    value: "5166",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Bastões para esqui",
  },
  {
    value: "5167",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Esquis",
  },
  {
    value: "5168",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Esquis > Esquis de fundo",
  },
  {
    value: "5169",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Esquis > Esqui alpino",
  },
  {
    value: "5170",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Peças de fixadores de snowboard",
  },
  {
    value: "5171",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Fixadores de snowboard",
  },
  {
    value: "5172",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Botas para snowboard",
  },
  {
    value: "5173",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Esqui e snowboard > Pranchas de snowboard",
  },
  {
    value: "5174",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Trenós",
  },
  {
    value: "5175",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Caminhada com raquetes de neve",
  },
  {
    value: "5176",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Caminhada com raquetes de neve > Fixadores de raquetes de neve",
  },
  {
    value: "5177",
    inputValue:
      "Artigos de desporto > Atividades recreativas no exterior > Atividades e desportos de inverno > Caminhada com raquetes de neve > Raquetes de neve",
  },
  { value: "5178", inputValue: "Brinquedos e jogos" },
  { value: "5179", inputValue: "Brinquedos e jogos > Temporizadores de jogo" },
  { value: "5180", inputValue: "Brinquedos e jogos > Jogos" },
  {
    value: "5181",
    inputValue: "Brinquedos e jogos > Jogos > Acessórios para Battle Top",
  },
  { value: "5182", inputValue: "Brinquedos e jogos > Jogos > Battle Tops" },
  {
    value: "5183",
    inputValue: "Brinquedos e jogos > Jogos > Conjuntos de bingo",
  },
  {
    value: "5184",
    inputValue: "Brinquedos e jogos > Jogos > Conjuntos de blackjack e craps",
  },
  {
    value: "5185",
    inputValue: "Brinquedos e jogos > Jogos > Jogos de tabuleiro",
  },
  {
    value: "5186",
    inputValue: "Brinquedos e jogos > Jogos > Acessórios de jogos de cartas",
  },
  { value: "5187", inputValue: "Brinquedos e jogos > Jogos > Jogos de cartas" },
  {
    value: "5188",
    inputValue: "Brinquedos e jogos > Jogos > Jogos de dexteridade",
  },
  {
    value: "5189",
    inputValue: "Brinquedos e jogos > Jogos > Jogos e conjuntos de dados",
  },
  {
    value: "5190",
    inputValue: "Brinquedos e jogos > Jogos > Acessórios de fichas de poker",
  },
  {
    value: "5191",
    inputValue:
      "Brinquedos e jogos > Jogos > Acessórios de fichas de poker > Tabuleiros e transportadores de fichas de poker",
  },
  {
    value: "5192",
    inputValue: "Brinquedos e jogos > Jogos > Conjuntos e fichas de poker",
  },
  {
    value: "5193",
    inputValue: "Brinquedos e jogos > Jogos > Jogos eletrónicos portáteis",
  },
  {
    value: "5194",
    inputValue: "Brinquedos e jogos > Jogos > Conjuntos e rodas de roleta",
  },
  { value: "5195", inputValue: "Brinquedos e jogos > Jogos > Slot Machines" },
  {
    value: "5196",
    inputValue: "Brinquedos e jogos > Jogos > Jogos de ladrilhos",
  },
  {
    value: "5197",
    inputValue: "Brinquedos e jogos > Equipamentos para brincar no exterior",
  },
  {
    value: "5198",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Acessórios de insufláveis",
  },
  {
    value: "5199",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Insufláveis",
  },
  {
    value: "5200",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Baloiços de brincar",
  },
  {
    value: "5201",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Túneis e tendas de brincar",
  },
  {
    value: "5202",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Casas de brincar",
  },
  {
    value: "5203",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Saltitões",
  },
  {
    value: "5204",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Caixas de areia",
  },
  {
    value: "5205",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Balancés",
  },
  {
    value: "5206",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Escorregas",
  },
  {
    value: "5207",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Andas",
  },
  {
    value: "5208",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Acessórios para conjuntos de recreio e baloiços",
  },
  {
    value: "5209",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Conjuntos de recreio e baloiços",
  },
  {
    value: "5210",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Acessórios para trampolins",
  },
  {
    value: "5211",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Trampolins",
  },
  {
    value: "5212",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Equipamentos para brincar com água",
  },
  {
    value: "5213",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Equipamentos para brincar com água > Aspersores de brincar",
  },
  {
    value: "5214",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Equipamentos para brincar com água > Escorregas e parques aquáticos",
  },
  {
    value: "5215",
    inputValue:
      "Brinquedos e jogos > Equipamentos para brincar no exterior > Equipamentos para brincar com água > Mesas de água",
  },
  { value: "5216", inputValue: "Brinquedos e jogos > Puzzles" },
  {
    value: "5217",
    inputValue:
      "Brinquedos e jogos > Puzzles > Acessórios de puzzles quebra-cabeças",
  },
  {
    value: "5218",
    inputValue: "Brinquedos e jogos > Puzzles > Puzzles quebra-cabeças",
  },
  {
    value: "5219",
    inputValue: "Brinquedos e jogos > Puzzles > Puzzles mecânicos",
  },
  {
    value: "5220",
    inputValue: "Brinquedos e jogos > Puzzles > Puzzles em madeira",
  },
  { value: "5221", inputValue: "Brinquedos e jogos > Brinquedos" },
  {
    value: "5222",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos de atividade",
  },
  {
    value: "5223",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de atividade > Jogos de bola e copo",
  },
  {
    value: "5224",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de atividade > Bolas saltitantes",
  },
  {
    value: "5225",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de atividade > Solução para soprar bolhas",
  },
  {
    value: "5226",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de atividade > Brinquedos para soprar bolhas",
  },
  {
    value: "5227",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de atividade > Brinquedos de mola",
  },
  {
    value: "5228",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de atividade > Berlindes",
  },
  {
    value: "5229",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de atividade > Brinquedos de raquetes",
  },
  {
    value: "5230",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de atividade > Brinquedos de fitas",
  },
  {
    value: "5231",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de atividade > Piões",
  },
  {
    value: "5232",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de atividade > Brinquedos de bugalha",
  },
  {
    value: "5233",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de atividade > Acessórios e peças para ioió",
  },
  {
    value: "5234",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de atividade > Ioiós",
  },
  {
    value: "5235",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de arte e desenho",
  },
  {
    value: "5236",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de arte e desenho > Plasticina e massa de modelar",
  },
  {
    value: "5237",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de arte e desenho > Brinquedos de placas de desenho",
  },
  {
    value: "5238",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios para piscinas de bolas",
  },
  {
    value: "5239",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios para piscinas de bolas > Bolas para piscinas de bolas",
  },
  {
    value: "5240",
    inputValue: "Brinquedos e jogos > Brinquedos > Piscinas de bolas",
  },
  {
    value: "5241",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos de banho",
  },
  {
    value: "5242",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos de praia",
  },
  {
    value: "5243",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos de construção",
  },
  {
    value: "5244",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de construção > Brinquedos de conjuntos de construção",
  },
  {
    value: "5245",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de construção > Blocos de esponja",
  },
  {
    value: "5246",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de construção > Blocos de encaixar",
  },
  {
    value: "5247",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de construção > Conjuntos de corridas de berlindes",
  },
  {
    value: "5248",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de construção > Blocos de madeira",
  },
  {
    value: "5249",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar",
  },
  {
    value: "5250",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar > Brinquedos e figuras de ação",
  },
  {
    value: "5251",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar > Figuras Bobblehead",
  },
  {
    value: "5252",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar > Acessórios para bonecas e figuras de ação",
  },
  {
    value: "5253",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar > Acessórios para casas de bonecas",
  },
  {
    value: "5254",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar > Casas de bonecas",
  },
  {
    value: "5255",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar > Bonecas",
  },
  {
    value: "5256",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar > Bonecas de papel e magnéticas",
  },
  {
    value: "5257",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar > Acessórios para fantoches e teatros de fantoches",
  },
  {
    value: "5258",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar > Teatros de fantoches",
  },
  {
    value: "5259",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar > Fantoches e marionetas",
  },
  {
    value: "5260",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar > Animais de peluche",
  },
  {
    value: "5261",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Bonecas, figuras e conjuntos de brincar > Conjuntos de brincar",
  },
  {
    value: "5262",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos educacionais",
  },
  {
    value: "5263",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos educacionais > Quintas de formigas",
  },
  {
    value: "5264",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos educacionais > Modelos e brinquedos de astronomia",
  },
  {
    value: "5265",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos educacionais > Conjuntos de recolha de insetos",
  },
  {
    value: "5266",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos educacionais > Cartões didáticos",
  },
  {
    value: "5267",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos educacionais > Brinquedos de leitura",
  },
  {
    value: "5268",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos educacionais > Conjuntos de exploração e ciência",
  },
  {
    value: "5269",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos educacionais > Brinquedos de ábacos",
  },
  {
    value: "5270",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos executivos",
  },
  {
    value: "5271",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos executivos > Brinquedos magnéticos",
  },
  {
    value: "5272",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios para brinquedos voadores",
  },
  {
    value: "5273",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios para brinquedos voadores > Acessórios para papagaios",
  },
  {
    value: "5274",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios para brinquedos voadores > Acessórios para papagaios > Enroladores e linhas para papagaios",
  },
  {
    value: "5275",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos voadores",
  },
  {
    value: "5276",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos voadores > Foguetes de ar e água",
  },
  {
    value: "5277",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos voadores > Papagaios",
  },
  {
    value: "5278",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos voadores > Brinquedos de planadores",
  },
  {
    value: "5279",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos voadores > Brinquedos de paraquedas",
  },
  {
    value: "5280",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos musicais",
  },
  {
    value: "5281",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos musicais > Brinquedos de instrumentos",
  },
  {
    value: "5282",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios de veículos de brincar",
  },
  {
    value: "5283",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios de veículos de brincar > Acessórios de brinquedos de pistas e carros de corrida",
  },
  {
    value: "5284",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios de veículos de brincar > Acessórios para brinquedos de comboios",
  },
  {
    value: "5285",
    inputValue: "Brinquedos e jogos > Brinquedos > Veículos de brincar",
  },
  {
    value: "5286",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Veículos de brincar > Brinquedos de aviões",
  },
  {
    value: "5287",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Veículos de brincar > Brinquedos de barcos",
  },
  {
    value: "5288",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Veículos de brincar > Brinquedos de carros",
  },
  {
    value: "5289",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Veículos de brincar > Brinquedos de helicópteros",
  },
  {
    value: "5290",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Veículos de brincar > Brinquedos de motas",
  },
  {
    value: "5291",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Veículos de brincar > Conjuntos de brinquedos de pistas e carros de corrida",
  },
  {
    value: "5292",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Veículos de brincar > Brinquedos de naves espaciais",
  },
  {
    value: "5293",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Veículos de brincar > Conjuntos de brinquedos de comboios",
  },
  {
    value: "5294",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Veículos de brincar > Brinquedos de camiões e veículos de construção",
  },
  {
    value: "5295",
    inputValue: "Brinquedos e jogos > Brinquedos > Faz de conta",
  },
  {
    value: "5296",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Faz de conta > Dinheiro de brincar",
  },
  {
    value: "5297",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Faz de conta > Dispositivos eletrónicos de faz de conta",
  },
  {
    value: "5298",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Faz de conta > Faz de conta de tarefas domésticas",
  },
  {
    value: "5299",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Faz de conta > Faz de conta de jardim e relvado",
  },
  {
    value: "5300",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Faz de conta > Faz de conta de profissões e encenação",
  },
  {
    value: "5301",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Faz de conta > Brinquedos de mercearia e compras",
  },
  {
    value: "5302",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Faz de conta > Comida de brincar e brinquedos de cozinhas",
  },
  {
    value: "5303",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Faz de conta > Comida de brincar e brinquedos de cozinhas > Comida de brincar",
  },
  {
    value: "5304",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Faz de conta > Comida de brincar e brinquedos de cozinhas > Utensílios de cozinha de brincar",
  },
  {
    value: "5305",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Faz de conta > Comida de brincar e brinquedos de cozinhas > Brinquedos de cozinhas",
  },
  {
    value: "5306",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Faz de conta > Comida de brincar e brinquedos de cozinhas > Brinquedos de louça",
  },
  {
    value: "5307",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Faz de conta > Brinquedos de ferramentas",
  },
  {
    value: "5308",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios de brinquedos com controlo remoto",
  },
  {
    value: "5309",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos com controlo remoto",
  },
  {
    value: "5310",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos com controlo remoto > Dirigíveis e aeronaves com controlo remoto",
  },
  {
    value: "5311",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos com controlo remoto > Embarcações e barcos com controlo remoto",
  },
  {
    value: "5312",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos com controlo remoto > Camiões e carros com controlo remoto",
  },
  {
    value: "5313",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos com controlo remoto > Helicópteros com controlo remoto",
  },
  {
    value: "5314",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos com controlo remoto > Motas com controlo remoto",
  },
  {
    value: "5315",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos com controlo remoto > Aviões com controlo remoto",
  },
  {
    value: "5316",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos com controlo remoto > Robôs com controlo remoto",
  },
  {
    value: "5317",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos com controlo remoto > Tanques com controlo remoto",
  },
  {
    value: "5318",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios de brinquedos para conduzir",
  },
  {
    value: "5319",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos para conduzir",
  },
  {
    value: "5320",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos para conduzir > Veículos elétricos para conduzir",
  },
  {
    value: "5321",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos para conduzir > Cavalos de brincar",
  },
  {
    value: "5322",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos para conduzir > Veículos a pedais e de empurrar para conduzir",
  },
  {
    value: "5323",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos para conduzir > Brinquedos com mola ou de balançar para conduzir",
  },
  {
    value: "5324",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos para conduzir > Carrela",
  },
  {
    value: "5325",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos robóticos",
  },
  {
    value: "5326",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios de brinquedos de desporto",
  },
  {
    value: "5327",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios de brinquedos de desporto > Acessórios de brinquedos de fitness",
  },
  {
    value: "5328",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios de brinquedos de desporto > Acessórios de brinquedos de fitness > Acessórios de arco",
  },
  {
    value: "5329",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos de desporto",
  },
  {
    value: "5330",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Brinquedos de basebol",
  },
  {
    value: "5331",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Brinquedos de basquetebol",
  },
  {
    value: "5332",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Boomerangs",
  },
  {
    value: "5333",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Brinquedos de bowling",
  },
  {
    value: "5334",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Fingerboards e conjuntos de fingerboard",
  },
  {
    value: "5335",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Brinquedos de pesca",
  },
  {
    value: "5336",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Brinquedos de fitness",
  },
  {
    value: "5337",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Brinquedos de fitness > Arcos",
  },
  {
    value: "5338",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Discos voadores",
  },
  {
    value: "5339",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Footbags",
  },
  {
    value: "5340",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Brinquedos de golfe",
  },
  {
    value: "5341",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Brinquedos de hóquei",
  },
  {
    value: "5342",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Bolas de recreio",
  },
  {
    value: "5343",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Brinquedos de desportos com raquetes",
  },
  {
    value: "5344",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de desporto > Brinquedos de Futebol Americano",
  },
  {
    value: "5345",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de cesto de oferta",
  },
  {
    value: "5346",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Acessórios de brinquedos de aparelhos e armas",
  },
  {
    value: "5347",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos de aparelhos e armas",
  },
  {
    value: "5348",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos visuais",
  },
  {
    value: "5349",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos visuais > Caleidoscópios",
  },
  {
    value: "5350",
    inputValue:
      "Brinquedos e jogos > Brinquedos > Brinquedos visuais > Prismas",
  },
  {
    value: "5351",
    inputValue: "Brinquedos e jogos > Brinquedos > Brinquedos de corda",
  },
  { value: "5352", inputValue: "Veículos e peças" },
  {
    value: "5353",
    inputValue: "Veículos e peças > Acessórios e peças para veículos",
  },
  {
    value: "5354",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para aeronaves",
  },
  {
    value: "5355",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Componentes eletrónicos para veículos motorizados",
  },
  {
    value: "5356",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Componentes eletrónicos para veículos motorizados > Sistemas integrados no tablier e leitores A/V para veículos motorizados",
  },
  {
    value: "5357",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Componentes eletrónicos para veículos motorizados > Amplificadores para veículos motorizados",
  },
  {
    value: "5358",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Componentes eletrónicos para veículos motorizados > Adaptadores de cassetes para veículos motorizados",
  },
  {
    value: "5359",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Componentes eletrónicos para veículos motorizados > Leitores de cassetes para veículos motorizados",
  },
  {
    value: "5360",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Componentes eletrónicos para veículos motorizados > Crossovers e equalizadores para veículos motorizados",
  },
  {
    value: "5361",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Componentes eletrónicos para veículos motorizados > Câmaras de estacionamento para veículos motorizados",
  },
  {
    value: "5362",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Componentes eletrónicos para veículos motorizados > Sistemas mãos-livres para veículos motorizados",
  },
  {
    value: "5363",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Componentes eletrónicos para veículos motorizados > Altifalantes para veículos motorizados",
  },
  {
    value: "5364",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Componentes eletrónicos para veículos motorizados > Subwoofers para veículos motorizados",
  },
  {
    value: "5365",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Componentes eletrónicos para veículos motorizados > Suportes de monitor de vídeo para veículos motorizados",
  },
  {
    value: "5366",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados",
  },
  {
    value: "5367",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Travagem para veículos motorizados",
  },
  {
    value: "5368",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Alcatifa e estofos para veículos motorizados",
  },
  {
    value: "5369",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Controlo de climatização para veículos motorizados",
  },
  {
    value: "5370",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Controlos para veículos motorizados",
  },
  {
    value: "5371",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Circulação de óleo do motor para veículos motorizados",
  },
  {
    value: "5372",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Peças de motor para veículos motorizados",
  },
  {
    value: "5373",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Motores para veículos motorizados",
  },
  {
    value: "5374",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Escapes para veículos motorizados",
  },
  {
    value: "5375",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Peças de carroçaria para veículos motorizados",
  },
  {
    value: "5376",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas de combustível para veículos motorizados",
  },
  {
    value: "5377",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Peças de interior para veículos motorizados",
  },
  {
    value: "5378",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Iluminação para veículos motorizados",
  },
  {
    value: "5379",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Espelhos para veículos motorizados",
  },
  {
    value: "5380",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas elétricos e de alimentação para veículos motorizados",
  },
  {
    value: "5381",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Assentos para veículos motorizados",
  },
  {
    value: "5382",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Manómetros e sensores para veículos motorizados",
  },
  {
    value: "5383",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Peças de suspensão para veículos motorizados",
  },
  {
    value: "5384",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Peças de reboque para veículos motorizados",
  },
  {
    value: "5385",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Peças de transmissão para veículos motorizados",
  },
  {
    value: "5386",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas de rodas para veículos motorizados",
  },
  {
    value: "5387",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas de rodas para veículos motorizados > Rodas e jantes para veículos motorizados",
  },
  {
    value: "5388",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas de rodas para veículos motorizados > Rodas e jantes para veículos motorizados > Rodas e jantes para automóveis",
  },
  {
    value: "5389",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas de rodas para veículos motorizados > Rodas e jantes para veículos motorizados > Rodas e jantes para motas",
  },
  {
    value: "5390",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas de rodas para veículos motorizados > Rodas e jantes para veículos motorizados > Rodas e jantes para veículos todo-o-terreno",
  },
  {
    value: "5391",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas de rodas para veículos motorizados > Acessórios de pneus para veículos motorizados",
  },
  {
    value: "5392",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas de rodas para veículos motorizados > Pneus para veículos motorizados",
  },
  {
    value: "5393",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas de rodas para veículos motorizados > Pneus para veículos motorizados > Pneus para automóveis",
  },
  {
    value: "5394",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas de rodas para veículos motorizados > Pneus para veículos motorizados > Pneus para motas",
  },
  {
    value: "5395",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas de rodas para veículos motorizados > Pneus para veículos motorizados > Pneus para veículos todo-o-terreno",
  },
  {
    value: "5396",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Sistemas de rodas para veículos motorizados > Peças de rodas para veículos motorizados",
  },
  {
    value: "5397",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Peças para veículos motorizados > Acessórios e peças de janelas para veículos motorizados",
  },
  {
    value: "5398",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos",
  },
  {
    value: "5399",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Recipientes para combustível portáteis",
  },
  {
    value: "5400",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Limpeza de veículos",
  },
  {
    value: "5401",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Limpeza de veículos > Escovas de lavagem de automóveis",
  },
  {
    value: "5402",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Limpeza de veículos > Soluções de lavagem de automóveis",
  },
  {
    value: "5403",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Limpeza de veículos > Produtos de limpeza para alcatifas e estofos de automóveis",
  },
  {
    value: "5404",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Limpeza de veículos > Kits de limpeza do sistema de injeção de combustível de automóveis",
  },
  {
    value: "5405",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Limpeza de veículos > Produtos de limpeza para vidros de automóveis",
  },
  {
    value: "5406",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Limpeza de veículos > Produtos de polimento, protetores e ceras para automóveis",
  },
  {
    value: "5407",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Coberturas para veículos",
  },
  {
    value: "5408",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Coberturas para veículos > Cabinas para carrinhos de golfe",
  },
  {
    value: "5409",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Coberturas para veículos > Coberturas de para-brisas para veículos motorizados",
  },
  {
    value: "5410",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Coberturas para veículos > Coberturas de caixa de carga",
  },
  {
    value: "5411",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Coberturas para veículos > Tejadilhos rígidos para veículos",
  },
  {
    value: "5412",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Coberturas para veículos > Tejadilhos flexíveis para veículos",
  },
  {
    value: "5413",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Coberturas para veículos > Coberturas de armazenamento para veículos",
  },
  {
    value: "5414",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Coberturas para veículos > Coberturas de armazenamento para veículos > Coberturas de armazenamento para automóveis",
  },
  {
    value: "5415",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Coberturas para veículos > Coberturas de armazenamento para veículos > Coberturas de armazenamento para carrinhos de golfe",
  },
  {
    value: "5416",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Coberturas para veículos > Coberturas de armazenamento para veículos > Coberturas de armazenamento para motas",
  },
  {
    value: "5417",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Coberturas para veículos > Coberturas de armazenamento para veículos > Coberturas de armazenamento para veículos recreativos",
  },
  {
    value: "5418",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Coberturas para veículos > Coberturas de armazenamento para veículos > Coberturas de armazenamento para embarcações",
  },
  {
    value: "5419",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos",
  },
  {
    value: "5420",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Autocolantes de para-choques",
  },
  {
    value: "5421",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Ambientadores para veículos",
  },
  {
    value: "5422",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Bolas de antena para veículos",
  },
  {
    value: "5423",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Acessórios de tablier para veículos",
  },
  {
    value: "5424",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Autocolantes para veículos",
  },
  {
    value: "5425",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Conjuntos de acessórios para decoração de veículos",
  },
  {
    value: "5426",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Bandeiras para veículos",
  },
  {
    value: "5427",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Emblemas e ornamentos de capot para veículos",
  },
  {
    value: "5428",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Coberturas de gancho de reboque para veículos",
  },
  {
    value: "5429",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Coberturas de matrícula para veículos",
  },
  {
    value: "5430",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Molduras de matrícula para veículos",
  },
  {
    value: "5431",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Suportes de matrícula para veículos",
  },
  {
    value: "5432",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Matrículas para veículos",
  },
  {
    value: "5433",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Imanes para veículos",
  },
  {
    value: "5434",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Ornamentos de retrovisor para veículos",
  },
  {
    value: "5435",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Foles da manete de velocidades para veículos",
  },
  {
    value: "5436",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Punhos da manete de velocidades para veículos",
  },
  {
    value: "5437",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Coberturas de volante para veículos",
  },
  {
    value: "5438",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Decoração de veículos > Vinil para veículos",
  },
  {
    value: "5439",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos",
  },
  {
    value: "5440",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos > Anticongelante para veículos",
  },
  {
    value: "5441",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos > Fluido de travões para veículos",
  },
  {
    value: "5442",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos > Aditivos de sistema de refrigeração para veículos",
  },
  {
    value: "5443",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos > Desengordurantes de motor para veículos",
  },
  {
    value: "5444",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos > Produtos de limpeza do sistema de combustível para veículos",
  },
  {
    value: "5445",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos > Massas lubrificantes para veículos",
  },
  {
    value: "5446",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos > Fluido de embraiagem hidráulica para veículos",
  },
  {
    value: "5447",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos > Óleo de motor para veículos",
  },
  {
    value: "5448",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos > Aditivos de desempenho para veículos",
  },
  {
    value: "5449",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos > Fluido de direção assistida para veículos",
  },
  {
    value: "5450",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos > Fluido de transmissão para veículos",
  },
  {
    value: "5451",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Fluidos para veículos > Fluido de para-brisas para veículos",
  },
  {
    value: "5452",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Tinta para veículos",
  },
  {
    value: "5453",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Tinta para veículos > Tinta de carroçaria para veículos motorizados",
  },
  {
    value: "5454",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Tinta para veículos > Tinta de pinças de travões para veículos motorizados",
  },
  {
    value: "5455",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Ferramentas especializadas e de reparação para veículos",
  },
  {
    value: "5456",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Ferramentas especializadas e de reparação para veículos > Conjuntos de reparação de travões para veículos motorizados",
  },
  {
    value: "5457",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Ferramentas especializadas e de reparação para veículos > Ferramentas de remoção e alinhamento de embraiagem para veículos motorizados",
  },
  {
    value: "5458",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Ferramentas especializadas e de reparação para veículos > Carregadores de bateria para veículos",
  },
  {
    value: "5459",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Ferramentas especializadas e de reparação para veículos > Testadores de bateria para veículos",
  },
  {
    value: "5460",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Ferramentas especializadas e de reparação para veículos > Massa de enchimento para veículos",
  },
  {
    value: "5461",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Ferramentas especializadas e de reparação para veículos > Máquinas de diagnóstico para veículos",
  },
  {
    value: "5462",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Ferramentas especializadas e de reparação para veículos > Dispositivos auxiliares de arranque para veículos",
  },
  {
    value: "5463",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Ferramentas especializadas e de reparação para veículos > Cabos auxiliares de arranque para veículos",
  },
  {
    value: "5464",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Ferramentas especializadas e de reparação para veículos > Ferramentas de mudança e reparação de pneus para veículos",
  },
  {
    value: "5465",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Decoração, cuidado e manutenção de veículos > Ferramentas especializadas e de reparação para veículos > Conjuntos de reparação de para-brisas",
  },
  {
    value: "5466",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos",
  },
  {
    value: "5467",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para motas",
  },
  {
    value: "5468",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para motas > Protetores de peito e costas para motas",
  },
  {
    value: "5469",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para motas > Proteções de pulso e cotovelo para motas",
  },
  {
    value: "5470",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para motas > Luvas para motas",
  },
  {
    value: "5471",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para motas > Óculos para motas",
  },
  {
    value: "5472",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para motas > Proteções de mãos para motas",
  },
  {
    value: "5473",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para motas > Acessórios e peças de capacetes para motas",
  },
  {
    value: "5474",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para motas > Capacetes para motas",
  },
  {
    value: "5475",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para motas > Cintas abdominais para motas",
  },
  {
    value: "5476",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para motas > Proteções de canela e joelho para motas",
  },
  {
    value: "5477",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para motas > Proteções de pescoço para motas",
  },
  {
    value: "5478",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para veículos todo-o-terreno",
  },
  {
    value: "5479",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de proteção para veículos todo-o-terreno > Esponja para barras de ATV e UTV",
  },
  {
    value: "5480",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos",
  },
  {
    value: "5481",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos > Acessórios de alarmes para automóveis",
  },
  {
    value: "5482",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos > Sistemas de alarme para automóveis",
  },
  {
    value: "5483",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos > Fechos e alarmes para motas",
  },
  {
    value: "5484",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos > Peças e fechos de portas para veículos",
  },
  {
    value: "5485",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos > Peças e fechos de portas para veículos > Atuadores de fechos de portas para veículos",
  },
  {
    value: "5486",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos > Peças e fechos de portas para veículos > Manípulos de fechos de portas para veículos",
  },
  {
    value: "5487",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos > Peças e fechos de portas para veículos > Fechos e sistemas de fecho de portas para veículos",
  },
  {
    value: "5488",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos > Fechos de reboque para veículos",
  },
  {
    value: "5489",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos > Imobilizadores para veículos",
  },
  {
    value: "5490",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos > Sistemas remotos sem chave para veículos",
  },
  {
    value: "5491",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos > Bloqueios de volante para veículos",
  },
  {
    value: "5492",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Fechos e alarmes para veículos > Grampos de rodas para veículos",
  },
  {
    value: "5493",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de segurança para veículos",
  },
  {
    value: "5494",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de segurança para veículos > Redes de janelas para carros",
  },
  {
    value: "5495",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de segurança para veículos > Foguete de sinalização de emergência",
  },
  {
    value: "5496",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de segurança para veículos > Peças de airbag para veículos motorizados",
  },
  {
    value: "5497",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de segurança para veículos > Barras e roll cages para veículos motorizados",
  },
  {
    value: "5498",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de segurança para veículos > Fivelas de cinto de segurança para veículos",
  },
  {
    value: "5499",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de segurança para veículos > Coberturas de cinto de segurança para veículos",
  },
  {
    value: "5500",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de segurança para veículos > Fitas de cinto de segurança para veículos",
  },
  {
    value: "5501",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de segurança para veículos > Cintos de segurança para veículos",
  },
  {
    value: "5502",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de segurança para veículos > Bandeiras de sinalização para veículos",
  },
  {
    value: "5503",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Segurança e proteção para veículos > Equipamento de segurança para veículos > Calços de rodas para veículos",
  },
  {
    value: "5504",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos",
  },
  {
    value: "5505",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Redes de carga para veículos motorizados",
  },
  {
    value: "5506",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Acessórios de suportes de carga para veículos motorizados",
  },
  {
    value: "5507",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Acessórios de suportes de carga para veículos motorizados > Acessórios de porta-bicicletas para veículos",
  },
  {
    value: "5508",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Acessórios de suportes de carga para veículos motorizados > Acessórios de suportes de esqui/snowboard para veículos",
  },
  {
    value: "5509",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Suportes de carga para veículos motorizados",
  },
  {
    value: "5510",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Suportes de carga para veículos motorizados > Sistemas de base de suporte para veículos",
  },
  {
    value: "5511",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Suportes de carga para veículos motorizados > Porta-bicicletas para veículos",
  },
  {
    value: "5512",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Suportes de carga para veículos motorizados > Suportes de barcos para veículos",
  },
  {
    value: "5513",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Suportes de carga para veículos motorizados > Suportes de carga para veículos",
  },
  {
    value: "5514",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Suportes de carga para veículos motorizados > Suportes de canas de pesca para veículos",
  },
  {
    value: "5515",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Suportes de carga para veículos motorizados > Suportes de armas para veículos",
  },
  {
    value: "5516",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Suportes de carga para veículos motorizados > Suportes de motas e scooters para veículos",
  },
  {
    value: "5517",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Suportes de carga para veículos motorizados > Suportes de esqui/snowboard para veículos",
  },
  {
    value: "5518",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Suportes de carga para veículos motorizados > Suportes de pranchas de desportos aquáticos para veículos",
  },
  {
    value: "5519",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Rampas de carregamento para veículos motorizados",
  },
  {
    value: "5520",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Atrelados para veículos motorizados",
  },
  {
    value: "5521",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Atrelados para veículos motorizados > Atrelados para barcos",
  },
  {
    value: "5522",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Atrelados para veículos motorizados > Atrelados para cavalos e gado",
  },
  {
    value: "5523",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Atrelados para veículos motorizados > Atrelados de viagem",
  },
  {
    value: "5524",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Atrelados para veículos motorizados > Atrelados de carga",
  },
  {
    value: "5525",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Malas e bolsas para motas",
  },
  {
    value: "5526",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Organizadores e caixas de arrumação para mala de carrinha",
  },
  {
    value: "5527",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Ganchos e cabides de apoios para cabeça de veículos",
  },
  {
    value: "5528",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Armazenamento e carga de veículos > Organizadores para veículos",
  },
  {
    value: "5529",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações",
  },
  {
    value: "5530",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Atracagem e ancoragem",
  },
  {
    value: "5531",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Atracagem e ancoragem > Correntes de âncoras",
  },
  {
    value: "5532",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Atracagem e ancoragem > Cordas e linhas de âncoras",
  },
  {
    value: "5533",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Atracagem e ancoragem > Guinchos para âncoras",
  },
  {
    value: "5534",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Atracagem e ancoragem > Âncoras",
  },
  {
    value: "5535",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Atracagem e ancoragem > Ganchos para barcos",
  },
  {
    value: "5536",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Atracagem e ancoragem > Escadas para barcos",
  },
  {
    value: "5537",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Atracagem e ancoragem > Cunhos para docas",
  },
  {
    value: "5538",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Atracagem e ancoragem > Degraus para docas",
  },
  {
    value: "5539",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças para veleiros",
  },
  {
    value: "5540",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Cuidado de embarcações",
  },
  {
    value: "5541",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Cuidado de embarcações > Produtos de limpeza de embarcações",
  },
  {
    value: "5542",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Cuidado de embarcações > Polimento de embarcações",
  },
  {
    value: "5543",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de motor para embarcações",
  },
  {
    value: "5544",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de motor para embarcações > Alternadores para embarcações",
  },
  {
    value: "5545",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de motor para embarcações > Peças e carburadores para embarcações",
  },
  {
    value: "5546",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de motor para embarcações > Controlos de motor para embarcações",
  },
  {
    value: "5547",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de motor para embarcações > Peças de ignição para embarcações",
  },
  {
    value: "5548",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de motor para embarcações > Turbinas para embarcações",
  },
  {
    value: "5549",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de motor para embarcações > Bloqueios de motor para embarcações",
  },
  {
    value: "5550",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de motor para embarcações > Apoios de motor para embarcações",
  },
  {
    value: "5551",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de motor para embarcações > Peças e pistões para embarcações",
  },
  {
    value: "5552",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de motor para embarcações > Hélices para embarcações",
  },
  {
    value: "5553",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Motores para embarcações",
  },
  {
    value: "5554",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de escape para embarcações",
  },
  {
    value: "5555",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de escape para embarcações > Coletores para embarcações",
  },
  {
    value: "5556",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de escape para embarcações > Peças e panelas para embarcações",
  },
  {
    value: "5557",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Sistemas de combustível para embarcações",
  },
  {
    value: "5558",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Sistemas de combustível para embarcações > Peças e linhas de combustível para embarcações",
  },
  {
    value: "5559",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Sistemas de combustível para embarcações > Indicadores de combustível para embarcações",
  },
  {
    value: "5560",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Sistemas de combustível para embarcações > Peças e bombas de combustível para embarcações",
  },
  {
    value: "5561",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Sistemas de combustível para embarcações > Peças e depósitos de combustível para embarcações",
  },
  {
    value: "5562",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Iluminação para embarcações",
  },
  {
    value: "5563",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de direção para embarcações",
  },
  {
    value: "5564",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de direção para embarcações > Cabos de direção para embarcações",
  },
  {
    value: "5565",
    inputValue:
      "Veículos e peças > Acessórios e peças para veículos > Acessórios e peças para embarcações > Peças de direção para embarcações > Volantes para embarcações",
  },
  { value: "5566", inputValue: "Veículos e peças > Veículos" },
  { value: "5567", inputValue: "Veículos e peças > Veículos > Aeronave" },
  {
    value: "5568",
    inputValue: "Veículos e peças > Veículos > Veículos motorizados",
  },
  {
    value: "5569",
    inputValue:
      "Veículos e peças > Veículos > Veículos motorizados > Carros, camiões e carrinhas",
  },
  {
    value: "5570",
    inputValue:
      "Veículos e peças > Veículos > Veículos motorizados > Carrinhos de golfe",
  },
  {
    value: "5571",
    inputValue:
      "Veículos e peças > Veículos > Veículos motorizados > Motas e scooters",
  },
  {
    value: "5572",
    inputValue:
      "Veículos e peças > Veículos > Veículos motorizados > Veículos todo-o-terreno",
  },
  {
    value: "5573",
    inputValue:
      "Veículos e peças > Veículos > Veículos motorizados > Veículos todo-o-terreno > ATV e UTV",
  },
  {
    value: "5574",
    inputValue:
      "Veículos e peças > Veículos > Veículos motorizados > Veículos todo-o-terreno > Karts e buggies",
  },
  {
    value: "5575",
    inputValue:
      "Veículos e peças > Veículos > Veículos motorizados > Veículos recreativos",
  },
  {
    value: "5576",
    inputValue:
      "Veículos e peças > Veículos > Veículos motorizados > Motas de neve",
  },
  { value: "5577", inputValue: "Veículos e peças > Veículos > Embarcação" },
  {
    value: "5578",
    inputValue: "Veículos e peças > Veículos > Embarcação > Barcos a motor",
  },
  {
    value: "5579",
    inputValue: "Veículos e peças > Veículos > Embarcação > Embarcação pessoal",
  },
  {
    value: "5580",
    inputValue: "Veículos e peças > Veículos > Embarcação > Veleiros",
  },
  {
    value: "5581",
    inputValue: "Veículos e peças > Veículos > Embarcação > Iates",
  },
  { value: "5582", inputValue: "Cartões de oferta" },
  {
    value: "5583",
    inputValue:
      "Acessórios e vestuário > Vestuário > Saias > Saias pelos Joelhos",
  },
  {
    value: "5584",
    inputValue: "Acessórios e vestuário > Vestuário > Saias > Saias compridas",
  },
  {
    value: "5585",
    inputValue: "Acessórios e vestuário > Vestuário > Saias > Mini Saias",
  },
  {
    value: "5586",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Kimono de exterior > Casacos Haori",
  },
  {
    value: "5587",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Kimono de exterior > Casacos Quimono",
  },
  {
    value: "5588",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Kimonos > Quimonos de Noiva",
  },
  {
    value: "5589",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Kimonos > Quimonos Casuais",
  },
  {
    value: "5590",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Kimonos > Quimonos Furisode",
  },
  {
    value: "5591",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Kimonos > Quimonos Iromuji",
  },
  {
    value: "5592",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Kimonos > Quimonos Komon",
  },
  {
    value: "5593",
    inputValue:
      "Acessórios e vestuário > Vestuário > Vestuário tradicional e de cerimónia > Kimonos > Quimonos Tomesode e Houmongi",
  },
  {
    value: "5594",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de vestuário tradicional > Roupas interiores em Quimono",
  },
  {
    value: "5595",
    inputValue:
      "Acessórios e vestuário > Acessórios de vestuário > Acessórios de vestuário tradicional > Acessórios Obi",
  },
];

export default array;
