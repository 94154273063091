import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../images/sapi-logo.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { loginPost } from "../api/requests/Login";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";

function Login() {
  //Variáveis
  const [email, setEmail] = useState("");
  const [palavraPasse, setPalavraPasse] = useState("");

  //Validações
  const [erroEmail, setErroEmail] = useState(false);
  const [erroPassword, setErroPassword] = useState(false);
  const [mensagemErroEmail, setMensagemErroEmail] = useState("");
  const [mensagemErroPalavraPasse, setMensagemErroPalavraPasse] = useState("");
  const [sucessoEmail, setSucessoEmail] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //Traduções
  const { t, i18n } = useTranslation();

  //Navigator
  const navigate = useNavigate();

  useEffect(() => {
    if (email) {
      //Validar email - obrigatóriamente da tectank
      let validacaoEmail = /^\"?[\w-_\.]*\"?@tectank\.pt$/.test(email);
      if (validacaoEmail) {
        setErroEmail(false);
        setErroEmail("");
        setMensagemErroEmail("");
        setSucessoEmail("success");
      } else {
        let validacaoFormatoEmail = /\S+@\S+\.\S+/.test(email);

        if (validacaoFormatoEmail) {
          setErroEmail(true);
          setMensagemErroEmail(t("naoExisteConta"));
        } else {
          setErroEmail(true);
          setMensagemErroEmail(t("emailInvalido"));
        }
      }
    }
  }, [email, palavraPasse]);

  async function VerificaLogin() {
    if (email && palavraPasse && !erroEmail) {
      const hashedPassword = bcrypt.hashSync(
        palavraPasse,
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      );
      await loginPost({
        email: email,
        password: hashedPassword,
      }).then(async (res) => {
        if (res.success) {
          //Guardar id e nome do utilizador
          localStorage.setItem("id", res.data.id);
          localStorage.setItem("nome", res.data.nome);

          //Definir cookie
          //Tempo em dias - 1hora
          Cookies.set("token", res.data.accessToken, { expires: 0.0416666667 });

          toast.success(
            t("loginSucesso") +
              " " +
              t("bemVindoUtilizador") +
              " " +
              res.data.nome +
              "!"
          );
          navigate("/home");
          // const id = await toast.loading(t("autenticacao"));
          // setTimeout(() => {
          //   toast.update(id, {
          //     render: t("loginSucesso"),
          //     type: toast.TYPE.SUCCESS,
          //     isLoading: false,
          //     limit: 1,
          //     autoClose: 3000,
          //     closeOnClick: true,
          //     closeButton: true,
          //   });
          // }, 3000);
        } else {
          toast.error(res.error);
        }
      });
    } else {
      if (!email && palavraPasse) {
        setErroEmail(true);
        setMensagemErroEmail(t("emailErro"));
      } else if (!palavraPasse && email) {
        setErroPassword(true);
        setMensagemErroPalavraPasse(t("palavraPasseErro"));
      } else if (!email && !palavraPasse) {
        setErroEmail(true);
        setMensagemErroEmail(t("emailErro"));
        setMensagemErroPalavraPasse(t("palavraPasseErro"));
        setErroPassword(true);
      }
    }
  }

  // const CloseButton = ({ closeToast }) => <FontAwesomeIcon icon={faClose} />;

  return (
    <div className="bodyLogin">
      <form class="log-in">
        <h4>
          <img src={logo} style={{ width: "150px", height: "49px" }} />
          {/* Tectank <span>SAPI</span> */}
        </h4>
        <h5>
          <span>{t("iniciarSessao")}</span>
        </h5>
        <p>{t("bemVindo")}</p>
        {/* Email */}
        <TextField
          className="inputlogin"
          style={{ marginBottom: "10px" }}
          id="email"
          label={t("email")}
          variant="standard"
          required={true}
          placeholder="sapi@tectank.pt"
          error={erroEmail}
          helperText={mensagemErroEmail}
          color={sucessoEmail}
          onChange={(e) => {
            setEmail(e.target.value.toLowerCase());
          }}
        />
        {/* Palavra-passe */}
        <TextField
          className="inputlogin"
          style={{ marginBottom: "10px" }}
          id="password"
          label={t("palavraPasse")}
          variant="standard"
          error={erroPassword}
          type={showPassword ? "text" : "password"}
          helperText={mensagemErroPalavraPasse}
          onChange={(e) => {
            setPalavraPasse(e.target.value);
            setErroPassword(false);
            setMensagemErroPalavraPasse("");
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  disableRipple
                  disableFocusRipple
                  style={{
                    justifyContent: "right",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    color: "#8A8A8A",
                  }}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </Button>
              </InputAdornment>
            ),
          }}
          required={true}
        ></TextField>
        <p
          onClick={() => {
            navigate("/recoverpassword");
          }}
          className="recoverPassword"
          style={{ font: "2px", marginTop: "10px" }}
        >
          {t("recuperarPalavraPasse")}
        </p>
        <p
          onClick={() => {
            navigate("/sign-up");
          }}
          className="recoverPassword"
          style={{ font: "2px" }}
        >
          {t("criarConta")}
        </p>
        <button
          className="button"
          type="button"
          onClick={() => {
            VerificaLogin();
          }}
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            borderRadius: 50,
          }}
        >
          {t("iniciarSessao")}
        </button>
      </form>
      <div class="left"></div>
      <Footer />
    </div>
  );
}
export default Login;
